import * as ActionTypes from '../actions/uiAction';
import { REHYDRATE } from 'redux-persist';

const { RESET_ERROR_MESSAGE, ADD_ERROR_MESSAGE, RESET_SUCCESS_MESSAGE, SHOW_SUCCESS_MESSAGE, APP_LOADING, APP_READY, NAV_TAB_UPDATE, UPDATE_NAV_TO_CART, UPDATE_IS_MOBILE, UPDATE_SHOW_SIDEMENU, UPDATE_SHOW_APPHEADER, UPDATE_SHOW_APPFOOTER } = ActionTypes
const UIReducer = (state = { loading: false, errorMessage: null, successMessage: null, currNavTab: 'landing', navToCart: false, isMobile: false, showSidemenu: true, showAppHeader: true, showAppFooter: true }, action) => {
    switch (action.type) {
        /** Message UI **/
        case REHYDRATE:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
            };

        case RESET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: null
            };

        case UPDATE_SHOW_SIDEMENU:
            return {
                ...state,
                showSidemenu: action.data
            };

        case UPDATE_SHOW_APPHEADER:
            return {
                ...state,
                showAppHeader: action.data
            };

        case UPDATE_SHOW_APPFOOTER:
            return {
                ...state,
                showAppFooter: action.data
            };

        case ADD_ERROR_MESSAGE:
            const { err = null } = action;
            let errorMessage = "Unknown error occurred.  Please try again.";
            if (err && err.message) {
                errorMessage = err.message
            } else if (err && err.error) {
                errorMessage = err.error
            } else if (err && err.errors) {
                errorMessage = err.errors;
            } else if (typeof err === 'string') {
                errorMessage = err
            }
            // don't show err for session timeout
            if (errorMessage.indexOf('There is no access token send to server') >= 0) {
                return state;
            }
            return {
                ...state,
                errorMessage
            };

        case RESET_SUCCESS_MESSAGE:
            return {
                ...state,
                successMessage: null
            };

        case SHOW_SUCCESS_MESSAGE:
            return {
                ...state,
                successMessage: action.message || 'Success'
            };

        /** LOADING **/
        case APP_LOADING:
            return {
                ...state,
                loading: true
            };
        case APP_READY:
            return {
                ...state,
                loading: false
            };
        case NAV_TAB_UPDATE:
            return {
                ...state,
                currNavTab: action.data
            };
        case UPDATE_NAV_TO_CART:
            return {
                ...state,
                navToCart: action.data
            };
        case UPDATE_IS_MOBILE:
            return {
                ...state,
                isMobile: action.data
            };
        default:
            return state;
    }
};

export default UIReducer;