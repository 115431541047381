import React from "react";

import FormCardContainer from "./components/FormCardContainer";
import ResetPasswordForm from "../../Components/Form/ResetPassword/ResetPasswordForm";

const ResetPasswordPage = () => {
  return (
    <FormCardContainer>
      <ResetPasswordForm />
    </FormCardContainer>
  );
};

export default ResetPasswordPage;
