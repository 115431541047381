export const handleRemoveTableRow = async (
  items,
  setItems,
  selectedIndexes,
  setSelectedIndexes,
  removeFn,
  handleClose
) => {
  const deletedIds = [];
  const permanent = true;
  await Promise.all(
    selectedIndexes.map(async dataIndex => {
      const data = await removeFn(items[dataIndex]._id, permanent);
      if (data) {
        deletedIds.push(items[dataIndex]._id);
      }
    })
  );

  setItems(items =>
    items.filter(category => !deletedIds.includes(category._id))
  );
  setSelectedIndexes([]);
  handleClose();
};
