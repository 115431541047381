import React from "react";
import { Helmet } from "react-helmet";
import FormCardContainer from "./components/FormCardContainer";
import LoginForm from "../../Components/Form/Login/LoginForm";

const LoginPage = ({}) => {
  return (
    <>
      <Helmet>
        <title>QBank Login Page</title>
        <meta
          name="description"
          content="QBank Sign Up Page allowed existing user to login to their user account with user email and password."
        />
      </Helmet>

      <FormCardContainer>
        <LoginForm />
      </FormCardContainer>
    </>
  );
};

export default LoginPage;
