import React from "react";
import { CURRENCY_FORMAT } from "../../../constants";

const ReceiptTotalTable = ({ transaction }) => {
  const orderTotal = `${CURRENCY_FORMAT}${
    Number(transaction.term.price) ? transaction.term.price.toFixed(2) : "0.00"
  }`;
  const paidTotal = `${CURRENCY_FORMAT}${transaction.total_price.toFixed(2)}`;

  return (
    <table align="right">
      <tbody>
        <tr>
          <td colSpan="2" align="right" style={{ padding: 0 }}>
            <div>
              <div></div>
              <table className="table table-bordered" style={{ width: "auto" }}>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "right" }}>Subtotal</td>
                    <td>{orderTotal}</td>
                  </tr>
                  {Boolean(transaction.discount) && transaction.discount !== 0 && (
                    <tr>
                      <td style={{ textAlign: "right" }}>Discount</td>
                      <td>{`${CURRENCY_FORMAT}-${transaction.discount}`}</td>
                    </tr>
                  )}

                  <tr>
                    <td style={{ textAlign: "right", width: 135 }}>
                      <b>Grand Total</b>
                    </td>
                    <td style={{ width: 135 }}>
                      <b>{paidTotal}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ReceiptTotalTable;
