import { makeStyles } from "@material-ui/styles";
import { PAYMENY_SECTION_BUTTON_STYLING } from "../../../../styles/constants";

export default makeStyles(theme => ({
  orderSummary: {
    backgroundColor: "#C4C4C426",
  },
  leftPlaceholder: {
    width: 40,
    height: "100%",
  },
  crossIcon: {
    width: 40,
    height: 40,
    cursor: "pointer",
  },
  paramsRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  line: {
    borderTop: `1px solid ${theme.palette.primary.main}`,
    width: "100%",
  },
  button: {
    ...PAYMENY_SECTION_BUTTON_STYLING,
  },
  discountCodeToggleIcon: {
    fontSize: 20,
    cursor: "pointer",
  },
  applyButton: {
    height: "100% !important",
  },
  discountCodeInputBox: {
    borderTop: "1px solid #d3d3d4",
    borderBottom: "1px solid #d3d3d4",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  discountCodeRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  paymentMethodIcon: {
    objectFit: "contain",
    width: "fit-content",
  },
}));
