export default {
  root: {
    height: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  sortIcon: {
    marginBottom: 3,
    width: 16,
    height: 16,
  },
  lightGreyText: {
    color: "#979797 !important",
    fontFamily: "metropolis",
  },
  noHover: {
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  showColumnsContainer: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    paddingTop: "25px",
    paddingBottom: "25px",
    color: "#979797",
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: "#f9f9f9 !important",
    },
  },
  scoreBox: {
    borderRadius: "30px",
    paddingTop: "12px",
    paddingBottom: "12px",
    width: "42px",
    textAlign: "center",
    fontSize: "13px",
    backgroundColor: "#e8e8e8",
  },
  centerContainer: {
    display: "flex",
    alignItems: "center",
  },
  hyphen: {
    margin: "0 5px",
  },
  icon: {
    width: "14px",
    height: "14px",
    cursor: "pointer",
  },
  editIcon: {
    marginLeft: "12px",
  },
  doneIcon: {
    marginRight: "4px",
    color: "green",
    width: "20px",
    height: "20px",
  },

  actionIcon: {
    width: "20px",
    height: "20px",
    cursor: "pointer",
  },
};
