import { Box } from "@material-ui/core";
import React from "react";
import CategoryTable from "./Table/CategoryTable";
import FeedbackTable from "./Table/FeedbackTable";
import MediaTable from "./Table/MediaTable";
import QuestionTable from "./Table/QuestionTable";
import TopicTable from "./Table/TopicTable";
import UserTable from "./Table/UserTable";
import VoucherTable from "./Table/VoucherTable";

const ManageDeletedItem = () => {
  return (
    <div className="pageCont manageMediaPage">
      <div className="bodyCont">
        <div className="pageContent">
          <Box mb={4}>
            <MediaTable />
          </Box>
          <Box mb={4}>
            <TopicTable />
          </Box>
          <Box mb={4}>
            <CategoryTable />
          </Box>
          <Box mb={4}>
            <UserTable />
          </Box>
          <Box mb={4}>
            <QuestionTable />
          </Box>
          <Box mb={4}>
            <FeedbackTable />
          </Box>
          <Box mb={4}>
            <VoucherTable />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default ManageDeletedItem;
