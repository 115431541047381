import clsx from "clsx";
import React from "react";

import useStyles from "./styles";
import { stepArrowIcon } from "../../../../../resources/assets";

const StepConnector = props => {
  const { active, completed } = props;
  const classes = useStyles({ active });

  return (
    <div className={classes.root}>
      {active && (
        <img className={classes.arrow} alt="Arrow icon" src={stepArrowIcon} />
      )}
      <span
        className={clsx(
          classes.line,
          (Boolean(active) || Boolean(completed)) && classes.lineActive
        )}
      ></span>
    </div>
  );
};

export default StepConnector;
