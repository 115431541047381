import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { Editor } from "@tinymce/tinymce-react";

import { AppConfig } from "../../../configs";
import styles from "../FlashcardModal/styles";
import { renderFormCharLimit } from "../../../utils";

// Constants
const MAX_CHAR = 2000;

const useStyles = makeStyles(theme => styles(theme));

const DoubleSideForm = props => {
  const {
    isDarkMode,
    watchAllFields,
    setValue,
    editingFront,
    startEditingFrontView,
    startEditingBackView,
    onExchangeContent,
    bindWithCategories,
  } = props;
  const classes = useStyles();

  return (
    <div
      className={clsx(
        bindWithCategories
          ? classes.categorySideFormCont
          : classes.sideFormCont,
        classes.doubleSideViewCont
      )}
    >
      <div className={classes.sideCardCont}>
        <div
          className={clsx(
            classes.cardFace,
            classes.mlAuto,
            editingFront
              ? classes.cardFaceActiveLight
              : isDarkMode
              ? classes.cardFaceActiveDark
              : classes.cardFaceLight
          )}
          onClick={startEditingFrontView}
        >
          Front
        </div>
        <div
          className={clsx(
            classes.cardFormBody,
            !editingFront
              ? isDarkMode
                ? classes.cardFormBodyInActiveDark
                : classes.cardFormBodyInActiveLight
              : null,

            !editingFront && classes.cardFormBodyInActive
          )}
          onClick={startEditingFrontView}
        >
          {editingFront ? (
            <Editor
              apiKey={AppConfig.tinyMceApiKey}
              value={watchAllFields.front}
              init={{
                height: "100%",
                content_style:
                  "* { line-height: 1.15 } ::-webkit-scrollbar {width: 5px; height: 5px;} ::-webkit-scrollbar-thumb { background: #cdcdcd; }::-webkit-scrollbar-track { background: none;} ",
                statusbar: false,
                menubar: false,
                branding: false,

                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help ",
                ],
                toolbar:
                  "bold italic underline | bullist numlist outdent indent |",
                toolbar_location: "bottom",
              }}
              onEditorChange={(content, _editor) => {
                setValue("front", content, {
                  shouldValidate: true,
                });
              }}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: watchAllFields.front,
              }}
            />
          )}
        </div>
        <span className={classes.editorFooterText}>
          {renderFormCharLimit(watchAllFields.front, MAX_CHAR)}
        </span>
      </div>
      <FontAwesomeIcon
        onClick={() => {
          onExchangeContent(watchAllFields);
        }}
        icon={faExchangeAlt}
        color={isDarkMode ? "#fff" : "rgba(74,74,74,.7)"}
        className={classes.exchangeIcon}
      />
      <div className={classes.sideCardCont}>
        <div
          className={clsx(
            classes.cardFace,
            classes.mlAuto,
            !editingFront
              ? classes.cardFaceActiveLight
              : isDarkMode
              ? classes.cardFaceActiveDark
              : classes.cardFaceLight
          )}
          onClick={startEditingBackView}
        >
          Back
        </div>
        <div
          className={clsx(
            classes.cardFormBody,
            editingFront
              ? isDarkMode
                ? classes.cardFormBodyInActiveDark
                : classes.cardFormBodyInActiveLight
              : null,
            editingFront && classes.cardFormBodyInActive
          )}
          onClick={startEditingBackView}
        >
          {!editingFront ? (
            <Editor
              apiKey={AppConfig.tinyMceApiKey}
              value={watchAllFields.back}
              init={{
                height: "100%",
                content_style:
                  "* { line-height: 1.15 } ::-webkit-scrollbar {width: 5px; height: 5px;} ::-webkit-scrollbar-thumb { background: #cdcdcd; }::-webkit-scrollbar-track { background: none;} ",
                statusbar: false,
                menubar: false,
                branding: false,

                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help ",
                ],
                toolbar:
                  "bold italic underline | bullist numlist outdent indent |",
                toolbar_location: "bottom",
              }}
              onEditorChange={(content, _editor) => {
                setValue("back", content, {
                  shouldValidate: true,
                });
              }}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: watchAllFields.back,
              }}
            />
          )}
        </div>
        <span className={classes.editorFooterText}>
          {renderFormCharLimit(watchAllFields.back, MAX_CHAR)}
        </span>
      </div>
    </div>
  );
};

export default DoubleSideForm;
