import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import RedeemOutlinedIcon from "@material-ui/icons/RedeemOutlined";
import { parseDateHtml } from "../../../../utils";
import useStyles from "./styles";
import MUICustomHeader from "../../../../Components/MUICustomHeader";
import { selectVoucherList } from "../../../../selectors/voucher";
import {
  getVoucherList,
  removeVoucher,
} from "../../../../actions/voucherAction";
import useModal from "../../../../hooks/useModal";
import ConfirmationModal from "../../../../Components/Modal/ConfirmationModal";
import { DELETED_STATUS_FILTER } from "../constants";
import TableTitle from "./TableTitle";

const VoucherTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const vouchers = useSelector(selectVoucherList);

  const { open, handleOpen, handleClose } = useModal();
  const handleOpenDeleteModal = () => {
    if (selectedIndexes.length > 0) {
      handleOpen();
    }
  };

  const handleRemoveVoucher = () => {
    const permanent = true;
    const byDeleted = true;

    selectedIndexes.map((dataIndex, i) => {
      let shouldRefresh = true;
      if (i !== selectedIndexes.length - 1) {
        shouldRefresh = false;
      }
      dispatch(
        removeVoucher(
          vouchers[dataIndex]._id,
          permanent,
          shouldRefresh,
          byDeleted
        )
      );
    });
    setSelectedIndexes([]);
    handleClose();
  };

  useEffect(() => {
    dispatch(getVoucherList(DELETED_STATUS_FILTER));
  }, [dispatch]);

  if (!vouchers) return null;

  const sortedVouchers = vouchers;

  return (
    <>
      <ConfirmationModal
        open={open}
        onClose={handleClose}
        actionFn={handleRemoveVoucher}
        ctaTitle={"Are you wish to permanently delete these discount code(s)?"}
        ctaTxt={"Delete"}
      />
      <TableTitle
        title="Discount Code"
        handleOpenDeleteModal={handleOpenDeleteModal}
      />
      <Fragment>
        <MUIDataTable
          data={vouchers.map((voucher, i) => [
            i + 1,
            voucher.code,
            voucher._createdOn,
            voucher._expiredOn,
            voucher.redeemedCount,
          ])}
          columns={[
            {
              name: "No",
              options: {
                customHeadRender: columnMeta => {
                  return (
                    <MUICustomHeader label={columnMeta.label}>
                      <>
                        {columnMeta.label}
                        <FormatListNumberedIcon
                          className={classes.thIcon}
                          color="secondary"
                        />
                      </>
                    </MUICustomHeader>
                  );
                },
              },
            },
            {
              name: "Code",
              options: {
                customHeadRender: columnMeta => {
                  return (
                    <MUICustomHeader label={columnMeta.label}>
                      <>
                        {columnMeta.label}
                        <LabelOutlinedIcon
                          className={classes.thIcon}
                          color="secondary"
                        />
                      </>
                    </MUICustomHeader>
                  );
                },
              },
            },
            {
              name: "Created On",
              options: {
                customHeadRender: columnMeta => {
                  return (
                    <MUICustomHeader label={columnMeta.label}>
                      {columnMeta.label}
                      <CalendarTodayOutlinedIcon
                        className={classes.thIcon}
                        color="secondary"
                      />
                    </MUICustomHeader>
                  );
                },
                customBodyRenderLite: dataIndex => {
                  let val = sortedVouchers[dataIndex]._createdOn;
                  return parseDateHtml(val, 130);
                },
              },
            },
            {
              name: "Expires On",
              options: {
                customHeadRender: columnMeta => {
                  return (
                    <MUICustomHeader label={columnMeta.label}>
                      <>
                        {columnMeta.label}
                        <EventOutlinedIcon
                          className={classes.thIcon}
                          color="secondary"
                        />
                      </>
                    </MUICustomHeader>
                  );
                },
                customBodyRenderLite: dataIndex => {
                  let val = sortedVouchers[dataIndex]._expiredOn;
                  return parseDateHtml(val);
                },
              },
            },
            {
              name: "Code Used Count",
              options: {
                customHeadRender: columnMeta => {
                  return (
                    <MUICustomHeader label={columnMeta.label}>
                      <>
                        {columnMeta.label}
                        <RedeemOutlinedIcon
                          className={classes.thIcon}
                          color="secondary"
                        />
                      </>
                    </MUICustomHeader>
                  );
                },
              },
            },
          ]}
          options={{
            download: false,
            print: false,
            setRowProps: (row, dataIndex, rowIndex) => {
              return {
                style: {
                  backgroundColor:
                    rowIndex % 2 !== 0 ? "#fff" : "rgba(0, 0, 0, 0.04)",
                },
              };
            },
            onRowSelectionChange: (
              currentRowsSelected,
              allRowsSelected,
              rowsSelected
            ) => {
              setSelectedIndexes(rowsSelected);
            },
            customToolbarSelect: () => {},
            rowsSelected: selectedIndexes,
          }}
        />
      </Fragment>
    </>
  );
};

export default VoucherTable;
