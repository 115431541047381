// Handle Logout
export const PRODUCT_LIST_ADD = "PRODUCT_LIST_ADD";
export const productListAdd = (data) => ({
    type: PRODUCT_LIST_ADD,
    data
});
export const addProductList = (data) => (dispatch, getState) => {
    const { grandTotal } = getState().ProductStore
    const params = {
        productList: data,
        grandTotal: grandTotal + data.price
    }
    dispatch(productListAdd(params))
};

export const PRODUCT_LIST_UPDATE = "PRODUCT_LIST_UPDATE";
export const productListUpdate = (data) => ({
    type: PRODUCT_LIST_UPDATE,
    data
});
export const updateProductList = (data) => (dispatch) => {
    const params = {
        productList: data,
        grandTotal: data.reduce((acc, curr) => acc + curr.price, 0)
    }
    dispatch(productListUpdate(params))
};
