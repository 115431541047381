import countryList from "./constants/country.json";
import stateList from "./constants/state.json";

const compare = (a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

const findEntryByCode = (source, code) => {
  if (code && source != null) {
    const codex = source.findIndex(c => {
      return c.isoCode === code;
    });
    return codex !== -1 ? source[codex] : undefined;
  }
  return undefined;
};

export const findStateByCodeAndCountryCode = (source, code, countryCode) => {
  if (code && countryCode && source != null) {
    const codex = source.findIndex(c => {
      return c.isoCode === code && c.countryCode === countryCode;
    });
    return codex !== -1 ? source[codex] : undefined;
  }
  return undefined;
};

function getCountryByCode(isoCode) {
  if (!isoCode) return undefined;

  return findEntryByCode(countryList, isoCode);
}

// Get a list of all countries.
function getAllCountries() {
  return countryList;
}

// Get a list of states belonging to a specific country.
function getStatesOfCountry(countryCode) {
  if (!countryCode) return [];
  const states = stateList.filter(value => {
    return value.countryCode === countryCode;
  });
  return states.sort(compare);
}

// Find a country by it's ISO code and the country in which it is contained.
function getStateByCodeAndCountry(stateCode, countryCode) {
  if (!stateCode) return undefined;
  if (!countryCode) return undefined;

  return findStateByCodeAndCountryCode(stateList, stateCode, countryCode);
}

export {
  getCountryByCode,
  getAllCountries,
  getStatesOfCountry,
  getStateByCodeAndCountry,
};
