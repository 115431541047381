import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";

import * as NoteActions from "../../actions/noteAction";
import * as FlashcardActions from "../../actions/flashcardAction";
import styles from "./styles";
import { orderByArr } from "./noteListArr";
import NoteCard from "./NoteCard";
import FilterMenu from "../../Components/FilterMenu";
import { CustomTablePagination } from "../../Components/CustomTableElements";
import {
  NOTE_ORDER_BY_TYPE,
  FLASHCARD_MODE_TYPE,
  FLASHCARD_LIST_TYPE,
} from "../../constants";
import { sortNotes, filterNotesByKeyWord } from "./notesParser";
import SearchInput from "../../Components/SearchInput";
import ImagePreviewModal from "../TestDetailPage/modal/themedModal/ImagePreviewModal";
import FlashcardListModal from "../TestDetailPage/modal/themedModal/FlashcardListModal";

const useStyles = makeStyles(theme => styles(theme));

const NoteList = ({
  getNotes,
  removeNote,
  getFlashcards,
  removeFlashcard,
  createFlashcard,
  updateFlashcard,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [notes, setNotes] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [orderBy, setOrderBy] = useState(NOTE_ORDER_BY_TYPE["QUESTION_ID"]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showImagePreview, setShowImagePreview] = useState({
    show: false,
    imgAttr: null,
  });
  // FlashcardList related Functions Start here
  const [selectedQuestionID, setSelectedQuestionID] = useState(null);
  const [showFlashcardListModal, setShowFlashcardListModal] = useState(false);
  const [
    openFlashcardListModalState,
    setOpenFlashcardListModalState,
  ] = useState({
    mode: FLASHCARD_MODE_TYPE["MAIN"],
  });
  const [flashcardsData, setFlashcardsData] = useState(null);
  const [searchedFlashcardsData, setSearchedFlashcardsData] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedSystems, setSelectedSystems] = useState([]);
  const [anchorFilterMenu, setAnchorFilterMenu] = useState(null);
  const [applyCategoriesFilter, setApplyCategoriesFilter] = useState(false);
  const [filteredTotal, setFilteredTotal] = useState(0);

  const fetchFlashcards = async () => {
    const res = await getFlashcards();
    if (res && res.flashcards) {
      setFlashcardsData(res.flashcards);
    }
  };

  const fetchFlashcardsByCategoriesFilter = async () => {
    const filter = {
      category_ids: selectedSubjects,
      subCategory_ids: selectedSystems,
    };

    const { flashcards } = await getFlashcards(filter);
    if (flashcards) {
      setFlashcardsData(flashcards);
      setFilteredTotal(flashcards.length);
    }
  };

  const onRemoveFlashcard = async flashcardID => {
    const res = await removeFlashcard(flashcardID);
    if (res && res.removeFlashcard) {
      const newFlashcardsData = flashcardsData.filter(
        flashcard => flashcard._id !== flashcardID
      );

      setFlashcardsData(newFlashcardsData);
    }
    return res;
  };
  const onCreateFlashcard = async data => {
    const res = await createFlashcard(data);
    if (res && res.createFlashcard) {
      let tempFlashcardsData = flashcardsData;
      tempFlashcardsData.unshift(res.createFlashcard);
      setFlashcardsData(tempFlashcardsData);
    }
  };
  const onUpdateFlashcard = async data => {
    const res = await updateFlashcard(data);
    if (res && res.updateFlashcard) {
      // Update Whole List
      let index = flashcardsData.findIndex(
        flashcard => flashcard._id === res.updateFlashcard._id
      );
      let tempFlashcardsData = flashcardsData;
      tempFlashcardsData.splice(index, 1, res.updateFlashcard);
      setFlashcardsData(tempFlashcardsData);

      // Update Search List
      if (
        openFlashcardListModalState.listType === FLASHCARD_LIST_TYPE["SEARCH"]
      ) {
        let searchListIndex = searchedFlashcardsData.findIndex(
          flashcard => flashcard._id === res.updateFlashcard._id
        );
        let tempFlashcardsData = searchedFlashcardsData;
        tempFlashcardsData.splice(searchListIndex, 1, res.updateFlashcard);
        setSearchedFlashcardsData(tempFlashcardsData);
      }
    }
  };
  // Handle Filter Menu Start
  const handleOpenFilterMenu = event => {
    setAnchorFilterMenu(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setAnchorFilterMenu(null);
  };
  // Handle Filter Menu Start
  // FlashcardList related Functions End here

  // Notes Functions Start here
  const fetchNotes = async () => {
    const { notes } = await getNotes();
    if (notes) {
      setNotes(notes.filter(note => note.status === "active"));
    }
  };
  const onRemoveNote = async id => {
    const res = await removeNote(id);
    if (res.removeNote) {
      setNotes(notes.filter(note => note._id !== id));
    }
  };
  // Notes Functions End here

  // Handle OrderBy Menu Start
  const handleOpenMenu = event => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleOrderBy = type => {
    setOrderBy(type);
  };
  const renderSelectedOrderBy = () => {
    switch (orderBy) {
      case NOTE_ORDER_BY_TYPE["QUESTION_ID"]:
        return "Question ID";
      case NOTE_ORDER_BY_TYPE["SUBJECT"]:
        return "Subject";
      case NOTE_ORDER_BY_TYPE["SYSTEM"]:
        return "System";
    }
  };
  // Handle OrderBy Menu End

  // Handle Pagination Start
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Handle Pagination End

  const arrowIconBtnProps = {
    classes: {
      root: classes.noHover,
    },
    disableFocusRipple: true,
    disableRipple: true,
  };

  useEffect(() => {
    fetchNotes();
    fetchFlashcards();
  }, []);

  if (!notes) return null;

  const paginatedNotes = sortNotes(
    filterNotesByKeyWord(notes, searchKeyword),
    orderBy
  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Fragment>
      <div>
        {/* Image Preview Modal */}
        <ImagePreviewModal
          visible={showImagePreview.show}
          imgAttr={showImagePreview.imgAttr}
          isDarkMode={false}
          customPos={true}
          onClose={() => setShowImagePreview({ show: false, imgAttr: null })}
          openFlashcardListModalState={openFlashcardListModalState}
          setOpenFlashcardListModalState={setOpenFlashcardListModalState}
          setShowFlashcardListModal={setShowFlashcardListModal}
        />

        {/* FlashcardList Modal */}
        {flashcardsData && (
          <FlashcardListModal
            visible={showFlashcardListModal}
            isDarkMode={false}
            customPos={{ x: 50, y: 0 }}
            flashcardsData={flashcardsData}
            currentQuestionID={selectedQuestionID}
            openModalState={openFlashcardListModalState}
            setOpenModalState={setOpenFlashcardListModalState}
            searchedFlashcardsData={searchedFlashcardsData}
            setSearchedFlashcardsData={setSearchedFlashcardsData}
            onClose={() => {
              setShowFlashcardListModal(false);
              setOpenFlashcardListModalState({
                mode: FLASHCARD_MODE_TYPE["MAIN"],
              });
            }}
            onRemoveFlashcard={onRemoveFlashcard}
            onCreateFlashcard={onCreateFlashcard}
            onUpdateFlashcard={onUpdateFlashcard}
            handleOpenFilterMenu={handleOpenFilterMenu}
            handleCloseFilterMenu={handleCloseFilterMenu}
            anchorFilterMenu={anchorFilterMenu}
            selectedSubjects={selectedSubjects}
            selectedSystems={selectedSystems}
            setSelectedSubjects={setSelectedSubjects}
            setSelectedSystems={setSelectedSystems}
            setApplyCategoriesFilter={setApplyCategoriesFilter}
            applyCategoriesFilter={applyCategoriesFilter}
            fetchFlashcards={fetchFlashcards}
            fetchFlashcardsByCategoriesFilter={
              fetchFlashcardsByCategoriesFilter
            }
            filteredTotal={filteredTotal}
          />
        )}

        <Paper elevation={0} className={classes.root}>
          <Fragment>
            <div className={classes.topContainer}>
              <Grid
                container
                justify="space-between"
                spacing={3}
                direction={smDown ? "column" : "row"}
              >
                <Grid item>
                  <SearchInput
                    onChange={e => setSearchKeyword(e.target.value)}
                    value={searchKeyword}
                  />
                </Grid>
                <Grid item>
                  <div style={{ marginTop: 20 }}>
                    <FilterMenu
                      handleOpenMenu={handleOpenMenu}
                      handleCloseMenu={handleCloseMenu}
                      anchorEl={anchorElMenu}
                      menuItemArr={orderByArr}
                      text={renderSelectedOrderBy()}
                      selectedFilter={orderBy}
                      handleSelectedFilter={handleOrderBy}
                      filterType={"STRING"}
                      showCheckBox={false}
                      showText="Sort By"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={classes.notePageContentHeader}>
              {notes.length} Total Notes
            </div>
          </Fragment>

          {
            <Fragment>
              {notes.length > 0 && (
                <Fragment>
                  {paginatedNotes.map(note => (
                    <NoteCard
                      key={note._id}
                      note={note}
                      removeNote={onRemoveNote}
                      showQuestion={true}
                      setShowImagePreview={setShowImagePreview}
                      setSelectedQuestionID={setSelectedQuestionID}
                    />
                  ))}
                </Fragment>
              )}
              <Table>
                <TableFooter>
                  <TableRow>
                    <CustomTablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        50,
                        100,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={8}
                      count={notes.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage={"Items per page"}
                      SelectProps={{
                        inputProps: { "aria-label": "Items per page" },
                        native: true,
                      }}
                      style={{
                        borderBottom: "none",
                      }}
                      backIconButtonProps={arrowIconBtnProps}
                      nextIconButtonProps={arrowIconBtnProps}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Fragment>
          }
        </Paper>
      </div>
    </Fragment>
  );
};
const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getNotes: () => dispatch(NoteActions.getNotes()),
  removeNote: id => dispatch(NoteActions.removeNote(id)),
  // FlashcardList related
  getFlashcards: filter => dispatch(FlashcardActions.getFlashcards(filter)),
  createFlashcard: data => dispatch(FlashcardActions.createFlashcard(data)),
  updateFlashcard: data => dispatch(FlashcardActions.updateFlashcard(data)),
  removeFlashcard: id => dispatch(FlashcardActions.removeFlashcard(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NoteList)
);
