import React, { Fragment, useEffect, useState } from "react";
import { Box, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import CardMembershipOutlinedIcon from "@material-ui/icons/CardMembershipOutlined";
import EventNoteOutlinedIcon from "@material-ui/icons/EventNoteOutlined";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import * as UserAction from "../../../actions/userAction";
import UserInfoModal from "../modal/Userinfo/UserInfoModal";
import {
  CustomDeleteIcon,
  CustomEditIcon,
  CustomViewIcon,
} from "../../../Components/CustomActionIcons";
import {
  CustomConfirmDialogActions,
  CustomActionBtn,
  CustomCancelBtn,
} from "../../../Components/CustomDialogElements";
import {
  parseCapitalizeHtml,
  parseDateHtml,
  parseUsername,
} from "../../../utils";
import { COURSE_APP_SUB_ROUTE } from "../../../routes/constants";
import MUICustomHeader from "../../../Components/MUICustomHeader";
import useStyles from "./styles";
import {
  parseUserEmailHtml,
  parseUserPlanHtml,
  parseUserStatusHtml,
} from "./helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTag } from "@fortawesome/free-solid-svg-icons";
import { RESOURCE_STATUS } from "../../../constants";
import BlockUserSection from "../UserForm/components/BlockUserSection";
import { BlockIcon } from "./components/BlockIcon";

const ManageUser = ({ history, getUsersList, removeUser }) => {
  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);

  // Handle Confirmtion Modal Start
  const handleOpenDeleteModal = event => {
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleOpenBlockModal = event => {
    setShowBlockModal(true);
  };

  const handleCloseBlockModal = () => {
    setShowBlockModal(false);
  };
  // Handle Confirmtion Modal End

  const fetchUsers = async () => {
    const res = await getUsersList();
    if (res && res.users) {
      setUsers(res.users);
    }
  };

  const onRemoveUser = async () => {
    const selectedID = selectedUser._id;
    const res = await removeUser(selectedID);
    if (res) {
      setUsers(users.filter(user => user._id !== selectedID));
    }
    handleCloseDeleteModal();
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Fragment>
      {/* Delete User Confirmation Modal */}
      <Dialog onClose={handleCloseDeleteModal} open={showDeleteModal}>
        <DialogContent>
          Are you sure you wish to delete this user?
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomActionBtn onClick={onRemoveUser}>Delete</CustomActionBtn>
          <CustomCancelBtn onClick={handleCloseDeleteModal}>
            Cancel
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>

      {/* Block User Modal */}
      <Dialog onClose={handleCloseBlockModal} open={showBlockModal}>
        <DialogContent>
          <BlockUserSection
            user={selectedUser}
            handleCloseModal={handleCloseBlockModal}
            fetchUsers={fetchUsers}
          />
        </DialogContent>
      </Dialog>

      <div className="pageCont manageUserPage">
        <div className="bodyCont">
          <div className="pageAction">
            <div className="pageActionRight">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  history.push(`${COURSE_APP_SUB_ROUTE}/manage-user/invite`);
                }}
              >
                Invite User
              </Button>
            </div>
          </div>
          <div className="pageContent">
            <MUIDataTable
              data={users.map(user => [
                user._ref,
                parseUsername(user.firstName, user.lastName),
                user.email,
                user.status,
                user.subscription,
                user.type,
                user._createdOn,
                user._lastAccessOn,
                user._loginHistory,
              ])}
              columns={[
                {
                  name: "ID",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <FormatListNumberedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                  },
                },
                {
                  name: "Username",
                  options: {
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <PersonOutlineOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                  },
                },
                {
                  name: "Email",
                  options: {
                    customBodyRenderLite: dataIndex => {
                      let email = users[dataIndex].email;
                      let emailHistory = users[dataIndex]._emailHistory;
                      return parseUserEmailHtml(email, emailHistory);
                    },
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <EmailOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                  },
                },
                {
                  name: "Status",
                  options: {
                    customBodyRender: value => {
                      return parseUserStatusHtml(value);
                    },
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <VerifiedUserOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                  },
                },
                {
                  name: "Plan",
                  options: {
                    customBodyRender: value => {
                      return (
                        <div style={{ width: 80 }}>
                          {parseUserPlanHtml(value)}
                        </div>
                      );
                    },

                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <CardMembershipOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                  },
                },
                {
                  name: "Role",
                  options: {
                    customBodyRender: value => {
                      return parseCapitalizeHtml(value);
                    },
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <FontAwesomeIcon
                              icon={faUserTag}
                              className={classes.faIcon}
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                  },
                },
                {
                  name: "Registration",
                  options: {
                    customBodyRenderLite: dataIndex => {
                      let val = users[dataIndex]._createdOn;
                      return parseDateHtml(val, 130);
                    },
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <CalendarTodayOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                    setCellHeaderProps: () => ({
                      className: "globalCustomMuiTableHead",
                    }),
                  },
                },
                {
                  name: "Last Login",
                  options: {
                    customBodyRenderLite: dataIndex => {
                      let val = users[dataIndex]._lastAccessOn;
                      return parseDateHtml(val);
                    },
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <EventNoteOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                    setCellHeaderProps: () => ({
                      className: "globalCustomMuiTableHead",
                    }),
                  },
                },
                {
                  name: "# Logins",
                  options: {
                    customBodyRender: value => {
                      return (
                        <Box width={80}>
                          {Array.isArray(value) ? value.length : 0}
                        </Box>
                      );
                    },
                    customHeadRender: columnMeta => {
                      return (
                        <MUICustomHeader label={columnMeta.label}>
                          <>
                            {columnMeta.label}
                            <VpnKeyOutlinedIcon
                              className={classes.thIcon}
                              color="secondary"
                            />
                          </>
                        </MUICustomHeader>
                      );
                    },
                  },
                },
                {
                  name: "Action",
                  options: {
                    customBodyRenderLite: dataIndex => {
                      return (
                        <div className="actionDiv">
                          <CustomViewIcon
                            onClickHandler={() => {
                              setSelectedUser(users[dataIndex]);
                              setShowInfoModal(show => !show);
                            }}
                          />
                          <CustomEditIcon
                            onClickHandler={() => {
                              history.push(
                                `${COURSE_APP_SUB_ROUTE}/manage-user/${users[dataIndex]._id}/edit`
                              );
                            }}
                            style={{ marginLeft: 8 }}
                          />
                          <CustomDeleteIcon
                            onClickHandler={() => {
                              setSelectedUser(users[dataIndex]);
                              handleOpenDeleteModal();
                            }}
                            style={{ marginLeft: 8 }}
                          />
                          <BlockIcon
                            user={users[dataIndex]}
                            onClick={() => {
                              setSelectedUser(users[dataIndex]);
                              handleOpenBlockModal();
                            }}
                            style={{ marginLeft: 8 }}
                          />
                        </div>
                      );
                    },
                  },
                },
              ]}
              options={{
                download: false,
                print: false,
                selectableRows: "none",
              }}
            />
          </div>
        </div>
        <UserInfoModal
          visible={showInfoModal}
          user={selectedUser}
          onClose={() => setShowInfoModal(false)}
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getUsersList: () => dispatch(UserAction.getUsersList()),
  removeUser: id => dispatch(UserAction.removeUser(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageUser)
);
