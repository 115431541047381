const SERVER_ERROR = {
  NO_MATCHED_RECORD: "The query return no match record",
  USER_SUBSCRIPTION_PLAN_HAS_NOT_ACTIVATED_ERROR: "You have an inactive",
};

const ERROR_CODE = {
  VALIDATION_ERROR: "VALIDATION_ERROR",
  ACCOUNT_HAS_NOT_ACTIVATED: "ACCOUNT_HAS_NOT_ACTIVATED",
  NO_MATCHED_RECORD: "NO_MATCHED_RECORD",
  INVALID_PASSWORD: "INVALID_PASSWORD",
};

export { SERVER_ERROR, ERROR_CODE };
