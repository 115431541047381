import {
  DARK_FIFTH_COLOR,
  COLOR_WHITE,
  DARK_FOURTH_COLOR,
  LIGHT_FONT_COLOR,
} from "../../../styles/constants";

const PRIMARY_LIGHT_FONT_COLOR = "#212529";
const PRIMARY_LIGHT_BG_COLOR = "#f3f3f4";
const SECONDARY_LIGHT_FONT_COLOR = "#212529";

const styles = theme => ({
  filterMenu: {
    width: 275,
    maxHeight: 400,
    backgroundColor: props =>
      props.isDarkMode ? DARK_FOURTH_COLOR : COLOR_WHITE,
    color: props => (props.isDarkMode ? COLOR_WHITE : LIGHT_FONT_COLOR),
  },
  title: {
    color: props => (props.isDarkMode ? COLOR_WHITE : PRIMARY_LIGHT_FONT_COLOR),
  },
  subTitle: {
    fontSize: "14px",
    color: props =>
      props.isDarkMode ? COLOR_WHITE : SECONDARY_LIGHT_FONT_COLOR,
  },
  categoryOptionsCont: {
    color: props => (props.isDarkMode ? COLOR_WHITE : PRIMARY_LIGHT_FONT_COLOR),
    backgroundColor: props =>
      props.isDarkMode ? DARK_FIFTH_COLOR : PRIMARY_LIGHT_BG_COLOR,
  },
  uncheckedIcon: {
    color: props => (props.isDarkMode ? COLOR_WHITE : ""),
  },
  rootClearBtn: {
    fontSize: "1.05em",
  },
  disabledClearBtn: {
    color: props =>
      props.isDarkMode ? `#ffffff99 !important` : `#00000061 !important`,
  },
  checkboxLabel: {
    fontSize: 14,
  },
});

export default styles;
