import React, { useState, useEffect } from "react";
import { Rnd } from "react-rnd";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { close1Icon } from "../../../../../resources/assets";
import Calculator from "./Calculator";
import styles from "../styles";

const useStyles = makeStyles(theme => styles(theme));

const DEFAULT_SIZE = { width: "263px", height: "450px" };

const CalculatorModal = ({ visible, onClose }) => {
  const theme = useTheme();
  const classes = useStyles();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const y = window.innerHeight - 480;
  const DEFAULT_POSITION = {
    x: window.innerWidth - 420,
    y,
    p: "a",
  };

  const DEFAULT_MD_POSITION = {
    x: window.innerWidth - 310,
    y,
    p: "a",
  };

  const DEFAULT_XS_POSITION = {
    x: window.innerWidth > 300 ? window.innerWidth - 300 : 0,
    y,
    p: "c",
  };

  const PRESET_SIZE = DEFAULT_SIZE;

  const PRESET_POSITION = xsDown
    ? DEFAULT_XS_POSITION
    : mdDown
    ? DEFAULT_MD_POSITION
    : DEFAULT_POSITION;

  const [position, setPosition] = useState(PRESET_POSITION);

  // Reset position when calculator modal mounted/unmounted
  useEffect(() => {
    setPosition(PRESET_POSITION);
  }, [visible]);

  if (!visible) return null;

  return (
    <Rnd
      className={classes.zIndex2}
      bounds={"window"}
      enableResizing={false}
      size={PRESET_SIZE}
      position={{ x: position.x, y: position.y }}
      onDragStop={(e, d) => {
        setPosition({ x: d.x, y: d.y });
      }}
      enableUserSelectHack={false}
    >
      <div className={classes.calculatorModal}>
        <div className={classes.calculatorTitleCont}>
          <div className={classes.calculatorTitle}>Calculator</div>
          <img
            src={close1Icon}
            alt="close icon"
            className={classes.calculatorQBIcon}
            onClick={onClose}
          />
        </div>
        <Calculator visible={visible} />
      </div>
    </Rnd>
  );
};

export default CalculatorModal;
