import React from "react";
import Container from "@material-ui/core/Container";
import usePath from "../../hooks/usePath";
import {
  FORGOT_PASSWORD_URL,
  LOGIN_URL,
  RESET_PASSWORD_URL,
} from "../../routes/constants";

const NavContainer = props => {
  const { children } = props;
  const { fullPath } = usePath();
  const shouldHideContainer =
    fullPath === LOGIN_URL ||
    fullPath === RESET_PASSWORD_URL ||
    fullPath === FORGOT_PASSWORD_URL;

  return shouldHideContainer ? (
    children
  ) : (
    <Container fixed maxWidth={false} {...props}>
      {children}
    </Container>
  );
};

export default NavContainer;
