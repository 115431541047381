import { Button, TextField } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";

import * as MediaAction from "../../actions/mediaAction";
import { MANAGE_MEDIA_URL } from "../../routes/constants";

const MediaForm = ({ match, getMediaById, updateMedia }) => {
  const { handleSubmit, control, reset, errors } = useForm({
    mode: "onChange",
    defaultValues: {
      title: "",
    },
  });
  const history = useHistory();
  const mediaId = match.params.id;
  const fetchMediaById = async () => {
    if (!mediaId) return;
    const res = await getMediaById(mediaId);

    if (res && res.media)
      reset({
        title: res.media.title,
      });
    else return history.push(MANAGE_MEDIA_URL);
  };

  useEffect(() => {
    fetchMediaById();
  }, []);

  const onSubmitUpdate = async ({ title }) => {
    const data = {
      title,
    };
    const res = await updateMedia({
      _id: mediaId,
      input: data,
    });
    if (res && res.updateMedia) {
      history.push(MANAGE_MEDIA_URL);
    }
  };

  return (
    <Fragment>
      <div className="pageCont">
        <div className="bodyCont">
          <div className="pageAction">
            <div className="pageActionLeft">
              <Link to={MANAGE_MEDIA_URL}>
                <FontAwesomeIcon icon={faReply} />
                Back to media list
              </Link>
            </div>
          </div>
          <div className="pageContent">
            <h4 className="pageTitle hiddenWeb hiddenTablet">Update Media</h4>
            <h3 className="pageTitle hiddenMobile">Update Media</h3>
            <form onSubmit={handleSubmit(onSubmitUpdate)}>
              <Controller
                control={control}
                name="title"
                rules={{
                  required: "Title cannot be empty",
                }}
                render={({ onChange, _onBlur, value, name, ref }) => (
                  <TextField
                    name={name}
                    label="Title"
                    inputRef={ref}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={!!errors.title}
                    helperText={errors.title && errors.title.message}
                    InputProps={{
                      style: { fontSize: 15 },
                    }}
                    InputLabelProps={{
                      style: { fontSize: 15, color: "rgba(0, 0, 0, 0.25)" },
                    }}
                    FormHelperTextProps={{
                      style: { fontSize: 13 },
                    }}
                    fullWidth
                    color="secondary"
                  />
                )}
              />
            </form>
          </div>
          <Button
            onClick={handleSubmit(onSubmitUpdate)}
            color="secondary"
            variant="contained"
            className="submitButton"
            size="large"
            style={{ marginTop: 40 }}
          >
            Update
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getMediaById: id => dispatch(MediaAction.getMediaById(id)),
  updateMedia: data => dispatch(MediaAction.updateMedia(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaForm);
