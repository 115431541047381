import { gql } from "@apollo/client";

export const GET_MEDIAS = gql`
  query Medias($filter: StatusFilterInput) {
    medias(filter: $filter) {
      _id
      filename
      size
      url
      title
      question {
        _id
        question
      }
      lesson {
        _id
        title
      }
      creator {
        firstName
        lastName
      }
      status
      _createdOn
    }
  }
`;

export const GET_MEDIA = gql`
  query Media($_id: ObjectId!) {
    media(_id: $_id) {
      _id
      filename
      size
      url
      title
      question {
        _id
        question
      }
      lesson {
        _id
        title
      }
      creator {
        firstName
        lastName
      }
      status
      _createdOn
    }
  }
`;

export const CREATE_MEDIA = gql`
  mutation CreateMedia($input: CreateMediaInput!) {
    createMedia(input: $input) {
      _id
    }
  }
`;

export const UPDATE_MEDIA = gql`
  mutation UpdateMedia($_id: ObjectId!, $input: UpdateMediaInput) {
    updateMedia(_id: $_id, input: $input) {
      _id
      filename
      size
      url
      title
      question_id
      question {
        _id
        question
      }
      lesson_id
      lesson {
        _id
        title
      }
      creator {
        firstName
        lastName
      }
      status
      _createdOn
    }
  }
`;

export const REMOVE_MEDIA = gql`
  mutation RemoveMedia($_id: ObjectId!, $permanent: Boolean) {
    removeMedia(_id: $_id, permanent: $permanent)
  }
`;
