import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  footerContainer: {
    backgroundColor: "#323232",
    color: "#fff",
    minHeight: 600,
    height: "100%",
    marginTop: "auto",
  },
  logo: {
    width: 200,
  },
  footerContent: {
    paddingTop: theme.spacing(8),
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  readMoreBtn: {
    width: 150,
    height: 45,
    borderRadius: 10,
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.5em",
  },
  contactLogo: {
    width: 25,
    height: 25,
  },
  infoSection: {
    height: 70,
  },
  info: {
    fontSize: "1em",
  },
  bottomSection: {
    marginTop: 90,
    backgroundColor: "#323232",
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
}));
