import React from "react";
import { useSelector } from "react-redux";
import { PrimaryButton } from "../../../../Components/CustomButton";
import { selectStripeOrderConfig } from "../../../../selectors/cart";

const StripePaymentButton = () => {
  const stripeOrderConfig = useSelector(selectStripeOrderConfig);
  const navigateToStripeCheckout = () => {
    window.location.href = stripeOrderConfig.url;
  };

  return (
    <PrimaryButton onClick={navigateToStripeCheckout}>
      Pay with Stripe
    </PrimaryButton>
  );
};

export default StripePaymentButton;
