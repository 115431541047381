export const COURSE_APP_PAGE_ID = "courseapp";
export const COURSE_APP_SUB_ROUTE = `/${COURSE_APP_PAGE_ID}`;
export const APP_PAGE_ID = "app";
export const APP_SUB_ROUTE = `/${APP_PAGE_ID}`;
export const idParams = ":id";
export const codeParams = ":code";

//Url Path
// Public
export const LOGIN_URL = "/login";
export const ACTIVATE_USER_SUB_PATH = "/user/activate";
export const ACTIVATE_USER_URL = `${ACTIVATE_USER_SUB_PATH}/${idParams}/${codeParams}`;
export const RESET_PASSWORD_URL = "/reset-password";
export const FORGOT_PASSWORD_URL = "/forgot-password";

export const REDIRECT_CHECKOUT_QUERY_PARAM = "redirect_checkout";
export const QUERY_PARAM_TRUE_VALUE = "true";

// Course app
export const WELCOME_URL = `${COURSE_APP_SUB_ROUTE}/welcome`;
export const TEST_LIST_URL = `${COURSE_APP_SUB_ROUTE}/test-list`;
export const TEST_DETAIL_URL = `${COURSE_APP_SUB_ROUTE}/test-detail`;
export const TEST_DETAIL_FULL_URL = `${COURSE_APP_SUB_ROUTE}/test-detail/${idParams}`;
export const CREATE_TEST_URL = `${COURSE_APP_SUB_ROUTE}/create-test`;
export const TOPICS_URL = `${COURSE_APP_SUB_ROUTE}/topics`;
export const HELP_URL = `${COURSE_APP_SUB_ROUTE}/help`;
export const TEST_RESULT_URL = `${COURSE_APP_SUB_ROUTE}/test-result/${idParams}`;
export const TEST_ANALYSIS_URL = `${COURSE_APP_SUB_ROUTE}/test-analysis/${idParams}`;
export const PERFORMANCE_OVERALL_URL = `${COURSE_APP_SUB_ROUTE}/performance/overall`;
export const NOTE_LIST_URL = `${COURSE_APP_SUB_ROUTE}/notes`;
export const FLASHCARD_LIST_URL = `${COURSE_APP_SUB_ROUTE}/flashcards`;

// Admin
export const MANAGE_TRANSACTION_URL = `${COURSE_APP_SUB_ROUTE}/manage-transaction`;
export const EDIT_TRANSACTION_URL = `${COURSE_APP_SUB_ROUTE}/manage-transaction/${idParams}/edit`;
export const MANAGE_DISCOUNT_CODE_URL = `${COURSE_APP_SUB_ROUTE}/manage-discount-code`;
export const EDIT_DISCOUNT_CODE_URL = `${COURSE_APP_SUB_ROUTE}/manage-discount-code/${idParams}/edit`;
export const CREATE_DISCOUNT_CODE_URL = `${COURSE_APP_SUB_ROUTE}/manage-discount-code/create`;
export const MANAGE_FEEDBACK_URL = `${COURSE_APP_SUB_ROUTE}/manage-feedback`;
export const MANAGE_MEDIA_URL = `${COURSE_APP_SUB_ROUTE}/manage-media`;
export const EDIT_USER_URL = `${COURSE_APP_SUB_ROUTE}/manage-user/${idParams}/edit`;
export const MANAGE_USER_URL = `${COURSE_APP_SUB_ROUTE}/manage-user`;
export const MANAGE_DELETED_ITEM_URL = `${COURSE_APP_SUB_ROUTE}/manage-deleted-item`;

// User Profile
export const CHECKOUT_URL = `${APP_SUB_ROUTE}/checkout`;
export const SIGNUP_HASH = "#signup";
export const CHECKOUT_SIGNUP_URL = `${CHECKOUT_URL}/${SIGNUP_HASH}`;
export const LOGIN_HASH = "#login";
export const CHECKOUT_LOGIN_URL = `${CHECKOUT_URL}/${LOGIN_HASH}`;
export const PRICING_URL = `${APP_SUB_ROUTE}/pricing`;
export const CART_URL = `${APP_SUB_ROUTE}/cart`;
export const SUBSCRIPTIONS_URL = `${APP_SUB_ROUTE}/subscriptions`;
export const PROFILE_URL = `${APP_SUB_ROUTE}/profile`;
export const BILLING_URL = `${APP_SUB_ROUTE}/billing`;
export const ORDER_RECEIPT_URL = "/order-receipt";
export const ORDER_SUCCESS_URL = `${APP_SUB_ROUTE}/order/success`;
