import { Box, Button } from "@material-ui/core";
import React, { useMemo } from "react";
import useStyles from "./styles";
import { paymentCrossIcon } from "../../../../resources/assets";
import {
  CURRENCY_CODE,
  CURRENCY_FORMAT,
  TRANSACTION_SOURCE,
} from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { selectUserAuthToken } from "../../../../selectors/user";
import { parseAuthToken } from "../../../../utils";
import { updateUserProfile } from "../../../../actions/userAction";
import {
  subscribePlanWithPaypal,
  subscribePlanWithStripe,
} from "../../../../actions/subscriptionAction";
import DiscountCodeInput from "./DiscountCodeInput";
import { selectVoucherAvailability } from "../../../../selectors/voucher";
import { checkIsFreeTrialPlan, getGrandTotalAndGrandDiscount } from "../helper";
import {
  selectIsValidToPay,
  selectPaymentMethod,
} from "../../../../selectors/cart";
import { setIsValidToPay } from "../../../../actions/cartAction";
import { useForm } from "react-hook-form";
import { setVoucherAvailability } from "../../../../actions/voucherAction";

const OrderSummary = ({ cartItems, handleOpenRemoveModal, handleSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authToken = useSelector(selectUserAuthToken);
  const voucherAvailability = useSelector(selectVoucherAvailability);
  const paymentMethod = useSelector(selectPaymentMethod);
  const isValidToPay = useSelector(selectIsValidToPay);

  const subTotal = useMemo(() => {
    if (cartItems.length > 0) {
      return cartItems.reduce((acc, curr) => acc + curr.price, 0);
    } else {
      return 0;
    }
  }, [cartItems]);
  const isVoucherApplied = voucherAvailability && voucherAvailability.valid;
  const discountUnit = isVoucherApplied ? voucherAvailability.unit : null;
  const discountAmount = isVoucherApplied ? voucherAvailability.discount : null;
  const { grandTotal, totalDiscount } = getGrandTotalAndGrandDiscount(
    subTotal,
    discountUnit,
    discountAmount
  );
  const showCTA = !isValidToPay && authToken;
  const isFreeTrialPlan = checkIsFreeTrialPlan(cartItems);

  const discountCodeInputMethods = useForm({
    mode: "onChange",
    defaultValues: {
      voucherCode: "",
    },
  });

  const onProceedCheckout = data => {
    if (authToken) {
      if (!isVoucherApplied) {
        discountCodeInputMethods.setValue("voucherCode", "");
        dispatch(setVoucherAvailability(null));
      }
      const userId = parseAuthToken(authToken);

      const updateProfileData = {
        _id: userId,
        input: { ...data },
      };

      const selectedPricePlan = cartItems[0];
      if (selectedPricePlan) {
        if (isFreeTrialPlan) {
          dispatch(updateUserProfile(updateProfileData));
          dispatch(setIsValidToPay(true));
        } else {
          const input = {
            plan_id: selectedPricePlan.plan_id,
            pricePlanID: selectedPricePlan.pricePlanID,
            voucherCode: isVoucherApplied
              ? voucherAvailability.code
              : undefined,
          };

          if (paymentMethod === TRANSACTION_SOURCE.PAYPAL) {
            dispatch(subscribePlanWithPaypal({ input }, updateProfileData));
          } else if (paymentMethod === TRANSACTION_SOURCE.STRIPE) {
            dispatch(subscribePlanWithStripe({ input }, updateProfileData));
          }
        }
      }
    }
  };

  return (
    <Box
      className={classes.orderSummary}
      display="flex"
      flexDirection="column"
      p={3}
    >
      <Box fontSize="1.8rem" mb={2}>
        Order Summary
      </Box>
      <Box mb={4}>
        {cartItems.map((item, index) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <img
                  className={classes.crossIcon}
                  src={paymentCrossIcon}
                  alt="Cross icon"
                  onClick={handleOpenRemoveModal}
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <Box fontWeight="600" fontSize="1rem" mb={1}>
                  {item.planName}
                </Box>
                <Box fontSize="0.9rem">{item.title}</Box>
              </Box>
            </Box>
            <Box
              display="flex"
              fontSize="1rem"
              color="primary.main"
              alignItems="center"
            >
              {`${CURRENCY_FORMAT} ${item.price.toFixed(2)}`}
            </Box>
          </Box>
        ))}
      </Box>
      <Box mb={2}>
        <Box component="span" fontSize="1.25rem">
          Summary
        </Box>{" "}
        <Box component="span" fontSize="0.9rem">
          ({CURRENCY_CODE})
        </Box>
      </Box>
      <Box display="flex" width="100%" mb={2}>
        <Box mr={1} display="flex" className={classes.leftPlaceholder}></Box>
        <Box fontSize="1rem" display="flex" flexDirection="column" flex={1}>
          <Box className={classes.paramsRow}>
            <Box display="flex" alignItems="center">
              <Box fontWeight="600">Subtotal</Box>
            </Box>
            <Box component="span" color="primary.main">
              {`${CURRENCY_FORMAT} ${subTotal.toFixed(2)}`}
            </Box>
          </Box>
          {isVoucherApplied && (
            <Box className={classes.paramsRow}>
              <Box display="flex" alignItems="center">
                <Box fontWeight="600">Discount</Box>
              </Box>
              <Box component="span" color="primary.main">
                {`${CURRENCY_FORMAT} -${totalDiscount}`}
              </Box>
            </Box>
          )}
          {authToken && !isFreeTrialPlan && (
            <DiscountCodeInput
              isValidToPay={isValidToPay}
              {...discountCodeInputMethods}
            />
          )}
        </Box>
      </Box>

      <Box mb={2} component="span" className={classes.line}></Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        fontSize="1.5rem"
        fontWeight="600"
        mb={showCTA ? 6 : 0}
      >
        <Box>Total</Box>
        <Box component="span" color="primary.main">
          {`${CURRENCY_FORMAT} ${grandTotal}`}
        </Box>
      </Box>
      {showCTA && (
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={handleSubmit(onProceedCheckout)}
        >
          Checkout
        </Button>
      )}
    </Box>
  );
};

export default OrderSummary;
