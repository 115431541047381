import { withStyles } from "@material-ui/core/styles";
import MUIAddIcon from "@material-ui/icons/Add";
import MUIRemoveIcon from "@material-ui/icons/Remove";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

import { LIGHTGREY_TEXT } from "../../styles/constants";

export const CustomTableCell = withStyles(theme => ({
  root: {
    fontFamily: "metropolis",
    fontSize: 13,
  },
}))(TableCell);

export const AddIcon = withStyles(theme => ({
  root: {
    color: "#ddd",
    marginTop: 8,
    cursor: "pointer",
  },
}))(MUIAddIcon);
export const RemoveIcon = withStyles(theme => ({
  root: {
    color: "#ddd",
    marginTop: 8,
    cursor: "pointer",
  },
}))(MUIRemoveIcon);

export const SubjectsRowCell = withStyles(theme => ({
  root: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
}))(CustomTableCell);

export const SystemsRowCell = withStyles(theme => ({
  root: {
    borderBottom: "none",
  },
}))(CustomTableCell);

export const CustomTableSortLabel = withStyles(theme => ({
  icon: {
    marginBottom: 3,
    width: 16,
    height: 16,
  },
  root: {
    ...LIGHTGREY_TEXT,
  },
  active: {
    ...LIGHTGREY_TEXT,
  },
}))(TableSortLabel);

export const CustomTablePagination = withStyles(theme => ({
  caption: {
    ...LIGHTGREY_TEXT,
  },
  select: {
    ...LIGHTGREY_TEXT,
  },
}))(TablePagination);

export const HoverSelectedTableRow = withStyles(theme => ({
  root: {
    cursor: "pointer",
  },
  hover: {
    "&:hover": {
      backgroundColor: "#f9f9f9 !important",
    },
  },
  selected: {
    backgroundColor: "#f9f9f9 !important",
  },
}))(TableRow);
