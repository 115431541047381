import React from "react";
import Container from "@material-ui/core/Container";

const CenterContainer = props => {
  const { children } = props;

  return (
    <Container fixed maxWidth={"lg"} {...props}>
      {children}
    </Container>
  );
};

export default CenterContainer;
