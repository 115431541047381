import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStylesPrimary = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.primary.lighter}`,
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: theme.palette.primary.lighter,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: theme.palette.primary.lighter,
    },
    "&$focused": {
      backgroundColor: theme.palette.primary.lighter,
      // boxShadow: `${theme.palette.primary.lighter} 0 0 0 2px`,
      // borderColor: theme.palette.primary.main,
    },
  },
  input: {
    "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active": {
      boxShadow: `0 0 0 30px ${theme.palette.primary.lighter} inset !important`,
    },
  },
  focused: {},
  textfieldRoot: {
    "& .MuiInputLabel-root": { color: "#00000080" },
  },
  disabled: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
  },
}));

export default function PrimaryTextField(props) {
  const { InputProps, ...rest } = props;
  const classes = useStylesPrimary();

  return (
    <TextField
      className={classes.textfieldRoot}
      InputProps={{
        classes: { ...classes, textfieldRoot: undefined },
        disableUnderline: true,
        ...InputProps,
      }}
      variant="filled"
      style={{ width: "100%" }}
      {...rest}
    />
  );
}
