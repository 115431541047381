import React from "react";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { ERROR_COLOR, SUCCESS_COLOR } from "../../styles/constants";

export const textFieldProps = {
  InputProps: {
    style: { fontSize: 15 },
  },
  InputLabelProps: {
    style: { fontSize: 15, color: "rgba(0, 0, 0, 0.25)" },
  },
  FormHelperTextProps: {
    style: { fontSize: 13 },
  },
  fullWidth: true,
  color: "secondary",
  style: { marginBottom: 8 },
};

export const parseBooleanValueHTML = val =>
  val ? (
    <DoneIcon style={{ color: SUCCESS_COLOR }} />
  ) : (
    <ClearIcon style={{ color: ERROR_COLOR }} />
  );

export const SELECT_INPUT_WIDTH = 250;
