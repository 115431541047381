
let env = '';
switch (process.env.NODE_ENV) {
    case 'development':
        env = 'stage';
        break;
    case 'stage':
        env = 'stage';
        break;
    case 'production':
        env = 'prod';
        break;
    default:
        env = 'stage'
}

const AppConfig = require(`./app-stage`).default;
const StorageConfig = require('./storage').default;
const googleAPIKey = 'AIzaSyCrpQ1gCrT4aTgbxni2w1DXddr3iS_zZUE';
export default {
    AppConfig,
    StorageConfig,
    googleAPIKey
}
export { StorageConfig }
export { AppConfig }
export { googleAPIKey }