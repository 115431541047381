export const sortList = (items, isAsc, orderBy) => {
  return items
    ? [...items].sort((a, b) => {
        if (isAsc) {
          switch (orderBy) {
            case "date":
              return a._createdOn > b._createdOn ? 1 : -1;
          }
        } else {
          switch (orderBy) {
            case "date":
              return a._createdOn < b._createdOn ? 1 : -1;
          }
        }
      })
    : [];
};
