const CAREER_STATUS = {
  STUDENT: "Student",
  RESIDENT: "Resident",
  PHYSICIAN: "Physician",
  GRADUATE: "Graduate",
};

const EDUCATIONAL_GROUP = {
  MEDICAL: "Medical",
};

export const CAREER_STATUS_LIST = [
  {
    label: "Student",
    value: CAREER_STATUS["STUDENT"],
  },
  {
    label: "Resident/ Fellow",
    value: CAREER_STATUS["RESIDENT"],
  },
  {
    label: "Practicing Physician",
    value: CAREER_STATUS["PHYSICIAN"],
  },
  {
    label: "Graduate",
    value: CAREER_STATUS["GRADUATE"],
  },
];

export const EDUCATIONAL_GROUP_LIST = [
  {
    label: "Medical",
    value: EDUCATIONAL_GROUP["MEDICAL"],
  },
];
