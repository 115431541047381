import { gql } from "@apollo/client";

export const categories = gql`
  query Categories($filter: StatusFilterInput) {
    categories(filter: $filter) {
      _id
      _ref
      _createdOn
      _count {
        all {
          category_id
          total
        }
        incorrect {
          category_id
          total
        }
        marked {
          category_id
          total
        }
        unused {
          category_id
          total
        }
      }
      name
      status
      type
      children {
        _id
        _count {
          all {
            category_id
            total
          }
          incorrect {
            category_id
            total
          }
          marked {
            category_id
            total
          }
          unused {
            category_id
            total
          }
        }
        name
        status
        type
      }
      parent_ids
      parents {
        _id
        name
        status
        type
      }
    }
  }
`;

export const createCategory = gql`
  mutation CreateCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      _id
      _ref
      _createdOn
      _count {
        all {
          category_id
          total
        }
        incorrect {
          category_id
          total
        }
        marked {
          category_id
          total
        }
        unused {
          category_id
          total
        }
      }
      name
      status
      type
      children {
        _id
        _count {
          all {
            category_id
            total
          }
          incorrect {
            category_id
            total
          }
          marked {
            category_id
            total
          }
          unused {
            category_id
            total
          }
        }
        name
        status
        type
      }
      parent_ids
      parents {
        _id
        name
        status
        type
      }
    }
  }
`;

export const updateCategory = gql`
  mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      _id
      _ref
      _count {
        all {
          category_id
          total
        }
        incorrect {
          category_id
          total
        }
        marked {
          category_id
          total
        }
        unused {
          category_id
          total
        }
      }
      name
      status
      type
      children {
        _id
        _count {
          all {
            category_id
            total
          }
          incorrect {
            category_id
            total
          }
          marked {
            category_id
            total
          }
          unused {
            category_id
            total
          }
        }
        name
        status
        type
      }
      parent_ids
      parents {
        _id
        name
        status
        type
      }
    }
  }
`;

export const removeCategory = gql`
  mutation RemoveCategory($_id: ObjectId!, $permanent: Boolean) {
    removeCategory(_id: $_id, permanent: $permanent)
  }
`;

export const questions = gql`
  query($filter: QuestionFilterInput) {
    questions(filter: $filter) {
      _id
      _ref
      _createdOn
      explanation
      question
      answers {
        content
        validAnswer
      }
      status
      category {
        _id
        name
      }
      subCategory {
        _id
        name
      }
      lessons {
        _id
        title
      }
      resources {
        url
        name
      }
      trial
    }
  }
`;

export const questionById = gql`
  query QuestionById($_id: ObjectId) {
    question(_id: $_id) {
      _id
      _createdOn
      explanation
      question
      answers {
        content
        validAnswer
      }
      status
      category {
        _id
        name
      }
      subCategory {
        _id
        name
      }
      lessons {
        _id
        title
      }
      resources {
        url
        name
      }
      trial
    }
  }
`;

export const createQuestion = gql`
  mutation CreateQuestion($input: CreateQuestionInput) {
    createQuestion(input: $input) {
      _id
      _createdOn
      explanation
      question
      answers {
        content
        validAnswer
      }
      status
      category {
        _id
        name
      }
      subCategory {
        _id
        name
      }
      lessons {
        _id
        title
      }
      resources {
        url
        name
      }
    }
  }
`;

export const updateQuestion = gql`
  mutation UpdateQuestion($_id: ObjectId!, $input: UpdateQuestionInput) {
    updateQuestion(_id: $_id, input: $input) {
      _id
      _createdOn
      explanation
      question
      answers {
        content
        validAnswer
      }
      status
      category {
        _id
        name
      }
      subCategory {
        _id
        name
      }
      lessons {
        _id
        title
      }
      resources {
        url
        name
      }
    }
  }
`;

export const removeQuestion = gql`
  mutation RemoveQuestion($_id: ObjectId!, $permanent: Boolean) {
    removeQuestion(_id: $_id, permanent: $permanent)
  }
`;

export const createExam = gql`
  mutation CreateExam($input: CreateExamInput!) {
    createExam(input: $input) {
      _id
    }
  }
`;

export const createUserExam = gql`
  mutation CreateUserExam($input: CreateUserExamInput!) {
    createUserExam(input: $input) {
      _id
      status
    }
  }
`;

export const userExams = gql`
  query UserExams($filter: UserExamDateRangeFilterInput) {
    userExams(filter: $filter) {
      _id
      _ref
      name
      _totalScore
      _avgScore
      _completedOn
      _createdOn
      _userExamNo
      status
      exam {
        _id
        _ref
        _createdOn
        _updatedOn
        status
        type
        questions {
          _ref
          explanation
          answers {
            content
            validAnswer
          }
          category {
            _id
            name
            type
          }
          subCategory {
            _id
            name
            type
          }
          lessons {
            _id
            title
          }
          resources {
            url
            name
          }
        }
        question_mode
        categories {
          _count
          category {
            _id
            name
          }
        }
        timed
        totalQuestions
        tutor
        subCategories {
          _count
          category {
            _id
            name
          }
        }
      }
      userExamAnswers {
        _id
        _score
        answer
        question {
          _id
        }
        flag
        _answerRight
        omitted
      }
    }
  }
`;

export const USER_EXAMS_BY_USER_ID = gql`
  query UserExamsByUserId(
    $user_id: ObjectId!
    $filter: UserExamDateRangeFilterInput
  ) {
    userExamsByUserId(user_id: $user_id, filter: $filter) {
      _id
      _totalScore
      _createdOn
      _completedOn
      status
      exam {
        questions {
          _ref
        }
      }
    }
  }
`;

export const userExam = gql`
  query UserExam($filter: UserExamQueryFilterInput) {
    userExam(filter: $filter) {
      _id
      status
      allocateTime
      _totalScore
      _avgScore
      exam {
        _id
        _ref
        _createdOn
        _updatedOn
        status
        type
        questions {
          _id
          _ref
          question
          explanation
          answers {
            content
            validAnswer
            percentage
          }
          category {
            _id
            name
            type
          }
          subCategory {
            _id
            name
            type
          }
          lessons {
            _id
            title
          }
          resources {
            url
            name
          }
        }
        question_mode
        categories {
          _count
          category {
            _id
            name
          }
        }
        timed
        totalQuestions
        tutor
        subCategories {
          _count
          category {
            _id
            name
          }
        }
      }
      userExamAnswers {
        _id
        _answerRight
        _score
        _timeUsed
        _updatedOn
        _enteredOn
        answer
        question {
          _id
          explanation
        }
        flag
        omitted
      }
    }
  }
`;

export const UPDATE_USER_EXAM = gql`
  mutation UpdateUserExam($_id: ObjectId!, $input: UpdateUserExamInput) {
    updateUserExam(_id: $_id, input: $input) {
      _id
      name
      _totalScore
      _completedOn
      _createdOn
      status
      exam {
        _id
        _ref
        _createdOn
        _updatedOn
        status
        type
        questions {
          explanation
          answers {
            content
            validAnswer
          }
          category {
            _id
            name
            type
          }
          subCategory {
            _id
            name
            type
          }
          lessons {
            _id
            title
          }
          resources {
            url
            name
          }
        }
        question_mode
        categories {
          _count
          category {
            _id
            name
          }
        }
        timed
        totalQuestions
        tutor
        subCategories {
          _count
          category {
            _id
            name
          }
        }
      }
      userExamAnswers {
        _id
        _score
        answer
        question {
          _id
        }
        flag
        _answerRight
        omitted
      }
    }
  }
`;

export const UPDATE_EXAM = gql`
  mutation UpdateExam($_id: ObjectId!, $input: UpdateExamInput) {
    updateExam(_id: $_id, input: $input) {
      _id
      name
    }
  }
`;

export const removeUserExam = gql`
  mutation RemoveUserExam($_id: ObjectId!) {
    removeUserExam(_id: $_id)
  }
`;

export const startExam = gql`
  mutation StartExam($_id: ObjectId!) {
    startExam(_id: $_id) {
      _id
      status
      allocateTime
      exam {
        _id
        _ref
        _createdOn
        _updatedOn
        status
        type
        questions {
          _id
          _ref
          question
          answers {
            content
            validAnswer
            percentage
          }
          category {
            _id
            name
            type
          }
          subCategory {
            _id
            name
            type
          }
          lessons {
            _id
            title
          }
          resources {
            url
            name
          }
        }
        question_mode
        categories {
          _count
          category {
            _id
            name
          }
        }
        timed
        totalQuestions
        tutor
        subCategories {
          _count
          category {
            _id
            name
          }
        }
      }
      userExamAnswers {
        _id
        _score
        _timeUsed
        answer
        question {
          _id
        }
        flag
      }
    }
  }
`;

export const pauseExam = gql`
  mutation PauseExam($_id: ObjectId!) {
    pauseExam(_id: $_id) {
      _id
      status
      exam {
        _id
        _ref
        _createdOn
        _updatedOn
        status
        type
        questions {
          _id
          _ref
          question
          answers {
            content
            validAnswer
          }
          category {
            _id
            name
            type
          }
          subCategory {
            _id
            name
            type
          }
          lessons {
            _id
            title
          }
          resources {
            url
            name
          }
        }
        question_mode
        categories {
          _count
          category {
            _id
            name
          }
        }
        timed
        totalQuestions
        tutor
        subCategories {
          _count
          category {
            _id
            name
          }
        }
      }
      userExamAnswers {
        _id
        _score
        answer
        question {
          _id
        }
        flag
      }
    }
  }
`;

export const resumeExam = gql`
  mutation ResumeExam($_id: ObjectId!) {
    resumeExam(_id: $_id) {
      _id
      status
      allocateTime
      exam {
        _id
        _ref
        _createdOn
        _updatedOn
        status
        type
        questions {
          _id
          _ref
          question
          answers {
            content
            validAnswer
            percentage
          }
          category {
            _id
            name
            type
          }
          subCategory {
            _id
            name
            type
          }
          lessons {
            _id
            title
          }
          resources {
            url
            name
          }
        }
        question_mode
        categories {
          _count
          category {
            _id
            name
          }
        }
        timed
        totalQuestions
        tutor
        subCategories {
          _count
          category {
            _id
            name
          }
        }
      }
      userExamAnswers {
        _id
        _score
        _timeUsed
        answer
        question {
          _id
        }
        flag
      }
    }
  }
`;

export const endExam = gql`
  mutation EndExam($_id: ObjectId!) {
    endExam(_id: $_id) {
      _id
      status
      exam {
        _id
        _ref
        _createdOn
        _updatedOn
        status
        type
        questions {
          _id
          question
          answers {
            content
            validAnswer
          }
          category {
            _id
            name
            type
          }
          subCategory {
            _id
            name
            type
          }
          lessons {
            _id
            title
          }
          resources {
            url
            name
          }
        }
        question_mode
        categories {
          _count
          category {
            _id
            name
          }
        }
        timed
        totalQuestions
        tutor
        subCategories {
          _count
          category {
            _id
            name
          }
        }
      }
      userExamAnswers {
        _id
        _score
        answer
        question {
          _id
        }
        flag
      }
    }
  }
`;

export const enterQuestion = gql`
  mutation EnterQuestion($question_id: ObjectId!, $userExam_id: ObjectId!) {
    enterQuestion(question_id: $question_id, userExam_id: $userExam_id) {
      _id
      _answerRight
      _score
      _timeUsed
      _updatedOn
      _enteredOn
      answer
      question {
        _id
        explanation
      }
      flag
      omitted
    }
  }
`;

export const pauseQuestion = gql`
  mutation PauseQuestion($question_id: ObjectId!, $userExam_id: ObjectId!) {
    pauseQuestion(question_id: $question_id, userExam_id: $userExam_id) {
      _id
    }
  }
`;
export const resumeQuestion = gql`
  mutation ResumeQuestion($question_id: ObjectId!, $userExam_id: ObjectId!) {
    resumeQuestion(question_id: $question_id, userExam_id: $userExam_id) {
      _id
    }
  }
`;

export const omitAndEnterQuestion = gql`
  mutation omitAndEnterQuestion(
    $omitUserAnswer_id: ObjectId!
    $question_id: ObjectId!
    $userExam_id: ObjectId!
  ) {
    omitQuestion(_id: $omitUserAnswer_id) {
      _id
      _score
      answer
      question {
        _id
      }
      flag
    }
    enterQuestion(question_id: $question_id, userExam_id: $userExam_id) {
      _id
      _answerRight
      _score
      _timeUsed
      _updatedOn
      answer
      question {
        _id
        explanation
      }
      flag
    }
  }
`;

export const flagQuestion = gql`
  mutation FlagQuestion($_id: ObjectId!, $flag: Boolean) {
    flagQuestion(_id: $_id, flag: $flag) {
      _id
      _answerRight
      _score
      _timeUsed
      _updatedOn
      _enteredOn
      answer
      question {
        _id
        explanation
      }
      flag
      omitted
    }
  }
`;

export const answerQuestion = gql`
  mutation AnswerQuestion($_id: ObjectId!, $answer: String!) {
    answerQuestion(_id: $_id, answer: $answer) {
      _id
      _answerRight
      _score
      _timeUsed
      _updatedOn
      _enteredOn
      answer
      question {
        _id
        explanation
      }
      flag
      omitted
    }
  }
`;

export const omitQuestion = gql`
  mutation OmitQuestion($_id: ObjectId!) {
    omitQuestion(_id: $_id) {
      _id
      _score
      answer
      question {
        _id
      }
      flag
    }
  }
`;

export const examAnalysis = gql`
  query ExamAnalysis($_id: ObjectId) {
    examAnalysis(_id: $_id) {
      answerChanges {
        correctToIncorrect
        incorrectToCorrect
        incorrectToIncorrect
      }
    }
  }
`;

export const USER_EXAM_ANSWER = gql`
  query UserExamAnswer($_id: ObjectId) {
    userExamAnswer(_id: $_id) {
      _id
      _answerRight
      _score
      _timeUsed
      _updatedOn
      _enteredOn
      answer
      question {
        _id
        explanation
      }
      flag
      omitted
    }
  }
`;
