// Sort subjects data
const sortSubjectsData = (subjectsData, orderBy, isAsc) =>
  subjectsData.sort((a, b) => {
    if (isAsc) {
      switch (orderBy) {
        case "name":
          return a.name > b.name ? 1 : -1;
        case "totalQuestion":
          return a.total > b.total ? 1 : -1;
        case "correct":
        case "incorrect":
        case "omitted":
          return a.subjectsSummary[orderBy].count / a.total >
            b.subjectsSummary[orderBy].count / b.total
            ? 1
            : -1;
      }
    } else {
      switch (orderBy) {
        case "name":
          return a.name < b.name ? 1 : -1;
        case "totalQuestion":
          return a.total < b.total ? 1 : -1;
        case "correct":
        case "incorrect":
        case "omitted":
          return a.subjectsSummary[orderBy].count / a.total <
            b.subjectsSummary[orderBy].count / b.total
            ? 1
            : -1;
      }
    }
  });

export { sortSubjectsData };
