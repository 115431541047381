import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  subscriptionBox: {
    backgroundColor: "#ededed",
    width: "100%",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      width: "65%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "55%",
    },
  },
  activeSubscriptionBox: {
    backgroundColor: "#dcf0e7",
  },
  buttonContainer: {
    borderLeft: "none",
    [theme.breakpoints.up("md")]: {
      borderLeft: "1px solid rgba(0,0,0,.2)",
    },
  },
  ctaButton: {
    boxShadow: "0 2px 5px 0 rgb(39 39 39 / 50%)",
    borderRadius: "5em",
    textTransform: "none",
    minWidth: "6rem",
    padding: theme.spacing(1),
  },
}));
