import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { emptyBG } from "../resources/assets";
import { Helmet } from "react-helmet";

class NotFound extends Component {
  render() {
    return (
      <div className="viewCont emptyState">
        <Helmet>
          <title>404</title>
          <meta name="404" content="Something wrong with the url" />
        </Helmet>
        <div className="bodyCont">
          <img src={emptyBG} className="dcImg" alt="not found" />
          <div className="dcCol">
            <Link to={`/`} className="dcBtn">
              <div className="animated slideInUp">BACK TO HOME</div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ UserStore }) => ({
  UserStore,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
