import { Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const CustomSwitch = withStyles(theme => ({
  switchBase: {
    "&$checked": {
      color: theme.palette.secondary.main,
    },
    "&.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.secondary.main,
      opacity: 0.5,
    },
  },
  checked: {},
  track: {},
}))(Switch);

export default CustomSwitch;
