import { makeStyles } from "@material-ui/styles";
import { COLOR_BLACK, COLOR_WHITE } from "../../../../../styles/constants";

export default makeStyles(theme => ({
  totalPriceBox: {
    position: "relative",
    borderRadius: 20,
    boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.1)",
    border: `0.5px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.lighter,
    color: COLOR_BLACK,
    height: 200,
    marginBottom: theme.spacing(7),
    width: "100%",
    [theme.breakpoints.between("md", "lg")]: {
      maxWidth: 400,
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: 490,
    },
  },
  checkoutButton: {
    position: "absolute",
    color: COLOR_WHITE,
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0px 0px 20px 5px rgba(0, 0, 0, 0.08)",
    borderRadius: 10,
    height: 80,
    width: "60%",
    top: "78%",
    left: "20%",
    zIndex: 1,
    cursor: "pointer",
    fontSize: "1.4rem",
    textTransform: "none !important",
  },
  crossIcon: {
    width: 20,
    height: 20,
  },
  continueTxt: {
    textDecoration: "underline",
    color: "#0047FF",
    maxWidth: 467,
    width: "100%",
    cursor: "pointer",
  },
}));
