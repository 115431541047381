export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';
export const resetError = () => ({ type: RESET_ERROR_MESSAGE });

export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';
export const addError = (err) => ({ type: ADD_ERROR_MESSAGE, err });

export const RESET_SUCCESS_MESSAGE = 'RESET_SUCCESS_MESSAGE';
export const resetSuccess = () => ({ type: RESET_SUCCESS_MESSAGE });

export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE';
export const showSuccess = (message) => ({ type: SHOW_SUCCESS_MESSAGE, message });

export const APP_LOADING = "APP_LOADING";
export const appLoading = () => ({ type: APP_LOADING });

export const APP_READY = "APP_READY";
export const appReady = () => ({ type: APP_READY });

export const NAV_TAB_UPDATE = "NAV_TAB_UPDATE";
export const updateNavTab = (data) => ({ type: NAV_TAB_UPDATE, data });

export const UPDATE_NAV_TO_CART = "UPDATE_NAV_TO_CART";
export const updateNavToCart = (data) => ({ type: UPDATE_NAV_TO_CART, data });

export const UPDATE_IS_MOBILE = "UPDATE_IS_MOBILE";
export const updateIsMobile = (data) => ({ type: UPDATE_IS_MOBILE, data });

export const UPDATE_SHOW_APPFOOTER = "UPDATE_SHOW_APPFOOTER";
export const updateShowFooter = (data) => ({ type: UPDATE_SHOW_APPFOOTER, data });

export const UPDATE_SHOW_APPHEADER = "UPDATE_SHOW_APPHEADER";
export const updateShowHeader = (data) => ({ type: UPDATE_SHOW_APPHEADER, data });

export const UPDATE_SHOW_SIDEMENU = "UPDATE_SHOW_SIDEMENU";
export const updateShowSidemenu = (data) => ({ type: UPDATE_SHOW_SIDEMENU, data });

export const toogleSidemenu = () => (dispatch, getState) => {
  const { showSidemenu } = getState().UiStore
  dispatch(updateShowSidemenu(!showSidemenu));
};
