import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { VictoryPie, VictoryLabel } from "victory";

import * as AnalyticsActions from "../../actions/analyticsAction";
import * as ExamActions from "../../actions/examAction";
import {
  YOUR_SCORE_PIE_COLORS,
  QBank_USAGE_PIE_COLORS,
  CONTENT_PADDING,
} from "../../styles/constants";
import StatsColumn from "./StatsColumn";
import PercentileStatsColumn from "./PercentileStatsColumn";
import PercentileCurve from "./PercentileCurve";
import { getCategorizedCount } from "../../utils";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    height: "100%",
    ...CONTENT_PADDING(theme),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #e7eaec",
    width: "100%",
    paddingBottom: theme.spacing(1),
    fontSize: "17px",
  },
  secondRow: {
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
    },
    marginTop: "20px",
  },
  thirdRow: {
    marginTop: "40px",
  },
}));

const OverallPerformancePage = props => {
  const { getUserPerformance, getCategoryList } = props;
  const classes = useStyles();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [userPerformanceData, setUserPerformanceData] = useState(null);
  const [yourScorePieData, setYourScorePieData] = useState(null);
  const [answerChanges, setAnswerChanges] = useState(null);
  const [qBankUsage, setQBankUsage] = useState(null);
  const [testCount, setTestCount] = useState(null);
  const [subjects, setSubjects] = useState(null);

  const fetchUserPerformanceData = async () => {
    const userPerformanceData = await getUserPerformance();
    const categoriesData = await getCategoryList();
    if (userPerformanceData && userPerformanceData.userPerformance) {
      setUserPerformanceData(userPerformanceData.userPerformance);
    }
    if (categoriesData && categoriesData.categories) {
      setSubjects(
        categoriesData.categories.filter(item => item.type === "subject")
      );
    }
  };
  useEffect(() => {
    fetchUserPerformanceData();
  }, []);

  const summaryTotal = userPerformanceData
    ? ["correct", "incorrect", "omitted"].reduce(
        (acc, key) => acc + userPerformanceData.summary[key],
        0
      )
    : 0;

  useEffect(() => {
    if (userPerformanceData && subjects) {
      const allQuestions = subjects
        ? subjects.reduce(
            (acc, subject) => acc + getCategorizedCount(subject._count, "all"),
            0
          )
        : 0;
      const unusedQuestions = subjects
        ? subjects.reduce(
            (acc, subject) =>
              acc + getCategorizedCount(subject._count, "unused"),
            0
          )
        : 0;
      const usedQuestions = allQuestions - unusedQuestions;
      setYourScorePieData([
        {
          x: "Total Correct",
          y: userPerformanceData.summary.correct,
        },
        {
          x: "Total Incorrect",
          y: userPerformanceData.summary.incorrect,
        },
        {
          x: "Total Omitted",
          y: userPerformanceData.summary.omitted,
        },
      ]);
      setAnswerChanges([
        {
          x: "Correct to Incorrect",
          y: userPerformanceData.answerChanges.correctToIncorrect,
        },
        {
          x: "Incorrect to Correct",
          y: userPerformanceData.answerChanges.incorrectToCorrect,
        },
        {
          x: "Incorrect to Incorrect",
          y: userPerformanceData.answerChanges.incorrectToIncorrect,
        },
      ]);
      setQBankUsage([
        {
          x: "Used Questions",
          y: usedQuestions,
        },
        {
          x: "Unused Questions",
          y: unusedQuestions,
        },
        {
          x: "Total Questions",
          y: allQuestions,
        },
      ]);
      setTestCount([
        {
          x: "Tests Created",
          y: userPerformanceData.count.created,
        },
        {
          x: "Tests Completed",
          y: userPerformanceData.count.completed,
        },
        {
          x: "Suspended Tests",
          y: userPerformanceData.count.suspended,
        },
      ]);
    }
  }, [userPerformanceData, subjects]);

  if (
    !userPerformanceData ||
    !subjects ||
    !yourScorePieData ||
    !answerChanges ||
    !qBankUsage
  )
    return null;
  // Get Used Questions & Unused Questions only
  const qbankUsageCopy = [...qBankUsage].slice(0, 2);
  return (
    <Fragment>
      <Helmet>
        <title>QBank Landing Page</title>
        <meta
          name="description"
          content="QBank landing allow user to know more about QBank Company background and also QBank products"
        />
      </Helmet>
      <Paper elevation={0} className={classes.root}>
        <div className={classes.titleContainer}>Statistics</div>
        <Grid container direction="column">
          <Grid item xs={12}>
            <Grid
              container
              spacing={mdDown ? 3 : 6}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={6} md={4} xl={2}>
                <div style={{ textAlign: "center" }}>
                  <svg width={245} height={240}>
                    {summaryTotal === 0 ? (
                      <VictoryPie
                        standalone={false}
                        colorScale={[QBank_USAGE_PIE_COLORS[1]]}
                        width={245}
                        height={240}
                        data={[{ x: "Dummy", y: 1 }]}
                        innerRadius={90}
                        labels={() => null}
                      />
                    ) : (
                      <VictoryPie
                        standalone={false}
                        colorScale={YOUR_SCORE_PIE_COLORS}
                        width={245}
                        height={240}
                        data={yourScorePieData}
                        innerRadius={90}
                        labels={() => null}
                      />
                    )}
                    <VictoryLabel
                      textAnchor="middle"
                      style={{ fontSize: 27 }}
                      x={123}
                      y={110}
                      text={
                        summaryTotal === 0
                          ? "0%"
                          : `${(
                              (userPerformanceData.summary.correct /
                                summaryTotal) *
                              100
                            ).toFixed(0)}%`
                      }
                    />
                    <VictoryLabel
                      textAnchor="middle"
                      style={{ fontSize: 16, fill: "#A1A1A1" }}
                      x={123}
                      y={135}
                      text="Correct"
                    />
                  </svg>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={5}>
                <StatsColumn title={"Your Score"} stats={yourScorePieData} />
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={5}>
                <StatsColumn title={"Answer Changes"} stats={answerChanges} />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={mdDown ? 3 : 6}
              justifyContent="space-between"
              className={classes.secondRow}
              alignItems={"center"}
            >
              <Grid item xs={12} sm={6} md={4} xl={2}>
                <div style={{ textAlign: "center" }}>
                  <svg width={245} height={240}>
                    <VictoryPie
                      standalone={false}
                      colorScale={QBank_USAGE_PIE_COLORS}
                      width={245}
                      height={240}
                      data={qbankUsageCopy}
                      innerRadius={90}
                      labels={() => null}
                    />
                    <VictoryLabel
                      textAnchor="middle"
                      style={{ fontSize: 27 }}
                      x={123}
                      y={110}
                      text={`${(
                        (qBankUsage[0].y / qBankUsage[2].y) *
                        100
                      ).toFixed(0)}%`}
                    />
                    <VictoryLabel
                      textAnchor="middle"
                      style={{ fontSize: 16, fill: "#A1A1A1" }}
                      x={123}
                      y={135}
                      text="Used"
                    />
                  </svg>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={5}>
                <StatsColumn title={"QBank Usage"} stats={qBankUsage} />
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={5}>
                <StatsColumn
                  title={"Test Count"}
                  stats={testCount}
                  style={{ marginTop: smDown ? 0 : "" }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={mdDown ? 3 : 6}
              justifyContent="space-between"
              className={classes.thirdRow}
              alignItems={"center"}
            >
              <Grid item xs={12} md={7}>
                <PercentileCurve
                  yourScore={userPerformanceData.yourScore}
                  medianScore={userPerformanceData.medianScore}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <PercentileStatsColumn
                  yourScore={userPerformanceData.yourScore}
                  medianScore={userPerformanceData.medianScore}
                  avgTimeSpent={userPerformanceData.avgTimeSpent}
                  otherAvgTimeSpent={userPerformanceData.otherAvgTimeSpent}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getUserPerformance: () => dispatch(AnalyticsActions.userPerformance()),
  getCategoryList: () => dispatch(ExamActions.getCategoryList()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OverallPerformancePage)
);
