import React, { useState, useEffect, Fragment } from "react";
import isTouchDevice from "is-touch-device";
import clsx from "clsx";
import { Rnd } from "react-rnd";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWindowRestore,
  faTh,
  faSlidersH,
  faPlus,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

import * as FlashcardActions from "../../../../../actions/flashcardAction";
import * as ExamActions from "../../../../../actions/examAction";
import styles from "../styles";
import {
  parseFlashcardThumbnail,
  filterFlashcardsByKeyWord,
} from "../../../../../utils";
import {
  FLASHCARD_MODE_TYPE,
  FLASHCARD_LIST_TYPE,
  REQUEST_STATUS,
} from "../../../../../constants";
import FlashcardView from "../../../../FlashcardsPage/FlashcardView";
import FlashcardForm from "../../../../FlashcardsPage/FlashcardForm";
import {
  CustomConfirmDialogActions,
  CustomActionBtn,
  CustomCancelBtn,
} from "../../../../../Components/CustomDialogElements";
import FlashcardFilter from "../../../../FlashcardsPage/FlashcardFilter";
import { useTheme } from "@material-ui/styles";
import { selectCategoriesRequestStatus } from "../../../../../selectors/category";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => styles(theme));

// Constants
const LIGHT_ICON_COLOR = "#000";
const DARK_ICON_COLOR = "#fff";
const DEFAULT_SIZE = { width: "65vw", height: "70vh" };
const DEFAULT_POSITION = {
  x: 50,
  y: 100,
};
const DEFAULT_SM_POSITION = {
  x: 20,
  y: 100,
};
const DEFAULT_XS_POSITION = {
  x: 0,
  y: 100,
};
const MIN_SIZE = { width: 280, height: 400 };

const FlashcardListModal = props => {
  const {
    getCategoryList,
    visible,
    isDarkMode,
    customPos,
    flashcardsData,
    separated = true,
    currentQuestionID,
    openModalState,
    setOpenModalState,
    searchedFlashcardsData,
    setSearchedFlashcardsData,
    onClose,
    onRemoveFlashcard,
    onCreateFlashcard,
    onUpdateFlashcard,
    handleOpenFilterMenu,
    handleCloseFilterMenu,
    anchorFilterMenu,
    selectedSubjects,
    selectedSystems,
    setSelectedSubjects,
    setSelectedSystems,
    setApplyCategoriesFilter,
    applyCategoriesFilter,
    fetchFlashcards,
    fetchFlashcardsByCategoriesFilter,
    filteredTotal,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const requestCategoriesStatus = useSelector(selectCategoriesRequestStatus);
  const PRIMARY_COLOR = theme.palette.secondary.main;
  const PRESET_POSITION = customPos
    ? customPos
    : window.innerWidth < 290
    ? DEFAULT_XS_POSITION
    : window.innerWidth < 360
    ? DEFAULT_SM_POSITION
    : DEFAULT_POSITION;

  const [size, setSize] = useState(DEFAULT_SIZE);
  const [position, setPosition] = useState(null);
  const [disableDragging, setDisableDragging] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentFlashcard, setCurrentFlashcard] = useState(null);
  const [showFrontView, setShowFrontView] = useState(true);
  const [currentPagination, setCurrentPagination] = useState(null);
  const [showInsertSnackBar, setShowInsertSnackBar] = useState(false);
  const [isUnsavedForm, setIsUnsavedForm] = useState(null);
  const [showUnsavedWarningModal, setShowUnsavedWarningModal] = useState(false);
  const [shouldCloseModal, setShouldCloseModal] = useState(null);
  const [shouldGoMainMode, setShouldGoMainMode] = useState(null);

  // Handle Confirmtion Modal Start
  const handleCloseUnsavedWarningModal = () => {
    setShowUnsavedWarningModal(false);
  };

  const handleOpenUnsavedWarningModal = () => {
    setShowUnsavedWarningModal(true);
  };

  const onClickUnsavedModalYesBtn = () => {
    if (shouldCloseModal) {
      // Reset shouldCloseModal state

      setShouldCloseModal(false);
      onCloseFlashcardListModal();
    } else if (shouldGoMainMode) {
      // Reset shouldGoMainMode state

      setShouldGoMainMode(false);
      goToMainMode();
    } else {
      // INSERT_WITH ELEMENT CASE
      if (
        (openModalState.mode === FLASHCARD_MODE_TYPE["EDIT"] ||
          openModalState.mode === FLASHCARD_MODE_TYPE["CREATE"]) &&
        openModalState.payload
      ) {
        setOpenModalState({
          payload: openModalState.payload,
          mode: FLASHCARD_MODE_TYPE["INSERT_WITH_ELEMENT"],
        });
      } else if (
        openModalState.mode === FLASHCARD_MODE_TYPE["CREATE"] ||
        openModalState.mode === FLASHCARD_MODE_TYPE["CREATE_WITH_ELEMENT"]
      ) {
        goToMainMode();
      } else if (openModalState.mode === FLASHCARD_MODE_TYPE["EDIT"]) {
        goBackFromEditMode();
      }
    }

    // Reset isUnsavedForm State
    setIsUnsavedForm(false);
    handleCloseUnsavedWarningModal();
  };
  // Handle Confirmtion Modal End

  // Handle Modal Start
  const handleViewFlashcardMode = (flashcard, listType) => {
    setCurrentFlashcard(flashcard);
    setOpenModalState({ mode: FLASHCARD_MODE_TYPE["VIEW"], listType });
  };

  const handleCreateFlashcardMode = () => {
    // INSERT_WITH_ELEMENT CASE
    if (openModalState.mode === FLASHCARD_MODE_TYPE["INSERT_WITH_ELEMENT"]) {
      setOpenModalState({
        ...openModalState,
        mode: FLASHCARD_MODE_TYPE["CREATE"],
      });
    }
    // CREATE CASE
    else {
      setOpenModalState({ mode: FLASHCARD_MODE_TYPE["CREATE"] });
    }
  };

  const goBackFromSearchMode = () => {
    setSearchKeyword("");
  };

  const goToMainMode = () => {
    setOpenModalState({ mode: FLASHCARD_MODE_TYPE["MAIN"] });
    setShowFrontView(true);
  };

  const goBackFromEditMode = () => {
    setOpenModalState({
      ...openModalState,
      mode: FLASHCARD_MODE_TYPE["VIEW"],
    });
  };

  const onClickCancelBtnInCreateCase = () => {
    if (isUnsavedForm) {
      handleOpenUnsavedWarningModal();
      return;
    }
    goToMainMode();
  };

  const onClickCancelBtnInEditCase = () => {
    if (isUnsavedForm) {
      handleOpenUnsavedWarningModal();
      return;
    }
    goBackFromEditMode();
  };

  const onClickCancelBtnInInsertCase = () => {
    if (isUnsavedForm) {
      handleOpenUnsavedWarningModal();
      return;
    }
    setOpenModalState({
      ...openModalState,
      mode: FLASHCARD_MODE_TYPE["INSERT_WITH_ELEMENT"],
    });
  };

  const isEditingForm = () => {
    return (
      openModalState.mode === FLASHCARD_MODE_TYPE["EDIT"] ||
      openModalState.mode === FLASHCARD_MODE_TYPE["CREATE"] ||
      openModalState.mode === FLASHCARD_MODE_TYPE["CREATE_WITH_ELEMENT"] ||
      openModalState.mode === FLASHCARD_MODE_TYPE["INSERT_WITH_ELEMENT"]
    );
  };

  const onClickGoMainModeBtn = () => {
    if (isEditingForm()) {
      if (isUnsavedForm) {
        setShouldGoMainMode(true);
        handleOpenUnsavedWarningModal();
        return;
      }
    }
    goToMainMode();
  };

  const onClickCloseBtn = () => {
    if (isEditingForm()) {
      if (isUnsavedForm) {
        setShouldCloseModal(true);
        handleOpenUnsavedWarningModal();
        return;
      }
    }
    onCloseFlashcardListModal();
  };

  const onCloseFlashcardListModal = () => {
    onClose();
    setShowFrontView(true);
  };

  const onClickFlashcard = (flashcard, listType) => {
    if (openModalState.mode === FLASHCARD_MODE_TYPE["MAIN"]) {
      handleViewFlashcardMode(flashcard, listType);
      if (listType === FLASHCARD_LIST_TYPE["SEARCH"]) {
        setSearchedFlashcardsData(filteredFlashcardsData);
      }
    } else if (
      openModalState.mode === FLASHCARD_MODE_TYPE["INSERT_WITH_ELEMENT"]
    ) {
      setCurrentFlashcard(flashcard);
      setOpenModalState({
        ...openModalState,
        mode: FLASHCARD_MODE_TYPE["EDIT"],
      });
    }
  };

  const handleCloseSnackbar = () => {
    setShowInsertSnackBar(false);
  };
  // Handle Modal End

  // Handle Actions Button Start
  const onRestoreWindowSize = () => {
    setSize(DEFAULT_SIZE);
  };
  // Handle Actions Button End

  useEffect(() => {
    // VIEW CASE
    if (openModalState.mode === FLASHCARD_MODE_TYPE["VIEW"]) {
      let index;
      if (openModalState.listType === FLASHCARD_LIST_TYPE["OWN"]) {
        index = ownFlashcardsData.findIndex(
          flashcard => flashcard._id === currentFlashcard._id
        );
      } else if (openModalState.listType === FLASHCARD_LIST_TYPE["OTHER"]) {
        index = otherFlashcardsData.findIndex(
          flashcard => flashcard._id === currentFlashcard._id
        );
      } else if (openModalState.listType === FLASHCARD_LIST_TYPE["SEARCH"]) {
        index = searchedFlashcardsData.findIndex(
          flashcard => flashcard._id === currentFlashcard._id
        );
      } else if (openModalState.listType === FLASHCARD_LIST_TYPE["ALL"]) {
        index = flashcardsData.findIndex(
          flashcard => flashcard._id === currentFlashcard._id
        );
      }
      setCurrentPagination(index);
    }
    // INSERT_WITH_ELEMENT CASE
    else if (
      openModalState.mode === FLASHCARD_MODE_TYPE["INSERT_WITH_ELEMENT"]
    ) {
      setShowInsertSnackBar(true);
    }
  }, [openModalState]);

  const filteredFlashcardsData = filterFlashcardsByKeyWord(
    flashcardsData,
    searchKeyword
  );

  const ownFlashcardsData = flashcardsData.filter(flashcard => {
    return flashcard.question_id === currentQuestionID;
  });

  const otherFlashcardsData = flashcardsData.filter(flashcard => {
    return flashcard.question_id !== currentQuestionID;
  });

  const isTouchDev = isTouchDevice();

  // Update position when scrolling
  const handleOnScroll = e => {
    const window = e.currentTarget;

    if (position) {
      setPosition(prevState => ({
        x: prevState.x,
        y: window.scrollY,
      }));
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleOnScroll);

    return () => {
      window.removeEventListener("scroll", handleOnScroll);
    };
  }, [handleOnScroll]);

  // Reset state when flashcardlist modal mounted/unmounted
  useEffect(() => {
    if (!position) {
      setPosition(PRESET_POSITION);
    }

    setSearchKeyword("");
    setShouldCloseModal(null);
    setShouldGoMainMode(null);

    return () => {
      handleCloseSnackbar();
    };
  }, [visible]);

  useEffect(() => {
    getCategoryList();
  }, []);

  if (!visible || requestCategoriesStatus !== REQUEST_STATUS.SUCCESS)
    return null;

  return (
    <Fragment>
      {/* Unsaved Warning Modal */}
      <Dialog
        onClose={handleCloseUnsavedWarningModal}
        open={showUnsavedWarningModal}
      >
        <DialogContent>
          Flashcard not saved. Any unsaved changes will be lost. Do you really
          wish to cancel the changes?
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomActionBtn onClick={onClickUnsavedModalYesBtn}>
            Yes
          </CustomActionBtn>
          <CustomCancelBtn onClick={handleCloseUnsavedWarningModal}>
            No
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>

      {/* FlashcardList Modal */}
      <Rnd
        className={classes.zIndex1001}
        bounds={"window"}
        minHeight={MIN_SIZE.height}
        minWidth={MIN_SIZE.width}
        size={{ width: size.width, height: size.height }}
        position={{ x: position.x, y: position.y }}
        onDragStop={(e, d) => {
          setPosition({ x: d.x, y: d.y });
        }}
        onResize={(e, direction, ref, delta, position) => {
          setSize({ width: ref.style.width, height: ref.style.height });
          setPosition({
            ...position,
          });
        }}
        enableUserSelectHack={false}
        disableDragging={disableDragging}
      >
        <div
          className={clsx(
            isDarkMode
              ? classes.flashcardListModalContDark
              : classes.flashcardListModalContLight,
            classes.flashcardModalCont,
            classes.modalCont
          )}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ top: "50%" }}
            classes={{
              root: classes.snackBarRoot,
            }}
            open={showInsertSnackBar}
            onClose={handleCloseSnackbar}
            autoHideDuration={2500}
            message="Please select a card to continue"
          />

          <div
            className={clsx(
              isDarkMode
                ? classes.flashcardListHeaderDark
                : classes.flashcardListHeaderLight,
              classes.flashcardListHeader
            )}
          >
            <Typography variant="h6">Flashcards</Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              {openModalState.mode !== FLASHCARD_MODE_TYPE["MAIN"] && (
                <FontAwesomeIcon
                  className="clickable"
                  icon={faTh}
                  style={{ fontSize: 18, marginRight: 8 }}
                  onClick={onClickGoMainModeBtn}
                />
              )}
              <FontAwesomeIcon
                className="clickable"
                icon={faWindowRestore}
                style={{ fontSize: 18, marginRight: 8 }}
                onClick={onRestoreWindowSize}
              />

              <CloseIcon className="clickable" onClick={onClickCloseBtn} />
            </div>
          </div>

          {(openModalState.mode === FLASHCARD_MODE_TYPE["MAIN"] ||
            openModalState.mode ===
              FLASHCARD_MODE_TYPE["INSERT_WITH_ELEMENT"]) && (
            <div
              className={clsx(
                isDarkMode
                  ? classes.flashcardListSearchBarDark
                  : classes.flashcardListSearchBarLight,
                classes.flashcardListSearchBar
              )}
            >
              <SearchIcon
                className={classes.searchIcon}
                style={{
                  color: isDarkMode ? DARK_ICON_COLOR : "#757575",
                }}
              />
              <InputBase
                placeholder="Search"
                classes={{
                  root: classes.searchInputRoot,
                }}
                value={searchKeyword}
                onChange={e => setSearchKeyword(e.target.value)}
                style={{
                  color: isDarkMode ? DARK_ICON_COLOR : LIGHT_ICON_COLOR,
                }}
              />
              <FontAwesomeIcon
                className={clsx(classes.filterIcon, "clickable")}
                icon={faSlidersH}
                color={
                  applyCategoriesFilter
                    ? PRIMARY_COLOR
                    : isDarkMode
                    ? DARK_ICON_COLOR
                    : "#757575"
                }
                onClick={handleOpenFilterMenu}
              />
              <FlashcardFilter
                isDarkMode={isDarkMode}
                horizontalOrigin={"right"}
                total={filteredTotal}
                onClose={handleCloseFilterMenu}
                anchorFilterMenu={anchorFilterMenu}
                selectedSubjects={selectedSubjects}
                selectedSystems={selectedSystems}
                setSelectedSubjects={setSelectedSubjects}
                setSelectedSystems={setSelectedSystems}
                setApplyCategoriesFilter={setApplyCategoriesFilter}
                applyCategoriesFilter={applyCategoriesFilter}
                fetchFlashcards={fetchFlashcards}
                fetchFlashcardsByCategoriesFilter={
                  fetchFlashcardsByCategoriesFilter
                }
                setSearchKeyword={setSearchKeyword}
              />
            </div>
          )}
          <div
            className={classes.flashcardListContentCont}
            onMouseEnter={() => {
              if (!isTouchDev) setDisableDragging(true);
            }}
            onMouseLeave={() => {
              if (!isTouchDev) setDisableDragging(false);
            }}
          >
            {/* Main FlashcardList View */}
            {openModalState.mode === FLASHCARD_MODE_TYPE["MAIN"] ||
            openModalState.mode ===
              FLASHCARD_MODE_TYPE["INSERT_WITH_ELEMENT"] ? (
              searchKeyword ? (
                <Fragment>
                  {/* Search Results View */}
                  <div className="d-flex">
                    <span className="clickable" onClick={goBackFromSearchMode}>
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={{
                          fontSize: 18,
                          marginRight: 8,
                          color: "#5AB0F6",
                        }}
                      />
                      <span style={{ fontSize: 16 }}>Search Results</span>
                    </span>
                  </div>

                  <Grid container spacing={3} className={classes.cardsCont}>
                    <Grid item>
                      <Paper
                        className={clsx(
                          isDarkMode && classes.cardDark,
                          classes.card
                        )}
                      >
                        <div
                          className={classes.cardContent}
                          style={{ flexDirection: "column" }}
                          onClick={handleCreateFlashcardMode}
                        >
                          <FontAwesomeIcon
                            className={classes.addCardIcon}
                            icon={faPlus}
                            size="2x"
                          />
                          New card
                        </div>
                      </Paper>
                    </Grid>
                    {filteredFlashcardsData &&
                      filteredFlashcardsData.length > 0 &&
                      filteredFlashcardsData.map(flashcard => (
                        <Fragment key={flashcard._id}>
                          <Grid item>
                            <Paper
                              className={clsx(
                                isDarkMode && classes.cardDark,
                                classes.card
                              )}
                            >
                              <div
                                className={classes.cardContent}
                                onClick={() =>
                                  onClickFlashcard(
                                    flashcard,
                                    FLASHCARD_LIST_TYPE["SEARCH"]
                                  )
                                }
                              >
                                {parseFlashcardThumbnail(flashcard.front)}
                              </div>
                            </Paper>
                          </Grid>
                        </Fragment>
                      ))}
                  </Grid>
                </Fragment>
              ) : !separated ? (
                <Fragment>
                  <Grid container spacing={3} className={classes.cardsCont}>
                    <Grid item>
                      <Paper
                        className={clsx(
                          isDarkMode && classes.cardDark,
                          classes.card
                        )}
                      >
                        <div
                          className={classes.cardContent}
                          style={{ flexDirection: "column" }}
                          onClick={handleCreateFlashcardMode}
                        >
                          <FontAwesomeIcon
                            className={classes.addCardIcon}
                            icon={faPlus}
                            size="2x"
                          />
                          New card
                        </div>
                      </Paper>
                    </Grid>
                    {flashcardsData &&
                      flashcardsData.length > 0 &&
                      flashcardsData.map(flashcard => (
                        <Fragment key={flashcard._id}>
                          <Grid item>
                            <Paper
                              className={clsx(
                                isDarkMode && classes.cardDark,
                                classes.card
                              )}
                            >
                              <div
                                className={classes.cardContent}
                                onClick={() =>
                                  onClickFlashcard(
                                    flashcard,
                                    FLASHCARD_LIST_TYPE["OTHER"]
                                  )
                                }
                              >
                                {parseFlashcardThumbnail(flashcard.front)}
                              </div>
                            </Paper>
                          </Grid>
                        </Fragment>
                      ))}
                  </Grid>
                </Fragment>
              ) : (
                <Fragment>
                  <div style={{ fontSize: 16 }}>This Question</div>
                  <Grid container spacing={3} className={classes.cardsCont}>
                    <Grid item>
                      <Paper
                        className={clsx(
                          isDarkMode && classes.cardDark,
                          classes.card
                        )}
                      >
                        <div
                          className={classes.cardContent}
                          style={{ flexDirection: "column" }}
                          onClick={handleCreateFlashcardMode}
                        >
                          <FontAwesomeIcon
                            className={classes.addCardIcon}
                            icon={faPlus}
                            size="2x"
                          />
                          New card
                        </div>
                      </Paper>
                    </Grid>
                    {ownFlashcardsData &&
                      ownFlashcardsData.length > 0 &&
                      ownFlashcardsData.map(flashcard => (
                        <Fragment key={flashcard._id}>
                          <Grid item>
                            <Paper
                              className={clsx(
                                isDarkMode && classes.cardDark,
                                classes.card
                              )}
                            >
                              <div
                                className={classes.cardContent}
                                onClick={() =>
                                  onClickFlashcard(
                                    flashcard,
                                    FLASHCARD_LIST_TYPE["OWN"]
                                  )
                                }
                              >
                                {parseFlashcardThumbnail(flashcard.front)}
                              </div>
                            </Paper>
                          </Grid>
                        </Fragment>
                      ))}
                  </Grid>
                  <div style={{ fontSize: 16 }}>Other</div>
                  <Grid container spacing={3} className={classes.cardsCont}>
                    {otherFlashcardsData &&
                      otherFlashcardsData.length > 0 &&
                      otherFlashcardsData.map(flashcard => (
                        <Fragment key={flashcard._id}>
                          <Grid item>
                            <Paper
                              className={clsx(
                                isDarkMode && classes.cardDark,
                                classes.card
                              )}
                            >
                              <div
                                className={classes.cardContent}
                                onClick={() =>
                                  onClickFlashcard(
                                    flashcard,
                                    FLASHCARD_LIST_TYPE["OTHER"]
                                  )
                                }
                              >
                                {parseFlashcardThumbnail(flashcard.front)}
                              </div>
                            </Paper>
                          </Grid>
                        </Fragment>
                      ))}
                  </Grid>
                </Fragment>
              )
            ) : openModalState.mode === FLASHCARD_MODE_TYPE["CREATE"] ? (
              <FlashcardForm
                flashcardlistModalWidth={size.width}
                isDarkMode={isDarkMode}
                mode={openModalState.mode}
                currentQuestionID={currentQuestionID}
                payload={openModalState.payload ? openModalState.payload : null}
                onCreateFlashcard={onCreateFlashcard}
                onClickCancelBtnHandler={
                  openModalState.payload
                    ? onClickCancelBtnInInsertCase
                    : onClickCancelBtnInCreateCase
                }
                handleGoBack={
                  openModalState.payload
                    ? onCloseFlashcardListModal
                    : goToMainMode
                }
                setIsUnsavedForm={setIsUnsavedForm}
                setOpenModalState={setOpenModalState}
              />
            ) : openModalState.mode ===
              FLASHCARD_MODE_TYPE["CREATE_WITH_ELEMENT"] ? (
              <FlashcardForm
                flashcardlistModalWidth={size.width}
                isDarkMode={isDarkMode}
                mode={openModalState.mode}
                currentQuestionID={currentQuestionID}
                payload={openModalState.payload}
                onCreateFlashcard={onCreateFlashcard}
                onClickCancelBtnHandler={onClickCancelBtnInCreateCase}
                handleGoBack={goToMainMode}
                setIsUnsavedForm={setIsUnsavedForm}
              />
            ) : openModalState.mode === FLASHCARD_MODE_TYPE["EDIT"] ? (
              <FlashcardForm
                flashcardlistModalWidth={size.width}
                isDarkMode={isDarkMode}
                flashcard={currentFlashcard}
                mode={openModalState.mode}
                payload={openModalState.payload ? openModalState.payload : null}
                onUpdateFlashcard={onUpdateFlashcard}
                onClickCancelBtnHandler={
                  openModalState.payload
                    ? onClickCancelBtnInInsertCase
                    : onClickCancelBtnInEditCase
                }
                handleGoBack={
                  openModalState.payload
                    ? onCloseFlashcardListModal
                    : goBackFromEditMode
                }
                setIsUnsavedForm={setIsUnsavedForm}
                setOpenModalState={setOpenModalState}
              />
            ) : openModalState.mode === FLASHCARD_MODE_TYPE["VIEW"] ? (
              <FlashcardView
                flashcardlistModalWidth={size.width}
                isDarkMode={isDarkMode}
                parsedFlashcardsData={
                  openModalState.listType === FLASHCARD_LIST_TYPE["OWN"]
                    ? ownFlashcardsData
                    : openModalState.listType === FLASHCARD_LIST_TYPE["OTHER"]
                    ? otherFlashcardsData
                    : searchedFlashcardsData
                }
                currentFlashcard={currentFlashcard}
                setCurrentFlashcard={setCurrentFlashcard}
                currentPagination={currentPagination}
                setCurrentPagination={setCurrentPagination}
                showFrontView={showFrontView}
                setShowFrontView={setShowFrontView}
                openModalState={openModalState}
                setOpenModalState={setOpenModalState}
                handleGoBack={goToMainMode}
                onRemoveFlashcard={onRemoveFlashcard}
              />
            ) : null}
          </div>
        </div>
      </Rnd>
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  createFlashcard: data => dispatch(FlashcardActions.createFlashcard(data)),
  updateFlashcard: data => dispatch(FlashcardActions.updateFlashcard(data)),
  removeFlashcard: id => dispatch(FlashcardActions.removeFlashcard(id)),
  getCategoryList: () => dispatch(ExamActions.getCategoryList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlashcardListModal);
