import React, { Fragment, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";

import * as TopicAction from "../../actions/topicAction";
import {
  CustomDeleteIcon,
  CustomEditIcon,
  CustomViewIcon,
} from "../../Components/CustomActionIcons";
import { parseDateHtml } from "../../utils";
import { COURSE_APP_SUB_ROUTE } from "../../routes/constants";
import { ACTIVE_STATUS_FILTER } from "./DeletedItem/constants";
import { parseBooleanValueHTML } from "./helper";
import ConfirmationModal from "../../Components/Modal/ConfirmationModal";
import useModal from "../../hooks/useModal";

const ManageTopic = ({ history, getTopicList, removeTopic }) => {
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(undefined);
  const { open, handleOpen, handleClose } = useModal();

  const fetchTopicList = async () => {
    const res = await getTopicList(ACTIVE_STATUS_FILTER);
    if (res && res.lessons) {
      setTopics(res.lessons);
    }
  };

  useEffect(() => {
    fetchTopicList();
  }, []);

  const handleRemoveTopic = async () => {
    const data = await removeTopic(selectedTopic._id);
    if (data) {
      setTopics(topics =>
        topics.filter(topic => topic._id !== selectedTopic._id)
      );
    }
    handleClose();
  };

  return (
    <Fragment>
      <ConfirmationModal
        open={open}
        onClose={handleClose}
        actionFn={handleRemoveTopic}
        ctaTitle={"Are you wish to delete this topic?"}
        ctaTxt={"Delete"}
      />
      <div className="pageCont manageTopicPage">
        <div className="bodyCont">
          <div className="pageAction">
            <div className="pageActionRight">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  history.push(`${COURSE_APP_SUB_ROUTE}/manage-topic/create`);
                }}
              >
                Create Topic
              </Button>
            </div>
          </div>
          <div className="pageContent">
            <MUIDataTable
              data={topics.map(topic => [
                topic._ref,
                topic._createdOn,
                topic.title,
                topic.category ? topic.category.name : "",
                topic.subCategory ? topic.subCategory.name : "",
                topic.tags.join(", "),
                topic.trial,
              ])}
              columns={[
                "ID",
                {
                  name: "Date Created",
                  options: {
                    customBodyRenderLite: dataIndex => {
                      let val = topics[dataIndex]._createdOn;
                      return parseDateHtml(val);
                    },
                    setCellHeaderProps: () => ({
                      className: "globalCustomMuiTableHead",
                    }),
                  },
                },
                "Title",
                "Subject",
                "System",
                "Tags",
                {
                  name: "Trial",
                  options: {
                    customBodyRenderLite: dataIndex => {
                      let val = topics[dataIndex].trial;
                      return parseBooleanValueHTML(val);
                    },
                  },
                },
                {
                  name: "Action",
                  options: {
                    customBodyRenderLite: dataIndex => {
                      return (
                        <div className="actionDiv">
                          <CustomViewIcon
                            onClickHandler={() => {
                              history.push(
                                `${COURSE_APP_SUB_ROUTE}/topics/${topics[dataIndex]._id}`
                              );
                            }}
                          />
                          <CustomEditIcon
                            onClickHandler={() => {
                              history.push(
                                `${COURSE_APP_SUB_ROUTE}/manage-topic/${topics[dataIndex]._id}/edit`
                              );
                            }}
                            style={{ marginLeft: 8 }}
                          />
                          <CustomDeleteIcon
                            onClickHandler={() => {
                              setSelectedTopic(topics[dataIndex]);
                              handleOpen();
                            }}
                            style={{ marginLeft: 8 }}
                          />
                        </div>
                      );
                    },
                  },
                },
              ]}
              options={{
                download: false,
                print: false,
                selectableRows: "none",
                // onRowsDelete: (rowsDeleted, data, newTableData) => {
                //   (async () => {
                //     const deletedIds = [];
                //     await Promise.all(
                //       rowsDeleted.data.map(async ({ dataIndex }) => {
                //         const data = await removeTopic(topics[dataIndex]._id);
                //         if (data) {
                //           deletedIds.push(topics[dataIndex]._id);
                //         }
                //       })
                //     );

                //     setTopics(topics =>
                //       topics.filter(topic => !deletedIds.includes(topic._id))
                //     );
                //   })();

                //   return false;
                // },
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getTopicList: filter => dispatch(TopicAction.getTopicList(filter)),
  removeTopic: id => dispatch(TopicAction.removeTopic(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageTopic)
);
