import {
  BILLING_URL,
  PROFILE_URL,
  SUBSCRIPTIONS_URL,
} from "../../routes/constants";

export const checkIsUserDetailTabPage = pathName => {
  return (
    pathName === SUBSCRIPTIONS_URL ||
    pathName === PROFILE_URL ||
    pathName === BILLING_URL
  );
};
