import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";
import PrimaryTextField from "../../PrimaryTextField";
import PrimarySelect from "../../PrimarySelect";
import { CAREER_STATUS_LIST, EDUCATIONAL_GROUP_LIST } from "../constants";
import {
  checkEmail,
  getUserProfile,
  setEmailAvailability,
  updateUserProfile,
} from "../../../actions/userAction";
import { parseAuthToken } from "../../../utils";
import { PrimaryButton } from "../../CustomButton";
import { EMAIL_REGEX, REQUEST_STATUS } from "../../../constants";
import { ERROR_COLOR, SUCCESS_COLOR } from "../../../styles/constants";
import { Dialog, DialogContent } from "@material-ui/core";
import {
  CustomCancelBtn,
  CustomConfirmDialogActions,
  CustomPrimaryActionBtn,
} from "../../CustomDialogElements";
import { selectUserCheckEmailStatus } from "../../../selectors/user";
import { getAllCountries, getCountryByCode, getStatesOfCountry } from "utils";

const UserProfileForm = () => {
  const { authToken, userInfo, emailAvailability } = useSelector(
    state => state.UserStore
  );
  const checkEmailStatus = useSelector(selectUserCheckEmailStatus);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    reset,
    errors,
    watch,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      city: "",
      country: "",
      state: "",
      zipCode: "",
      phoneNo: "",
      educationalGroup: "",
      careerStatus: "",
      graduatingSchool: "",
    },
  });
  const watchCountry = watch("country");
  const COUNTRIES = useMemo(() => getAllCountries(), []);
  const STATES = useMemo(() => {
    const states = getStatesOfCountry(watchCountry);
    if (watchCountry) {
      if (Array.isArray(states) && states.length > 0) {
        return states;
      } else {
        const foundCountry = getCountryByCode(watchCountry);
        return foundCountry ? [foundCountry] : [];
      }
    } else return [];
  }, [watchCountry]);
  const isEmailChanged = userInfo && userInfo.email !== watch("email");

  const [showEmailAvailability, setShowEmailAvailability] = useState(false);
  const [showUpdateProfileModal, setShowUpdateProfileModal] = useState(false);

  // Handle Confirmtion Modal Start
  const handleOpenUpdateProfileModal = event => {
    setShowUpdateProfileModal(true);
  };

  const handleCloseUpdateProfileModal = () => {
    setShowUpdateProfileModal(false);
  };
  // Handle Confirmtion Modal End

  const onSubmit = data => {
    const userId = parseAuthToken(authToken);

    const inputData = {
      _id: userId,
      input: { ...data, email: isEmailChanged ? data.email : undefined },
    };

    dispatch(updateUserProfile(inputData));
    handleCloseUpdateProfileModal();
  };

  const handleCheckEmailAvailibility = () => {
    const newEmail = getValues("email");

    if (isEmailChanged) {
      dispatch(checkEmail(newEmail));
      setShowEmailAvailability(true);
    } else {
      dispatch(setEmailAvailability(true));
      setShowEmailAvailability(true);
    }
  };

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (userInfo) {
      reset({
        email: userInfo.email || "",
        firstName: userInfo.firstName || "",
        lastName: userInfo.lastName || "",
        address1: userInfo.address1 || "",
        address2: userInfo.address2 || "",
        city: userInfo.city || "",
        country: userInfo.country || "",
        state: userInfo.state || "",
        zipCode: userInfo.zipCode || "",
        phoneNo: userInfo.phoneNo || "",
        educationalGroup: userInfo.educationalGroup || "",
        careerStatus: userInfo.careerStatus || "",
        graduatingSchool: userInfo.graduatingSchool || "",
      });
    }
  }, [userInfo]);

  return (
    <>
      <Dialog
        onClose={handleCloseUpdateProfileModal}
        open={showUpdateProfileModal}
      >
        <DialogContent>
          <div>Are you sure you wish to update profile?</div>
          {isEmailChanged && (
            <div>
              Are you sure you want to change your email? This action is
              irreversible.
            </div>
          )}
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomPrimaryActionBtn onClick={handleSubmit(onSubmit)}>
            Update
          </CustomPrimaryActionBtn>
          <CustomCancelBtn onClick={handleCloseUpdateProfileModal}>
            Cancel
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>

      <Grid direction="column" container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="firstName"
              rules={{
                required: "First Name cannot be empty",
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="First Name"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName && errors.firstName.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="lastName"
              rules={{
                required: "Last Name cannot be empty",
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Last Name"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName && errors.lastName.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="address1"
              rules={{ required: "Address cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Address (PO Box is not allowed)"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.address1)}
                  helperText={errors.address1 && errors.address1.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="address2"
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Address 2"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.address2)}
                  helperText={errors.address2 && errors.address2.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="city"
              rules={{ required: "City cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="City"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.city)}
                  helperText={errors.city && errors.city.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="country"
              rules={{ required: "Country cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimarySelect
                  label="Select Country"
                  onChange={e => {
                    onChange(e.target.value);
                    setValue("state", "", { shouldValidate: true });
                  }}
                  value={value}
                  error={errors.country && errors.country.message}
                >
                  <option aria-label="None" value="" />
                  {COUNTRIES.map(country => (
                    <option key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </option>
                  ))}
                </PrimarySelect>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="state"
              rules={{ required: "State cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimarySelect
                  label="Select State"
                  disabled={!Boolean(watchCountry)}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  error={errors.state && errors.state.message}
                >
                  <option aria-label="None" value="" />
                  {STATES.length > 0 &&
                    STATES.map(state => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                </PrimarySelect>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="zipCode"
              rules={{ required: "Zip Code cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Zip Code"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.zipCode)}
                  helperText={errors.zipCode && errors.zipCode.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="phoneNo"
              rules={{
                required: "Telephone cannot be empty",
                minLength: { value: 6, message: "Minimum 6 characters" },
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Telephone"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.phoneNo)}
                  helperText={errors.phoneNo && errors.phoneNo.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: "Email cannot be empty",
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Invalid Email Format",
                },
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Email"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                />
              )}
            />
            <Box mt={1} display="flex" fontSize="1rem">
              <Box
                color="secondary.main"
                mr={2}
                className="clickable"
                onClick={handleCheckEmailAvailibility}
              >
                Check Availability
              </Box>
              {showEmailAvailability &&
                checkEmailStatus === REQUEST_STATUS.SUCCESS &&
                (emailAvailability ? (
                  <Box color={SUCCESS_COLOR}>Available</Box>
                ) : (
                  <Box color={ERROR_COLOR}>Not Available</Box>
                ))}
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box mt={4} mb={2}>
            {""}
          </Box>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="educationalGroup"
              rules={{ required: "Group cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimarySelect
                  label="Select Group"
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  error={
                    errors.educationalGroup && errors.educationalGroup.message
                  }
                >
                  <option aria-label="None" value="" />
                  {EDUCATIONAL_GROUP_LIST.map(state => (
                    <option key={state.label} value={state.value}>
                      {state.label}
                    </option>
                  ))}
                </PrimarySelect>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="careerStatus"
              rules={{ required: "Career status cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimarySelect
                  label="Select Career Status"
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  error={errors.careerStatus && errors.careerStatus.message}
                >
                  <option aria-label="None" value="" />
                  {CAREER_STATUS_LIST.map(state => (
                    <option key={state.label} value={state.value}>
                      {state.label}
                    </option>
                  ))}
                </PrimarySelect>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="graduatingSchool"
              rules={{ required: "Graduating School cannot be empty" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Graduating School"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.graduatingSchool)}
                  helperText={
                    errors.graduatingSchool && errors.graduatingSchool.message
                  }
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mt={6}>
            <PrimaryButton onClick={handleOpenUpdateProfileModal} fullWidth>
              Update
            </PrimaryButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default UserProfileForm;
