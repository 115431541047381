import "babel-polyfill";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import "./styles/incredible/incredible.css";
import "./styles/aos.css";
import "./styles/slick.min.css";
import "./styles/slick-theme.min.css";
import App from "./App";
import unregister from "./registerServiceWorker";
import { Router, Route } from "react-router-dom";
import { createStore, compose, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import UserReducer, {
  initialState as initialUserStoreState,
} from "./reducers/userReducer";
import ProductReducer from "./reducers/productReducer";
import UIReducer from "./reducers/uiReducer";
import axios from "axios";
import * as UIActions from "./actions/uiAction";
import * as UserActions from "./actions/userAction";
import "date-input-polyfill-2";
import { createBrowserHistory } from "history";
import categoryReducer from "./reducers/categoryReducer";
import subscriptionReducer from "./reducers/subscriptionReducer";
import planReducer from "./reducers/planReducer";
import { combineReducers } from "redux";
import {
  ACCESS_TOKEN_EXPIRED_TIME,
  AUTH_TOKEN,
  CART_ITEMS,
  USER_TYPE,
} from "./localStorage/constants";
import { cartReducer } from "./reducers/cartReducer";
import history from "./routes/history";
import transactionReducer from "./reducers/transactionReducer";
import signupReducer from "./reducers/signupReducer";
import voucherReducer from "./reducers/voucherReducer";
import { PAYMENT_METHOD_LIST } from "./views/app/Checkout/constants";
import topicListReducer from "./reducers/topicListReducer";

unregister();

const unregisterSW = async () => {
  try {
    if ("serviceWorker" in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (let i = 0; i < registrations.length; i += 0) {
        const registration = registrations[i];
        const unregisterResult = await registration.unregister();
        console.log("service worker unregistered: ", unregisterResult);
      }
    }
  } catch (err) {
    console.log(err, { depth: null, colors: true });
  }
};

unregisterSW();

const baseUrl = process.env.PUBLIC_URL;
const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

/** store initialization **/
// localforage.config(StorageConfig);
// let storeRehydrating = true;
// const storageMiddleware = store => next => action => {
//   if (
//     action.type === REHYDRATE ||
//     action.type === PERSIST ||
//     storeRehydrating
//   ) {
//     return next(action);
//   }
//   const stampedAction = {
//     action,
//     timeStamp: Date.now(),
//   };

//   localStorage.setItem("LAST-ACTION", JSON.stringify(stampedAction));

//   next(action);
// };

const middleware = [thunk];

const authTokenFromStorage = localStorage.getItem(AUTH_TOKEN)
  ? localStorage.getItem(AUTH_TOKEN)
  : null;
const userTypeFromStorage = localStorage.getItem(USER_TYPE)
  ? localStorage.getItem(USER_TYPE)
  : null;
const cartItemsFromStorage = localStorage.getItem(CART_ITEMS)
  ? JSON.parse(localStorage.getItem(CART_ITEMS))
  : [];

const initialState = {
  UserStore: {
    ...initialUserStoreState,
    authToken: authTokenFromStorage,
    type: userTypeFromStorage,
  },
  cart: {
    cartItems: cartItemsFromStorage,
    paymentMethod: PAYMENT_METHOD_LIST[0].value,
    isValidToPay: false,
  },
};

const reducer = combineReducers({
  UserStore: UserReducer,
  ProductStore: ProductReducer,
  UiStore: UIReducer,
  CategoryStore: categoryReducer,
  SubscriptionStore: subscriptionReducer,
  plan: planReducer,
  cart: cartReducer,
  transaction: transactionReducer,
  signup: signupReducer,
  voucher: voucherReducer,
  topicList: topicListReducer,
});

export const store = createStore(
  reducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

/** axios interceptors **/
axios.interceptors.request.use(
  config => {
    const { UserStore, locales } = store.getState();
    let locale = locales.locale || "en";

    let headers = {
      "Accept-Language": locale,
    };

    if (UserStore && UserStore.authToken) {
      headers = {
        ...headers,
        "x-public-token": UserStore.authToken,
      };
    }

    config.headers = {
      ...headers,
      ...config.headers,
    };
    return config;
  },
  error => {
    let err =
      error.response && error.response.data ? error.response.data : error;
    store.dispatch(UIActions.addError(err));
    store.dispatch(UIActions.appReady());
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    let err =
      error.response && error.response.data ? error.response.data : error;

    if (error.response.data.code === "VERIFICATION_FAILED") {
      store.dispatch(UIActions.appReady());
    } else if (error.response.data.code === "INVALID_PROMO_CODE") {
      store.dispatch(UIActions.appReady());
    } else if (error.response.data.code === "MULTIPLE_USER_ACCOUNT") {
      store.dispatch(UIActions.appReady());
    } else {
      store.dispatch(UIActions.appReady());
      store.dispatch(UIActions.addError(err));
    }
    return Promise.reject(err);
  }
);

// const persistor = persistStore(store, null, () => {
//   const { SESSION_TIMEOUT } = AppConfig;

// if (
//   Date.now() - lastAction.timeStamp > SESSION_TIMEOUT - 60 * 1000 ||
//   (lastAction.action && lastAction.action.type === UserActions.USER_LOGOUT)
// ) {
//   store.dispatch(UserActions.logout());
// }

// force locale to reload messages
// const { locales } = store.getState();
// const langArray = ['en', 'ko', 'zh', 'id', 'th'];
// let lang = locales.locale || navigator.language || navigator.browserLanguage;
// lang = lang.substring(0, 2);
// if (langArray.indexOf(lang) === -1) {
//     lang = 'en';
// }
// changeLocale(lang);
//   storeRehydrating = false;
// });
const lastAction = JSON.parse(localStorage.getItem("LAST-ACTION")) || {};
const EXPIRED_TIME = localStorage.getItem(ACCESS_TOKEN_EXPIRED_TIME) || {};

if (
  Date.now() > new Date(EXPIRED_TIME) ||
  (lastAction.action && lastAction.action.type === UserActions.USER_LOGOUT)
) {
  store.dispatch(UIActions.addError("Current Session Expired"));
  store.dispatch(UserActions.logout());
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Route path="/" component={App} />
    </Router>
  </Provider>,
  document.getElementById("root")
);
