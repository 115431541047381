import { makeStyles } from "@material-ui/styles";
import { TH_ICON_STYLING } from "../../../styles/constants";

export default makeStyles(theme => ({
  thIcon: { ...TH_ICON_STYLING },
  fileImg: { objectFit: "contain", width: 100, height: 80 },
  fileName: {
    fontWeight: "bold",
    fontSize: 13,
    color: "#2196f3",
    marginTop: 4,
  },
}));
