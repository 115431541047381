import { COLOR_BLACK, ERROR_COLOR } from "../../../../styles/constants";
import React from "react";
import BlockOutlinedIcon from "@material-ui/icons/BlockOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { differenceInMinutes } from "date-fns";
import { convertMinutesToDaysHours } from "../helper";
import { checkShouldBlockUser } from "../../../../utils/access-helper";
import { useTheme } from "@material-ui/core";

export const BlockIcon = props => {
  const { user, onClick, style } = props;
  const theme = useTheme();
  const isBlockedBefore = user.blockedStartDate && user.blockedEndDate;
  const shouldBlock =
    user.blockedStartDate && user.blockedEndDate
      ? checkShouldBlockUser(user)
      : false;
  const tootipTxt = shouldBlock
    ? convertMinutesToDaysHours(
        differenceInMinutes(
          new Date(user.blockedEndDate),
          new Date(user.blockedStartDate)
        )
      )
    : "Block";

  return (
    <Tooltip title={tootipTxt}>
      <BlockOutlinedIcon
        className="clickable"
        onClick={onClick}
        style={{
          ...style,
          color: shouldBlock
            ? COLOR_BLACK
            : isBlockedBefore
            ? ERROR_COLOR
            : theme.palette.secondary.main,
        }}
      />
    </Tooltip>
  );
};
