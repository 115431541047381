import * as ActionTypes from "../actions/subscriptionAction";
import * as UserTypes from "../actions/userAction";

const {
  GET_SUBSCRIPTION_BY_PROFILE,
  RESET_SUBSCRIPTION,
  PAYPAL_PAYMENT_APPROVED,
  ASSIGN_SUBSCRIPTION_TRIAL_PLAN,
  ACTIVATE_SUBSCRIPTION,
  TOGGLE_ACTIVATION_MODAL,
} = ActionTypes;
const { USER_LOGOUT } = UserTypes;
const initialAppState = {
  loading: false,
  subscription: null,
  activationModalState: {
    open: false,
    msg: "",
  },
};

const subscriptionReducer = (state = initialAppState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SUBSCRIPTION_BY_PROFILE.AWAIT:
    case RESET_SUBSCRIPTION.AWAIT:
    case PAYPAL_PAYMENT_APPROVED.AWAIT:
    case ACTIVATE_SUBSCRIPTION.AWAIT:
    case ASSIGN_SUBSCRIPTION_TRIAL_PLAN.AWAIT:

    case GET_SUBSCRIPTION_BY_PROFILE.FAIL:
    case RESET_SUBSCRIPTION.FAIL:
    case PAYPAL_PAYMENT_APPROVED.FAIL:
    case ACTIVATE_SUBSCRIPTION.FAIL:
    case ASSIGN_SUBSCRIPTION_TRIAL_PLAN.FAIL:
      return { ...state, loading: true };

    case GET_SUBSCRIPTION_BY_PROFILE.SUCCESS:
    case RESET_SUBSCRIPTION.SUCCESS:
    case PAYPAL_PAYMENT_APPROVED.SUCCESS:
    case ACTIVATE_SUBSCRIPTION.SUCCESS:
    case ASSIGN_SUBSCRIPTION_TRIAL_PLAN.SUCCESS:
      return {
        ...state,
        loading: false,
        subscription: payload,
      };

    case USER_LOGOUT: {
      return initialAppState;
    }
    case TOGGLE_ACTIVATION_MODAL: {
      return {
        ...state,
        activationModalState: payload,
      };
    }
    default:
      return state;
  }
};

export default subscriptionReducer;
