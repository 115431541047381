import React, { Fragment, useState, useEffect, useReducer } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { useBeforeunload } from "react-beforeunload";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import CropDinOutlinedIcon from "@material-ui/icons/CropDinOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  faClock,
  faCalendarAlt,
  faChartBar,
} from "@fortawesome/free-regular-svg-icons";
import { faBolt, faCircle } from "@fortawesome/free-solid-svg-icons";

import * as UIActions from "../../actions/uiAction";
import * as ExamActions from "../../actions/examAction";
import * as NoteActions from "../../actions/noteAction";
import * as TopicActions from "../../actions/topicAction";
import * as FeedbackActions from "../../actions/feedbackAction";
import * as FlashcardActions from "../../actions/flashcardAction";
import {
  bookmark0IconW,
  helpIconW,
  bookmark1IconW,
  menuIconW,
  checkIconW,
  flagIcon,
  flagIconW,
  prevIconC,
  prevDisabledIconC,
  nextIconC,
  nextDisabledIconC,
  calcIconW,
  chatIconW,
  flashIconW2,
  fullIconW,
  exitFullIcon,
  labIconW,
  pauseIconW,
  settingIconW,
  stopIconW,
  whiteboardIconW,
  fontCaseIcon,
  minusIcon,
  plusIcon,
} from "../../resources/assets";
import { highlightSelection, parseLightBoxHTML } from "../../utils";
import {
  CustomConfirmDialogActions,
  CustomActionBtn,
  CustomCancelBtn,
} from "../../Components/CustomDialogElements";
import CustomTooltip from "../../Components/CustomTooltip";
import CalculatorModal from "./modal/themedModal/CalculatorModal/CalculatorModal";
import NoteModal from "./modal/themedModal/NoteModal";
import FeedbackModal from "./modal/themedModal/FeedbackModal";
import ImagePreviewModal from "./modal/themedModal/ImagePreviewModal";
import FlashcardListModal from "./modal/themedModal/FlashcardListModal";
import styles from "./styles";
import {
  INITIAL_TOUR_STATE,
  labValuesObj,
  LAB_VALUES_CONSTANTS,
} from "./constants";
import tourReducer from "../../Components/CustomTour/tourReducer";
import CustomTour from "../../Components/CustomTour";
import { hightlightParser } from "./highlightParser";
import {
  FLASHCARD_MODE_TYPE,
  FLASHCARD_LIST_TYPE,
  HTML_TAG_TYPE,
  USER_EXAM_STATUS,
} from "../../constants";
import { COURSE_APP_SUB_ROUTE, TEST_LIST_URL } from "../../routes/constants";
import FreezeOverlayModal from "../../Components/Modal/FreezeOverlayModal";
import useModal from "../../hooks/useModal";
import useProtectContent from "../../hooks/useProtectContent";
import { validateIsQuestionEntered } from "./utils";

const useStyles = makeStyles(theme => styles(theme));

// Constants
const DEFAULT_FONT_SIZE = 13;

const TestDetailPage = ({
  addError,
  getUserExam,
  startExam,
  pauseExam,
  resumeExam,
  endExam,
  enterQuestion,
  pauseQuestion,
  resumeQuestion,
  flagQuestion,
  answerQuestion,
  omitQuestion,
  getNotes,
  createNote,
  updateNote,
  removeNote,
  createFeedback,
  getFlashcards,
  removeFlashcard,
  createFlashcard,
  updateFlashcard,

  getUserExamAnswer,
  history,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [uiControls, setUiControls] = useState({
    showSidemenu: true,
  });
  const [timed, setTimed] = useState(false);
  const [timer, setTimer] = useState(); // Unit in ms
  const [timerInterval, setTimerInterval] = useState(); // Unit in ms
  const [userExam, setUserExam] = useState(undefined);
  const [currQuestionIndex, setCurrQuestionIndex] = useState(0);
  const [showCalculator, setShowCalculator] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const [showEndModal, setShowEndModal] = useState(false);
  const [notes, setNotes] = useState({});
  const [userAnswers, setUserAnswers] = useState(null);
  const [currentUserAnswer, setCurrentUserAnswer] = useState(undefined);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [revealTab, setRevealTab] = useState("explanation");
  const [showLabValues, setShowLabValues] = useState(false);
  const [labKeyword, setLabKeyword] = useState("");
  const [openMinimumFontSizeTooltip, setOpenMinimumFontSizeTooltip] = useState(
    false
  );
  const [openMaximumFontSizeTooltip, setOpenMaximumFontSizeTooltip] = useState(
    false
  );

  const [showSIReference, setShowSIReference] = useState(false);
  const [selectedLabTab, setSelectedLabTab] = useState(
    LAB_VALUES_CONSTANTS.SERUM
  );
  const [searchLabTabArr, setSearchLabTabArr] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const [fontSize, setFontSize] = useState(DEFAULT_FONT_SIZE);
  const [showTimer, setShowTimer] = useState(true);
  const [showImagePreview, setShowImagePreview] = useState({
    show: false,
    imgAttr: null,
  });
  const [showFlashcardListModal, setShowFlashcardListModal] = useState(false);
  const [
    openFlashcardListModalState,
    setOpenFlashcardListModalState,
  ] = useState({
    mode: FLASHCARD_MODE_TYPE["MAIN"],
  });
  const [flashcardsData, setFlashcardsData] = useState(null);
  const [searchedFlashcardsData, setSearchedFlashcardsData] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedSystems, setSelectedSystems] = useState([]);
  const [anchorFilterMenu, setAnchorFilterMenu] = useState(null);
  const [applyCategoriesFilter, setApplyCategoriesFilter] = useState(false);
  const [filteredTotal, setFilteredTotal] = useState(0);
  const [highlightMenuState, setHighlightMenuState] = useState({
    show: false,
    top: 0,
    left: 0,
    selectionText: "",
  });

  const exam = userExam ? userExam.exam : undefined;
  const questions = exam ? exam.questions : [];
  const params = useParams();

  const handleHightlightText = () => {
    const selection = window.getSelection();
    const highlightableEl = document.querySelectorAll(".highlightableEl");

    if (selection && selection.rangeCount >= 1) {
      const selectionRange = selection.getRangeAt(0); // startNode is the element that the selection starts in
      const startNode = selectionRange.startContainer.parentNode; // endNode is the element that the selection ends in
      const endNode = selectionRange.endContainer.parentNode; // if the selected text is not part of the highlightableEl (i.e. <p>)    // OR    //
      const { width, left, top } = selectionRange.getBoundingClientRect();
      let isContainNodes = false;
      for (let i = 0; i < highlightableEl.length; i++) {
        if (
          highlightableEl[i].contains(startNode) &&
          highlightableEl[i].contains(endNode)
        ) {
          isContainNodes = true;
          break;
        }
      }

      // Only open highlight menu when it is valid selection
      if (isContainNodes && width > 5) {
        setHighlightMenuState({
          show: true,
          left: `${left + width / 2}px`,
          top: `${top - 40}px`,
          selectionText: selection.toString(),
          selectionNode: selectionRange,
        });
      }
    }
  };

  // onselectionchange version
  document.onselectionchange = () => {
    handleHightlightText();
  };

  // Handle Modal State Start here
  const handleOpenSuspendModal = () => {
    setShowSuspendModal(true);
  };
  const handleCloseSuspendModal = () => {
    setShowSuspendModal(false);
  };
  const handleOpenEndModal = () => {
    setShowEndModal(true);
  };
  const handleCloseEndModal = () => {
    setShowEndModal(false);
  };
  // Handle Modal State End here

  // Handle HighlightMenu State Start here
  const handleCloseHighlightMenu = () => {
    setHighlightMenuState({
      show: false,
      top: 0,
      left: 0,
      selectionText: "",
    });
  };
  // Handle HighlightMenu State Start here

  // Handle Settings Menu Start here
  const handleOpenSettingsMenu = () => {
    setShowSettingsMenu(true);
  };
  const handleCloseSettingsMenu = () => {
    setShowSettingsMenu(false);
  };

  const onIncreaseFontSize = () => {
    if (fontSize < 26) {
      setFontSize(fontSize + 1);
    } else {
      setOpenMaximumFontSizeTooltip(true);
    }
  };
  const onDecreaseFontSize = () => {
    if (fontSize > 11) {
      setFontSize(fontSize - 1);
    } else {
      setOpenMinimumFontSizeTooltip(true);
    }
  };

  const onRestoreDefaultFontSize = () => {
    setFontSize(DEFAULT_FONT_SIZE);
  };
  /// Tooltip State Control Start here
  const handleCloseMinimumFontSizeTooltip = () => {
    setOpenMinimumFontSizeTooltip(false);
  };
  const handleCloseMaximumFontSizeTooltip = () => {
    setOpenMaximumFontSizeTooltip(false);
  };
  /// Tooltip State Control End here

  // Show timer settings
  const handleChangeShowTimer = event => {
    setShowTimer(event.target.checked);
  };
  // Handle Settings Menu End here

  // Handle Tour state Start here
  const [tourState, dispatchTour] = useReducer(
    tourReducer(INITIAL_TOUR_STATE),
    INITIAL_TOUR_STATE
  );
  const startTour = () => {
    // Start the tour manually
    dispatchTour({ type: "RESTART" });
  };
  // Handle Tour state End here

  // Handle Lab Values Start here
  const toggleShowLabValues = () => {
    // Reset SearchLabTabArr
    setSearchLabTabArr([]);
    setShowLabValues(!showLabValues);
  };

  const onClickLabTab = tab => {
    setSelectedLabTab(tab);
  };

  const toggleShowSIReference = e => {
    setShowSIReference(e.target.checked);
  };

  const handleChangeKeyword = e => {
    setLabKeyword(e.target.value);
  };
  // Handle Lab Values End here

  // Handle Dark mode  Start here
  const toggleIsDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };
  // Handle Dark mode Ends here

  const parseHTMLContent = html => parseLightBoxHTML(html, setShowImagePreview);
  const exitReviewMode = () => {
    history.push(TEST_LIST_URL);
  };

  const updateUserAnswer = userAnswer => {
    setUserAnswers(answers =>
      answers.filter(answer => answer.question._id === userAnswer.question._id)
        .length
        ? answers.map(answer =>
            answer.question._id === userAnswer.question._id
              ? userAnswer
              : answer
          )
        : answers.concat(userAnswer)
    );
  };
  const fetchExam = async () => {
    const { id: examId } = params;
    const filter = {
      key: "_id",
      value: examId,
    };
    let res = await getUserExam(filter);
    if (res && res.userExam) {
      let userExamData = res.userExam;
      switch (userExamData.status) {
        case "new":
          userExamData = (await startExam(userExamData._id)).startExam;
          break;
        case "pause":
          userExamData = (await resumeExam(userExamData._id)).resumeExam;
          break;
      }

      setUserExam({
        ...userExamData,
        exam: {
          ...userExamData.exam,
          questions: userExamData.exam.questions.map(question => ({
            ...question,
            answers: question.answers,
          })),
        },
      });
      setUserAnswers(userExamData.userExamAnswers);
      setSelectedAnswers(selectedAnswers => {
        userExamData.exam.questions.forEach(question => {
          selectedAnswers[question._id] = null;
        });
        return selectedAnswers;
      });

      const enteredUserExamAnswer = await handleEnterQuestion(
        userExamData._id,
        userExamData.exam.questions[currQuestionIndex]._id,
        userExamData.userExamAnswers
      );

      if (enteredUserExamAnswer) {
        updateUserAnswer(enteredUserExamAnswer);
        setCurrentUserAnswer(enteredUserExamAnswer);
      } else {
        addError("Something wrong on our end, please try again later.");
        history.push(TEST_LIST_URL);
      }
    }
  };

  const fetchNotes = async () => {
    const res = await getNotes();
    const notesObj = {};
    if (res && res.notes) {
      res.notes
        .filter(note => note.status === "active")
        .forEach(note => {
          notesObj[note.question._id] = note;
        });

      setNotes(notesObj);
    }
  };

  // FlashcardList related Functions Start here
  const fetchFlashcards = async () => {
    const res = await getFlashcards();
    if (res && res.flashcards) {
      setFlashcardsData(res.flashcards);
    }
  };

  const fetchFlashcardsByCategoriesFilter = async () => {
    const filter = {
      category_ids: selectedSubjects,
      subCategory_ids: selectedSystems,
    };
    const { flashcards } = await getFlashcards(filter);
    if (flashcards) {
      setFlashcardsData(flashcards);
      setFilteredTotal(flashcards.length);
    }
  };

  const onRemoveFlashcard = async flashcardID => {
    const res = await removeFlashcard(flashcardID);
    if (res && res.removeFlashcard) {
      const newFlashcardsData = flashcardsData.filter(
        flashcard => flashcard._id !== flashcardID
      );

      setFlashcardsData(newFlashcardsData);
    }
    return res;
  };
  const onCreateFlashcard = async data => {
    const res = await createFlashcard(data);
    if (res && res.createFlashcard) {
      let tempFlashcardsData = flashcardsData;
      tempFlashcardsData.unshift(res.createFlashcard);
      setFlashcardsData(tempFlashcardsData);
    }
  };
  const onUpdateFlashcard = async data => {
    const res = await updateFlashcard(data);
    if (res && res.updateFlashcard) {
      // Update Whole List
      let index = flashcardsData.findIndex(
        flashcard => flashcard._id === res.updateFlashcard._id
      );
      let tempFlashcardsData = flashcardsData;
      tempFlashcardsData.splice(index, 1, res.updateFlashcard);
      setFlashcardsData(tempFlashcardsData);

      // Update Search List
      if (
        openFlashcardListModalState.listType === FLASHCARD_LIST_TYPE["SEARCH"]
      ) {
        let searchListIndex = searchedFlashcardsData.findIndex(
          flashcard => flashcard._id === res.updateFlashcard._id
        );
        let tempFlashcardsData = searchedFlashcardsData;
        tempFlashcardsData.splice(searchListIndex, 1, res.updateFlashcard);
        setSearchedFlashcardsData(tempFlashcardsData);
      }
    }
  };

  // Handle Filter Menu Start
  const handleOpenFilterMenu = event => {
    setAnchorFilterMenu(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setAnchorFilterMenu(null);
  };
  // Handle Filter Menu Start
  // FlashcardList related Functions End here

  // Time Over Modal related functions
  const {
    open: openTimeOverModal,
    handleOpen: handleOpenTimeOverModal,
    handleClose: handleCloseTimeOverModal,
  } = useModal();

  const handleContinueTimeOver = async () => {
    await onResumeQuestion(currQuestionIndex);
    handleCloseTimeOverModal();
    setTimerInterval(setInterval(() => setTimer(timer => timer - 250), 250));
  };

  useEffect(() => {
    fetchExam();
    fetchNotes();
  }, []);

  useEffect(() => {
    if (mdDown) {
      setUiControls(controls => ({ ...controls, showSidemenu: false }));
    }
  }, [mdDown]);

  useEffect(() => {
    if (userExam) {
      fetchFlashcards();
    }
  }, [userExam]);

  // Timer use effect
  useEffect(() => {
    if (!userExam) return;
    //totalTimeUsed in ms

    const isExamEnded = userExam.status === USER_EXAM_STATUS.END;

    if (isExamEnded) {
      // Exclude miliseconds
      const totalTimeSpent = userExam.userExamAnswers.reduce(
        (acc, curr) =>
          curr._timeUsed
            ? acc + (curr._timeUsed - (curr._timeUsed % 1000))
            : acc,
        0
      );
      setTimer(totalTimeSpent);
    } else {
      if (userExam.exam.timed) {
        // Convert allocateTime in s to ms
        const time = userExam.allocateTime * 1000;
        setTimer(time);
        setTimerInterval(
          setInterval(() => setTimer(timer => timer - 250), 250)
        );
      } else {
        const totalTimeUsed = userExam.userExamAnswers.reduce(
          (acc, curr) => (curr._timeUsed ? acc + curr._timeUsed : acc),
          0
        );
        setTimer(totalTimeUsed);
        setTimerInterval(
          setInterval(() => setTimer(timer => timer + 250), 250)
        );
      }
    }

    setTimed(userExam.exam.timed);

    return () => {
      clearInterval(timerInterval);
    };
  }, [userExam]);

  useEffect(() => {
    if (!userExam) return;
    const isExamEnded = userExam.status === USER_EXAM_STATUS.END;
    if (userExam.exam.timed && !isExamEnded && timer === 0) {
      onPauseQuestion(currQuestionIndex);
      clearInterval(timerInterval);
      handleOpenTimeOverModal();
    }
  }, [userExam, timer]);

  const omitCurrentQuestion = async () => {
    if (!currentUserAnswer || currentUserAnswer.answer) return;

    await omitQuestion(currentUserAnswer._id);
  };

  useBeforeunload(async () => {
    if (userExam) {
      switch (userExam.status) {
        case "end":
          break;
        default:
          await omitCurrentQuestion();
          await pauseExam(userExam._id);
      }
    }
  });

  useEffect(() => {
    if (smDown)
      setUiControls(controls => ({ ...controls, showSidemenu: false }));
  }, [smDown]);

  const handleEnterQuestion = async (
    userExamId,
    questionId,
    userExamAnswers
  ) => {
    // Only feasible because test will be not contain duplicated questions
    const currentUserExamAnswer =
      Array.isArray(userExamAnswers) && userExamAnswers.length > 0
        ? userExamAnswers.find(
            userExamAnswer =>
              userExamAnswer.question &&
              userExamAnswer.question._id === questionId
          )
        : null;
    if (!currentUserExamAnswer) return null;
    const resGetUserExamAnswer = await getUserExamAnswer(
      currentUserExamAnswer._id
    );
    let enteringUserExamAnswer = null;
    if (resGetUserExamAnswer && resGetUserExamAnswer.userExamAnswer) {
      enteringUserExamAnswer = resGetUserExamAnswer.userExamAnswer;
    }

    if (!enteringUserExamAnswer) return null;

    let enteredUserExamAnswer = null;
    const isQuestionEntered = validateIsQuestionEntered(enteringUserExamAnswer);
    if (isQuestionEntered) {
      enteredUserExamAnswer = enteringUserExamAnswer;
    } else {
      const res = await enterQuestion(userExamId, questionId);
      if (res && res.enterQuestion) {
        enteredUserExamAnswer = res.enterQuestion;
      }
    }
    return enteredUserExamAnswer;
  };

  const onEnterQuestion = async index => {
    const enteredUserExamAnswer = await handleEnterQuestion(
      userExam._id,
      exam.questions[index]._id,
      userAnswers
    );

    if (enteredUserExamAnswer) {
      updateUserAnswer(enteredUserExamAnswer);
      setCurrentUserAnswer(enteredUserExamAnswer);
      setCurrQuestionIndex(() => index);
    } else {
      addError("Something wrong on our end, please try again later.");
      history.push(TEST_LIST_URL);
    }
  };

  const onPauseQuestion = async index => {
    await pauseQuestion(userExam._id, exam.questions[index]._id);
  };

  const onResumeQuestion = async index => {
    await resumeQuestion(userExam._id, exam.questions[index]._id);
  };

  const onSelect = async index => {
    handleCloseHighlightMenu();
    onEnterQuestion(index);
    setShowFlashcardListModal(false);
    if (smDown)
      setUiControls(controls => ({ ...controls, showSidemenu: false }));
  };

  const onNext = async () => {
    if (currQuestionIndex >= exam.questions.length - 1) {
      return;
    }

    onEnterQuestion(currQuestionIndex + 1);
  };

  const onPrev = async () => {
    if (currQuestionIndex <= 0) {
      return;
    }

    onEnterQuestion(currQuestionIndex - 1);
  };

  const onFlag = async () => {
    const { flagQuestion: flagQuestionData } = await flagQuestion(
      currentUserAnswer._id,
      !currentUserAnswer.flag
    );
    updateUserAnswer(flagQuestionData);
    setCurrentUserAnswer(flagQuestionData);
  };

  const isInFullScreen = () => {
    const document = window.document;
    return (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null)
    );
  };

  const requestFullScreen = elem => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullScreen) {
      elem.webkitRequestFullScreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else {
      console.warn("Unsupported functionality for current browser", elem);
    }
  };

  const exitFullScreen = () => {
    const document = window.document;
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  const toggleFullScreen = elem => {
    if (isInFullScreen()) {
      exitFullScreen();
    } else {
      requestFullScreen(elem || document.body);
    }
  };

  const toogleSidemenu = () => {
    setUiControls(controls => ({
      ...controls,
      showSidemenu: !controls.showSidemenu,
    }));
  };

  const onAnswerSelect = async answer => {
    setSelectedAnswers(selectedAnswers => ({
      ...selectedAnswers,
      [currentUserAnswer._id]: answer,
    }));
  };

  const currentSelectedUserAnswer = () => {
    if (!currentUserAnswer) return "";
    const answer =
      selectedAnswers[currentUserAnswer._id] || currentUserAnswer.answer;

    return answer ? answer.trim() : answer;
  };
  const currentAnswerHadChanged = () => {
    if (!currentUserAnswer || !selectedAnswers[currentUserAnswer._id])
      return false;

    return selectedAnswers[currentUserAnswer._id] != currentUserAnswer.answer;
  };

  const submitAnswerQuestion = async () => {
    if (
      !currentUserAnswer ||
      !currentAnswerHadChanged() ||
      !currentSelectedUserAnswer()
    ) {
      if (!userExam.exam.tutor) {
        onNext();
      }
      return;
    }

    const res = await answerQuestion(
      currentUserAnswer._id,
      currentSelectedUserAnswer()
    );
    if (res && res.answerQuestion) {
      const answerQuestionData = res.answerQuestion;
      updateUserAnswer(answerQuestionData);
      setCurrentUserAnswer(answerQuestionData);
    }

    if (!userExam.exam.tutor) {
      onNext();
    }
  };

  const shouldRevealAnswer = () => {
    // Timed Mode

    if (userExam.status === USER_EXAM_STATUS.END) return true;

    if (!currentUserAnswer || !userExam.exam.tutor) return false;

    return !!currentUserAnswer.answer;
  };

  const onCreateNote = async ({ content }) => {
    const currQuestion = exam.questions[currQuestionIndex];
    const data = await createNote({
      input: {
        category_id: currQuestion.category._id,
        subCategory_id: currQuestion.subCategory._id,
        question_id: currQuestion._id,
        content: content,
      },
    });
    setNotes(notes => ({ ...notes, [currQuestion._id]: data.createNote }));
  };

  const onUpdateNote = async (id, { content }) => {
    const data = await updateNote({
      _id: id,
      input: {
        content: content,
      },
    });
    setNotes(notes =>
      Object.assign(notes, { [data.updateNote.question._id]: data.updateNote })
    );
  };

  const onRemoveNote = async note => {
    const data = await removeNote(note._id);
    if (data.removeNote)
      setNotes(notes => {
        delete notes[note.question._id];
        return notes;
      });
  };

  const onSuspend = async () => {
    await omitCurrentQuestion();
    await pauseExam(userExam._id);
    history.push(TEST_LIST_URL);
  };

  const onEnd = async () => {
    await omitCurrentQuestion();
    await endExam(userExam._id);
    history.push(`${COURSE_APP_SUB_ROUTE}/test-result/${userExam._id}`);
  };

  const userAnswerById = id =>
    userAnswers.filter(answer => answer.question._id === id).length
      ? userAnswers.filter(answer => answer.question._id === id)[0]
      : undefined;

  useEffect(() => {
    if (labKeyword) {
      const lowerCaseKeyword = labKeyword.toLowerCase();
      const labValuesKeyArr = Object.keys(labValuesObj);
      let tabKeyArr = [];
      labValuesKeyArr.forEach(key => {
        labValuesObj[key].forEach(row => {
          let colsArr = row.cols;
          for (let i = 0; i < colsArr.length; i++) {
            let col = colsArr[i];
            if (col.text) {
              if (col.text.toLowerCase().includes(lowerCaseKeyword)) {
                if (!tabKeyArr.includes(key)) {
                  tabKeyArr.push(key);
                }
              }
            } else {
              if (col.toLowerCase().includes(lowerCaseKeyword)) {
                if (!tabKeyArr.includes(key)) {
                  tabKeyArr.push(key);
                }
              }
            }
          }
        });

        setSearchLabTabArr(tabKeyArr);
      });
    } else {
      setSearchLabTabArr([]);
    }
  }, [labKeyword]);

  useProtectContent();

  if (!exam || !userExam || !currentUserAnswer || !userAnswers) return null;

  const renderAnswerRevealIcon = answer => {
    if (answer.validAnswer) {
      return (
        <FontAwesomeIcon icon={faCheck} style={{ fontSize: `${fontSize}pt` }} />
      );
    } else if (answer.content.trim() === currentSelectedUserAnswer()) {
      return (
        <FontAwesomeIcon
          icon={faTimes}
          style={{ fontSize: `${fontSize + 3}pt`, marginTop: 5 }}
        />
      );
    } else return null;
  };
  const renderStatistic = () => {
    const correctAnswer = questions[currQuestionIndex].answers.find(
      answer => answer.validAnswer
    );
    const correctAnswerIndex = questions[
      currQuestionIndex
    ].answers.findIndex(answer =>
      correctAnswer ? answer.content === correctAnswer.content : false
    );

    return (
      <div className="questionStatisticCont">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <div className="statisticBox">
              <div className="statisticLogo">
                <FontAwesomeIcon
                  icon={
                    correctAnswer &&
                    correctAnswer.content === currentUserAnswer.answer
                      ? faCheck
                      : faTimes
                  }
                  className={classes.statsIcon}
                />
              </div>
              <div className="statisticInfo">
                <span className="title">
                  {correctAnswerIndex !== undefined
                    ? String.fromCharCode(65 + correctAnswerIndex)
                    : "N/A"}
                </span>
                <p className="subTitle">Correct Answer</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="statisticBox">
              <div className="statisticLogo">
                <FontAwesomeIcon
                  icon={faChartBar}
                  className={classes.statsIcon}
                />
              </div>
              <div className="statisticInfo">
                <span className="title">
                  {correctAnswer && correctAnswer.percentage >= 0
                    ? `${correctAnswer.percentage.toFixed(2)} %`
                    : "N/A"}
                </span>
                <p className="subTitle">Answered correctly</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="statisticBox">
              <div className="statisticLogo">
                <FontAwesomeIcon icon={faClock} className={classes.statsIcon} />
              </div>
              <div className="statisticInfo">
                <span className="title">
                  {currentUserAnswer._timeUsed
                    ? moment.utc(currentUserAnswer._timeUsed).format("HH:mm:ss")
                    : "N/A"}
                </span>
                <p className="subTitle">Time spent</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="statisticBox">
              <div className="statisticLogo">
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className={classes.statsIcon}
                />
              </div>
              <div className="statisticInfo">
                <span className="title">
                  {currentUserAnswer._updatedOn
                    ? moment(currentUserAnswer._updatedOn).format("DD/MM/YYYY")
                    : "N/A"}
                </span>
                <p className="subTitle">Last Updated</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };
  const renderRevealContent = () => {
    switch (revealTab) {
      case "explanation":
        return currentUserAnswer
          ? parseHTMLContent(currentUserAnswer.question.explanation)
          : null;
      case "references":
        return questions[currQuestionIndex] &&
          questions[currQuestionIndex].resources &&
          questions[currQuestionIndex].resources.length > 0 ? (
          <ul>
            {questions[currQuestionIndex].resources.map((resource, index) => (
              <li key={index}>
                <a href={resource.url} target="_blank" rel="noreferrer">
                  {resource.name}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          "No references available"
        );
      case "furtherReading": {
        const readingList = questions[currQuestionIndex].lessons;

        return readingList && readingList.length > 0 ? (
          <ul>
            {readingList.map(topic => (
              <li key={topic._id}>
                <Link
                  to={`${COURSE_APP_SUB_ROUTE}/topics/${topic._id}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {topic.title}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          "No further reading available"
        );
      }
    }
  };

  const renderQuestion = () =>
    shouldRevealAnswer() ? (
      <div
        className="formCont htmlContentContainer"
        key={currQuestionIndex}
        onCopy={e => {
          e.preventDefault();
        }}
        onDragStart={e => {
          e.preventDefault();
        }}
      >
        <div className="fBody highlightableEl">
          {questions[currQuestionIndex] &&
            parseHTMLContent(questions[currQuestionIndex].question)}
        </div>
        <div className="answerCont">
          {questions[currQuestionIndex] &&
            questions[currQuestionIndex].answers.map((answer, index) => (
              <div
                className={clsx(
                  isDarkMode ? "itemContDark" : "itemContLight",
                  "itemCont"
                )}
                key={index}
              >
                <div
                  className={"iconCont"}
                  style={{
                    width: `${fontSize}pt`,
                  }}
                >
                  {renderAnswerRevealIcon(answer)}
                </div>
                <div className="checkBox">
                  {currentSelectedUserAnswer() === answer.content.trim() && (
                    <div className="activeBox" />
                  )}
                </div>
                <div className="fCaption">{`${String.fromCharCode(
                  65 + index
                )}.  ${answer.content}`}</div>
              </div>
            ))}
        </div>

        {renderStatistic()}
        <div className="tabCont">
          <div
            className={`tabItem ${revealTab === "explanation" ? "active" : ""}`}
            onClick={() => setRevealTab("explanation")}
          >
            <h4>Explanation</h4>
          </div>
          <div
            className={`tabItem ${revealTab === "references" ? "active" : ""}`}
            onClick={() => setRevealTab("references")}
          >
            <h4>References</h4>
          </div>
          <div
            className={`tabItem ${
              revealTab === "furtherReading" ? "active" : ""
            }`}
            onClick={() => setRevealTab("furtherReading")}
          >
            <h4>Further Reading</h4>
          </div>
        </div>
        <div className="answerRevealCont">
          <div className="revealContent highlightableEl">
            {renderRevealContent()}
          </div>
        </div>
        <div className="questionInfoCont">
          <div className="questionInfo">
            <div
              className={clsx(
                isDarkMode
                  ? classes.revealBottomTitleDark
                  : classes.revealBottomTitleLight,
                classes.revealBottomTitle
              )}
            >
              {questions[currQuestionIndex] &&
                questions[currQuestionIndex].category.name}
            </div>
            <p
              className={clsx(
                isDarkMode
                  ? classes.revealBottomSubtitleDark
                  : classes.revealBottomSubtitleLight,
                classes.revealBottomSubtitle
              )}
            >
              Subject
            </p>
          </div>
          <div className="questionInfo">
            <div
              className={clsx(
                isDarkMode
                  ? classes.revealBottomTitleDark
                  : classes.revealBottomTitleLight,
                classes.revealBottomTitle
              )}
            >
              {questions[currQuestionIndex] &&
                questions[currQuestionIndex].subCategory.name}
            </div>
            <p
              className={clsx(
                isDarkMode
                  ? classes.revealBottomSubtitleDark
                  : classes.revealBottomSubtitleLight,
                classes.revealBottomSubtitle
              )}
            >
              System
            </p>
          </div>
          <div className="questionInfo">
            <div
              className={clsx(
                isDarkMode
                  ? classes.revealBottomTitleDark
                  : classes.revealBottomTitleLight,
                classes.revealBottomTitle
              )}
            >
              {(questions[currQuestionIndex] &&
                questions[currQuestionIndex].lessons
                  .map(lesson => lesson.title)
                  .join(", ")) ||
                "N/A"}
            </div>
            <p
              className={clsx(
                isDarkMode
                  ? classes.revealBottomSubtitleDark
                  : classes.revealBottomSubtitleLight,
                classes.revealBottomSubtitle
              )}
            >
              Topic
            </p>
          </div>
        </div>
      </div>
    ) : (
      <div
        className="formCont htmlContentContainer"
        key={currQuestionIndex}
        onCopy={e => {
          e.preventDefault();
        }}
        onDragStart={e => {
          e.preventDefault();
        }}
      >
        <div className="fBody highlightableEl">
          {questions[currQuestionIndex] &&
            parseHTMLContent(questions[currQuestionIndex].question)}
        </div>
        <div className="answerListCont">
          <div className="answerCont">
            {questions[currQuestionIndex] &&
              questions[currQuestionIndex].answers.map((answer, index) => (
                <div
                  className={clsx(
                    isDarkMode ? "itemContDark" : "itemContLight",
                    "itemCont"
                  )}
                  key={index}
                  onClick={() => onAnswerSelect(answer.content.trim())}
                >
                  <div className="checkBox">
                    {currentSelectedUserAnswer() === answer.content.trim() && (
                      <div className="activeBox" />
                    )}
                  </div>
                  <div className="fCaption">{`${String.fromCharCode(
                    65 + index
                  )}.  ${answer.content}`}</div>
                </div>
              ))}
          </div>
        </div>

        <Button
          className="answerSubmit"
          variant="contained"
          color="secondary"
          classes={{
            disabled: classes.submitDisabledBtnDark,
          }}
          disabled={!currentAnswerHadChanged()}
          onClick={submitAnswerQuestion}
        >
          Submit
        </Button>
      </div>
    );

  const renderTimer = () => {
    const duration = moment.duration(Math.abs(timer), "milliseconds");
    const hours = duration
      .hours()
      .toString()
      .padStart(2, "0");
    const minutes = duration
      .minutes()
      .toString()
      .padStart(2, "0");
    const seconds = duration
      .seconds()
      .toString()
      .padStart(2, "0");

    return `${timer < 0 ? "-" : ""}${hours}:${minutes}:${seconds}`;
  };

  const isExamEnded = userExam.status === USER_EXAM_STATUS.END;

  return (
    <Fragment>
      <Helmet>
        <title>QBank Test List Page</title>
        <meta
          name="description"
          content="List of test create from QBank main portal"
        />
      </Helmet>
      {/* Time Over Modal */}
      <FreezeOverlayModal
        open={openTimeOverModal}
        ctaTitle={"Your allocated time is over"}
        leftTxt="Continue"
        rightTxt="End Test"
        leftActionFn={handleContinueTimeOver}
        rightActionFn={onEnd}
      />

      {/* Interctive Tutorials */}
      <CustomTour
        tourState={tourState}
        dispatchTour={dispatchTour}
        steps={INITIAL_TOUR_STATE.steps}
      />

      {/* Settings Menu */}
      <Drawer
        open={showSettingsMenu}
        anchor="right"
        onClose={handleCloseSettingsMenu}
        classes={{
          paper: classes.settingsPaper,
        }}
        BackdropProps={{ invisible: true }}
      >
        <div>
          <div className={classes.settingsHeader}>
            <div />
            <span className={classes.settingsHeaderTitle}>Settings</span>
            <div className={"clickable"} onClick={handleCloseSettingsMenu}>
              <CloseIcon />
            </div>
          </div>
          <div className={classes.settingsHeaderLabel}>Appearance</div>
          <div className={classes.settingsCont}>
            <div className={classes.settingsOption}>
              <div style={{ flex: 1 }}>
                <span className={classes.settingsLabel}>Font Size</span>
              </div>
              <div className={classes.btnGroup}>
                <ClickAwayListener
                  onClickAway={handleCloseMinimumFontSizeTooltip}
                >
                  <CustomTooltip
                    placement="top"
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    arrow
                    title={
                      <span style={{ color: "#65656a" }}>
                        Reached minimum font size
                      </span>
                    }
                    onClose={handleCloseMinimumFontSizeTooltip}
                    open={openMinimumFontSizeTooltip}
                  >
                    <label
                      className={clsx(
                        classes.minusBtnCont,
                        classes.fontSettingsBtnCont
                      )}
                      onClick={onDecreaseFontSize}
                    >
                      <img src={minusIcon} style={{ width: 15, height: 15 }} />
                    </label>
                  </CustomTooltip>
                </ClickAwayListener>

                <label
                  className={clsx(
                    classes.fontCaseBtnCont,
                    classes.fontSettingsBtnCont
                  )}
                  onClick={onRestoreDefaultFontSize}
                >
                  <img src={fontCaseIcon} style={{ width: 19, height: 19 }} />
                </label>
                <ClickAwayListener
                  onClickAway={handleCloseMaximumFontSizeTooltip}
                >
                  <CustomTooltip
                    placement="top"
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    arrow
                    title={
                      <span style={{ color: "#65656a" }}>
                        Reached maximum font size
                      </span>
                    }
                    onClose={handleCloseMaximumFontSizeTooltip}
                    open={openMaximumFontSizeTooltip}
                  >
                    <label
                      className={clsx(
                        classes.addBtnCont,
                        classes.fontSettingsBtnCont
                      )}
                      onClick={onIncreaseFontSize}
                    >
                      <img src={plusIcon} style={{ width: 15, height: 15 }} />
                    </label>
                  </CustomTooltip>
                </ClickAwayListener>
              </div>
            </div>
            <div
              className={classes.settingsOption}
              style={{ borderTop: "1px solid #ddd" }}
            >
              <div style={{ flex: 1 }}>
                <span className={classes.settingsLabel}>Show Timer</span>
              </div>
              <div>
                <Switch
                  checked={showTimer}
                  onChange={handleChangeShowTimer}
                  color="secondary"
                />
              </div>
            </div>
          </div>
        </div>
      </Drawer>

      <div className="testDetail">
        <ClickAwayListener
          onClickAway={() => {
            if (!window.getSelection().toString()) handleCloseHighlightMenu();
          }}
        >
          <div
            className={classes.highlightMenuCont}
            id="hightlightPopoverMenu"
            style={{
              top: highlightMenuState.top,
              left: highlightMenuState.left,
              visibility: highlightMenuState.show ? "visible" : "hidden",
              borderRadius: "4px",
            }}
          >
            <div className="d-flex">
              <div
                className={classes.highlightMenuBtn}
                onClick={() => {
                  highlightSelection(highlightMenuState.selectionNode);
                  handleCloseHighlightMenu();
                }}
                style={{
                  borderTopLeftRadius: "4px",
                  borderBottomLeftRadius: "4px",
                  borderLeft: "1px solid #4A4A4A",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  className={classes.hightLightIcon}
                  color={"#FF0"}
                />
              </div>

              <div
                className={classes.highlightMenuBtn}
                onClick={() => {
                  setShowFlashcardListModal(true);
                  setOpenFlashcardListModalState({
                    mode: FLASHCARD_MODE_TYPE["CREATE_WITH_ELEMENT"],
                    payload: {
                      type: HTML_TAG_TYPE["TEXT"],
                      html: highlightMenuState.selectionText,
                    },
                  });
                  handleCloseHighlightMenu();
                }}
              >
                <FontAwesomeIcon
                  icon={faBolt}
                  className={classes.toolIcon}
                  color={"#fff"}
                />
                <span>New</span>
              </div>

              <div
                className={classes.highlightMenuBtn}
                onClick={() => {
                  setShowFlashcardListModal(true);
                  setOpenFlashcardListModalState({
                    mode: FLASHCARD_MODE_TYPE["INSERT_WITH_ELEMENT"],
                    payload: {
                      type: HTML_TAG_TYPE["TEXT"],
                      html: highlightMenuState.selectionText,
                    },
                  });
                  handleCloseHighlightMenu();
                }}
                style={{
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                  borderRight: "1px solid #4A4A4A",
                }}
              >
                <span className={classes.stackCont}>
                  <FontAwesomeIcon
                    icon={faBolt}
                    className={classes.flashIcon}
                    color={"#fff"}
                  />
                  <CropDinOutlinedIcon
                    style={{
                      color: "#fff",
                    }}
                    className={classes.squareIcon}
                  />
                </span>

                <span>Existing</span>
              </div>
            </div>
          </div>
        </ClickAwayListener>

        {uiControls.showSidemenu && (
          <ClickAwayListener
            onClickAway={() => {
              if (mdDown) {
                setUiControls(controls => ({
                  ...controls,
                  showSidemenu: false,
                }));
              }
            }}
          >
            <div
              className={clsx(
                isDarkMode && classes.sideMenuDark,
                classes.sideMenu,
                "sideMenu"
              )}
            >
              {questions.map((question, index) => (
                <div
                  className={clsx(
                    currQuestionIndex === index && "active",
                    isDarkMode && "itemContDark",
                    "itemCont"
                  )}
                  key={index}
                  onClick={() => onSelect(index)}
                >
                  <div
                    className={`qbDot ${userAnswerById(question._id) &&
                      userAnswerById(question._id).answer &&
                      "hidden"}`}
                  />
                  <div className="fLabel">{index + 1}</div>
                  {userAnswerById(question._id) &&
                    userAnswerById(question._id).flag && (
                      <img
                        src={currQuestionIndex === index ? flagIconW : flagIcon}
                        alt="flag icon"
                        className="qbIcon"
                        style={{}}
                      />
                    )}
                </div>
              ))}
            </div>
          </ClickAwayListener>
        )}

        <div className="mainCont">
          <div
            className={clsx(
              isDarkMode ? classes.headContDark : classes.headContLight,
              "headCont"
            )}
          >
            <div className="menuBox">
              <img
                src={menuIconW}
                alt="menu icon"
                className="qbIcon clickable"
                onClick={() => toogleSidemenu()}
              />
              <div className="titleCont">
                <div className="fTitle">
                  Item {currQuestionIndex + 1} of {questions.length}
                </div>
                <div className="fCaption">
                  Question Id: {exam.questions[currQuestionIndex]._ref}
                </div>
              </div>
            </div>
            <div className="menuBox flexBox">
              {!mdDown ? (
                <div
                  className={clsx(
                    isDarkMode && "checkContDark",
                    "checkCont tour-step1"
                  )}
                  onClick={() => onFlag()}
                >
                  <div
                    className={`qbCheckBox ${
                      currentUserAnswer && currentUserAnswer.flag
                        ? "active"
                        : ""
                    }`}
                  >
                    <img
                      src={checkIconW}
                      alt="checkIcon"
                      className="checkIcon"
                    />
                  </div>
                  <img src={flagIconW} alt="flag icon" className="qbIcon" />
                  <div className="fTitle">Flag</div>
                </div>
              ) : (
                <div className="imgCont tour-step1" onClick={() => onFlag()}>
                  <img
                    src={
                      currentUserAnswer && currentUserAnswer.flag
                        ? bookmark1IconW
                        : bookmark0IconW
                    }
                    alt="Book Mark Icon"
                    className="qbIcon"
                    style={{ marginBottom: 5 }}
                  />
                </div>
              )}
              <div className="flexCont">
                <div
                  className={clsx(
                    currQuestionIndex <= 0 ? "noHover" : "clickable",
                    isDarkMode && "itemContDark",
                    "itemCont tour-step2"
                  )}
                  onClick={() => onPrev()}
                >
                  <img
                    src={currQuestionIndex <= 0 ? prevDisabledIconC : prevIconC}
                    alt="Previous Icon"
                    className="qbIcon"
                  />
                  <div
                    className="fLabel"
                    style={{
                      color: currQuestionIndex <= 0 ? "#c4c4c4" : "#fff",
                    }}
                  >
                    Previous
                  </div>
                </div>

                <div
                  className={clsx(
                    currQuestionIndex >= exam.questions.length - 1
                      ? "noHover"
                      : "clickable",
                    isDarkMode && "itemContDark",
                    "itemCont tour-step3"
                  )}
                  onClick={() => onNext()}
                >
                  <img
                    src={
                      currQuestionIndex >= exam.questions.length - 1
                        ? nextDisabledIconC
                        : nextIconC
                    }
                    alt="Next Icon"
                    className="qbIcon"
                  />
                  <div
                    className="fLabel"
                    style={{
                      color:
                        currQuestionIndex >= exam.questions.length - 1
                          ? "#c4c4c4"
                          : "#fff",
                    }}
                  >
                    Next
                  </div>
                </div>
              </div>
              {!smDown && (
                <div
                  className={clsx(
                    isDarkMode && "imgContDark",
                    "imgCont tour-step4"
                  )}
                  onClick={() => toggleFullScreen()}
                >
                  <img
                    src={!isInFullScreen() ? fullIconW : exitFullIcon}
                    alt="Full Screen Icon"
                    className="qbIcon"
                  />
                  <div className="fCaption">
                    {!isInFullScreen() ? "Full Screen" : "Exit Mode"}
                  </div>
                </div>
              )}
              {!smDown && (
                <div
                  className={clsx(isDarkMode && "imgContDark", "imgCont")}
                  onClick={startTour}
                >
                  <img src={helpIconW} alt="Tutorial Icon" className="qbIcon" />
                  <div className="fCaption">Tutorial</div>
                </div>
              )}
              <div
                className={clsx(
                  showLabValues && "activeBox",
                  isDarkMode && "imgContDark",
                  "imgCont tour-step5"
                )}
                onClick={toggleShowLabValues}
              >
                <img src={labIconW} alt="Lab Values Icon" className="qbIcon" />
                <div className="fCaption">Lab Values</div>
              </div>
              <div
                className={clsx(
                  showNote && "activeBox",
                  isDarkMode && "imgContDark",
                  "imgCont tour-step6"
                )}
                onClick={() => setShowNote(true)}
                style={{ padding: "5px 10px 0" }}
              >
                <img
                  src={whiteboardIconW}
                  alt="Notes Icon"
                  className="qbIcon"
                />
                <div className="fCaption">Notes</div>
              </div>
              <div
                className={clsx(
                  isDarkMode && "imgContDark",
                  showCalculator && "activeBox",
                  "imgCont tour-step7"
                )}
                onClick={() => setShowCalculator(!showCalculator)}
              >
                <img src={calcIconW} alt="Calculator Icon" className="qbIcon" />
                <div className="fCaption">Calculator</div>
              </div>

              {!smDown && (
                <div
                  className={clsx(
                    isDarkMode && "imgContDark",
                    "imgCont tour-step8"
                  )}
                  onClick={toggleIsDarkMode}
                >
                  {isDarkMode ? (
                    <Brightness4Icon className={classes.themeModeIcon} />
                  ) : (
                    <Brightness7Icon className={classes.themeModeIcon} />
                  )}
                  <div className="fCaption">
                    {isDarkMode ? "Dark Mode" : "Light Mode"}
                  </div>
                </div>
              )}

              <div
                className={clsx(
                  isDarkMode && "imgContDark",
                  "imgCont tour-step9"
                )}
                onClick={handleOpenSettingsMenu}
              >
                <img
                  src={settingIconW}
                  alt="Settings Icon"
                  className="qbIcon"
                />
                <div className="fCaption">Settings</div>
              </div>
            </div>
          </div>

          {/* Note Modal */}
          {questions.map((question, index) => (
            <NoteModal
              visible={showNote && index == currQuestionIndex}
              isDarkMode={isDarkMode}
              onClose={() => {
                setShowNote(!showNote);
              }}
              onSubmit={(data, instance) => {
                instance
                  ? onUpdateNote(instance._id, data)
                  : onCreateNote(data);
              }}
              instance={notes[question._id]}
              onRemove={onRemoveNote}
              key={index}
            />
          ))}

          {/* Feedback Modal */}
          {questions.map((question, index) => (
            <FeedbackModal
              visible={showFeedback && index == currQuestionIndex}
              isDarkMode={isDarkMode}
              onClose={() => {
                setShowFeedback(false);
              }}
              onSubmit={data => {
                createFeedback({
                  ...data,
                  exam_id: exam._id,
                  question_id: question._id,
                  resolved: false,
                });
              }}
              key={index}
            />
          ))}

          {/* Image Preview Modal */}
          <ImagePreviewModal
            visible={showImagePreview.show}
            imgAttr={showImagePreview.imgAttr}
            isDarkMode={isDarkMode}
            openFlashcardListModalState={openFlashcardListModalState}
            setOpenFlashcardListModalState={setOpenFlashcardListModalState}
            setShowFlashcardListModal={setShowFlashcardListModal}
            onClose={() => setShowImagePreview({ show: false, imgAttr: null })}
          />

          {/* FlashcardList Modal */}
          {flashcardsData && (
            <FlashcardListModal
              visible={showFlashcardListModal}
              isDarkMode={isDarkMode}
              flashcardsData={flashcardsData}
              currentQuestionID={userExam.exam.questions[currQuestionIndex]._id}
              openModalState={openFlashcardListModalState}
              setOpenModalState={setOpenFlashcardListModalState}
              searchedFlashcardsData={searchedFlashcardsData}
              setSearchedFlashcardsData={setSearchedFlashcardsData}
              onClose={() => {
                setShowFlashcardListModal(false);
                setOpenFlashcardListModalState({
                  mode: FLASHCARD_MODE_TYPE["MAIN"],
                });
              }}
              onRemoveFlashcard={onRemoveFlashcard}
              onCreateFlashcard={onCreateFlashcard}
              onUpdateFlashcard={onUpdateFlashcard}
              handleOpenFilterMenu={handleOpenFilterMenu}
              handleCloseFilterMenu={handleCloseFilterMenu}
              anchorFilterMenu={anchorFilterMenu}
              selectedSubjects={selectedSubjects}
              selectedSystems={selectedSystems}
              setSelectedSubjects={setSelectedSubjects}
              setSelectedSystems={setSelectedSystems}
              setApplyCategoriesFilter={setApplyCategoriesFilter}
              applyCategoriesFilter={applyCategoriesFilter}
              fetchFlashcards={fetchFlashcards}
              fetchFlashcardsByCategoriesFilter={
                fetchFlashcardsByCategoriesFilter
              }
              filteredTotal={filteredTotal}
            />
          )}

          <CalculatorModal
            visible={showCalculator}
            onClose={() => {
              setShowCalculator(show => !show);
            }}
          />

          {/* Body Cont */}
          <div
            className={clsx(
              isDarkMode ? "bodyContDark" : "bodyContLight",
              "bodyCont"
            )}
          >
            {/* Question Content */}
            {!(showLabValues && smDown) && (
              <div
                className={clsx(
                  isDarkMode
                    ? classes.questionContDark
                    : classes.questionContLight,
                  classes.questionCont
                )}
                style={{ fontSize: `${fontSize}pt` }}
              >
                {renderQuestion()}
              </div>
            )}

            {/* Lab Values */}
            {showLabValues && (
              <div
                className={clsx(
                  isDarkMode ? classes.labContDark : classes.labContLight,
                  classes.labCont,
                  "animated fadeInRight"
                )}
              >
                <div className={classes.searchCont}>
                  <Grid container spacing={2} justify="space-between">
                    <Grid item>
                      <input
                        type="text"
                        className={clsx(
                          isDarkMode
                            ? classes.searchBoxDark
                            : classes.searchBoxLight,
                          classes.searchBox
                        )}
                        onChange={handleChangeKeyword}
                      />
                    </Grid>
                    <Grid item>
                      <div className={classes.dFlex}>
                        <input
                          type="checkbox"
                          onChange={toggleShowSIReference}
                          checked={showSIReference}
                          className={classes.SICheckBox}
                        />
                        <span className={classes.SICheckBoxLabel}>
                          &nbsp;SI Reference Intervals
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </div>

                {/* Lab Values Tabs Header */}
                <div className={classes.dFlex}>
                  {Object.values(LAB_VALUES_CONSTANTS).map(labTab => (
                    <div
                      className={clsx(
                        selectedLabTab === labTab && classes.labTabHeaderActive,
                        isDarkMode
                          ? classes.labTabHeaderDark
                          : classes.labTabHeaderLight,
                        classes.labTabHeader
                      )}
                      style={{
                        borderBottom: searchLabTabArr.includes(labTab)
                          ? "4px solid rgba(247, 248, 0, 0.7)"
                          : "4px solid transparent",
                      }}
                      onClick={() => onClickLabTab(labTab)}
                      key={labTab}
                    >
                      {labTab}
                    </div>
                  ))}
                </div>

                {/* Lab Values Content Table */}
                <div
                  className={clsx(
                    isDarkMode
                      ? classes.labContentDark
                      : classes.labContentLight,
                    classes.labContent
                  )}
                >
                  <table className={classes.labTable}>
                    <tbody>
                      {labValuesObj[selectedLabTab].map((row, rowIndex) => {
                        const isSingleCol = row.cols.length === 1;
                        const newCols = hightlightParser(labKeyword, [
                          ...row.cols,
                        ]);

                        if (!isSingleCol && !showSIReference) {
                          // Remove SI Reference Column
                          newCols.pop();
                        }
                        return (
                          <Fragment key={rowIndex}>
                            <tr>
                              {newCols.map((col, colIndex) => {
                                // Handle Special HTML Tag
                                let colHTML = "";
                                if (typeof col === "object") {
                                  const spacing = "&nbsp;".repeat(col.spacing);
                                  colHTML = spacing + col.text;
                                } else if (
                                  rowIndex === 0 ||
                                  (colIndex === 0 &&
                                    rowIndex === 31 &&
                                    selectedLabTab ===
                                      LAB_VALUES_CONSTANTS.BLOOD) ||
                                  rowIndex === 0 ||
                                  (colIndex === 0 &&
                                    rowIndex === 14 &&
                                    selectedLabTab ===
                                      LAB_VALUES_CONSTANTS.URINE_AND_BMI)
                                ) {
                                  colHTML = `<b>${col}</b>`;
                                } else {
                                  colHTML = col;
                                }

                                return (
                                  <td
                                    dangerouslySetInnerHTML={{
                                      __html: colHTML,
                                    }}
                                    className={classes.labTableTd}
                                    style={{
                                      width: isSingleCol ? "100%" : "33%",
                                    }}
                                    colSpan={isSingleCol ? 3 : 1}
                                    key={colIndex}
                                  />
                                );
                              })}
                            </tr>
                            {row.showLine && (
                              <tr>
                                <td colSpan="3">
                                  <hr
                                    className={clsx(
                                      isDarkMode
                                        ? classes.hrDark
                                        : classes.hrLight,
                                      classes.hr
                                    )}
                                  />
                                </td>
                              </tr>
                            )}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>

          {/* Suspend Test Modal */}
          <Dialog onClose={handleCloseSuspendModal} open={showSuspendModal}>
            <DialogContent>
              Are you sure you want to suspend the test?
            </DialogContent>
            <CustomConfirmDialogActions>
              <CustomActionBtn onClick={onSuspend}>
                Suspend Test
              </CustomActionBtn>
              <CustomCancelBtn onClick={handleCloseSuspendModal}>
                Cancel
              </CustomCancelBtn>
            </CustomConfirmDialogActions>
          </Dialog>

          {/* End Test Modal */}
          <Dialog onClose={handleCloseEndModal} open={showEndModal}>
            <DialogContent>Are you sure you want to end?</DialogContent>
            <CustomConfirmDialogActions>
              <CustomActionBtn onClick={onEnd}>End Test</CustomActionBtn>
              <CustomCancelBtn onClick={handleCloseEndModal}>
                Cancel
              </CustomCancelBtn>
            </CustomConfirmDialogActions>
          </Dialog>

          {/* Bottom Bar */}
          <div
            className={clsx(
              isDarkMode ? classes.footContDark : classes.footContLight,
              "footCont"
            )}
          >
            <div className="countCont">
              {showTimer && (
                <div className={`fTitle ${timer < 0 ? "timeout" : ""}`}>
                  {`${
                    isExamEnded
                      ? "Total time spent"
                      : timed
                      ? "Block Time Remaining"
                      : "Block Time Used"
                  }: ${renderTimer()}`}
                </div>
              )}
              {exam && (
                <div className="fTitle">
                  {exam.tutor
                    ? exam.timed
                      ? "Tutor Timed"
                      : "Tutor"
                    : exam.timed
                    ? "Timed"
                    : "Untimed"}
                </div>
              )}
            </div>
            <div className="rightCont">
              <div
                className={clsx(
                  isDarkMode && "imgContDark",
                  "imgCont tour-step10"
                )}
                style={{ padding: "10px 0" }}
                onClick={() => {
                  setShowFlashcardListModal(true);
                }}
              >
                <img
                  src={flashIconW2}
                  alt="add flash card icon"
                  className="qbIcon flashcardIcon"
                />
                <div className="qbBubble">
                  {flashcardsData &&
                    flashcardsData.filter(
                      flashcard =>
                        flashcard.question_id ===
                        userExam.exam.questions[currQuestionIndex]._id
                    ).length}
                </div>
              </div>
              <div
                className={clsx(
                  isDarkMode && "imgContDark",
                  "imgCont tour-step11"
                )}
                onClick={() => {
                  setShowFeedback(true);
                }}
              >
                <img src={chatIconW} alt="feedback icon" className="qbIcon" />
                <div className="fCaption">Feedback</div>
              </div>
              {isExamEnded ? (
                <div
                  className={clsx(
                    isDarkMode && "imgContDark",
                    "imgCont exitReviewImgCont"
                  )}
                  onClick={exitReviewMode}
                >
                  <ExitToAppOutlinedIcon className={"exitIcon"} />
                  <div className="fCaption">Exit Review</div>
                </div>
              ) : (
                <Fragment>
                  <div
                    className={clsx(
                      isDarkMode && "imgContDark",
                      "imgCont tour-step12"
                    )}
                    onClick={handleOpenSuspendModal}
                  >
                    <img
                      src={pauseIconW}
                      alt="suspend icon"
                      className="qbIcon"
                    />
                    <div className="fCaption">Suspend</div>
                  </div>
                  <div
                    className={clsx(
                      isDarkMode && "imgContDark",
                      "imgCont tour-step13"
                    )}
                    onClick={handleOpenEndModal}
                  >
                    <img
                      src={stopIconW}
                      alt="End Block icon"
                      className="qbIcon"
                    />
                    <div className="fCaption">End Block</div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  addError: msg => dispatch(UIActions.addError(msg)),
  getUserExam: filter => dispatch(ExamActions.getUserExam(filter)),
  startExam: id => dispatch(ExamActions.startExam(id)),
  pauseExam: id => dispatch(ExamActions.pauseExam(id)),
  resumeExam: id => dispatch(ExamActions.resumeExam(id)),
  endExam: id => dispatch(ExamActions.endExam(id)),
  enterQuestion: (userExam_id, question_id) =>
    dispatch(ExamActions.enterQuestion(userExam_id, question_id)),
  pauseQuestion: (userExam_id, question_id) =>
    dispatch(ExamActions.pauseQuestion(userExam_id, question_id)),
  resumeQuestion: (userExam_id, question_id) =>
    dispatch(ExamActions.resumeQuestion(userExam_id, question_id)),
  omitAndEnterQuestion: (omitUserAnswer_id, userExam_id, question_id) =>
    dispatch(
      ExamActions.omitAndEnterQuestion(
        omitUserAnswer_id,
        userExam_id,
        question_id
      )
    ),
  flagQuestion: (id, flag) => dispatch(ExamActions.flagQuestion(id, flag)),
  answerQuestion: (id, answer) =>
    dispatch(ExamActions.answerQuestion(id, answer)),

  omitQuestion: id => dispatch(ExamActions.omitQuestion(id)),
  getNotes: () => dispatch(NoteActions.getNotes()),
  createNote: data => dispatch(NoteActions.createNote(data)),
  updateNote: data => dispatch(NoteActions.updateNote(data)),
  removeNote: data => dispatch(NoteActions.removeNote(data)),
  getTopicList: () => dispatch(TopicActions.getTopicList()),
  createFeedback: data => dispatch(FeedbackActions.createFeedback(data)),
  getFlashcards: filter => dispatch(FlashcardActions.getFlashcards(filter)),
  createFlashcard: data => dispatch(FlashcardActions.createFlashcard(data)),
  updateFlashcard: data => dispatch(FlashcardActions.updateFlashcard(data)),
  removeFlashcard: id => dispatch(FlashcardActions.removeFlashcard(id)),
  getUserExamAnswer: id => dispatch(ExamActions.getUserExamAnswer(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestDetailPage);
