import ordinal from "ordinal";

// sort Reports data
const sortReports = (reportData, orderBy, isAsc) =>
  reportData
    ? [...reportData].sort((a, b) => {
        const aRank = a.rank ? a.rank : 0;
        const bRank = b.rank ? b.rank : 0;
        const atotalUsedQuestion = !a.usage
          ? 0
          : parseInt(a.usage.split("/")[0]);
        const btotalUsedQuestion = !b.usage
          ? 0
          : parseInt(b.usage.split("/")[0]);

        if (isAsc) {
          switch (orderBy) {
            case "name":
              return a.name > b.name ? 1 : -1;
            case "firstAttemptCorrect":
            case "firstAttemptIncorrect":
            case "firstAttemptOmitted":
              const aOrderBy =
                a[orderBy] === 0 ? 0 : (a[orderBy] / atotalUsedQuestion) * 100;
              const bOrderBy =
                b[orderBy] === 0 ? 0 : (b[orderBy] / btotalUsedQuestion) * 100;

              return aOrderBy > bOrderBy ? 1 : -1;
            case "usage":
              return atotalUsedQuestion > btotalUsedQuestion ? 1 : -1;
            case "pRank":
              return aRank > bRank ? 1 : -1;
          }
        } else {
          switch (orderBy) {
            case "name":
              return a.name < b.name ? 1 : -1;
            case "firstAttemptCorrect":
            case "firstAttemptIncorrect":
            case "firstAttemptOmitted":
              const aOrderBy =
                a[orderBy] === 0 ? 0 : (a[orderBy] / atotalUsedQuestion) * 100;
              const bOrderBy =
                b[orderBy] === 0 ? 0 : (b[orderBy] / btotalUsedQuestion) * 100;

              return aOrderBy < bOrderBy ? 1 : -1;
            case "usage":
              return atotalUsedQuestion < btotalUsedQuestion ? 1 : -1;
            case "pRank":
              return aRank < bRank ? 1 : -1;
          }
        }
      })
    : [];

const parseReportDetail = reportData => {
  const rank = reportData.rank ? ordinal(reportData.rank) : "-";
  const totalUsedQuestion = !reportData.usage
    ? 0
    : parseInt(reportData.usage.split("/")[0]);
  const totalQuestion = !reportData.usage
    ? 0
    : parseInt(reportData.usage.split("/")[1]);
  const usagePercentStr = `${(
    (totalUsedQuestion / totalQuestion) *
    100
  ).toFixed(0)}%`;

  let correctPercentStr, incorrectPercentStr, omittedPercentStr;
  correctPercentStr = incorrectPercentStr = omittedPercentStr = "0%";

  if (totalUsedQuestion > 0) {
    correctPercentStr =
      reportData.firstAttemptCorrect === 0
        ? "0%"
        : `${(
            (reportData.firstAttemptCorrect / totalUsedQuestion) *
            100
          ).toFixed(0)}%`;
    incorrectPercentStr =
      reportData.firstAttemptIncorrect === 0
        ? "0%"
        : `${(
            (reportData.firstAttemptIncorrect / totalUsedQuestion) *
            100
          ).toFixed(0)}%`;
    omittedPercentStr =
      reportData.firstAttemptOmitted === 0
        ? "0%"
        : `${(
            (reportData.firstAttemptOmitted / totalUsedQuestion) *
            100
          ).toFixed(0)}%`;
  }

  return {
    rank,
    correctPercentStr,
    incorrectPercentStr,
    omittedPercentStr,
    usagePercentStr,
  };
};

const parseSystemPerformanceReport = userPerformanceData => {
  let systemsHashMap = {};
  userPerformanceData.category.forEach(data => {
    data.subCategory.forEach(subCategory => {
      if (subCategory["_id"] in systemsHashMap) {
        let temp = systemsHashMap[subCategory["_id"]];
        temp.push(subCategory);
        systemsHashMap[subCategory["_id"]] = temp;
      } else {
        systemsHashMap[subCategory["_id"]] = [subCategory];
      }
    });
  });

  for (const key in systemsHashMap) {
    let systemList = systemsHashMap[key];
    let system = {
      name: systemList[0].name,
      _id: systemList[0]._id,
      correct: 0,
      incorrect: 0,
      omitted: 0,
      firstAttemptCorrect: 0,
      firstAttemptIncorrect: 0,
      firstAttemptOmitted: 0,
    };

    let used = 0;
    let totalQuestion = 0;
    for (let i = 0; i < systemList.length; i++) {
      let temp = systemList[i];
      let usage = temp["usage"] ? temp["usage"].split("/") : null;
      const totalUsedQuestion = usage ? parseInt(usage[0]) : 0;
      const totalProvidedQuestion = usage ? parseInt(usage[1]) : 0;
      system["correct"] += temp["correct"];
      system["incorrect"] += temp["incorrect"];
      system["omitted"] += temp["omitted"];
      system["firstAttemptCorrect"] += temp["firstAttemptCorrect"];
      system["firstAttemptIncorrect"] += temp["firstAttemptIncorrect"];
      system["firstAttemptOmitted"] += temp["firstAttemptOmitted"];
      used += totalUsedQuestion;
      totalQuestion += totalProvidedQuestion;
    }
    system["usage"] = `${used}/${totalQuestion}`;
    systemsHashMap[key] = system;
  }
  return Object.values(systemsHashMap);
};

export { sortReports, parseReportDetail, parseSystemPerformanceReport };
