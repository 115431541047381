import React, { useState, useEffect, Fragment } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import CollectionsBookmarkOutlinedIcon from "@material-ui/icons/CollectionsBookmarkOutlined";
import LibraryBooksOutlinedIcon from "@material-ui/icons/LibraryBooksOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import CloseIcon from "@material-ui/icons/Close";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";
import ChipInput from "material-ui-chip-input";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import styles from "../FlashcardModal/styles";
import "./tinymce.css";
import {
  FLASHCARD_SIDE_TYPE,
  HTML_TAG_TYPE,
  FLASHCARD_MODE_TYPE,
} from "../../../constants";
import {
  compareObjects,
  convertVWToPX,
  isShowCategoriesInputPage,
  validateMaxLength,
} from "../../../utils";
import DoubleSideForm from "./DoubleSideForm";
import SingleSideForm from "./SingleSideForm";
import { selectInputProps, selectProps } from "./helper";
import {
  selectSubjectList,
  selectSystemList,
} from "../../../selectors/category";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => styles(theme));

const FlashcardForm = props => {
  const {
    flashcardlistModalWidth,
    isDarkMode,
    flashcard,
    mode,
    payload,
    currentQuestionID,
    onCreateFlashcard,
    onUpdateFlashcard,
    onClickCancelBtnHandler,
    handleGoBack,
    setIsUnsavedForm,
    setOpenModalState,
  } = props;

  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const isMinimumSizeAndMobileView =
    xsDown ||
    (flashcardlistModalWidth
      ? convertVWToPX(flashcardlistModalWidth) < 600
      : false);

  const {
    handleSubmit,
    control,
    reset,
    errors,
    getValues,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      front: "",
      back: "",
      subject: "",
      system: "",
      tags: [],
    },
  });

  const [editingFront, setEditingFront] = useState(true);
  const [initialForm, setInitialForm] = useState(null);
  const [showInsertModal, setShowInsertModal] = useState(false);
  const subjects = useSelector(selectSubjectList);
  const systems = useSelector(selectSystemList);

  const watchAllFields = watch();
  const watchSubject = watch("subject");
  const watchSystem = watch("system");

  const shouldShowCategoriesInput = flashcard
    ? !Boolean(flashcard.question_id)
    : isShowCategoriesInputPage(location.pathname);

  // Handle Confirmtion Modal Start
  const handleCloseInsertModal = () => {
    setShowInsertModal(false);
  };

  const handleOpenInsertModal = () => {
    setShowInsertModal(true);
  };
  // Handle Confirmtion Modal End

  // Default functions Start here
  const startEditingFrontView = () => {
    setEditingFront(true);
  };
  const startEditingBackView = () => {
    setEditingFront(false);
  };

  const onExchangeContent = form => {
    reset({
      front: form.back,
      back: form.front,
      tags: form.tags,
      subject: form.subject,
      system: form.system,
    });
  };
  // Default functions End here

  // CREATE_WITH_ELEMENT & INSERT_WITH_ELEMENT Case functions Start here
  const parsePayloadHTML = payload => {
    return payload.type === HTML_TAG_TYPE["IMG"]
      ? `<img src=${payload.html.url} width="100%" height="100%"/>`
      : `<p>${payload.html}</p>`;
  };

  const onClickInsertModalCloseBtn = () => {
    setOpenModalState({
      mode: FLASHCARD_MODE_TYPE["INSERT_WITH_ELEMENT"],
      payload,
    });
  };

  const onInsertExistingFlashcard = side => {
    let formFields = {
      front: flashcard ? flashcard.front : "",
      back: flashcard ? flashcard.back : "",
      tags: flashcard ? flashcard.tags : [],
    };

    formFields[side] = formFields[side] + parsePayloadHTML(payload);
    reset(formFields);
    handleCloseInsertModal();
  };
  // CREATE_WITH_ELEMENT & INSERT_WITH_ELEMENT Case functions End here

  // API Request Functions Start here
  const onSubmitCreate = async ({ front, back, subject, system, tags }) => {
    let flashcardInput = { front, back, tags };
    if (shouldShowCategoriesInput) {
      // Bind with Subject & System
      flashcardInput.category_id = subject;
      flashcardInput.subCategory_id = system;
    } else if (currentQuestionID) {
      // Bind with question
      flashcardInput.question_id = currentQuestionID;
    }
    await onCreateFlashcard({ input: flashcardInput });
    handleGoBack();
  };

  const onSubmitUpdate = async ({ front, back, subject, system, tags }) => {
    const flashcardInput = {
      front,
      back,
      tags,
    };
    if (shouldShowCategoriesInput) {
      flashcardInput.category_id = subject;
      flashcardInput.subCategory_id = system;
    }

    await onUpdateFlashcard({
      input: flashcardInput,
      _id: flashcard._id,
    });
    handleGoBack();
  };
  // API Request Functions End here

  useEffect(() => {
    let initialFormFields, resetFormFields;
    initialFormFields = resetFormFields = {
      front: "",
      back: "",
      subject: "",
      system: "",
      tags: [],
    };

    // INSERT_WITH_ELEMENT CASE
    if (
      (mode === FLASHCARD_MODE_TYPE["EDIT"] ||
        mode === FLASHCARD_MODE_TYPE["CREATE"]) &&
      payload
    ) {
      handleOpenInsertModal();
    }

    // EDIT CASE
    if (mode === FLASHCARD_MODE_TYPE["EDIT"]) {
      initialFormFields = resetFormFields = {
        front: flashcard ? flashcard.front : "",
        back: flashcard ? flashcard.back : "",
        tags: flashcard ? flashcard.tags : [],
        subject: flashcard && flashcard.category ? flashcard.category._id : "",
        system:
          flashcard && flashcard.subCategory ? flashcard.subCategory._id : "",
      };
    }
    // CREATE_WITH_ELEMENT CASE
    else if (mode === FLASHCARD_MODE_TYPE["CREATE_WITH_ELEMENT"]) {
      // Auto populate front side
      resetFormFields = {
        front: parsePayloadHTML(payload),
        back: "",
        tags: [],
      };
    }

    reset(resetFormFields);
    setInitialForm(initialFormFields);
  }, []);

  // Determine whether to open unsaved changes warning modal
  useEffect(() => {
    if (initialForm) {
      if (!compareObjects(initialForm, watchAllFields)) {
        setIsUnsavedForm(true);
      } else {
        setIsUnsavedForm(false);
      }
    }
  }, [
    watchAllFields.front,
    watchAllFields.back,
    watchAllFields.tags,
    watchAllFields.subject,
    watchAllFields.system,
  ]);

  return (
    <Fragment>
      {/* Insert HTML Modal */}
      {showInsertModal && (
        <div
          className={clsx(
            isDarkMode
              ? classes.insertModalContDark
              : classes.insertModalContLight,
            classes.insertModalCont
          )}
        >
          <div className={classes.closeIconCont}>
            <div>
              <CloseIcon
                className="clickable"
                onClick={onClickInsertModalCloseBtn}
              />
            </div>
          </div>
          <h4
            style={{
              fontSize: 18,
              marginLeft: 30,
              marginRight: 30,
              marginTop: 1,
              color: isDarkMode ? "#fff" : "#000",
            }}
          >
            Add to Flash Card
          </h4>
          <p
            style={{
              fontSize: 13,
              color: "#5C5C5C",
              margin: "0px 25px",
            }}
          >
            Please select which side you wish to add your selected content
          </p>
          <div className={classes.insertModalActionsCont}>
            <button
              className={classes.insertModalActionBtn}
              onClick={() =>
                onInsertExistingFlashcard(FLASHCARD_SIDE_TYPE["FRONT"])
              }
            >
              Front
            </button>
            <button
              className={classes.insertModalActionBtn}
              onClick={() =>
                onInsertExistingFlashcard(FLASHCARD_SIDE_TYPE["BACK"])
              }
            >
              Back
            </button>
          </div>
        </div>
      )}

      {/* Form Fields */}

      {/* Initialize form state */}
      <Controller
        control={control}
        name={"front"}
        rules={{
          validate: {
            maxLength: v => validateMaxLength(v, 2000),
            contentRequired: v =>
              Boolean(getValues("back")) ||
              Boolean(getValues("front")) ||
              "Please enter content in either sides of card",
          },
        }}
      />

      <Controller
        control={control}
        name={"back"}
        rules={{
          validate: {
            maxLength: v => validateMaxLength(v, 2000),
            contentRequired: v =>
              Boolean(getValues("back")) ||
              Boolean(getValues("front")) ||
              "Please enter content in either sides of card",
          },
        }}
      />

      {isMinimumSizeAndMobileView ? (
        <SingleSideForm
          isDarkMode={isDarkMode}
          watchAllFields={watchAllFields}
          setValue={setValue}
          editingFront={editingFront}
          startEditingFrontView={startEditingFrontView}
          startEditingBackView={startEditingBackView}
          bindWithCategories={shouldShowCategoriesInput}
        />
      ) : (
        <DoubleSideForm
          isDarkMode={isDarkMode}
          watchAllFields={watchAllFields}
          setValue={setValue}
          editingFront={editingFront}
          startEditingFrontView={startEditingFrontView}
          startEditingBackView={startEditingBackView}
          onExchangeContent={onExchangeContent}
          bindWithCategories={shouldShowCategoriesInput}
        />
      )}
      <Controller
        control={control}
        name="subject"
        rules={{
          required: shouldShowCategoriesInput
            ? "Subject cannot be empty"
            : false,
        }}
        defaultValue=""
      />

      <Controller
        control={control}
        name="system"
        rules={{
          required: shouldShowCategoriesInput
            ? "System cannot be empty"
            : false,
        }}
        defaultValue=""
      />
      {/* Categories Inputs Start Here */}
      {shouldShowCategoriesInput && (
        <div className={classes.categoriesInputCont}>
          <div className={classes.categoryInput}>
            <div
              className={clsx(classes.cardFace, classes.cardFaceActiveLight)}
              style={{ cursor: "default" }}
            >
              Subject
            </div>
            <TextField
              placeholder="Select a subject"
              value={watchSubject}
              onChange={e => {
                setValue("subject", e.target.value, { shouldValidate: true });
                setValue("system", "");
              }}
              error={!!errors.subject}
              helperText={errors.subject && errors.subject.message}
              InputProps={{
                ...selectInputProps(classes),
                startAdornment: (
                  <div className={classes.adornedStartIconContainer}>
                    <CollectionsBookmarkOutlinedIcon fontSize="small" />
                  </div>
                ),
              }}
              {...selectProps(classes)}
            >
              {subjects.map(subject => (
                <MenuItem value={subject._id} key={subject._id}>
                  {subject.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={classes.categoryInput}>
            <div
              className={clsx(classes.cardFace, classes.cardFaceActiveLight)}
              style={{ cursor: "default" }}
            >
              System
            </div>

            <TextField
              placeholder="Select a system"
              value={watchSystem}
              onChange={e =>
                setValue("system", e.target.value, { shouldValidate: true })
              }
              error={!!errors.system}
              helperText={errors.system && errors.system.message}
              disabled={!watchSubject}
              InputProps={{
                ...selectInputProps(classes),
                startAdornment: (
                  <div className={classes.adornedStartIconContainer}>
                    <LibraryBooksOutlinedIcon fontSize="small" />
                  </div>
                ),
              }}
              {...selectProps(classes)}
            >
              {systems
                .filter(system =>
                  Array.isArray(system.parents) && system.parents.length > 0
                    ? system.parent_ids.includes(watchSubject)
                    : false
                )
                .map(system => (
                  <MenuItem value={system._id} key={system._id}>
                    {system.name}
                  </MenuItem>
                ))}
            </TextField>
          </div>
        </div>
      )}
      {/* Categories Inputs End Here */}

      {/* Tags Input Start Here */}
      <div className={classes.tagsInputCont}>
        <div className={classes.tagIconCont}>
          <LocalOfferOutlinedIcon className={classes.tagInputIcon} />
        </div>
        <Controller
          control={control}
          name="tags"
          render={({ onChange, value, name }) => (
            <ChipInput
              onAdd={chip => {
                if (!value.includes(chip)) onChange([...value, chip]);
              }}
              onDelete={chip => onChange(value.filter(c => c !== chip))}
              value={value}
              placeholder="Tag name"
              name={name}
              style={{ marginTop: 8 }}
              InputProps={{
                style: { fontSize: 14, height: "100%" },
              }}
              disableUnderline
              fullWidth
            />
          )}
        />
      </div>
      {/* Tags Input End Here */}

      {/* Actions Button Start Here */}
      <div className={classes.formActionsCont}>
        {/* Error Messages */}
        <p
          className={classes.errorMsg}
          style={{
            fontSize: isMinimumSizeAndMobileView ? 11 : 13,
            visibility: !!errors.front || !!errors.back ? "visible" : "hidden",
          }}
        >
          {Object.values(errors).length > 0
            ? Object.values(errors)[0]["message"]
            : ""}
        </p>

        <div className={classes.formActionsBtnCont}>
          <Button
            className={clsx(
              isDarkMode
                ? classes.formActionsButtonDark
                : classes.formActionsButtonLight,
              classes.formActionsButton
            )}
            style={{ marginLeft: isMinimumSizeAndMobileView ? -15 : 0 }}
            onClick={onClickCancelBtnHandler}
          >
            Cancel
          </Button>

          {isMinimumSizeAndMobileView && (
            <FontAwesomeIcon
              onClick={() => {
                onExchangeContent(
                  getValues(["front", "back", "tags", "subject", "system"])
                );
              }}
              icon={faExchangeAlt}
              color={isDarkMode ? "#fff" : "rgba(74,74,74,.7)"}
              className={classes.exchangeIcon}
            />
          )}

          {mode === FLASHCARD_MODE_TYPE["CREATE"] ||
          mode === FLASHCARD_MODE_TYPE["CREATE_WITH_ELEMENT"] ? (
            <Button
              className={clsx(
                isDarkMode
                  ? classes.formActionsButtonDark
                  : classes.formActionsButtonLight,
                classes.formActionsButton
              )}
              onClick={handleSubmit(onSubmitCreate)}
            >
              Save
            </Button>
          ) : (
            <Button
              className={clsx(
                isDarkMode
                  ? classes.formActionsButtonDark
                  : classes.formActionsButtonLight,
                classes.formActionsButton
              )}
              onClick={handleSubmit(onSubmitUpdate)}
            >
              Update
            </Button>
          )}
        </div>
      </div>

      {/* Actions Button End Here */}
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FlashcardForm);
