import * as UIActions from "./uiAction";
import * as FlashcardApi from "../services/flashcard";
import { AppConfig } from "../configs";

const { client } = AppConfig;

export const getFlashcards = filter => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.query({
      query: FlashcardApi.GET_FLASHCARDS,
      variables: { filter },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError(
          "We are having problem getting flashcards. Please try again later."
        )
      );
  }
};

export const createFlashcard = data => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: FlashcardApi.CREATE_FLASHCARD,
      variables: data,
    });
    dispatch(UIActions.showSuccess("Flashcard created successfully."));
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error creating flashcard"));
  }
};

export const updateFlashcard = data => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: FlashcardApi.UPDATE_FLASHCARD,
      variables: data,
    });
    dispatch(UIActions.showSuccess("Flashcard updated successfully."));
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error updating flashcard"));
  }
};

export const removeFlashcard = id => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: FlashcardApi.REMOVE_FLASHCARD,
      variables: { _id: id },
    });
    dispatch(UIActions.showSuccess("Flashcard removed successfully."));
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error removing flashcard"));
  }
};
