import React, {
  Fragment,
  useState,
  useEffect,
  useReducer,
  useMemo,
} from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";
import Grid from "@material-ui/core/Grid";
import FlightTakeoffRoundedIcon from "@material-ui/icons/FlightTakeoffRounded";
import { uniqBy } from "lodash";

import { upArrowIcon, checkIconW } from "../../resources/assets";
import * as ExamActions from "../../actions/examAction";
import MultipleRowTooltipBody from "../../Components/TootipBody/CreateTestTooltipBody";
import CustomTooltip from "../../Components/CustomTooltip";
import { testModeArr, questionModeArr } from "./tooltipArr";
import { INITIAL_STATE } from "./tourSteps";
import tourReducer from "../../Components/CustomTour/tourReducer";
import CustomTour from "../../Components/CustomTour";
import { REGEX } from "../../constants";
import { COURSE_APP_SUB_ROUTE } from "../../routes/constants";
import {
  getCategorizedCount,
  getSecondsFromHHMMSS,
  getUniqueSystemChildrenDocs,
  toHHMMSS,
  trimStr,
} from "../../utils";
import styles from "./styles";

const BlueSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#2196f3",
    },
    "&$checked + $track": {
      backgroundColor: "#2196f3",
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles(theme => styles(theme));

const CreateTest = ({ getCategoryList, createTest, getUserExam }) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    register,
    trigger,
    formState,
    handleSubmit,
    watch,
    getValues,
    setValue,
    control,
    errors,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      selectedQuestion: "unused",
      selectedSubject: [],
      selectedSystem: [],
      timer: 84,
      totalTime: "00:01:24",
      questionRefs: "",
    },
  });
  const { isValid } = formState;

  const selectedQuestion = watch("selectedQuestion");
  const selectedSubject = watch("selectedSubject");
  const selectedSystem = watch("selectedSystem");
  const questionRefs = watch("questionRefs");

  const [openTestModeTooltip, setOpenTestModeTooltip] = useState(false);
  const [openQuestionModeTooltip, setOpenQuestionModeTooltip] = useState(false);
  const [openSubjectsTooltip, setOpenSubjectsTooltip] = useState(false);
  const [uiControls, setUiControls] = useState({
    showTestMode: true,
    showQuestionMode: true,
    showSubject: true,
    showSystem: true,
    showNoQuestion: true,
    showTotalTime: true,
    showCustomMode: true,
  });
  const [subjects, setSubjects] = useState(null);
  const [testID, setTestID] = useState("");

  // Filter Categorized Count Function
  const filterBySelectedSubject = c => selectedSubject.includes(c.category_id);
  const filterBySelectedSystem = (subject, system) => c =>
    selectedSystem.includes(system._id) && c.category_id === subject._id;

  // Custom Mode related functions Start here
  const isValidQuestionRefs = str => {
    let isValid = true;
    if (selectedQuestion !== "custom") return true;
    const trimmedStr = trimStr(str);

    const questionRefsArr = trimmedStr.split(",");

    if (Array.isArray(questionRefsArr) && questionRefsArr.length > 0) {
      questionRefsArr.map(ref => {
        // Check is Empty Str Or is Not Digit
        if (!ref || !/^\d+$/.test(ref)) {
          isValid = false;
        }
      });
    }
    return isValid;
  };

  const fetchUserExamByRef = async () => {
    const filter = {
      key: "_ref",
      value: testID.startsWith("AK") ? testID.substr(2) : testID,
    };
    const res = await getUserExam(filter);
    if (res && res.userExam) {
      const questionsRefsStr = res.userExam.exam.questions
        .map(question => question._ref)
        .join(",");
      setValue("questionRefs", questionsRefsStr, { shouldValidate: true });
    }
  };
  // Custom Mode related functions End here

  // Tour state related Start here
  const [tourState, dispatchTour] = useReducer(
    tourReducer(INITIAL_STATE),
    INITIAL_STATE
  );
  const startTour = () => {
    // Start the tour manually
    dispatchTour({ type: "RESTART" });
  };
  // Tour state related End here

  // Tooltip State Control Start here
  const handleCloseSubjectsTooltip = () => {
    setOpenSubjectsTooltip(false);
  };

  const toggleSubjectsTooltip = () => {
    setOpenSubjectsTooltip(!openSubjectsTooltip);
  };

  const handleCloseTestModeTooltip = () => {
    setOpenTestModeTooltip(false);
  };

  const toggleTestModeTooltip = () => {
    setOpenTestModeTooltip(!openTestModeTooltip);
  };

  const handleCloseQuestionModeTooltip = () => {
    setOpenQuestionModeTooltip(false);
  };

  const toggleQuestionModeTooltip = () => {
    setOpenQuestionModeTooltip(!openQuestionModeTooltip);
  };
  // Tooltip State Control End here

  const onExpand = section => {
    setUiControls(c => Object.assign({}, c, { [section]: !c[section] }));
  };

  const getCategories = async () => {
    const res = await getCategoryList();
    if (res && res.categories) {
      setSubjects(res.categories.filter(item => item.type === "subject"));
    }
  };

  // Trigger validation for question mode when subjects change
  const triggerValidateQuestionMode = async () => {
    await trigger("selectedQuestion");
  };

  useEffect(() => {
    if (subjects) triggerValidateQuestionMode();
  }, [subjects]);

  // Trigger validation for questionRefs when selectedQuestion change
  const triggerValidateQuestionRefs = async () => {
    await trigger("questionRefs");
  };

  useEffect(() => {
    triggerValidateQuestionRefs();
  }, [selectedQuestion]);

  // Trigger validation for noQuestion when selectedSubject change
  const triggerValidateNoQuestion = async () => {
    await trigger("noQuestion");
  };

  useEffect(() => {
    triggerValidateNoQuestion();
  }, [selectedSubject, selectedSystem]);

  // Timer related Start here
  const watchNoQuestion = watch("noQuestion");
  const watchTotalTime = watch("totalTime");
  const watchTimer = watch("timer");
  const watchTimed = watch("timed");

  useEffect(() => {
    if (isValidQuestionRefs(questionRefs)) {
      const trimmedStr = trimStr(questionRefs);
      if (trimmedStr) {
        setValue(
          "totalTime",
          toHHMMSS(watchTimer * trimmedStr.split(",").length)
        );
      }
    }
  }, [questionRefs]);

  // Change totalTime when noQuestion change
  useEffect(() => {
    if (watchNoQuestion > 0) {
      setValue("totalTime", toHHMMSS(watchTimer * watchNoQuestion));
    }
  }, [watchNoQuestion]);

  useEffect(() => {
    if (selectedQuestion === "custom" && isValidQuestionRefs(questionRefs)) {
      const trimmedStr = trimStr(questionRefs);
      if (trimmedStr) {
        setValue(
          "totalTime",
          toHHMMSS(watchTimer * trimmedStr.split(",").length)
        );
      }
    }
  }, [watchTimed, selectedQuestion]);

  // Change totalTime value when timer change
  useEffect(() => {
    if (selectedQuestion !== "custom" && watchNoQuestion > 0) {
      setValue("totalTime", toHHMMSS(watchTimer * watchNoQuestion));
    } else if (
      selectedQuestion === "custom" &&
      isValidQuestionRefs(questionRefs)
    ) {
      const trimmedStr = trimStr(questionRefs);
      if (trimmedStr) {
        setValue(
          "totalTime",
          toHHMMSS(watchTimer * trimmedStr.split(",").length)
        );
      }
    }
  }, [watchTimer]);

  // Change timer value when totalTime change
  useEffect(() => {
    // Valid Total Time Format
    if (REGEX["HH:MM:SS"].test(watchTotalTime)) {
      if (selectedQuestion !== "custom" && watchNoQuestion > 0) {
        const seconds = getSecondsFromHHMMSS(watchTotalTime) / watchNoQuestion;
        const formattedSecond = Math.round(seconds * 100) / 100;
        setValue("timer", formattedSecond);
      } else if (
        selectedQuestion === "custom" &&
        isValidQuestionRefs(questionRefs)
      ) {
        const trimmedStr = trimStr(questionRefs);
        if (trimmedStr) {
          const seconds =
            getSecondsFromHHMMSS(watchTotalTime) / trimmedStr.split(",").length;
          const formattedSecond = Math.round(seconds * 100) / 100;
          setValue("timer", formattedSecond);
        }
      }
    }
  }, [watchTotalTime]);
  // Timer related End here

  // API Request for generating test
  const onSubmit = async ({
    noQuestion,
    selectedQuestion,
    selectedSystem,
    timed,
    timer,
    tutor,
    questionRefs,
  }) => {
    let input = {
      allocateTime: timed ? parseInt(timer) * parseInt(noQuestion) : 0,
      categoryOptions:
        Array.isArray(selectedSubject) && selectedSubject.length > 0
          ? selectedSubject.map(item => {
              return { _id: item };
            })
          : [],
      subCategoryOptions:
        Array.isArray(selectedSystem) && selectedSystem.length > 0
          ? selectedSystem.map(item => {
              return { _id: item };
            })
          : [],
      question_mode: selectedQuestion,
      tutor: tutor,
      timed: timed,
      totalQuestions: parseInt(noQuestion),
      type: "test",
    };
    if (selectedQuestion === "custom") {
      const questionRefsArr = questionRefs.split(",").map(ref => Number(ref));
      input.question_refs = questionRefsArr;
      input.totalQuestions = questionRefsArr.length;
    }
    const result = await createTest({
      input,
    });

    if (result && result.createUserExam) {
      history.push(
        `${COURSE_APP_SUB_ROUTE}/test-detail/${result.createUserExam._id}`
      );
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const systemOptions = useMemo(() => {
    if (!subjects || selectedSubject.length === 0) return [];

    let tempSubjects = subjects.filter(subject =>
      selectedSubject.includes(subject._id)
    );

    return getUniqueSystemChildrenDocs(tempSubjects);
  }, [subjects, selectedSubject]);

  const availableSubjects = useMemo(() => {
    if (!subjects) return [];

    return subjects.filter(
      subject => getCategorizedCount(subject._count, selectedQuestion) !== 0
    );
  }, [subjects, selectedQuestion]);

  const nonSelectedSystems = useMemo(() => {
    if (!subjects) return [];

    let tempSubjects = availableSubjects.filter(
      subject => !selectedSubject.includes(subject._id)
    );

    return getUniqueSystemChildrenDocs(tempSubjects).filter(
      system =>
        !selectedSystem.includes(system._id) &&
        getCategorizedCount(system._count, selectedQuestion) !== 0
    );
  }, [subjects, selectedSubject]);

  if (!subjects) return null;

  const selectedAllSubjects =
    Array.isArray(selectedSubject) &&
    selectedSubject.length > 0 &&
    selectedSubject.length === subjects.length;
  const selectedAllSystems =
    Array.isArray(selectedSystem) &&
    selectedSystem.length > 0 &&
    selectedSystem.length === systemOptions.length;
  // Validate Function
  const isValidQuestionMode = question => {
    if (question === "custom") return true;
    const totalQuestionModeSubjects =
      subjects.length > 0
        ? subjects.reduce(
            (acc, subject) =>
              acc + getCategorizedCount(subject._count, question),
            0
          )
        : 0;

    if (totalQuestionModeSubjects > 0) return true;
    else return false;
  };

  return (
    <Fragment>
      <Helmet>
        <title>QBank Create Test Page</title>
        <meta
          name="description"
          content="QBank create test allow user to create test"
        />
      </Helmet>
      <CustomTour
        tourState={tourState}
        dispatchTour={dispatchTour}
        steps={INITIAL_STATE.steps}
      />
      <div className={classes.buttonContainer}>
        <button className={classes.button} onClick={startTour}>
          <FlightTakeoffRoundedIcon className={classes.launchIcon} />
          Launch Tutorial
        </button>
      </div>
      <div className="createTestPage">
        <form
          onSubmit={e => {
            e.preventDefault();
            if (isValid) {
              handleSubmit(onSubmit)();
            }
          }}
        >
          <div className="bodyCont">
            <div className="titleBox">
              <div className="fLabel">
                <span className={clsx("tour-step1", classes.stepContainer)}>
                  Test Mode
                  <ClickAwayListener onClickAway={handleCloseTestModeTooltip}>
                    <CustomTooltip
                      placement="right"
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      arrow
                      title={<MultipleRowTooltipBody rowArr={testModeArr} />}
                      onClose={handleCloseTestModeTooltip}
                      open={openTestModeTooltip}
                    >
                      <InfoRoundedIcon
                        onClick={toggleTestModeTooltip}
                        className={classes.infoIcon}
                      />
                    </CustomTooltip>
                  </ClickAwayListener>
                </span>
              </div>
              <img
                onClick={() => onExpand("showTestMode")}
                src={upArrowIcon}
                alt=""
                className={uiControls.showTestMode ? "qbIcon" : "qbIcon active"}
              />
            </div>
            {uiControls.showTestMode && (
              <div className="sectionBox">
                <div className="itemBox">
                  <BlueSwitch
                    defaultChecked
                    id="tutor"
                    name="tutor"
                    inputRef={register}
                  />
                  <div className="fLabel">Tutor</div>
                </div>
                <div className="itemBox">
                  <BlueSwitch id="timed" name="timed" inputRef={register} />
                  <div className="fLabel">Timed</div>
                </div>
                {watch("timed") && (
                  <div className="itemBox">
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <TextField
                          id="timer"
                          name="timer"
                          type="number"
                          label="Seconds"
                          variant="filled"
                          inputRef={register({
                            validate: value =>
                              getValues("timed")
                                ? !!value || "Time field cannot be empty"
                                : true,
                            min: {
                              value: 1,
                              message: "Second must be more than or equal to 1",
                            },
                          })}
                          error={!!errors.timer}
                          helperText={errors.timer && errors.timer.message}
                          inputProps={{
                            min: 1,
                          }}
                          InputProps={{
                            style: { fontSize: 15 },
                          }}
                          InputLabelProps={{
                            style: {
                              fontSize: 15,
                              color: "rgba(0, 0, 0, 0.25)",
                            },
                          }}
                          FormHelperTextProps={{
                            style: { fontSize: 13 },
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <div
                          className={clsx(
                            !!errors.timer && classes.mb15,
                            classes.customFLabelCont,
                            "fLabel"
                          )}
                        >
                          <div>Time allocated per question</div>
                          <div className="qbTag">Default 84s</div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </div>
            )}
            <div className="titleBox">
              <div className="fLabel">
                <span className={clsx("tour-step2", classes.stepContainer)}>
                  Question Mode
                  <ClickAwayListener
                    onClickAway={handleCloseQuestionModeTooltip}
                  >
                    <CustomTooltip
                      placement="right"
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      arrow
                      title={
                        <MultipleRowTooltipBody rowArr={questionModeArr} />
                      }
                      onClose={handleCloseQuestionModeTooltip}
                      open={openQuestionModeTooltip}
                    >
                      <InfoRoundedIcon
                        onClick={toggleQuestionModeTooltip}
                        className={classes.infoIcon}
                      />
                    </CustomTooltip>
                  </ClickAwayListener>
                </span>
              </div>
              <img
                onClick={() => onExpand("showQuestionMode")}
                src={upArrowIcon}
                alt=""
                className={
                  uiControls.showQuestionMode ? "qbIcon" : "qbIcon active"
                }
              />
            </div>
            {uiControls.showQuestionMode && (
              <Controller
                control={control}
                name="selectedQuestion"
                rules={{
                  validate: isValidQuestionMode,
                }}
                defaultValue={"unused"}
                render={({ onChange, value }) => (
                  <div className="sectionBox">
                    {["unused", "incorrect", "marked", "all"].map(
                      (question, index) => {
                        const totalSubjects = subjects.reduce(
                          (acc, subject) =>
                            acc + getCategorizedCount(subject._count, question),
                          0
                        );

                        return (
                          <div
                            className={clsx(
                              "itemBox",
                              totalSubjects !== 0 && "clickable"
                            )}
                            key={index}
                            onClick={() => {
                              if (totalSubjects !== 0) {
                                onChange(question);
                                // Search available subjects and systems with no zero count of its questionMode
                                const availableSubjects = subjects.filter(
                                  subject =>
                                    getCategorizedCount(
                                      subject._count,
                                      question
                                    ) !== 0
                                );

                                const availableSystems = availableSubjects.flatMap(
                                  subject =>
                                    subject.children.filter(
                                      child =>
                                        getCategorizedCount(
                                          child._count,
                                          question
                                        ) !== 0
                                    )
                                );
                                // Mapping into its `_id` field
                                const availableSubjectIDs = availableSubjects.map(
                                  subject => subject._id
                                );
                                const availableSystemIDs = availableSystems.map(
                                  system => system._id
                                );

                                // Filter the selectedSubject arr & selectedSystem arr based on available availableSubjectIDs & availableSystemIDs
                                let newSubjects = selectedSubject;
                                newSubjects = newSubjects.filter(subjectID =>
                                  availableSubjectIDs.includes(subjectID)
                                );

                                let newSystems = selectedSystem;
                                newSystems = newSystems.filter(systemID =>
                                  availableSystemIDs.includes(systemID)
                                );

                                // Set newValue into react-hook-form state
                                setValue("selectedSubject", newSubjects, {
                                  shouldValidate: true,
                                });
                                setValue("selectedSystem", newSystems, {
                                  shouldValidate: true,
                                });
                              }
                            }}
                          >
                            <div
                              className={clsx(
                                value === question && totalSubjects !== 0
                                  ? "checkBox circle active"
                                  : "checkBox circle",
                                totalSubjects === 0 && classes.disabledCheckbox
                              )}
                            >
                              {totalSubjects !== 0 && (
                                <img
                                  src={checkIconW}
                                  alt=""
                                  className="qbIcon"
                                />
                              )}
                            </div>
                            <div className="fLabel">
                              {question.charAt(0).toUpperCase() +
                                question.slice(1)}
                            </div>
                            <div className="qbTag">{totalSubjects}</div>
                          </div>
                        );
                      }
                    )}
                    <div
                      className="itemBox clickable"
                      onClick={() => onChange("custom")}
                    >
                      <div
                        className={
                          value === "custom"
                            ? "checkBox circle active"
                            : "checkBox circle"
                        }
                      >
                        <img src={checkIconW} alt="" className="qbIcon" />
                      </div>
                      <div className="fLabel">Custom</div>
                    </div>
                    {!!errors.selectedQuestion && (
                      <p className={classes.errorMsg}>
                        {"Please select valid Question Mode"}
                      </p>
                    )}
                  </div>
                )}
              />
            )}

            {selectedQuestion !== "custom" ? (
              <Fragment>
                <Controller
                  control={control}
                  name="selectedSubject"
                  rules={{
                    validate: value => value.length > 0,
                  }}
                  defaultValue={[]}
                  render={({ onChange, value }) => (
                    <Fragment>
                      <div className="titleBox">
                        <div className="fLabel">
                          <span
                            className={clsx(
                              "tour-step3",
                              classes.stepContainer
                            )}
                          >
                            <div
                              className={clsx(
                                selectedAllSubjects
                                  ? "checkBox header active"
                                  : "checkBox header",
                                !!errors.selectedQuestion &&
                                  classes.disabledCheckbox
                              )}
                              onClick={() => {
                                if (!Boolean(errors.selectedQuestion)) {
                                  if (selectedAllSubjects) {
                                    onChange([]);
                                    setValue("selectedSystem", [], {
                                      shouldValidate: true,
                                    });
                                  } else {
                                    const nonSelectedChilds = nonSelectedSystems.map(
                                      system => system._id
                                    );
                                    onChange(
                                      availableSubjects.map(
                                        subject => subject._id
                                      )
                                    );
                                    const finalSelectedSystem = uniqBy(
                                      getValues("selectedSystem").concat(
                                        nonSelectedChilds
                                      )
                                    );

                                    setValue(
                                      "selectedSystem",
                                      finalSelectedSystem,
                                      { shouldValidate: true }
                                    );
                                  }
                                }
                              }}
                            >
                              {!errors.selectedQuestion && (
                                <img
                                  src={checkIconW}
                                  alt=""
                                  className="qbIcon"
                                />
                              )}
                            </div>
                            Subjects
                            <ClickAwayListener
                              onClickAway={handleCloseSubjectsTooltip}
                            >
                              <CustomTooltip
                                placement="right"
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                arrow
                                title={
                                  <div>
                                    Some questions may be shared across
                                    subjects.
                                  </div>
                                }
                                onClose={handleCloseSubjectsTooltip}
                                open={openSubjectsTooltip}
                              >
                                <InfoRoundedIcon
                                  onClick={toggleSubjectsTooltip}
                                  className={classes.infoIcon}
                                />
                              </CustomTooltip>
                            </ClickAwayListener>
                          </span>
                        </div>
                        <img
                          src={upArrowIcon}
                          alt=""
                          className={
                            uiControls.showSubject ? "qbIcon" : "qbIcon active"
                          }
                          onClick={() => onExpand("showSubject")}
                        />
                      </div>
                      {uiControls.showSubject && (
                        <Fragment>
                          <div className="sectionBox">
                            {subjects.map(subject => {
                              const subjectCount = getCategorizedCount(
                                subject._count,
                                selectedQuestion
                              );

                              return (
                                <div
                                  className={clsx(
                                    "itemBox fixed",
                                    subjectCount !== 0 && "clickable"
                                  )}
                                  key={subject._id}
                                  onClick={() => {
                                    const childrenIds = subject.children
                                      .filter(
                                        child =>
                                          getCategorizedCount(
                                            child._count,
                                            selectedQuestion
                                          ) !== 0
                                      )
                                      .map(child => child._id);
                                    if (subjectCount !== 0) {
                                      if (value.includes(subject._id)) {
                                        onChange(
                                          value
                                            .map(
                                              item =>
                                                item !== subject._id && item
                                            )
                                            .filter(item => item !== false)
                                        );
                                        setValue(
                                          "selectedSystem",
                                          getValues("selectedSystem")
                                            .map(
                                              item =>
                                                !childrenIds.includes(item) &&
                                                item
                                            )
                                            .filter(item => item !== false),
                                          { shouldValidate: true }
                                        );
                                      } else {
                                        onChange(value.concat(subject._id));
                                        const finalSelectedSystem = uniqBy(
                                          getValues("selectedSystem").concat(
                                            childrenIds
                                          )
                                        );
                                        setValue(
                                          "selectedSystem",
                                          finalSelectedSystem,
                                          { shouldValidate: true }
                                        );
                                      }
                                    }
                                  }}
                                >
                                  <div
                                    className={clsx(
                                      value.includes(subject._id)
                                        ? "checkBox active"
                                        : "checkBox",
                                      subjectCount === 0 &&
                                        classes.disabledCheckbox
                                    )}
                                  >
                                    {subjectCount !== 0 && (
                                      <img
                                        src={checkIconW}
                                        alt=""
                                        className="qbIcon"
                                      />
                                    )}
                                  </div>
                                  <div className="fLabel">{subject.name}</div>
                                  <div className="qbTag">{subjectCount}</div>
                                </div>
                              );
                            })}
                            {!!errors.selectedSubject && (
                              <div
                                className={classes.errorMsg}
                                style={{
                                  display: "flex",
                                  width: "100%",
                                }}
                              >
                                {"Please select at least one subject"}
                              </div>
                            )}
                          </div>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                />
                <Controller
                  control={control}
                  name="selectedSystem"
                  rules={{
                    validate: value => value.length > 0,
                  }}
                  defaultValue={[]}
                  render={({ onChange, value }) => (
                    <Fragment>
                      <div className="titleBox">
                        <div className="fLabel">
                          <span
                            className={clsx(
                              "tour-step4",
                              classes.stepContainer
                            )}
                          >
                            <div
                              className={clsx(
                                selectedAllSystems
                                  ? "checkBox header active"
                                  : "checkBox header",
                                (Boolean(errors.selectedSubject) ||
                                  Boolean(errors.selectedQuestion)) &&
                                  classes.disabledCheckbox
                              )}
                              onClick={() => {
                                if (
                                  !Boolean(errors.selectedSubject) &&
                                  !Boolean(errors.selectedQuestion)
                                ) {
                                  if (selectedAllSystems) {
                                    onChange([]);
                                  } else {
                                    onChange(
                                      systemOptions
                                        .filter(
                                          child =>
                                            getCategorizedCount(
                                              child._count,
                                              selectedQuestion
                                            ) !== 0
                                        )
                                        .map(child => child._id)
                                    );
                                  }
                                }
                              }}
                            >
                              {!errors.selectedSubject && (
                                <img
                                  src={checkIconW}
                                  alt=""
                                  className="qbIcon"
                                />
                              )}
                            </div>
                            Systems
                          </span>
                        </div>

                        <img
                          src={upArrowIcon}
                          alt=""
                          className={
                            uiControls.showSystem ? "qbIcon" : "qbIcon active"
                          }
                          onClick={() => onExpand("showSystem")}
                        />
                      </div>
                      {uiControls.showSystem && (
                        <Fragment>
                          <div className="sectionBox">
                            {systemOptions.map(child => {
                              const systemCount = getCategorizedCount(
                                child._count,
                                selectedQuestion,
                                filterBySelectedSubject
                              );

                              return (
                                <div
                                  className={clsx(
                                    "itemBox fixed",
                                    systemCount !== 0 && "clickable"
                                  )}
                                  key={child._id}
                                  onClick={() => {
                                    if (systemCount !== 0) {
                                      onChange(
                                        value.includes(child._id)
                                          ? value
                                              .map(
                                                item =>
                                                  item !== child._id && item
                                              )
                                              .filter(item => item !== false)
                                          : value.concat(child._id)
                                      );
                                    }
                                  }}
                                >
                                  <div
                                    className={clsx(
                                      selectedSystem.includes(child._id)
                                        ? "checkBox active"
                                        : "checkBox",
                                      systemCount === 0 &&
                                        classes.disabledCheckbox
                                    )}
                                  >
                                    {systemCount !== 0 && (
                                      <img
                                        src={checkIconW}
                                        alt=""
                                        className="qbIcon"
                                      />
                                    )}
                                  </div>
                                  <div className="fLabel">{child.name}</div>
                                  <div className="qbTag">{systemCount}</div>
                                </div>
                              );
                            })}
                            {!!errors.selectedSystem && (
                              <div
                                className={classes.errorMsg}
                                style={{
                                  display: "flex",
                                  width: "100%",
                                }}
                              >
                                {"Please select at least one system"}
                              </div>
                            )}
                          </div>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                />
                {!errors.selectedQuestion && (
                  <Fragment>
                    <div className="titleBox">
                      <div className="fLabel">No. of Question</div>
                      <img
                        src={upArrowIcon}
                        alt=""
                        className={
                          uiControls.showNoQuestion ? "qbIcon" : "qbIcon active"
                        }
                        onClick={() => onExpand("showNoQuestion")}
                      />
                    </div>
                    {uiControls.showNoQuestion && (
                      <div className="sectionBox">
                        <div className="itemBox">
                          <Grid container spacing={2}>
                            <Grid item>
                              <TextField
                                id="noQuestion"
                                className="tour-step5"
                                name="noQuestion"
                                type="number"
                                label="Quantity"
                                variant="filled"
                                color="secondary"
                                inputRef={register({
                                  validate: {
                                    required: value =>
                                      getValues("selectedQuestion") === "custom"
                                        ? true
                                        : !!value || "Quantity cannot be empty",
                                    moreThan40: value =>
                                      value > 40
                                        ? "Quantity must be less than or equal to 40"
                                        : true,
                                    moreThanQuestionPool: value => {
                                      const availableQuestionPools = subjects
                                        .filter(subject =>
                                          selectedSubject.includes(subject._id)
                                        )
                                        .reduce((acc, subject) => {
                                          const getTotalSelectedSystemCount = subject.children.reduce(
                                            (acc, system) =>
                                              acc +
                                              getCategorizedCount(
                                                system._count,
                                                selectedQuestion,
                                                filterBySelectedSystem(
                                                  subject,
                                                  system
                                                )
                                              ),
                                            0
                                          );

                                          return (
                                            acc + getTotalSelectedSystemCount
                                          );
                                        }, 0);

                                      return value > availableQuestionPools
                                        ? "Quantity must be less than or equal to total available selected questions pool"
                                        : true;
                                    },
                                  },
                                  min: {
                                    value: 1,
                                    message:
                                      "Quantity must be more than or equal to 1",
                                  },
                                })}
                                error={!!errors.noQuestion}
                                helperText={
                                  errors.noQuestion && errors.noQuestion.message
                                }
                                inputProps={{
                                  min: 1,
                                }}
                                InputProps={{
                                  style: {
                                    fontSize: 15,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    fontSize: 15,
                                    color: "rgba(0, 0, 0, 0.25)",
                                  },
                                }}
                                FormHelperTextProps={{
                                  style: { fontSize: 13, maxWidth: 150 },
                                }}
                                className={classes.textField}
                              />
                            </Grid>
                            <Grid item>
                              <div
                                className={clsx(
                                  classes.customFLabelCont,
                                  "fLabel"
                                )}
                                style={{ height: 55 }}
                              >
                                <div>Max allowed per block</div>
                                <div className="qbTag">40</div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}{" "}
                  </Fragment>
                )}
                {watch("timed") && watchNoQuestion > 0 && (
                  <Fragment>
                    <div className="titleBox">
                      <div className="fLabel">Total Time Field</div>
                      <img
                        src={upArrowIcon}
                        alt=""
                        className={
                          uiControls.showTotalTime ? "qbIcon" : "qbIcon active"
                        }
                        onClick={() => onExpand("showTotalTime")}
                      />
                    </div>
                    {uiControls.showTotalTime && (
                      <div className="sectionBox">
                        <div className="itemBox">
                          <Grid container spacing={2} alignItems="center">
                            <Grid item>
                              <TextField
                                id="totalTime"
                                name="totalTime"
                                label="HH:MM:SS"
                                variant="filled"
                                inputRef={register({
                                  validate: value =>
                                    getValues("selectedQuestion") === "custom"
                                      ? true
                                      : !!value ||
                                        "Total time field cannot be empty",
                                  pattern: {
                                    value: REGEX["HH:MM:SS"],
                                    message: "Invalid time format",
                                  },
                                })}
                                error={!!errors.totalTime}
                                helperText={
                                  errors.totalTime && errors.totalTime.message
                                }
                                InputProps={{
                                  style: {
                                    fontSize: 15,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    fontSize: 15,
                                    color: "rgba(0, 0, 0, 0.25)",
                                  },
                                }}
                                FormHelperTextProps={{
                                  style: { fontSize: 13, maxWidth: 150 },
                                }}
                                className={classes.textField}
                              />
                            </Grid>
                            <Grid item>
                              <div
                                className={clsx(
                                  classes.customFLabelCont,
                                  "fLabel"
                                )}
                              >
                                <div>Total time allocated</div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <div className="titleBox">
                  <div className="fLabel">
                    <span className={clsx(classes.stepContainer)}>
                      Instructions on using Custom mode
                    </span>
                  </div>
                  <img
                    onClick={() => onExpand("showCustomMode")}
                    src={upArrowIcon}
                    alt=""
                    className={
                      uiControls.showCustomMode ? "qbIcon" : "qbIcon active"
                    }
                  />
                </div>
                {uiControls.showCustomMode && (
                  <div className="sectionBox">
                    <div className="itemBox">
                      <Grid container style={{ padding: "0", fontSize: 14.5 }}>
                        <Grid
                          item
                          xs={12}
                          md={7}
                          xl={8}
                          className={classes.customLeftCont}
                        >
                          <p>
                            This mode is intended to allow for faculty/group
                            review; the faculty/group leader can designate the
                            question IDs to allow the entire group to create the
                            same test within each subscription.
                          </p>
                          <p>
                            If you are a group member (not the faculty/group
                            leader), you will need to enter the list of question
                            IDs provided by your group leader. Separate each
                            question ID with a comma (,) without spaces or other
                            characters. Only unused questions can be utilized to
                            create a custom test.
                          </p>
                          <p>
                            If you were not provided a list of question IDs but
                            were given a test ID instead, enter the test ID and
                            click “Retrieve Questions” to automatically generate
                            the list of question IDs. Only unused questions can
                            be utilized to create a custom test.
                          </p>
                          <p>
                            You can enter only numbers or commas (,) in the
                            space provided. Please do not enter spaces or other
                            characters as this will result in an error prompt.
                          </p>
                          <p>
                            The maximum number of questions you can select is
                            the same as the maximum number allowed per
                            block/test.
                          </p>
                          <p>
                            You will not be able to include the question if it
                            has been deactivated or if its ID is invalid.
                          </p>
                          <p>
                            Some questions are part of a question set and must
                            be included within the same test. In such cases, you
                            must provide the question IDs for each question in
                            the set. If you omit one or more question IDs in the
                            set, the software will not generate the test.
                            Instead, it will provide a list of question IDs that
                            must be entered to complete the question set.
                          </p>
                          <p>
                            If you are a group leader or member of a study group
                            and need additional assistance using this feature,
                            please contact us at support@uworld.com
                          </p>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={5}
                          xl={4}
                          className={classes.customRightCont}
                        >
                          <div className={classes.customRightRowCont}>
                            <p>Retrieve Questions of a Test #</p>
                            <div className={classes.testIDCont}>
                              <input
                                placeholder="Enter Test Id"
                                maxLength="10"
                                className={classes.testIDInput}
                                value={testID}
                                onChange={e => setTestID(e.target.value)}
                              />
                              <button
                                className={classes.testIDBtn}
                                disabled={!testID}
                                type="button"
                                onClick={fetchUserExamByRef}
                              >
                                RETRIEVE
                              </button>
                            </div>
                          </div>
                          <div className={classes.customRightRowCont}>OR</div>
                          <div className={classes.customRightRowCont}>
                            <p>Enter Question IDs separated by comma (,)</p>
                            <textarea
                              maxLength="2000"
                              name="questionRefs"
                              value={questionRefs}
                              onChange={e =>
                                setValue("questionRefs", e.target.value, {
                                  shouldValidate: true,
                                })
                              }
                              className={classes.customQuestionArea}
                            ></textarea>

                            {errors &&
                              errors.questionRefs &&
                              errors.questionRefs.type &&
                              (errors.questionRefs.type === "required" ? (
                                <p className={classes.questionIDsErrMsg}>
                                  Question IDs cannot be empty
                                </p>
                              ) : (
                                errors.questionRefs.type === "validate" && (
                                  <p className={classes.questionIDsErrMsg}>
                                    Please enter valid question IDs
                                  </p>
                                )
                              ))}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                )}

                {watch("timed") &&
                  (watchNoQuestion > 0 ||
                    isValidQuestionRefs(questionRefs)) && (
                    <Fragment>
                      <div className="titleBox">
                        <div className="fLabel">Total Time Field</div>
                        <img
                          src={upArrowIcon}
                          alt=""
                          className={
                            uiControls.showTotalTime
                              ? "qbIcon"
                              : "qbIcon active"
                          }
                          onClick={() => onExpand("showTotalTime")}
                        />
                      </div>
                      {uiControls.showTotalTime && (
                        <div className="sectionBox">
                          <div className="itemBox">
                            <Grid container spacing={2} alignItems="center">
                              <Grid item>
                                <TextField
                                  id="totalTime"
                                  name="totalTime"
                                  label="HH:MM:SS"
                                  variant="filled"
                                  inputRef={register({
                                    validate: value =>
                                      getValues("selectedQuestion") === "custom"
                                        ? true
                                        : !!value ||
                                          "Total time field cannot be empty",
                                    pattern: {
                                      value: REGEX["HH:MM:SS"],
                                      message: "Invalid time format",
                                    },
                                  })}
                                  error={!!errors.totalTime}
                                  helperText={
                                    errors.totalTime && errors.totalTime.message
                                  }
                                  InputProps={{
                                    style: {
                                      fontSize: 15,
                                    },
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      fontSize: 15,
                                      color: "rgba(0, 0, 0, 0.25)",
                                    },
                                  }}
                                  FormHelperTextProps={{
                                    style: { fontSize: 13, maxWidth: 150 },
                                  }}
                                  className={classes.textField}
                                />
                              </Grid>
                              <Grid item>
                                <div
                                  className={clsx(
                                    classes.customFLabelCont,
                                    "fLabel"
                                  )}
                                >
                                  <div>Total time allocated</div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  )}
              </Fragment>
            )}
            {/* To preserve questionRefs form state  */}
            <Controller
              control={control}
              name="questionRefs"
              rules={{
                required: selectedQuestion === "custom" ? true : false,
                validate: val => isValidQuestionRefs(val),
              }}
              defaultValue=""
            />
            <div className="actionCont">
              <Button
                className={"tour-step6"}
                classes={{
                  root: classes.submitBtn,
                  disabled: classes.disabledSubmitBtn,
                }}
                type="submit"
                color="secondary"
                variant="contained"
                disabled={!isValid}
              >
                GENERATE TEST
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getCategoryList: () => dispatch(ExamActions.getCategoryList()),
  getUserExam: filter => dispatch(ExamActions.getUserExam(filter)),
  createTest: data => dispatch(ExamActions.createTest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTest);
