import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Drawer } from "@material-ui/core";
import React, { useEffect } from "react";
import { appMenuItems } from "../constants";
import useStyles from "./styles";
import history from "../../../routes/history";
import { useSelector } from "react-redux";
import { selectUserAuthToken } from "../../../selectors/user";
import { useDispatch } from "react-redux";
import { logout } from "../../../actions/userAction";
import { LOGIN_URL } from "../../../routes/constants";

const AppDrawer = props => {
  const { open, onClose } = props;
  const classes = useStyles();
  const isAuthorized = useSelector(selectUserAuthToken);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    history.push(LOGIN_URL);
  };

  useEffect(() => {
    return () => {
      onClose();
    };
  }, []);

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={onClose}
      classes={{
        paper: classes.appDrawerPaper,
      }}
      BackdropProps={{ invisible: true }}
    >
      <Box className={classes.appDrawerContent}>
        <Box className={classes.appDrawerHeader}>
          <Box />
          <Box>
            <FontAwesomeIcon
              onClick={onClose}
              className={classes.appDrawerCloseIcon}
              icon={faTimes}
            />
          </Box>
        </Box>
        <Box className={classes.appDrawerMenuBox}>
          {appMenuItems.map(item => (
            <Box
              key={item.title}
              className={classes.appDrawerMenuItem}
              onClick={() => history.push(item.route)}
            >
              {item.title}
            </Box>
          ))}
        </Box>
      </Box>
      <Box className={classes.appDrawerMenuBox}>
        <Box className={classes.appDrawerMenuSubItem}>
          {isAuthorized ? (
            <Box onClick={handleLogout}>Logout</Box>
          ) : (
            <Box
              onClick={() => {
                history.push(LOGIN_URL);
              }}
            >
              Login
            </Box>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default AppDrawer;
