import React, { Fragment, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MUIAddIcon from "@material-ui/icons/Add";
import MUIRemoveIcon from "@material-ui/icons/Remove";

import styles from "./styles";
import { HOW_TO_USE_ARR, FAQs_ARR } from "./contentArr";

const Accordion = withStyles({
  root: {
    border: "thin solid #e8e8e8",
    borderRadius: 5,
    boxShadow: "none",
    marginTop: "0.5rem",
    "&:before": {
      display: "none",
    },
    "&$expanded": { marginTop: "0.5rem" },
    "&:hover": {
      backgroundColor: "#f8f8f8",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles(theme => ({
  root: {
    marginBottom: -1,
    minHeight: 60,
    "&$expanded": {
      minHeight: 60,
    },
    padding: `0px ${theme.spacing(1)}px`,
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))(MuiAccordionDetails);

export const AddIcon = withStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    cursor: "pointer",
  },
}))(MUIAddIcon);
export const RemoveIcon = withStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    cursor: "pointer",
  },
}))(MUIRemoveIcon);

const useStyles = makeStyles(theme => styles(theme));

const HelpPage = () => {
  const classes = useStyles();

  const [expandedHowToUseList, setExpandedHowToUseList] = useState([]);
  const [expandedFAQsList, setExpandedFAQsList] = useState([]);

  // Handle Accordion ExpandedList Start
  const onClickHowToUseAccordion = index => {
    if (expandedHowToUseList.includes(index)) {
      setExpandedHowToUseList(
        expandedHowToUseList.filter(elem => elem !== index)
      );
    } else {
      setExpandedHowToUseList([...expandedHowToUseList, index]);
    }
  };

  const onClickFAQsAccordion = index => {
    if (expandedFAQsList.includes(index)) {
      setExpandedFAQsList(expandedFAQsList.filter(elem => elem !== index));
    } else {
      setExpandedFAQsList([...expandedFAQsList, index]);
    }
  };
  // Handle Accordion ExpandedList End

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0} className={classes.paperRoot}>
            <h3 className={classes.heading}>How to use</h3>
            {HOW_TO_USE_ARR.map((item, index) => (
              <Accordion
                expanded={expandedHowToUseList.includes(index)}
                key={index}
              >
                <AccordionSummary
                  onClick={() => onClickHowToUseAccordion(index)}
                >
                  <div className={classes.accordionHeaderCont}>
                    {expandedHowToUseList.includes(index) ? (
                      <RemoveIcon />
                    ) : (
                      <AddIcon />
                    )}
                    <div className={classes.accordionHeaderTxt}>
                      {item.summary}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.detail }}
                    style={{ width: "100%" }}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0} className={classes.paperRoot}>
            <h3 className={classes.heading}>FAQs</h3>
            {FAQs_ARR.map((item, index) => (
              <Accordion
                expanded={expandedFAQsList.includes(index)}
                key={index}
              >
                <AccordionSummary onClick={() => onClickFAQsAccordion(index)}>
                  <div className={classes.accordionHeaderCont}>
                    {expandedFAQsList.includes(index) ? (
                      <RemoveIcon />
                    ) : (
                      <AddIcon />
                    )}
                    <div className={classes.accordionHeaderTxt}>
                      {item.summary}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.detail }}
                    style={{ width: "100%" }}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default HelpPage;
