import { Dialog, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import {
  CustomActionBtn,
  CustomCancelBtn,
  CustomConfirmDialogActions,
} from "../CustomDialogElements";

const useStyles = makeStyles(theme => ({
  backdropRoot: {
    backgroundColor: "rgba(0, 0, 0, 0.92)",
  },
}));

const FreezeOverlayModal = props => {
  const classes = useStyles();
  const {
    open,
    ctaTitle,
    leftTxt,
    leftActionFn,
    rightTxt,
    rightActionFn,
  } = props;
  return (
    <Dialog
      open={open}
      BackdropProps={{ classes: { root: classes.backdropRoot } }}
    >
      <DialogContent>{ctaTitle}</DialogContent>
      <CustomConfirmDialogActions>
        <CustomCancelBtn onClick={leftActionFn}>{leftTxt}</CustomCancelBtn>
        <CustomActionBtn onClick={rightActionFn}>{rightTxt}</CustomActionBtn>
      </CustomConfirmDialogActions>
    </Dialog>
  );
};

export default FreezeOverlayModal;
