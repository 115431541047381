import { Box } from "@material-ui/core";
import React from "react";
import {
  safeFormatDefaultDateTime,
  safeParseCountry,
} from "../../../../../utils";

const RegistrationSection = ({ user }) => {
  return (
    <>
      <Box display="flex" flexDirection="column">
        <h4>Date: {safeFormatDefaultDateTime(user._createdOn)}</h4>
        <h4>IP Address: {user.registeredIpAddress}</h4>
        <h4>Country: {safeParseCountry(user.registeredCountry)}</h4>
        <h4>City: {user.registeredCity}</h4>
      </Box>
    </>
  );
};

export default RegistrationSection;
