import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import ShowMoreText from "react-show-more-text";
import MUIDataTable from "mui-datatables";
import * as FeedbackActions from "../../../../actions/feedbackAction";
import { parseDateHtml } from "../../../../utils";
import useModal from "../../../../hooks/useModal";
import { handleRemoveTableRow } from "../helper";
import ConfirmationModal from "../../../../Components/Modal/ConfirmationModal";
import TableTitle from "./TableTitle";
import { DELETED_STATUS_FILTER } from "../constants";

const FeedbackTable = ({ getFeedbacks, removeFeedback }) => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const { open, handleOpen, handleClose } = useModal();
  const handleOpenDeleteModal = () => {
    if (selectedIndexes.length > 0) {
      handleOpen();
    }
  };

  const fetchFeedbackData = async () => {
    const res = await getFeedbacks(DELETED_STATUS_FILTER);
    if (res && res.feedbacks) setFeedbacks(res.feedbacks);
  };

  const onRemoveFeedback = async () => {
    await handleRemoveTableRow(
      feedbacks,
      setFeedbacks,
      selectedIndexes,
      setSelectedIndexes,
      removeFeedback,
      handleClose
    );
  };

  useEffect(() => {
    fetchFeedbackData();
  }, []);

  return (
    <Fragment>
      <ConfirmationModal
        open={open}
        onClose={handleClose}
        actionFn={onRemoveFeedback}
        ctaTitle={
          "Are you sure you wish to permanently delete these feedback(s)?"
        }
        ctaTxt={"Delete"}
      />
      <TableTitle
        title="Feedback"
        handleOpenDeleteModal={handleOpenDeleteModal}
      />
      <MUIDataTable
        data={feedbacks.map(feedback => [
          feedback.question ? feedback.question._ref : "",
          feedback.content,
          feedback.question ? feedback.question.question : "",
          feedback.creator
            ? `${
                feedback.creator.firstName
                  ? `${feedback.creator.firstName} `
                  : ""
              }${
                feedback.creator.lastName ? `${feedback.creator.lastName}` : ""
              }`
            : "",
          feedback.resolved ? "Closed" : "Open",
          feedback._createdOn,
          feedback.technicalIssue ? "Yes" : "No",
        ])}
        columns={[
          {
            name: "Question ID",
            options: {
              setCellHeaderProps: () => ({
                className: "globalCustomMuiTableHead",
              }),
            },
          },
          {
            name: "Feedback",
            options: {
              customBodyRender: value => {
                return (
                  <div style={{ width: 175, wordBreak: "break-word" }}>
                    {value}
                  </div>
                );
              },
            },
          },
          {
            name: "Question",
            options: {
              customBodyRender: value => {
                const parsedHTML = ReactHtmlParser(value, {
                  transform: node => {
                    if (node.name === "img") {
                      const { src } = node.attribs;
                      return (
                        <a href={src} key={src} target="_blank">
                          {src}
                        </a>
                      );
                    }
                  },
                });
                return (
                  <div style={{ width: 175, wordBreak: "break-word" }}>
                    <ShowMoreText>{parsedHTML}</ShowMoreText>
                  </div>
                );
              },
            },
          },
          "User",
          "Resolved",
          {
            name: "Date Created",
            options: {
              customBodyRenderLite: dataIndex => {
                let val = feedbacks[dataIndex]._createdOn;
                return parseDateHtml(val);
              },
              setCellHeaderProps: () => ({
                className: "globalCustomMuiTableHead",
              }),
            },
          },
          "Technical",
        ]}
        options={{
          download: false,
          print: false,
          rowHover: false,
          sort: false,
          setRowProps: (row, dataIndex, rowIndex) => {
            const currentFeedback = feedbacks[dataIndex];

            return {
              style: {
                backgroundColor: currentFeedback.resolved
                  ? "#fff"
                  : "rgba(0, 0, 0, 0.04)",
              },
            };
          },
          onRowSelectionChange: (
            currentRowsSelected,
            allRowsSelected,
            rowsSelected
          ) => {
            setSelectedIndexes(rowsSelected);
          },
          customToolbarSelect: () => {},
          rowsSelected: selectedIndexes,
        }}
      />
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getFeedbacks: filter => dispatch(FeedbackActions.getFeedbacks(filter)),
  removeFeedback: (id, permanent) =>
    dispatch(FeedbackActions.removeFeedback(id, permanent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackTable);
