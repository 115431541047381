const testModeArr = [
  {
    title: "Tutor",
    desc:
      "Shows the correct answer and explanation after you answer each question",
  },
  {
    title: "Timed",
    desc: "Sets a time limit on the test",
  },
];

const questionModeArr = [
  {
    title: "Unused",
    desc: "Selects questions from a set of new/unseen questions",
  },
  {
    title: "Incorrect",
    desc: "Generates test using questions that were answered incorrectly",
  },
  {
    title: "Marked",
    desc: "Selects questions that were previously marked/flagged for review",
  },
  {
    title: "All",
    desc: "Randomly selects questions from a set of all available questions",
  },
];

export { testModeArr, questionModeArr };
