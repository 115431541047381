import React, { Fragment } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Editor } from "@tinymce/tinymce-react";
import { AppConfig } from "../../../configs";

import styles from "../FlashcardModal/styles";
import { renderFormCharLimit } from "../../../utils";

// Constants
const MAX_CHAR = 2000;

const useStyles = makeStyles(theme => styles(theme));

const SingleSideForm = props => {
  const {
    isDarkMode,
    watchAllFields,
    setValue,
    editingFront,
    startEditingFrontView,
    startEditingBackView,
    bindWithCategories,
  } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <div
        className={clsx(
          bindWithCategories
            ? classes.categorySideFormCont
            : classes.sideFormCont
        )}
      >
        <div className={classes.cardFaceCont}>
          <div
            className={clsx(
              classes.cardFace,
              editingFront
                ? classes.cardFaceActiveLight
                : isDarkMode
                ? classes.cardFaceActiveDark
                : classes.cardFaceLight
            )}
            onClick={startEditingFrontView}
          >
            Front
          </div>
          <div
            className={clsx(
              classes.cardFace,
              !editingFront
                ? classes.cardFaceActiveLight
                : isDarkMode
                ? classes.cardFaceActiveDark
                : classes.cardFaceLight
            )}
            onClick={startEditingBackView}
          >
            Back
          </div>
        </div>

        <div className={clsx(classes.cardFormBody)}>
          <Editor
            apiKey={AppConfig.tinyMceApiKey}
            value={editingFront ? watchAllFields.front : watchAllFields.back}
            init={{
              height: "100%",
              content_style:
                "* { line-height: 1.15 } ::-webkit-scrollbar {width: 5px; height: 5px;} ::-webkit-scrollbar-thumb { background: #cdcdcd; }::-webkit-scrollbar-track { background: none;} ",
              statusbar: false,
              menubar: false,
              branding: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help ",
              ],
              toolbar:
                "bold italic underline | bullist numlist outdent indent |",
              toolbar_location: "bottom",
            }}
            onEditorChange={(content, _editor) => {
              let name;
              if (editingFront) {
                name = "front";
              } else {
                name = "back";
              }

              setValue(name, content, {
                shouldValidate: true,
              });
            }}
          />
        </div>
        <span className={classes.editorFooterText}>
          {renderFormCharLimit(
            editingFront ? watchAllFields.front : watchAllFields.back,
            MAX_CHAR
          )}
        </span>
      </div>
    </Fragment>
  );
};

export default SingleSideForm;
