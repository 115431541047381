import { makeStyles } from "@material-ui/styles";
import {
  COLOR_BLACK,
  COLOR_WHITE,
  CONTENT_VERTICAL_PADDING,
} from "../../../styles/constants";

export default makeStyles(theme => ({
  root: {
    height: "100%",
  },
  stepContentContainer: {
    ...CONTENT_VERTICAL_PADDING(theme),
    width: "100%",
  },
  stepperRoot: {
    background: COLOR_WHITE,
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.08)",
    borderRadius: 10,
    width: "100%",
    maxWidth: 950,
    [theme.breakpoints.down("md")]: {
      maxWidth: 600,
    },
    [theme.breakpoints.between("sm", "sm")]: {
      maxWidth: 440,
    },
  },
  stepLabel: {
    fontSize: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
    color: "#00000073",
    cursor: "pointer",
  },
  stepLabelActive: {
    fontWeight: "700 !important",
    color: "#000000 !important",
  },
  stepIconRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLOR_WHITE,
    color: theme.palette.primary.main,
    border: `0.5px solid ${COLOR_WHITE}`,
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "50%",
    width: 30,
    height: 30,
    [theme.breakpoints.down("xs")]: {
      width: 25,
      height: 25,
    },
    cursor: "pointer",
  },
  stepIconActive: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${COLOR_WHITE} !important`,
  },
  cartCircle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 16,
    height: 16,
    borderRadius: 20,
    fontSize: 12,
    color: COLOR_WHITE,
    backgroundColor: COLOR_BLACK,
    position: "absolute",
    left: "calc(56%)",
    bottom: "-10%",
    [theme.breakpoints.down("md")]: {
      left: "calc(62%)",
    },
    [theme.breakpoints.down("xs")]: {
      bottom: "-18%",
      left: "calc(57%)",
    },
  },
  backButton: {
    cursor: "pointer",
    width: 60,
    height: 60,
    background: COLOR_WHITE,
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.08)",
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: 50,
      height: 50,
    },
  },
  backArrow: {
    [theme.breakpoints.down("md")]: {
      width: 30,
      height: 20,
    },
  },
  arrowBox: {
    border: "2px solid #262626",
    position: "relative",
    minHeight: 200,
    color: "#262626",
    "&::before": {
      content: "''",
      position: "absolute",
      width: 30,
      height: 30,
      background: "#ffef64",
      border: "2px solid #262626",
      boxSizing: "border-box",
      borderLeftColor: "transparent",
      borderTopColor: "transparent",
      bottom: -15,
      transform: "rotate(45deg)",
    },
  },
}));
