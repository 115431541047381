import React, { useState, useEffect, Fragment } from "react";
import clsx from "clsx";
import { useForm } from "react-hook-form/dist/index.ie11";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { Rnd } from "react-rnd";

import {
  uworldCloseIcon,
  submitBtn,
  feedbackIcon,
} from "../../../../../resources/assets";
import styles from "../styles";
import {
  CustomConfirmDialogActions,
  CustomActionBtn,
  CustomCancelBtn,
} from "../../../../../Components/CustomDialogElements";

const useStyles = makeStyles(theme => styles(theme));

// Constants
const DEFAULT_SIZE = { width: "550px", height: "450px" };
const DEFAULT_SM_SIZE = { width: "400px", height: "450px" };
const DEFAULT_XS_SIZE = { width: "280px", height: "450px" };

const FeedbackModal = props => {
  const { visible, isDarkMode, onSubmit, onClose } = props;
  const classes = useStyles();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const y = window.innerHeight - 517;
  const DEFAULT_POSITION = {
    x: window.innerWidth - 670,
    y,
  };

  const DEFAULT_SM_POSITION = {
    x: window.innerWidth - 450,
    y,
  };

  const DEFAULT_XS_POSITION = {
    x: window.innerWidth > 300 ? window.innerWidth - 300 : 0,
    y,
  };

  const PRESET_SIZE = xsDown
    ? DEFAULT_XS_SIZE
    : smDown
    ? DEFAULT_SM_SIZE
    : DEFAULT_SIZE;
  const PRESET_POSITION = xsDown
    ? DEFAULT_XS_POSITION
    : smDown
    ? DEFAULT_SM_POSITION
    : DEFAULT_POSITION;

  const { register, handleSubmit, reset, errors, watch, trigger } = useForm({
    mode: "onChange",
  });
  const watchContent = watch("content");

  const [position, setPosition] = useState(PRESET_POSITION);
  const [showUnsavedWarningModal, setShowUnsavedWarningModal] = useState(false);

  useEffect(() => {
    setPosition(PRESET_POSITION);
  }, [xsDown, smDown]);

  // Handle Confirmtion Modal Start
  const handleCloseUnsavedWarningModal = () => {
    setShowUnsavedWarningModal(false);
  };

  const handleOpenUnsavedWarningModal = () => {
    setShowUnsavedWarningModal(true);
  };
  // Handle Confirmtion Modal End

  // Handle Actions Button Start
  const handleOnSubmit = async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSubmit(data => {
        onSubmit(data);
        reset();
      })();
      onClose();
    }
  };
  const onClickCloseBtn = () => {
    if (watchContent !== "") {
      handleOpenUnsavedWarningModal();
    } else {
      onClose();
    }
  };

  const onCloseAllModal = () => {
    handleCloseUnsavedWarningModal();
    onClose();
  };
  // Handle Actions Button End

  // Reset position when feedback modal mounted/unmounted
  useEffect(() => {
    setPosition(PRESET_POSITION);
  }, [visible, xsDown, smDown]);

  if (!visible) return null;

  return (
    <Fragment>
      {/* Unsaved Warning Modal */}
      <Dialog
        onClose={handleCloseUnsavedWarningModal}
        open={showUnsavedWarningModal}
      >
        <DialogContent>
          Do you really wish to close the feedback window without sending
          feedback?
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomActionBtn onClick={onCloseAllModal}>Yes</CustomActionBtn>
          <CustomCancelBtn onClick={handleCloseUnsavedWarningModal}>
            No
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>

      {/* Feedback Modal */}
      <Rnd
        className={classes.zIndex1}
        bounds={"window"}
        enableResizing={false}
        size={PRESET_SIZE}
        position={{ x: position.x, y: position.y }}
        onDragStop={(e, d) => {
          setPosition({ x: d.x, y: d.y });
        }}
        enableUserSelectHack={false}
      >
        <div
          className={clsx(
            isDarkMode ? classes.modalContDark : classes.modalContLight,
            classes.modalCont
          )}
        >
          <div className={classes.feedbackHeader}>
            <span className={classes.feedbackHeaderTitle}>
              <img
                src={feedbackIcon}
                alt="Feedback icon"
                style={{ margin: "0 5px" }}
              />
              Feedback
            </span>
            <div>
              <div
                style={{ backgroundImage: `url(${uworldCloseIcon})` }}
                className={classes.closeIcon}
                onClick={onClickCloseBtn}
              />
            </div>
          </div>
          <div className={classes.formCont}>
            <TextField
              id="content"
              name="content"
              inputRef={register({ required: "Feedback cannot be empty" })}
              placeholder={`We appreciate your feedback. However, please provide as much detail as possible in the permitted space avoiding simplistic feedback like "Good question", "hard question" etc.`}
              variant="outlined"
              error={!!errors.content}
              helperText={errors.content && errors.content.message}
              InputProps={{
                classes: {
                  root: clsx(
                    isDarkMode
                      ? classes.textAreaRootDark
                      : classes.textAreaRootLight,
                    classes.textAreaRoot
                  ),
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                style: { fontSize: 15, color: "rgba(0, 0, 0, 0.25)" },
              }}
              FormHelperTextProps={{
                style: { fontSize: 13 },
              }}
              multiline
              fullWidth
              rows={xsDown ? 16 : smDown ? 16 : 18}
            />
          </div>

          <div className={classes.feedbackActionCont}>
            <Grid container>
              <Grid item>
                <FormControlLabel
                  classes={{
                    label: clsx(
                      isDarkMode
                        ? classes.checkBoxLabelDark
                        : classes.checkBoxLabelLight,
                      classes.checkBoxLabel
                    ),
                  }}
                  control={
                    <Checkbox
                      style={{ color: "#3852a4" }}
                      name="technicalIssue"
                      inputRef={register}
                    />
                  }
                  label="Check here if concern is for software/technical issue."
                />
              </Grid>
              <Grid item>
                <button
                  className={classes.submitBtn}
                  style={{
                    backgroundImage: `url(${submitBtn})`,
                    marginTop: 8,
                  }}
                  onClick={handleOnSubmit}
                ></button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Rnd>
    </Fragment>
  );
};

export default FeedbackModal;
