import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import useStyles from "./styles";
import { Controller } from "react-hook-form";
import { PrimaryButton } from "../../../../Components/CustomButton";
import PrimaryTextField from "../../../../Components/PrimaryTextField";
import { useDispatch, useSelector } from "react-redux";
import { selectCartItems } from "../../../../selectors/cart";
import {
  checkVoucher,
  setVoucherAvailability,
} from "../../../../actions/voucherAction";
import Alert from "../../../../Components/Alert/Alert";
import { selectVoucherAvailability } from "../../../../selectors/voucher";
import { ALERT_VARIANT } from "../../../../Components/Alert/constants";

const DiscountCodeInput = ({
  isValidToPay,
  handleSubmit,
  control,
  watch,
  setValue,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(false);
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const cartItems = useSelector(selectCartItems);
  const voucherAvailability = useSelector(selectVoucherAvailability);

  const toggleExpand = () => {
    setExpand(!expand);
  };

  const voucherCode = watch("voucherCode");

  const onCheckVoucher = ({ voucherCode }) => {
    const selectedPricePlan = cartItems[0];
    if (selectedPricePlan) {
      const input = {
        code: voucherCode,
        pricePlanID: selectedPricePlan.pricePlanID,
      };
      dispatch(checkVoucher({ input }));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setVoucherAvailability(null));
    };
  }, []);

  return (
    <>
      {/* To preserve questionRefs form state  */}
      <Controller control={control} name="voucherCode" />
      <Box className={classes.discountCodeInputBox} mb={3}>
        <Box className={classes.discountCodeRow}>
          <Box display="flex" alignItems="center">
            <Box fontWeight="600">Discount code</Box>
          </Box>
          {expand ? (
            <RemoveIcon
              className={classes.discountCodeToggleIcon}
              onClick={toggleExpand}
            />
          ) : (
            <AddIcon
              className={classes.discountCodeToggleIcon}
              onClick={toggleExpand}
            />
          )}
        </Box>
        {expand && (
          <>
            {voucherAvailability && (
              <Box mt={1} mb={3}>
                <Alert
                  variant={
                    voucherAvailability.valid
                      ? ALERT_VARIANT.SUCCESS
                      : ALERT_VARIANT.ERROR
                  }
                >
                  {voucherAvailability.valid ? (
                    <>
                      <strong>{voucherAvailability.code}</strong> has been
                      successfully applied
                    </>
                  ) : (
                    "Oops, your promo code is not valid - try a different one?"
                  )}
                </Alert>
              </Box>
            )}

            <Box
              display="flex"
              flexDirection={xsDown ? "column" : "row"}
              width="100%"
              mt={2}
              mb={1}
            >
              <Box width="100%" mr={1.5} mb={xsDown ? 2 : 0}>
                <PrimaryTextField
                  label="Enter discount code here"
                  name="voucherCode"
                  value={voucherCode}
                  onChange={e => setValue("voucherCode", e.target.value)}
                  disabled={isValidToPay}
                />
              </Box>
              <Box>
                <PrimaryButton
                  className={classes.applyButton}
                  onClick={handleSubmit(onCheckVoucher)}
                  disabled={isValidToPay}
                >
                  APPLY
                </PrimaryButton>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default DiscountCodeInput;
