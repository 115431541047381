import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { VictoryPie, VictoryLabel } from "victory";

import * as ExamActions from "../../actions/examAction";
import { upArrowIcon } from "../../resources/assets";
import StatsColumn from "../OverallPerformancePage/StatsColumn";
import {
  CustomTableCell,
  SubjectsRowCell,
  SystemsRowCell,
  CustomTableSortLabel,
  HoverSelectedTableRow,
  AddIcon,
  RemoveIcon,
} from "../../Components/CustomTableElements";
import { YOUR_SCORE_PIE_COLORS } from "../../styles/constants";
import styles from "./styles";
import { headCells, showBarsArr } from "./testAnalysisArr";
import { parseSubjectsData } from "./subjectsDataParser";
import { sortSubjectsData } from "./subjectsDataSorter";
import FilterMenu from "../../Components/FilterMenu";
import { QUESTION_STATUS } from "../../constants";
import { ScoreBar } from "../../Components/customBar";

const useStyles = makeStyles(theme => styles(theme));

const TestAnalysis = ({ match, getExamAnalysis, userExam }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [analysisData, setAnalysisData] = useState(undefined);
  const [yourScorePieData, setYourScorePieData] = useState(null);
  const [answerChanges, setAnswerChanges] = useState(null);
  const [showSubjects, setShowSubjects] = useState(true);
  const [subjectOrderBy, setSubjectOrderBy] = useState("name");
  const [subjectIsAsc, setSubjectIsAsc] = useState(true);
  const [anchorElBarsMenu, setAnchorElBarsMenu] = useState(null);
  const [showBarsList, setShowBarsList] = useState([
    QUESTION_STATUS["CORRECT"],
  ]);
  const [expandedList, setExpandedList] = useState([]);
  const [expandAll, setExpandAll] = useState(false);

  const loadExamAnalysis = async () => {
    const { id: examId } = match.params;

    const { examAnalysis } = await getExamAnalysis(examId);

    if (examAnalysis) setAnalysisData(examAnalysis);
  };

  // Handle Bars Menu Start
  const handleOpenMenu = event => {
    setAnchorElBarsMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElBarsMenu(null);
  };

  const handleShowBarsList = event => {
    const status = event.target.name;

    if (event.target.checked) {
      if (showBarsList.includes(status)) {
        setShowBarsList(showBarsList.filter(elem => elem !== status));
      } else {
        setShowBarsList([...showBarsList, status]);
      }
    } else {
      setShowBarsList(showBarsList.filter(elem => elem !== status));
    }
  };

  const renderSelectedFilter = () => {
    if (showBarsList.length === 0) return "None";
    if (showBarsList.length === 1) return `${showBarsList[0]} Only`;
    if (showBarsList.length >= 3) return "All";
    else {
      return showBarsList.join(",");
    }
  };
  // Handle Bars Menu End

  // Handle Accordion Collapese Start
  const onClickAccordion = subjectName => {
    if (expandedList.includes(subjectName)) {
      setExpandedList(expandedList.filter(elem => elem !== subjectName));
    } else {
      setExpandedList([...expandedList, subjectName]);
    }
  };

  const toggleExpandAll = () => {
    setExpandAll(!expandAll);
  };
  useEffect(() => {
    if (expandAll) {
      setExpandedList(subjectsNameArr);
    } else {
      setExpandedList([]);
    }
  }, [expandAll]);
  // Handle Accordion Collapese End

  const onSortSubjects = value => {
    if (subjectOrderBy === value) {
      setSubjectIsAsc(!subjectIsAsc);
    } else {
      setSubjectOrderBy(value);
    }
  };

  const questionsData = {};
  questionsData.total = userExam ? userExam.exam.questions.length : 0;
  questionsData.correct = userExam
    ? userExam.userExamAnswers.reduce(
        (acc, curr) => (curr._score === 1 ? acc + 1 : acc),
        0
      )
    : 0;
  questionsData.incorrect = userExam
    ? userExam.userExamAnswers.reduce(
        (acc, curr) => (curr._score === 0 ? acc + 1 : acc),
        0
      )
    : 0;
  questionsData.omitted =
    questionsData.total - questionsData.correct - questionsData.incorrect;

  useEffect(() => {
    loadExamAnalysis();
  }, []);
  useEffect(() => {
    if (userExam) {
      setYourScorePieData([
        {
          x: "Total Correct",
          y: questionsData.correct,
        },
        {
          x: "Total Incorrect",
          y: questionsData.incorrect,
        },
        {
          x: "Total Omitted",
          y: questionsData.omitted,
        },
      ]);
    }
    if (analysisData) {
      setAnswerChanges([
        {
          x: "Correct to Incorrect",
          y: analysisData.answerChanges.correctToIncorrect,
        },
        {
          x: "Incorrect to Correct",
          y: analysisData.answerChanges.incorrectToCorrect,
        },
        {
          x: "Incorrect to Incorrect",
          y: analysisData.answerChanges.incorrectToIncorrect,
        },
      ]);
    }
  }, [userExam, analysisData]);

  if (!userExam || !yourScorePieData || !analysisData || !answerChanges)
    return null;
  const parsedSubjectsData = parseSubjectsData(userExam);
  const subjectsDataArr = sortSubjectsData(
    parsedSubjectsData,
    subjectOrderBy,
    subjectIsAsc
  );
  const subjectsNameArr = parsedSubjectsData.map(data => data.name);

  return (
    <Fragment>
      <Paper elevation={0} className={classes.root}>
        {/* Test Stats Start Here */}
        <Grid container direction="column">
          <Grid item xs={12}>
            <Grid
              container
              spacing={mdDown ? 3 : 6}
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={6} md={4} xl={2}>
                <div style={{ textAlign: "center" }}>
                  <svg width={245} height={240}>
                    <VictoryPie
                      standalone={false}
                      colorScale={YOUR_SCORE_PIE_COLORS}
                      width={245}
                      height={240}
                      data={yourScorePieData}
                      innerRadius={90}
                      labels={() => null}
                    />
                    <VictoryLabel
                      textAnchor="middle"
                      style={{ fontSize: 27 }}
                      x={123}
                      y={110}
                      text={`${(
                        (questionsData.correct / questionsData.total) *
                        100
                      ).toFixed(0)}%`}
                    />
                    <VictoryLabel
                      textAnchor="middle"
                      style={{ fontSize: 16, fill: "#A1A1A1" }}
                      x={123}
                      y={135}
                      text="Correct"
                    />
                  </svg>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={5}>
                <StatsColumn title={"Your Score"} stats={yourScorePieData} />
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={5}>
                <StatsColumn title={"Answer Changes"} stats={answerChanges} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Test Stats End Here */}

        {/* Subjects Summary Start Here */}
        <div className={classes.showContainer}>
          <FilterMenu
            handleOpenMenu={handleOpenMenu}
            handleCloseMenu={handleCloseMenu}
            anchorEl={anchorElBarsMenu}
            menuItemArr={showBarsArr}
            text={renderSelectedFilter()}
            selectedFilter={showBarsList}
            handleSelectedFilter={handleShowBarsList}
            filterType={"ARRAY"}
            align="right"
          />
        </div>
        <div
          className="sectionAccordion"
          onClick={() => setShowSubjects(show => !show)}
        >
          <div className="fLabel">Subjects</div>
          <img
            src={upArrowIcon}
            alt="dropdown arrow icon"
            className={showSubjects ? "qbIcon" : "qbIcon active"}
          />
        </div>

        {showSubjects && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <CustomTableCell>
                    {expandAll ? (
                      <RemoveIcon onClick={toggleExpandAll} />
                    ) : (
                      <AddIcon onClick={toggleExpandAll} />
                    )}
                  </CustomTableCell>
                  {headCells.map(headCell => {
                    return (
                      <CustomTableCell
                        key={headCell.id}
                        padding={"normal"}
                        sortDirection={subjectIsAsc ? "asc" : "desc"}
                      >
                        <CustomTableSortLabel
                          active={subjectOrderBy === headCell.id}
                          direction={subjectIsAsc ? "asc" : "desc"}
                          onClick={() => onSortSubjects(headCell.id)}
                        >
                          {headCell.label}
                        </CustomTableSortLabel>
                      </CustomTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {subjectsDataArr.map(subjectsData => (
                  <Fragment key={subjectsData.name}>
                    <HoverSelectedTableRow
                      hover
                      selected={expandedList.includes(subjectsData.name)}
                      onClick={() => onClickAccordion(subjectsData.name)}
                    >
                      <SubjectsRowCell>
                        {expandedList.includes(subjectsData.name) ? (
                          <RemoveIcon />
                        ) : (
                          <AddIcon />
                        )}
                      </SubjectsRowCell>
                      <SubjectsRowCell
                        style={{
                          width: "44%",
                        }}
                      >
                        <div style={{ marginBottom: 12 }}>
                          {subjectsData.name}
                        </div>
                        <ScoreBar
                          correctWidth={
                            showBarsList.includes(QUESTION_STATUS["CORRECT"])
                              ? `${(
                                  (subjectsData.subjectsSummary["correct"]
                                    .count /
                                    subjectsData.total) *
                                  100
                                ).toFixed(0)}%`
                              : "0%"
                          }
                          incorrectWidth={
                            showBarsList.includes(QUESTION_STATUS["INCORRECT"])
                              ? `${(
                                  (subjectsData.subjectsSummary["incorrect"]
                                    .count /
                                    subjectsData.total) *
                                  100
                                ).toFixed(0)}%`
                              : "0%"
                          }
                          omittedWidth={
                            showBarsList.includes(QUESTION_STATUS["OMITTED"])
                              ? `${(
                                  (subjectsData.subjectsSummary["omitted"]
                                    .count /
                                    subjectsData.total) *
                                  100
                                ).toFixed(0)}%`
                              : "0%"
                          }
                        />
                      </SubjectsRowCell>
                      <SubjectsRowCell style={{ width: "20%" }}>
                        {subjectsData.total}
                      </SubjectsRowCell>
                      {Object.keys(subjectsData.subjectsSummary).map(key => (
                        <SubjectsRowCell key={key} style={{ width: "12%" }}>
                          <span>{subjectsData.subjectsSummary[key].count}</span>{" "}
                          <span>{`(${(
                            (subjectsData.subjectsSummary[key].count /
                              subjectsData.total) *
                            100
                          ).toFixed(0)}%)`}</span>
                        </SubjectsRowCell>
                      ))}
                    </HoverSelectedTableRow>

                    {expandedList.includes(subjectsData.name) &&
                      subjectsData.systemsSummaryArr.map(systemsSummary => (
                        <TableRow key={systemsSummary.name}>
                          <SystemsRowCell></SystemsRowCell>
                          <SystemsRowCell style={{ width: "44%" }}>
                            {systemsSummary.name}
                          </SystemsRowCell>
                          <SystemsRowCell style={{ width: "20%" }}>
                            {systemsSummary.total}
                          </SystemsRowCell>
                          {Object.keys(systemsSummary.summary).map(key => (
                            <SystemsRowCell key={key} style={{ width: "12%" }}>
                              <span>{systemsSummary.summary[key].count}</span>
                              <span>{`(${(
                                (systemsSummary.summary[key].count /
                                  systemsSummary.total) *
                                100
                              ).toFixed(0)}%)`}</span>
                            </SystemsRowCell>
                          ))}
                        </TableRow>
                      ))}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {/* Subjects Summary End Here */}
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  getExamAnalysis: id => dispatch(ExamActions.examAnalysis(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TestAnalysis)
);
