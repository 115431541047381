import { Box } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React, { useMemo } from "react";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import CardMembershipOutlinedIcon from "@material-ui/icons/CardMembershipOutlined";

import MUICustomHeader from "../../../../Components/MUICustomHeader";
import { XS_MUIDATATABLE_OPTIONS } from "../../../../constants";
import useStyles from "./styles";
import { parseDateHtml } from "../../../../utils";

const RedeemHistoriesTable = props => {
  const { redeemHistory } = props;
  const classes = useStyles();
  const data = useMemo(
    () =>
      redeemHistory && Array.isArray(redeemHistory) && redeemHistory.length > 0
        ? redeemHistory
            .sort((a, b) => (a._redeemedOn < b._redeemedOn ? 1 : -1))
            .map((p, index) => [
              index + 1,
              p.creator.email,
              p.creator.firstName,
              p.creator.lastName,
              p.plan.title || "",
              p._redeemedOn,
            ])
        : [],
    [redeemHistory]
  );

  const columns = [
    {
      name: "No",
      options: {
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <FormatListNumberedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
    {
      name: "Email",
      options: {
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <EmailOutlinedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
    {
      name: "First Name",
      options: {
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <AssignmentIndOutlinedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
    {
      name: "Last Name",
      options: {
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <PersonOutlineOutlinedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
    {
      name: "Plan",
      options: {
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <CardMembershipOutlinedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
    {
      name: "Used On",
      options: {
        customBodyRender: value => {
          return parseDateHtml(value, 120);
        },
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <CalendarTodayOutlinedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
  ];

  return (
    <>
      <Box width="100%" mb={2}>
        <MUIDataTable
          data={data}
          columns={columns}
          options={{ ...XS_MUIDATATABLE_OPTIONS }}
        />
      </Box>
    </>
  );
};

export default RedeemHistoriesTable;
