import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";

const AntTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    padding: "0 24px",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 18,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

export default AntTab;
