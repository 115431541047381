import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { assignSubscriptionTrialPlan } from "../../../../actions/subscriptionAction";
import { PrimaryButton } from "../../../../Components/CustomButton";
import { selectSubscription } from "../../../../selectors/subscription";

const FreeTrialPaymentButton = () => {
  const dispatch = useDispatch();
  const subscription = useSelector(selectSubscription);
  const handleAssignFreeTrialPlan = () => {
    if (subscription._id) {
      dispatch(assignSubscriptionTrialPlan(subscription._id));
    }
  };

  return (
    <PrimaryButton onClick={handleAssignFreeTrialPlan}>
      Proceed with free trial
    </PrimaryButton>
  );
};

export default FreeTrialPaymentButton;
