import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckEmailForm from "../../../../Components/Form/CheckEmail/CheckEmailForm";
import SignupForm from "../../../../Components/Form/Signup/SignupForm";
import usePath from "../../../../hooks/usePath";
import {
  CHECKOUT_LOGIN_URL,
  LOGIN_HASH,
  SIGNUP_HASH,
} from "../../../../routes/constants";
import history from "../../../../routes/history";
import { selectCheckUserExists } from "../../../../selectors/signup";
import WelcomeUserForm from "../../../../Components/Form/WelcomeUser/WelcomeUserForm";

const NewUserSection = () => {
  const checkUserExists = useSelector(selectCheckUserExists);
  const dispatch = useDispatch();
  const { hash } = usePath();

  useEffect(() => {
    if (checkUserExists && checkUserExists.username) {
      history.push(CHECKOUT_LOGIN_URL);
    }
  }, [checkUserExists, dispatch]);

  return (
    <>
      {hash === LOGIN_HASH && checkUserExists && checkUserExists.username ? (
        <WelcomeUserForm />
      ) : hash === SIGNUP_HASH ? (
        <SignupForm />
      ) : (
        <CheckEmailForm />
      )}
    </>
  );
};

export default NewUserSection;
