import { makeStyles } from "@material-ui/core/styles";
import {
  LIST_ITEM_ACTIVE_COLOR,
  LIST_ITEM_MUTE_COLOR,
  DRAWER_WIDTH,
  LIST_ITEM_ACTIVE_STYLE,
} from "../../styles/constants";

export default makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  icon: {
    width: "24px",
    height: "24px",
  },
  mainSideBarList: {
    display: props => (props.notShowMainSideBar ? "none" : ""),
    overflowY: props => (props.notShowMainSideBar ? "hidden" : "auto"),
  },
  drawerRoot: {
    height: "100vh",
    flexShrink: 0,
    position: "fixed",
    zIndex: 150,
  },
  drawerPaper: {
    overflowY: "hidden",
    paddingBottom: theme.spacing(8),
    position: "relative",
    width: DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  container: {
    position: "relative",
    paddingTop: 65,
  },
  chveron: {
    backgroundColor: "#c3cbd5",
    borderRadius: "50%",
    color: "#7b8e9d",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#7b8e9d",
      color: "#fff",
    },
  },
  chevronContainer: {
    position: "fixed",
    top: 75,
    zIndex: 1000,
  },
  chevronLeft: {
    left: DRAWER_WIDTH - 10,
  },
  chevronRight: {
    left: -12,
  },
  activeLinkContainer: {
    ...LIST_ITEM_ACTIVE_STYLE,
    "& span": {
      color: `${LIST_ITEM_ACTIVE_COLOR} !important`,
    },
  },
  linkContainer: {
    borderColor: "transparent",
    borderStyle: "solid",
    borderWidth: "1px 0px",
    transition: "all 100ms ease-in-out",
    "& span": {
      color: LIST_ITEM_MUTE_COLOR,
      fontFamily: "metropolis",
    },
    "&:hover": {
      "& span": {
        color: LIST_ITEM_ACTIVE_COLOR,
      },
      backgroundColor: "rgb(248, 250, 252)",
      cursor: "pointer",
      borderColor: "rgb(238, 242, 245)",
      borderStyle: "solid",
      borderWidth: "1px 0px",
    },
  },
  performanceActiveLinkContainer: {
    ...LIST_ITEM_ACTIVE_STYLE,
  },
  performanceLinkContainer: {
    borderColor: "transparent",
    borderStyle: "solid",
    borderWidth: "1px 0px",
    transition: "all 100ms ease-in-out",
    "& span": {
      color: LIST_ITEM_MUTE_COLOR,
      fontFamily: "metropolis",
    },
    "&:hover": {
      backgroundColor: "rgb(248, 250, 252)",
      cursor: "pointer",
      borderColor: "rgb(238, 242, 245)",
      borderStyle: "solid",
      borderWidth: "1px 0px",
    },
  },
  performanceTitle: {
    "&:hover": {
      "& span": {
        color: LIST_ITEM_ACTIVE_COLOR,
      },
    },
  },
  performanceActiveTitle: {
    "& span": {
      color: LIST_ITEM_ACTIVE_COLOR,
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  footCont: {
    minHeight: 90,
    backgroundColor: LIST_ITEM_MUTE_COLOR,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    padding: "15px 15px 10px",
    marginTop: "auto",
  },
}));
