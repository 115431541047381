import { Box, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { SELECT_INPUT_WIDTH } from "../../helper";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import ConfirmationModal from "../../../../Components/Modal/ConfirmationModal";
import useModal from "../../../../hooks/useModal";
import { validateDate } from "../../../../constants";
import history from "../../../../routes/history";
import { MANAGE_USER_URL } from "../../../../routes/constants";
import * as UserAction from "actions/userAction";
import {
  convertDateGMT_Plus08ToPlus10,
  convertDateGMT_Plus10ToPlus08,
} from "../../../../utils";
import { connect } from "react-redux";
import { checkShouldBlockUser } from "../../../../utils/access-helper";
import CalendarPresentButton from "../../../../Components/CalendarPresentButton";

const BlockUserSection = ({
  user,
  handleCloseModal,
  updateUser,
  fetchUsers,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { open, handleOpen, handleClose } = useModal();
  const validateStartDate = validateDate(startDate, "Start date");
  const validateEndDate = validateDate(endDate, "End date");
  const isBlocked =
    user.blockedStartDate && user.blockedEndDate && checkShouldBlockUser(user);

  const handleBlockUser = async () => {
    if (user._id) {
      const data = {
        _id: user._id,
        input: {
          blockedStartDate: convertDateGMT_Plus08ToPlus10(startDate),
          blockedEndDate: convertDateGMT_Plus08ToPlus10(endDate),
        },
      };

      await updateUser(data);
      if (handleCloseModal !== undefined) {
        handleCloseModal();
      } else {
        handleClose();
      }

      if (fetchUsers !== undefined) {
        fetchUsers();
      } else {
        history.push(MANAGE_USER_URL);
      }
    }
  };

  useEffect(() => {
    if (user.blockedStartDate) {
      setStartDate(convertDateGMT_Plus10ToPlus08(user.blockedStartDate));
    }
    if (user.blockedEndDate) {
      setEndDate(convertDateGMT_Plus10ToPlus08(user.blockedEndDate));
    }
  }, [user]);

  return (
    <>
      <ConfirmationModal
        open={open}
        onClose={handleClose}
        actionFn={handleBlockUser}
        ctaTitle={"Are you wish to block this user"}
        ctaTxt={"Block"}
      />
      <Box display="flex" flexDirection="column">
        {isBlocked && (
          <Box fontWeight="bold" fontSize="1rem">
            Status: Blocked
          </Box>
        )}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            margin="normal"
            value={startDate}
            label="Start Date (GMT+10)"
            format="yyyy-MM-dd hh:mm a"
            onChange={date => {
              setStartDate(date);
            }}
            error={Boolean(validateStartDate)}
            helperText={validateStartDate}
            style={{ maxWidth: SELECT_INPUT_WIDTH }}
            color="secondary"
            InputLabelProps={{ shrink: true }}
          />
          <CalendarPresentButton setFunc={setStartDate} />
          <KeyboardDateTimePicker
            margin="normal"
            value={endDate}
            label="End Date (GMT+10)"
            format="yyyy-MM-dd hh:mm a"
            onChange={date => {
              setEndDate(date);
            }}
            error={Boolean(validateEndDate)}
            helperText={validateEndDate}
            style={{ maxWidth: SELECT_INPUT_WIDTH }}
            color="secondary"
            InputLabelProps={{ shrink: true }}
          />
          <CalendarPresentButton setFunc={setEndDate} />
        </MuiPickersUtilsProvider>
        <Box my={3}>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleOpen}
            disabled={
              Boolean(validateStartDate) ||
              Boolean(validateEndDate) ||
              Boolean(!user.subscription)
            }
          >
            Block
          </Button>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  updateUser: data => dispatch(UserAction.updateUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlockUserSection);
