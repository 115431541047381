import * as ActionTypes from "../actions/examAction";
import * as UserTypes from "../actions/userAction";
import { REQUEST_STATUS } from "../constants";

const { CATEGORY_LIST_REQUEST } = ActionTypes;
const { USER_LOGOUT } = UserTypes;
const initialAppState = {
  subjects: [],
  systems: [],
  requestStatus: REQUEST_STATUS.UNINITIALIZED,
};

const categoryReducer = (state = initialAppState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CATEGORY_LIST_REQUEST.AWAIT:
      return { ...state, requestStatus: REQUEST_STATUS.LOADING };
    case CATEGORY_LIST_REQUEST.SUCCESS:
      return {
        ...state,
        requestStatus: REQUEST_STATUS.SUCCESS,
        subjects: payload.subjects,
        systems: payload.systems,
      };
    case CATEGORY_LIST_REQUEST.FAIL:
      return {
        ...state,
        requestStatus: REQUEST_STATUS.FAIL,
        error: payload.error,
      };
    case USER_LOGOUT: {
      return initialAppState;
    }
    default:
      return state;
  }
};

export default categoryReducer;
