import * as ActionTypes from "../actions/voucherAction";
import * as UserTypes from "../actions/userAction";

const {
  GET_VOUCHER_LIST,
  GET_VOUCHER_BY_ID,
  UPDATE_VOUCHER,
  CHECK_VOUCHER,
  SET_VOUCHER_AVAILABILITY,
} = ActionTypes;
const { USER_LOGOUT } = UserTypes;
const initialState = {
  vouchers: [],
  form: null,
  voucherAvailability: null,
};

const voucherReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_VOUCHER_LIST.AWAIT:
    case GET_VOUCHER_BY_ID.AWAIT:
    case UPDATE_VOUCHER.AWAIT:
    case GET_VOUCHER_LIST.FAIL:
    case GET_VOUCHER_BY_ID.FAIL:
    case UPDATE_VOUCHER.FAIL:
      return { ...state };
    case GET_VOUCHER_LIST.SUCCESS:
      return {
        ...state,
        vouchers: payload,
      };
    case GET_VOUCHER_BY_ID.SUCCESS:
      return {
        ...state,
        form: payload,
      };
    case UPDATE_VOUCHER.SUCCESS:
      return {
        ...state,
        form: payload,
      };
    case SET_VOUCHER_AVAILABILITY:
    case CHECK_VOUCHER.FAIL:
    case CHECK_VOUCHER.SUCCESS:
      return {
        ...state,
        voucherAvailability: payload,
      };
    case USER_LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default voucherReducer;
