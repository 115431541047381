import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#F15A24",
      light: "rgba(241, 90, 36, 0.5)",
      lighter: "#fcdfd4",
      dark: "#db5a2c",
    },
    secondary: {
      main: "#2196f3",
    },
    text: {
      primary: "#313131",
    },
  },
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  typography: {
    fontFamily: ['"metropolis"'].join(","),
  },
});

export default responsiveFontSizes(theme);
