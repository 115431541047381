import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  dialogTitleroot: {
    fontSize: 18,
    fontWeight: 600,
    color: "#313131",
    backgroundColor: "#d9d9d9",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.text.primary,
  },
});

export const CustomDialog = withStyles(theme => ({
  paper: {
    width: 700,
    maxWidth: "80vw",
    maxHeight: "85vh",
    boxShadow:
      "0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%)",
  },
}))(Dialog);

export const CustomDialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle
      disableTypography
      classes={{ root: classes.dialogTitleroot }}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          disableFocusRipple={true}
          disableRipple={true}
          disableTouchRipple={true}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

export const CustomDialogContent = withStyles(theme => ({
  root: {
    padding: `${theme.spacing(2.2)}px ${theme.spacing(3)}px`,
  },
}))(DialogContent);

export const CustomDialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions);

export const CustomConfirmDialogActions = withStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 15,
  },
}))(DialogActions);

export const CustomActionBtn = withStyles(theme => ({
  root: {
    backgroundColor: "#df4545",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#df4545",
      boxShadow: "none",
    },
  },
}))(Button);

export const CustomPrimaryActionBtn = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "none",
    },
  },
}))(Button);

export const CustomCancelBtn = withStyles(theme => ({
  root: {
    border: "1px solid #b2b2b2",
    color: "grey",
    textTransform: "capitalize",
    "&:hover": {
      boxShadow: "none",
    },
  },
}))(Button);
