import React from "react";
import { useSelector } from "react-redux";
import CheckoutProfileForm from "../../../../Components/Form/CheckoutProfile/CheckoutProfileForm";
import { selectCartItems } from "../../../../selectors/cart";
import { checkIsFreeTrialPlan } from "../helper";
import PaymentMethods from "./PaymentMethods";

const AuthUserCheckoutSection = ({ methods }) => {
  const cartItems = useSelector(selectCartItems);
  const isFreeTrialPlan = checkIsFreeTrialPlan(cartItems);

  return (
    <>
      {!isFreeTrialPlan && <PaymentMethods />}
      <CheckoutProfileForm {...methods} />
    </>
  );
};

export default AuthUserCheckoutSection;
