import { TRANSACTION_SOURCE, TRANSACTION_STATUS } from "../../../constants";

export const TRANSACTION_STATUS_LIST = [
  {
    label: "Completed",
    value: TRANSACTION_STATUS["COMPLETED"],
  },
  {
    label: "Failed",
    value: TRANSACTION_STATUS["FAILED"],
  },
  {
    label: "Pending",
    value: TRANSACTION_STATUS["PENDING"],
  },
  {
    label: "Refunded",
    value: TRANSACTION_STATUS["REFUNDED"],
  },
];

export const TRANSACTION_SOURCE_LIST = [
  {
    label: TRANSACTION_SOURCE["PAYPAL"],
    value: TRANSACTION_SOURCE["PAYPAL"],
  },
  {
    label: TRANSACTION_SOURCE["STRIPE"],
    value: TRANSACTION_SOURCE["STRIPE"],
  },
  {
    label: TRANSACTION_SOURCE["MANUAL"],
    value: TRANSACTION_SOURCE["MANUAL"],
  },
  {
    label: TRANSACTION_SOURCE["FREE"],
    value: TRANSACTION_SOURCE["FREE"],
  },
];
