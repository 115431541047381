import { CONTENT_PADDING } from "../../styles/constants";

const styles = theme => ({
  // NoteList styles
  root: {
    display: "flex",
    flexWrap: "wrap",
    height: "100%",
    ...CONTENT_PADDING(theme),
  },
  topContainer: {
    marginBottom: 50,
    width: "100%",
  },
  notePageContentHeader: {
    color: "#313131",
    fontSize: 15,
    marginBottom: 25,
  },
  noHover: {
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  // NoteCard styles
  noteCardContainer: {
    border: "1px solid #e8e8e8",
    borderRadius: 5,
    marginBottom: 25,
    width: "100%",
  },
  noteCardHeaderContainer: {
    backgroundColor: "#f4f4f4",
    height: 50,
    padding: "0 15px",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    display: "flex",
    alignItems: "center",
    wordBreak: "break-word",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "0 8px",
      height: 70,
    },
  },
  noteCardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  noteCardContentContainer: {
    fontSize: 13,
    color: "grey",
    padding: 16,
  },
  questionID: {
    fontWeight: 600,
    fontSize: 13,
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  noteCardActions: { display: "flex", alignItems: "center" },
  actionsIcon: {
    fontSize: "1.4em",
    cursor: "pointer",
    color: theme.palette.secondary.main,
  },
  verticalLine: {
    borderRight: "1px solid #b2b2b2",
    height: 21,
    width: 1,
  },
  optionTxt: {
    fontSize: 13,
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
});

export default styles;
