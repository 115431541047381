import { Box, Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React, { useState, useEffect } from "react";
import MUICustomHeader from "../../../../../Components/MUICustomHeader";
import EqualizerOutlinedIcon from "@material-ui/icons/EqualizerOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import { XS_MUIDATATABLE_OPTIONS } from "../../../../../constants";
import * as ExamActions from "../../../../../actions/examAction";

import useStyles from "./styles";
import { connect, useDispatch } from "react-redux";
import { safeFormatTotalScore } from "../../../../TestList/utils";
import { parseDateHtml } from "../../../../../utils";
import ConfirmationModal from "../../../../../Components/Modal/ConfirmationModal";
import useModal from "../../../../../hooks/useModal";
import { resetSubscription } from "../../../../../actions/subscriptionAction";

const PreviousTestListTable = ({
  userId,
  subscriptionId,
  getUserExamListByUserId,
  isEditing = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [exams, setExams] = useState([]);
  const { open, handleOpen, handleClose } = useModal();
  const disabled = exams.length === 0 && !subscriptionId;

  const fetchUserExamList = async () => {
    const userExamListData = await getUserExamListByUserId({ user_id: userId });

    if (userExamListData && userExamListData.userExamsByUserId) {
      setExams(
        userExamListData.userExamsByUserId.sort((a, b) =>
          a._createdOn < b._createdOn ? 1 : -1
        )
      );
    }
  };

  const handleResetSubscription = () => {
    const isAdmin = true;
    dispatch(resetSubscription(subscriptionId, isAdmin));
    handleClose();
  };

  const columns = [
    {
      name: "Completed On",
      options: {
        customBodyRender: value => {
          return parseDateHtml(value);
        },
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <CalendarTodayOutlinedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
    {
      name: "Score",
      options: {
        customBodyRenderLite: dataIndex => (
          <Box width={100}>{safeFormatTotalScore(exams[dataIndex])}</Box>
        ),
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <EqualizerOutlinedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
  ];
  useEffect(() => {
    fetchUserExamList();
  }, []);

  return (
    <>
      <ConfirmationModal
        open={open}
        onClose={handleClose}
        actionFn={handleResetSubscription}
        ctaTitle={"Are you wish to reset this user's progress?"}
        ctaTxt={"Reset"}
      />
      <Box width="100%" mb={2}>
        <MUIDataTable
          data={exams.map(userExam => [
            userExam._completedOn,
            userExam._totalScore,
          ])}
          columns={columns}
          options={{ ...XS_MUIDATATABLE_OPTIONS }}
        />
        {isEditing && (
          <Box mt={3}>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleOpen}
              disabled={disabled}
            >
              Reset Test Data
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getUserExamListByUserId: data =>
    dispatch(ExamActions.getUserExamListByUserId(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviousTestListTable);
