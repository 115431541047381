import React, { Fragment, useEffect, useState } from "react";
import clsx from "clsx";
import { useHistory, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import Tooltip from "@material-ui/core/Tooltip";
import DoneIcon from "@material-ui/icons/Done";
import PlayCircleFilledWhiteOutlinedIcon from "@material-ui/icons/PlayCircleFilledWhiteOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import MenuBookOutlinedIcon from "@material-ui/icons/MenuBookOutlined";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import copy from "copy-to-clipboard";
import moment from "moment";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import {
  CustomTableCell,
  CustomTableSortLabel,
  HoverSelectedTableRow,
  CustomTablePagination,
} from "../../Components/CustomTableElements";
import * as ExamActions from "../../actions/examAction";
import { headCells, showColumnsArr } from "./testListArr";
import { sortUserExams } from "./examsDataParser";
import styles from "./styles";
import FilterMenu from "../../Components/FilterMenu";
import { COURSE_APP_SUB_ROUTE } from "../../routes/constants";
import { safeFormatTotalScore } from "./utils";
import { safeFormatDefaultDate } from "../../utils";

const useStyles = makeStyles(theme => styles);

const TestList = props => {
  const { getUserExamList, updateUserExam } = props;
  const classes = useStyles();
  const [exams, setExams] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isAsc, setIsAsc] = useState(false);
  const [orderBy, setOrderBy] = useState("date");
  const [anchorElColumnMenu, setAnchorElColumnMenu] = useState(null);
  const [showColumns, setShowColumns] = useState({
    date: true,
    mode: true,
    questionMode: true,
    subjects: true,
    systems: true,
    qs: true,
    score: true,
    name: true,
    actions: true,
  });
  const [iconsState, setIconState] = useState({
    isShow: null,
    id: null,
  });
  const [nameFieldState, setNameFieldState] = useState({
    isShow: null,
    id: null,
    value: null,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Handle Pagination Start
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Handle Pagination End

  // Handle Columns Menu Start
  const handleOpenMenu = event => {
    setAnchorElColumnMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElColumnMenu(null);
  };

  const handleShowColumns = event => {
    setShowColumns({
      ...showColumns,
      [event.target.name]: event.target.checked,
    });
  };
  // Handle Columns Menu End

  // Handle NameField Icon Start
  const handleMouseLeaveRow = () => {
    setIconState({ isShow: false, id: null });
  };

  const handleMouseEnterRow = id => {
    setIconState({ isShow: true, id });
  };

  const handleChangeNameField = event => {
    setNameFieldState({ ...nameFieldState, value: event.target.value });
  };
  const handleUpdateUserExam = async userExamID => {
    if (nameFieldState.value) {
      const res = await updateUserExam({
        _id: userExamID,
        input: {
          name: nameFieldState.value,
        },
      });

      if (res && res.updateUserExam) {
        // Refetch Data once updated
        await fetchUserExamList();
        // Restore back default namefield state
        clearNameFieldState();
      }
    } else clearNameFieldState();
  };

  const clearNameFieldState = () => {
    setNameFieldState({ isShow: null, id: null, value: null });
  };
  // Handle NameField Icon End

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const fetchUserExamList = async () => {
    const userExamListData = await getUserExamList();

    if (userExamListData && userExamListData.userExams) {
      setExams(userExamListData.userExams);
    }
  };

  const history = useHistory();

  useEffect(() => {
    fetchUserExamList();
  }, []);

  const onSort = value => {
    if (orderBy === value) {
      setIsAsc(!isAsc);
    } else {
      setOrderBy(value);
    }
  };

  const onCopy = id => {
    copy(id, {
      message: "Press #{key} to copy",
    });
    setOpenSnackbar(true);
  };

  const arrowIconBtnProps = {
    classes: {
      root: classes.noHover,
    },
    disableFocusRipple: true,
    disableRipple: true,
  };

  if (!exams) return null;

  const currentExams = sortUserExams(exams, isAsc, orderBy).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Fragment>
      <Helmet>
        <title>QBank Test List Page</title>
        <meta
          name="description"
          content="List of test create from QBank main portal"
        />
      </Helmet>
      <Paper elevation={0} className={classes.root}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          autoHideDuration={2500}
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          message={
            <div className={classes.centerContainer}>
              <DoneIcon className={classes.doneIcon} /> TestId Copied
              Sucessfully
            </div>
          }
        />
        <div className={classes.showColumnsContainer}>
          <FilterMenu
            handleOpenMenu={handleOpenMenu}
            handleCloseMenu={handleCloseMenu}
            anchorEl={anchorElColumnMenu}
            menuItemArr={showColumnsArr}
            text={"Columns"}
            selectedFilter={showColumns}
            handleSelectedFilter={handleShowColumns}
          />
        </div>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map(headCell => {
                  return (
                    showColumns[headCell.id] && (
                      <CustomTableCell
                        key={headCell.id}
                        padding={"normal"}
                        sortDirection={isAsc ? "asc" : "desc"}
                      >
                        {headCell.id !== "actions" ? (
                          <CustomTableSortLabel
                            active={orderBy === headCell.id}
                            direction={isAsc ? "asc" : "desc"}
                            onClick={() => onSort(headCell.id)}
                          >
                            {headCell.label}
                          </CustomTableSortLabel>
                        ) : (
                          <div className={classes.lightGreyText}>
                            {headCell.label}
                          </div>
                        )}
                      </CustomTableCell>
                    )
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentExams.length > 0 &&
                currentExams.map(userExam => {
                  return (
                    <HoverSelectedTableRow
                      key={userExam._id}
                      hover
                      onMouseEnter={() => handleMouseEnterRow(userExam._id)}
                      onMouseLeave={handleMouseLeaveRow}
                    >
                      <CustomTableCell style={{ width: "10%" }}>
                        <div className={classes.scoreBox}>
                          {safeFormatTotalScore(userExam)}
                        </div>
                      </CustomTableCell>
                      <CustomTableCell style={{ width: "30%" }}>
                        <div className={classes.centerContainer}>
                          {
                            <div
                              className={classes.centerContainer}
                              style={{ width: "100%" }}
                            >
                              {nameFieldState.isShow &&
                              nameFieldState.id === userExam._id ? (
                                <div
                                  className={classes.centerContainer}
                                  style={{ width: "100%" }}
                                >
                                  <span>{userExam._userExamNo}</span>
                                  <span className={classes.hyphen}> - </span>

                                  <TextField
                                    id="standard-basic"
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                    }}
                                    value={nameFieldState.value}
                                    onChange={handleChangeNameField}
                                    inputProps={{ style: { fontSize: 13 } }}
                                  />

                                  <Tooltip title="Done">
                                    <DoneIcon
                                      className={classes.actionIcon}
                                      color="secondary"
                                      style={{ marginLeft: "6px" }}
                                      onClick={() =>
                                        handleUpdateUserExam(userExam._id)
                                      }
                                    />
                                  </Tooltip>

                                  <Tooltip title="Cancel">
                                    <CloseIcon
                                      className={classes.actionIcon}
                                      color="secondary"
                                      style={{ marginLeft: "6px" }}
                                      onClick={() =>
                                        setNameFieldState({
                                          isShow: false,
                                          id: null,
                                          value: null,
                                        })
                                      }
                                    />
                                  </Tooltip>
                                </div>
                              ) : (
                                <Fragment>
                                  <span>{userExam._userExamNo}</span>
                                  {userExam.name && (
                                    <Fragment>
                                      <span className={classes.hyphen}>
                                        {" "}
                                        -{" "}
                                      </span>
                                      <span>{userExam.name}</span>
                                    </Fragment>
                                  )}

                                  {iconsState.isShow &&
                                    iconsState.id === userExam._id && (
                                      <Fragment>
                                        <Tooltip title="Edit Test Name">
                                          <EditOutlinedIcon
                                            className={clsx(
                                              classes.icon,
                                              classes.editIcon
                                            )}
                                            color="secondary"
                                            onClick={() =>
                                              setNameFieldState({
                                                isShow: true,
                                                id: userExam._id,
                                                value: userExam.name,
                                              })
                                            }
                                          />
                                        </Tooltip>
                                        <Tooltip title="Copy TestId">
                                          <FileCopyOutlinedIcon
                                            className={classes.icon}
                                            color="secondary"
                                            style={{ marginLeft: "4px" }}
                                            onClick={() =>
                                              onCopy("AK" + userExam._ref)
                                            }
                                          />
                                        </Tooltip>
                                      </Fragment>
                                    )}
                                </Fragment>
                              )}
                            </div>
                          }
                        </div>
                      </CustomTableCell>
                      {showColumns["date"] && (
                        <CustomTableCell style={{ width: "10%" }}>
                          {safeFormatDefaultDate(userExam._createdOn)}
                        </CustomTableCell>
                      )}
                      {showColumns["mode"] && (
                        <CustomTableCell style={{ width: "5%" }}>
                          {userExam.exam.tutor
                            ? userExam.exam.timed
                              ? "Tutor Timed"
                              : "Tutor"
                            : userExam.exam.timed
                            ? "Timed"
                            : "Untimed"}
                        </CustomTableCell>
                      )}
                      {showColumns["questionMode"] && (
                        <CustomTableCell style={{ width: "5%" }}>
                          {userExam.exam.question_mode.charAt(0).toUpperCase() +
                            userExam.exam.question_mode.slice(1)}
                        </CustomTableCell>
                      )}
                      {showColumns["subjects"] && (
                        <CustomTableCell style={{ width: "12%" }}>
                          {userExam.exam.categories.length === 0
                            ? "N/A"
                            : userExam.exam.categories.length > 1
                            ? "Multiple"
                            : userExam.exam.categories[0].category.name}
                        </CustomTableCell>
                      )}
                      {showColumns["systems"] && (
                        <CustomTableCell style={{ width: "12%" }}>
                          {userExam.exam.subCategories.length === 0
                            ? "N/A"
                            : userExam.exam.subCategories.length > 1
                            ? "Multiple"
                            : userExam.exam.subCategories[0].category.name}
                        </CustomTableCell>
                      )}

                      {showColumns["qs"] && (
                        <CustomTableCell style={{ width: "6%" }}>
                          {userExam.exam.questions.length}
                        </CustomTableCell>
                      )}

                      <CustomTableCell style={{ width: "3%" }}>
                        <div className={classes.centerContainer}>
                          <Tooltip
                            title={
                              userExam.status === "end" ? "Review" : "Resume"
                            }
                          >
                            {userExam.status === "end" ? (
                              <MenuBookOutlinedIcon
                                color="secondary"
                                className={classes.actionIcon}
                                style={{ marginRight: "8px" }}
                                onClick={() =>
                                  history.push(
                                    `${COURSE_APP_SUB_ROUTE}/test-detail/${userExam._id}`
                                  )
                                }
                              />
                            ) : (
                              <PlayCircleFilledWhiteOutlinedIcon
                                color="secondary"
                                className={classes.actionIcon}
                                style={{ marginRight: "8px" }}
                                onClick={() =>
                                  history.push(
                                    `${COURSE_APP_SUB_ROUTE}/test-detail/${userExam._id}`
                                  )
                                }
                              />
                            )}
                          </Tooltip>
                          <Tooltip title="Results">
                            <AssignmentOutlinedIcon
                              color="secondary"
                              className={classes.actionIcon}
                              onClick={() =>
                                history.push(
                                  `${COURSE_APP_SUB_ROUTE}/test-result/${userExam._id}`
                                )
                              }
                              style={{ marginRight: "8px" }}
                            />
                          </Tooltip>
                          <Tooltip title="Analysis">
                            <AssessmentOutlinedIcon
                              color="secondary"
                              className={classes.actionIcon}
                              onClick={() =>
                                history.push(
                                  `${COURSE_APP_SUB_ROUTE}/test-analysis/${userExam._id}`
                                )
                              }
                            />
                          </Tooltip>
                        </div>
                      </CustomTableCell>
                    </HoverSelectedTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <CustomTablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    50,
                    100,
                    { label: "All", value: -1 },
                  ]}
                  colSpan={8}
                  count={exams.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage={"Items per page"}
                  SelectProps={{
                    inputProps: { "aria-label": "Items per page" },
                    native: true,
                  }}
                  backIconButtonProps={arrowIconBtnProps}
                  nextIconButtonProps={arrowIconBtnProps}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getUserExamList: () => dispatch(ExamActions.getUserExamList()),
  updateUserExam: data => dispatch(ExamActions.updateUserExam(data)),
  retakeTest: id => dispatch(ExamActions.retakeTest(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TestList)
);
