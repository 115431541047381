import { gql } from "@apollo/client";

export const userPerformance = gql`
  query {
    userPerformance {
      answerChanges {
        correctToIncorrect
        incorrectToCorrect
        incorrectToIncorrect
      }
      avgTimeSpent
      otherAvgTimeSpent
      category {
        _id
        name
        usage
        correct
        incorrect
        omitted
        firstAttemptCorrect
        firstAttemptIncorrect
        firstAttemptOmitted
        rank
        subCategory {
          _id
          name
          usage
          correct
          incorrect
          omitted
          firstAttemptCorrect
          firstAttemptIncorrect
          firstAttemptOmitted
        }
      }
      count {
        completed
        created
        suspended
      }

      summary {
        correct
        incorrect
        omitted
      }
      medianScore {
        percentile
        rank
      }
      yourScore {
        percentile
        rank
      }
    }
  }
`;
