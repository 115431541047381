import moment from "moment-timezone";
const DEFAULT_DATE_TIME_FORMAT = "DD/MM/YYYY,h:mm:ss A";
const DEFAULT_DATE_FORMAT = "MMM DD, YYYY";
const DEFAULT_RECEIPT_DATE_TIME_FORMAT = "DD/MM/YYYY h:mm:ss A";
export const DEFAULT_UTC_OFFSET = "+10:00";

export const formatDateWithDefaultTimeZone = date => {
  if (!date) return "N/A";
  return (
    moment
      .utc(date)
      .utcOffset(DEFAULT_UTC_OFFSET)
      .format("MMM DD, YYYY h:mm A") + " GMT+10"
  );
};
export const safeFormatDefaultDateTime = datetime => {
  if (!datetime) return "";
  return moment(datetime).format(DEFAULT_DATE_TIME_FORMAT);
};

export const dateFormatAdapter = date => {
  return moment(date).format("YYYY-MM-DD");
};

export const parseStartOfTheDate = date =>
  moment
    .utc(date)
    .utcOffset(DEFAULT_UTC_OFFSET)
    .toDate();

export const parseDefaultToSystemTimezone = date => {
  return moment(date).add(1, "days");
};

export const safeFormatDefaultDate = datetime => {
  if (!datetime) return "";
  return moment(datetime).format(DEFAULT_DATE_FORMAT);
};

export const safeFormatDefaultReceiptDateTime = datetime => {
  if (!datetime) return "";
  return moment(datetime).format(DEFAULT_RECEIPT_DATE_TIME_FORMAT);
};

export const convertDateGMT_Plus10ToPlus08 = date => {
  const converted = moment
    .utc(date)
    .utcOffset(DEFAULT_UTC_OFFSET)
    .tz("Asia/Kuala_Lumpur", true)
    .set("second", 0)
    .toString();

  return new Date(converted);
};

export const convertDateGMT_Plus08ToPlus10 = date => {
  const converted = moment(date)
    .tz("Asia/Vladivostok", true)
    .set("second", 0)
    .toString();
  return new Date(converted);
};

export const isBeforeDatetime = compareDate => {
  const compareDateObj = moment(compareDate);

  return moment().isBefore(compareDateObj);
};

export const isAfterDatetime = (afterTime, compareTime) => {
  const afterTimeObj = moment(afterTime);
  const compareTimeObj = moment(compareTime);

  return afterTimeObj.isAfter(compareTimeObj);
};
