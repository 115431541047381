import { gql } from "@apollo/client";

export const register = gql`
  mutation Register($input: SignUpInput!) {
    register(input: $input) {
      _id
      _ref
      _createdBy
      _createdOn
      _updatedBy
      _updatedOn
    }
  }
`;

export const login = gql`
  mutation Login($input: SignInInput) {
    login(input: $input) {
      accessToken
      accessTokenExpiredOn
      lastAccessOn
      type
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePasswordInput) {
    changePassword(input: $input)
  }
`;

export const ACTIVATE_USER = gql`
  mutation activate($_id: String!, $code: String!) {
    activate(_id: $_id, code: $code) {
      _id
    }
  }
`;

export const USERS = gql`
  query {
    users {
      _id
      email
      status
    }
  }
`;

export const usersList = gql`
  query Users($filter: StatusFilterInput) {
    users(filter: $filter) {
      _id
      _ref
      _activateCode
      subscription {
        _planHistory {
          _id
          _createdOn
          _activatedOn
          term {
            _id
            price
            term
            unit
            title
          }
          source
          status
        }
        _resetHistory {
          _id
          _createdOn
          ipAddress
          country
          city
        }
        resetUsage
      }
      email
      _emailHistory {
        email
        _registeredOn
        status
      }
      _loginHistory {
        _createdOn
        ipAddress
        country
        city
      }
      firstName
      lastName
      address1
      address2
      city
      country
      state
      zipCode
      phoneNo
      educationalGroup
      careerStatus
      graduatingSchool
      status
      type
      _activateOn
      _createdOn
      _lastAccessOn
      registeredIpAddress
      registeredCountry
      registeredCity
      remark
      blockedStartDate
      blockedEndDate
    }
  }
`;

export const userById = gql`
  query UserById($_id: ObjectId!) {
    user(_id: $_id) {
      _id
      _ref
      _activateCode
      subscription {
        _id
        _planHistory {
          _id
          _createdOn
          _activatedOn
          term {
            _id
            price
            term
            unit
            title
          }
          source
          status
          transaction_id
        }
        _resetHistory {
          _id
          _createdOn
          ipAddress
          country
          city
        }
        resetUsage
        resetLimit
        subscriptionPlanEndOn
      }
      email
      _emailHistory {
        email
        _registeredOn
        status
      }
      _loginHistory {
        _createdOn
        ipAddress
        country
        city
      }
      firstName
      lastName
      address1
      address2
      city
      country
      state
      zipCode
      phoneNo
      educationalGroup
      careerStatus
      graduatingSchool
      status
      type
      _activateOn
      _createdOn
      _lastAccessOn
      registeredIpAddress
      registeredCountry
      registeredCity
      remark
      blockedStartDate
      blockedEndDate
    }
  }
`;

export const updateUser = gql`
  mutation updateUser($_id: ObjectId!, $input: UpdateUserInput!) {
    updateUser(_id: $_id, input: $input) {
      _id
      _ref
      email
      firstName
      lastName
      address1
      address2
      city
      country
      state
      zipCode
      phoneNo
      educationalGroup
      careerStatus
      graduatingSchool
      status
      type
    }
  }
`;

export const createUser = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      _createdOn
      _id
      _ref
    }
  }
`;

export const REMOVE_USER = gql`
  mutation RemoveUser($_id: ObjectId!, $permanent: Boolean) {
    removeUser(_id: $_id, permanent: $permanent)
  }
`;

export const GET_USER_PROFILE = gql`
  query Profile {
    profile {
      _id
      _ref
      email
      firstName
      lastName
      address1
      address2
      city
      country
      state
      zipCode
      phoneNo
      educationalGroup
      careerStatus
      graduatingSchool
      billing_firstName
      billing_lastName
      billing_address1
      billing_address2
      billing_city
      billing_country
      billing_state
      billing_zipCode
      billing_phoneNo
      blockedStartDate
      blockedEndDate
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($_id: ObjectId!, $input: UpdateUserProfileInput!) {
    updateUserProfile(_id: $_id, input: $input) {
      _id
      _ref
      email
      firstName
      lastName
      address1
      address2
      city
      country
      state
      zipCode
      phoneNo
      educationalGroup
      careerStatus
      graduatingSchool
      billing_firstName
      billing_lastName
      billing_address1
      billing_address2
      billing_city
      billing_country
      billing_state
      billing_zipCode
      billing_phoneNo
      blockedStartDate
      blockedEndDate
    }
  }
`;

export const CHECK_EMAIL = gql`
  query CheckEmail($email: String!) {
    checkEmail(email: $email) {
      available
      email
      username
    }
  }
`;

export const GET_USER_STATION_DETAIL = gql`
  query GetUserStationDetail {
    getUserStationDetail {
      ipAddress
      country
      city
    }
  }
`;

export const TRIGGER_RESET_PASSWORD = gql`
  mutation triggerResetPassword($email: String) {
    triggerResetPassword(email: $email)
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($input: ForgotPasswordInput) {
    forgotPassword(input: $input)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput) {
    resetPassword(input: $input)
  }
`;
