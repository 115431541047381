import * as UIActions from "./uiAction";
import * as TopicApi from "../services/topic";
import * as ExamApi from "../services/exam";
import { AppConfig } from "../configs";
import { validationErrorAdapter } from "../utils/api-helper";
import { CATEGORY_TYPE } from "../constants";
import { sortCategories } from "../views/topic/TopicList/helper";

const { client } = AppConfig;

const MODULE_NAME = "TOPIC_LIST";

export const SET_TOPIC_LIST_STATE = `${MODULE_NAME}/SET_TOPIC_LIST_STATE`;
export const CLEAR_TOPIC_LIST_STATE = `${MODULE_NAME}/CLEAR_TOPIC_LIST_STATE`;

export const setTopicListState = payload => ({
  type: SET_TOPIC_LIST_STATE,
  payload,
});

export const clearTopicListState = () => ({
  type: CLEAR_TOPIC_LIST_STATE,
});

// User
export const getTopicListState = filter => async dispatch => {
  dispatch(UIActions.appLoading());

  try {
    const topicListData = await client.query({
      query: TopicApi.lessons,
      variables: { filter },
    });

    const categoryListData = await client.query({
      query: ExamApi.categories,
      variables: { filter },
    });

    if (
      categoryListData &&
      categoryListData.data &&
      categoryListData.data.categories
    ) {
      let subjects = [];
      let systems = [];
      categoryListData.data.categories.map(category => {
        if (category.type == CATEGORY_TYPE.SUBJECT) {
          subjects.push(category);
        } else if (category.type == CATEGORY_TYPE.SYSTEM) {
          systems.push(category);
        }
      });
      let categoriesHashMap = {
        [CATEGORY_TYPE.SUBJECT]: sortCategories(subjects),
        [CATEGORY_TYPE.SYSTEM]: sortCategories(systems),
      };
      dispatch(
        setTopicListState({
          categories: categoriesHashMap,
        })
      );
    }

    if (topicListData && topicListData.data && topicListData.data.lessons) {
      dispatch(
        setTopicListState({
          topics: topicListData.data.lessons,
        })
      );
    }

    dispatch(UIActions.appReady());
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.message) dispatch(UIActions.addError(err.message));
    else if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else
      dispatch(
        UIActions.addError(
          "We are having problem getting topic list. Please try again later."
        )
      );
  }
};

//Admin
export const getTopicList = filter => async dispatch => {
  if (!filter) {
    dispatch(UIActions.appLoading());
  }

  try {
    const res = await client.query({
      query: TopicApi.lessons,
      variables: { filter },
    });

    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.message) dispatch(UIActions.addError(err.message));
    else if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else
      dispatch(
        UIActions.addError(
          "We are having problem getting topic list. Please try again later."
        )
      );
  }
};

export const getTopicById = id => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.query({
      query: TopicApi.lessonById,
      variables: { _id: id },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.message) dispatch(UIActions.addError(err.message));
    else if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else
      dispatch(
        UIActions.addError(
          "We are having problem getting topic. Please try again later."
        )
      );
  }
};

export const createTopic = data => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: TopicApi.createLesson,
      variables: data,
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors) {
      dispatch(UIActions.addError(validationErrorAdapter(err.graphQLErrors)));
    } else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("There is some error with create topic"));
  }
};

export const updateTopic = data => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: TopicApi.updateLesson,
      variables: data,
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors) {
      dispatch(UIActions.addError(validationErrorAdapter(err.graphQLErrors)));
    } else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(UIActions.addError("There is some error with updating topic"));
  }
};

export const removeTopic = (id, permanent) => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: TopicApi.removeLesson,
      variables: { _id: id, permanent },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error removing topic"));
  }
};
