import * as UIActions from "./uiAction";
import * as SubscriptionApi from "../services/subscription";
import { AppConfig } from "../configs";
import { PRIMARY_PLAN_ID, SERVER_ERROR } from "../constants";
import {
  clearCart,
  setIsValidToPay,
  setPayPalOrderConfig,
  setStripeOrderConfig,
} from "./cartAction";
import { handleAxiosError } from "./errorAction";
import history from "../routes/history";
import { MANAGE_USER_URL, SUBSCRIPTIONS_URL } from "../routes/constants";
import * as UserApi from "../services/user";
import { updateUserProfile } from "./userAction";

const { client } = AppConfig;

const MODULE_NAME = "SUBSCRIPTION";

// export const GET_SUBSCRIPTION_BY_ID = {
//   AWAIT: `${MODULE_NAME}/BY_ID/AWAIT`,
//   SUCCESS: `${MODULE_NAME}/BY_ID/SUCCESS`,
//   FAIL: `${MODULE_NAME}/BY_ID/FAIL`,
// };
export const GET_SUBSCRIPTION_BY_PROFILE = {
  AWAIT: `${MODULE_NAME}/BY_USER_ID/AWAIT`,
  SUCCESS: `${MODULE_NAME}/BY_USER_ID/SUCCESS`,
  FAIL: `${MODULE_NAME}/BY_USER_ID/FAIL`,
};
export const UPDATE_SUBSCRIPTION = {
  AWAIT: `${MODULE_NAME}/UPDATE/AWAIT`,
  SUCCESS: `${MODULE_NAME}/UPDATE/SUCCESS`,
  FAIL: `${MODULE_NAME}/UPDATE/FAIL`,
};
export const RESET_SUBSCRIPTION = {
  AWAIT: `${MODULE_NAME}/RESET/AWAIT`,
  SUCCESS: `${MODULE_NAME}/RESET/SUCCESS`,
  FAIL: `${MODULE_NAME}/RESET/FAIL`,
};
export const SUBSCRIBE_PLAN_WITH_PAYPAL = {
  AWAIT: `${MODULE_NAME}/SUBSCRIBE_PLAN_WITH_PAYPAL/AWAIT`,
  SUCCESS: `${MODULE_NAME}/SUBSCRIBE_PLAN_WITH_PAYPAL/SUCCESS`,
  FAIL: `${MODULE_NAME}/SUBSCRIBE_PLAN_WITH_PAYPAL/FAIL`,
};
export const SUBSCRIBE_PLAN_WITH_STRIPE = {
  AWAIT: `${MODULE_NAME}/SUBSCRIBE_PLAN_WITH_STRIPE/AWAIT`,
  SUCCESS: `${MODULE_NAME}/SUBSCRIBE_PLAN_WITH_STRIPE/SUCCESS`,
  FAIL: `${MODULE_NAME}/SUBSCRIBE_PLAN_WITH_STRIPE/FAIL`,
};
export const TOGGLE_ACTIVATION_MODAL = `${MODULE_NAME}/TOGGLE_ACTIVATION_MODAL/AWAIT`;
export const PAYPAL_PAYMENT_APPROVED = {
  AWAIT: `${MODULE_NAME}/PAYPAL_PAYMENT_APPROVED/AWAIT`,
  SUCCESS: `${MODULE_NAME}/PAYPAL_PAYMENT_APPROVED/SUCCESS`,
  FAIL: `${MODULE_NAME}/PAYPAL_PAYMENT_APPROVED/FAIL`,
};
export const ACTIVATE_SUBSCRIPTION = {
  AWAIT: `${MODULE_NAME}/ACTIVATE/AWAIT`,
  SUCCESS: `${MODULE_NAME}/ACTIVATE/SUCCESS`,
  FAIL: `${MODULE_NAME}/ACTIVATE/FAIL`,
};
export const REFUND_SUBSCRIPTION_PLAN = {
  AWAIT: `${MODULE_NAME}/REFUND/AWAIT`,
  SUCCESS: `${MODULE_NAME}/REFUND/SUCCESS`,
  FAIL: `${MODULE_NAME}/REFUND/FAIL`,
};
export const ASSIGN_MANUAL_SUBSCRIPTION_PLAN = {
  AWAIT: `${MODULE_NAME}ASSIGN_MANUAL_PLAN/AWAIT`,
  SUCCESS: `${MODULE_NAME}ASSIGN_MANUAL_PLAN/SUCCESS`,
  FAIL: `${MODULE_NAME}ASSIGN_MANUAL_PLAN/FAIL`,
};
export const ASSIGN_SUBSCRIPTION_TRIAL_PLAN = {
  AWAIT: `${MODULE_NAME}ASSIGN_TRIAL_PLAN/AWAIT`,
  SUCCESS: `${MODULE_NAME}ASSIGN_TRIAL_PLAN/SUCCESS`,
  FAIL: `${MODULE_NAME}ASSIGN_TRIAL_PLAN/FAIL`,
};

// export const getSubscriptionById = id => async dispatch => {
//   dispatch(UIActions.appLoading());
//   dispatch({ type: GET_SUBSCRIPTION_BY_ID.AWAIT });
//   try {
//     const res = await client.query({
//       query: SubscriptionApi.GET_SUBSCRIPTION,
//       variables: { _id: id },
//     });

//     if (res && res.data) {
//       dispatch({
//         type: GET_SUBSCRIPTION_BY_ID.SUCCESS,
//         payload: res.data.subscription,
//       });
//     }

//     dispatch(UIActions.appReady());
//     return res.data;
//   } catch (err) {
//     handleAxiosError(
//       dispatch,
//       err,
//       "We are having problem getting subscription. Please try again later."
//     );
//     dispatch({
//       type: GET_SUBSCRIPTION_BY_ID.FAIL,
//     });
//   }
// };

export const getSubscriptionByProfile = () => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: GET_SUBSCRIPTION_BY_PROFILE.AWAIT });
  try {
    const res = await client.query({
      query: SubscriptionApi.GET_SUBSCRIPTION_BY_PROFILE,
    });

    if (res && res.data && res.data.profile) {
      dispatch({
        type: GET_SUBSCRIPTION_BY_PROFILE.SUCCESS,
        payload: res.data.profile.subscription,
      });
    }

    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    handleAxiosError(
      dispatch,
      err,
      "We are having problem getting subscription. Please try again later."
    );
    dispatch({
      type: GET_SUBSCRIPTION_BY_PROFILE.FAIL,
    });
  }
};

export const resetSubscription = (id, isAdmin = false) => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: RESET_SUBSCRIPTION.AWAIT });
  try {
    const userStationRes = await client.query({
      query: UserApi.GET_USER_STATION_DETAIL,
    });

    if (userStationRes.data.getUserStationDetail) {
      const {
        ipAddress,
        country,
        city,
      } = userStationRes.data.getUserStationDetail;
      const res = await client.mutate({
        mutation: SubscriptionApi.RESET_SUBSCRIPTION,
        variables: {
          _id: id,
          input: {
            ipAddress,
            country,
            city,
          },
        },
      });

      if (res && res.data && res.data.resetSubscription) {
        const newSubscription = res.data.resetSubscription;
        dispatch({
          type: RESET_SUBSCRIPTION.SUCCESS,
          payload: newSubscription,
        });
        dispatch(UIActions.showSuccess("Test data removed successfully."));
        if (isAdmin) {
          history.push(MANAGE_USER_URL);
        }
      }

      dispatch(UIActions.appReady());
      return res.data;
    }
  } catch (err) {
    dispatch(UIActions.appReady());
    let errMsg;
    if (err.message) errMsg = err.message;
    else if (err.graphQLErrors)
      errMsg = err.graphQLErrors.map(item => item.message).join(". ");
    else errMsg = "Error removing test data";
    if (errMsg === SERVER_ERROR.NO_MATCHED_RECORD)
      errMsg = "You have no test data to be resetted";
    dispatch(UIActions.addError(errMsg));
    dispatch({
      type: RESET_SUBSCRIPTION.FAIL,
    });
  }
};

export const subscribePlanWithPaypal = (
  data,
  updateProfileData
) => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: SUBSCRIBE_PLAN_WITH_PAYPAL.AWAIT });
  try {
    const res = await client.mutate({
      mutation: SubscriptionApi.SUBSCRIBE_PLAN_WITH_PAYPAL,
      variables: data,
    });
    let result = null;
    if (res && res.data && res.data.subscribePlanWithPaypal) {
      const paypalOrderConfig = res.data.subscribePlanWithPaypal;
      dispatch({
        type: SUBSCRIBE_PLAN_WITH_PAYPAL.SUCCESS,
      });
      if (updateProfileData) {
        dispatch(updateUserProfile(updateProfileData));
      }
      dispatch(setPayPalOrderConfig(paypalOrderConfig));
      dispatch(setIsValidToPay(true));

      result = paypalOrderConfig;
    }
    dispatch(UIActions.appReady());
    return result;
  } catch (err) {
    if (
      err.message &&
      err.message.substring(0, 20) ===
        SERVER_ERROR.USER_SUBSCRIPTION_PLAN_HAS_NOT_ACTIVATED_ERROR
    ) {
      dispatch(UIActions.appReady());
      dispatch(toggleActivationModal({ open: true, msg: err.message }));
    } else {
      handleAxiosError(dispatch, err);
    }
    dispatch({
      type: SUBSCRIBE_PLAN_WITH_PAYPAL.FAIL,
    });
  }
};

export const subscribePlanWithStripe = (
  data,
  updateProfileData
) => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: SUBSCRIBE_PLAN_WITH_STRIPE.AWAIT });
  try {
    const res = await client.mutate({
      mutation: SubscriptionApi.SUBSCRIBE_PLAN_WITH_STRIPE,
      variables: data,
    });
    let result = null;
    if (res && res.data && res.data.subscribePlanWithStripe) {
      const stripeOrderConfig = res.data.subscribePlanWithStripe;
      dispatch({
        type: SUBSCRIBE_PLAN_WITH_STRIPE.SUCCESS,
      });
      if (updateProfileData) {
        dispatch(updateUserProfile(updateProfileData));
      }
      dispatch(setStripeOrderConfig(stripeOrderConfig));
      dispatch(setIsValidToPay(true));
      result = stripeOrderConfig;
    }
    dispatch(UIActions.appReady());
    return result;
  } catch (err) {
    if (
      err.message &&
      err.message.substring(0, 20) ===
        SERVER_ERROR.USER_SUBSCRIPTION_PLAN_HAS_NOT_ACTIVATED_ERROR
    ) {
      dispatch(UIActions.appReady());
      dispatch(toggleActivationModal({ open: true, msg: err.message }));
    } else {
      handleAxiosError(dispatch, err);
    }
    dispatch({
      type: SUBSCRIBE_PLAN_WITH_STRIPE.FAIL,
    });
  }
};

export const paypalPaymentApproved = orderID => async (dispatch, getState) => {
  dispatch(UIActions.appLoading());
  dispatch({ type: PAYPAL_PAYMENT_APPROVED.AWAIT });
  const { cartItems = [] } = getState().cart;
  const { voucherAvailability = [] } = getState().voucher;

  try {
    if (Array.isArray(cartItems) && cartItems.length > 0) {
      const { plan_id = null, pricePlanID = null } = cartItems[0];
      if (plan_id && pricePlanID) {
        const isVoucherApplied =
          voucherAvailability && voucherAvailability.valid;

        const res = await client.mutate({
          mutation: SubscriptionApi.PAYPAL_PAYMENT_APPROVED,
          variables: {
            input: {
              orderID,
              plan_id,
              pricePlanID,
              voucherCode: isVoucherApplied
                ? voucherAvailability.code
                : undefined,
            },
          },
        });

        if (res && res.data && res.data.paypalPaymentApproved) {
          dispatch({
            type: PAYPAL_PAYMENT_APPROVED.SUCCESS,
            payload: res.data.paypalPaymentApproved,
          });
          history.push(SUBSCRIPTIONS_URL);
          dispatch(
            UIActions.showSuccess("Subscription plan bought successfully")
          );
          dispatch(clearCart());
        }
      }
    }

    dispatch(UIActions.appReady());
  } catch (err) {
    handleAxiosError(dispatch, err);
    dispatch({
      type: PAYPAL_PAYMENT_APPROVED.FAIL,
    });
  }
};

export const assignSubscriptionTrialPlan = _id => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: ASSIGN_SUBSCRIPTION_TRIAL_PLAN.AWAIT });

  try {
    const res = await client.mutate({
      mutation: SubscriptionApi.ASSIGN_SUBSCRIPTION_TRIAL_PLAN,
      variables: {
        _id,
        input: {
          plan_id: PRIMARY_PLAN_ID,
        },
      },
    });

    if (res && res.data && res.data.assignSubscriptionTrialPlan) {
      dispatch({
        type: ASSIGN_SUBSCRIPTION_TRIAL_PLAN.SUCCESS,
        payload: res.data.assignSubscriptionTrialPlan,
      });
      history.push(SUBSCRIPTIONS_URL);
      dispatch(UIActions.showSuccess("Trial plan bought successfully"));
      dispatch(clearCart());
    }

    dispatch(UIActions.appReady());
  } catch (err) {
    handleAxiosError(dispatch, err);
    dispatch({
      type: ASSIGN_SUBSCRIPTION_TRIAL_PLAN.FAIL,
    });
  }
};

export const activateSubscription = (id, planHistoryID) => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: ACTIVATE_SUBSCRIPTION.AWAIT });

  try {
    const res = await client.mutate({
      mutation: SubscriptionApi.ACTIVATE_SUBSCRIPTION,
      variables: { _id: id, planHistoryID },
    });

    if (res && res.data && res.data.activateSubscription) {
      dispatch({
        type: ACTIVATE_SUBSCRIPTION.SUCCESS,
        payload: res.data.activateSubscription,
      });
      dispatch(
        UIActions.showSuccess("Subscription plan activated successfully")
      );
    }

    dispatch(UIActions.appReady());
  } catch (err) {
    handleAxiosError(dispatch, err);
    dispatch({
      type: ACTIVATE_SUBSCRIPTION.FAIL,
    });
  }
};

export const refundSubscriptionPlan = transaction_id => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: REFUND_SUBSCRIPTION_PLAN.AWAIT });

  try {
    const res = await client.mutate({
      mutation: SubscriptionApi.REFUND_SUBSCRIPTION_PLAN,
      variables: { transaction_id },
    });

    if (res && res.data && res.data.refundSubscriptionPlan) {
      dispatch({
        type: REFUND_SUBSCRIPTION_PLAN.SUCCESS,
      });
      dispatch(
        UIActions.showSuccess("Subscription plan refunded successfully")
      );
      history.push(MANAGE_USER_URL);
    }

    dispatch(UIActions.appReady());
  } catch (err) {
    handleAxiosError(dispatch, err);
    dispatch({
      type: REFUND_SUBSCRIPTION_PLAN.FAIL,
    });
  }
};

export const assignManualSubscriptionPlan = data => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: ASSIGN_MANUAL_SUBSCRIPTION_PLAN.AWAIT });

  try {
    const res = await client.mutate({
      mutation: SubscriptionApi.ASSIGN_SUBSCRIPTION_PLAN,
      variables: data,
    });

    if (res && res.data && res.data.assignSubscriptionPlan) {
      dispatch({
        type: ASSIGN_MANUAL_SUBSCRIPTION_PLAN.SUCCESS,
      });
      dispatch(
        UIActions.showSuccess("Subscription plan assigned successfully")
      );
      history.push(MANAGE_USER_URL);
    }

    dispatch(UIActions.appReady());
  } catch (err) {
    handleAxiosError(dispatch, err);
    dispatch({
      type: ASSIGN_MANUAL_SUBSCRIPTION_PLAN.FAIL,
    });
  }
};

export const toggleActivationModal = payload => ({
  type: TOGGLE_ACTIVATION_MODAL,
  payload,
});

export const updateSubscription = data => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: UPDATE_SUBSCRIPTION.AWAIT });

  try {
    const res = await client.mutate({
      mutation: SubscriptionApi.UPDATE_SUBSCRIPTION,
      variables: data,
    });

    if (res && res.data && res.data.updateSubscription) {
      dispatch({
        type: UPDATE_SUBSCRIPTION.SUCCESS,
      });
      dispatch(UIActions.showSuccess("Updated successfully"));
      history.push(MANAGE_USER_URL);
    }

    dispatch(UIActions.appReady());
  } catch (err) {
    handleAxiosError(dispatch, err);
    dispatch({
      type: UPDATE_SUBSCRIPTION.FAIL,
    });
  }
};
