import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";

import * as ExamActions from "../../../../actions/examAction";
import { parseDateHtml, parseQuestionHtml } from "../../../../utils";
import useModal from "../../../../hooks/useModal";
import ConfirmationModal from "../../../../Components/Modal/ConfirmationModal";
import { handleRemoveTableRow } from "../helper";
import TableTitle from "./TableTitle";
import { DELETED_STATUS_FILTER } from "../constants";

const QuestionTable = ({ getQuestionList, removeQuestion }) => {
  const [questions, setQuestions] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const { open, handleOpen, handleClose } = useModal();
  const handleOpenDeleteModal = () => {
    if (selectedIndexes.length > 0) {
      handleOpen();
    }
  };

  const fetchQuestionList = async () => {
    const byAdmin = true;
    const res = await getQuestionList(DELETED_STATUS_FILTER, byAdmin);

    if (res && res.questions) setQuestions(res.questions);
  };

  useEffect(() => {
    fetchQuestionList();
  }, []);

  const onRemoveQuestion = async () => {
    await handleRemoveTableRow(
      questions,
      setQuestions,
      selectedIndexes,
      setSelectedIndexes,
      removeQuestion,
      handleClose
    );
  };

  return (
    <Fragment>
      <ConfirmationModal
        open={open}
        onClose={handleClose}
        actionFn={onRemoveQuestion}
        ctaTitle={
          "Are you sure you wish to permanently delete these question(s)?"
        }
        ctaTxt={"Delete"}
      />
      <TableTitle
        title="Question"
        handleOpenDeleteModal={handleOpenDeleteModal}
      />
      <MUIDataTable
        data={questions.map(question => [
          question._ref,
          question._createdOn,
          question.question,
          question.category && question.category.name
            ? question.category.name
            : "",
          question.subCategory && question.subCategory.name
            ? question.subCategory.name
            : "",
        ])}
        columns={[
          "ID",
          {
            name: "Date Created",
            options: {
              customBodyRenderLite: dataIndex => {
                let val = questions[dataIndex]._createdOn;
                return parseDateHtml(val);
              },
              setCellHeaderProps: () => ({
                className: "globalCustomMuiTableHead",
              }),
            },
          },
          {
            name: "Question",
            options: {
              customBodyRender: value => (
                <div style={{ width: 270, wordWrap: "break-word" }}>
                  {parseQuestionHtml(value)}
                </div>
              ),
            },
          },
          "Subject",
          "System",
        ]}
        options={{
          download: false,
          print: false,
          sort: false,
          onRowSelectionChange: (
            currentRowsSelected,
            allRowsSelected,
            rowsSelected
          ) => {
            setSelectedIndexes(rowsSelected);
          },
          customToolbarSelect: () => {},
          rowsSelected: selectedIndexes,
        }}
      />
    </Fragment>
  );
};
const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getQuestionList: (filter, byAdmin) =>
    dispatch(ExamActions.getQuestionList(filter, byAdmin)),
  removeQuestion: (id, permanent) =>
    dispatch(ExamActions.removeQuestion(id, permanent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionTable);
