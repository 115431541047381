import * as UIActions from "./uiAction";
import * as PlanApi from "../services/plan";
import { AppConfig } from "../configs";

const { client } = AppConfig;

const MODULE_NAME = "PLAN";

export const PLAN_LIST_REQUEST = {
  AWAIT: `${MODULE_NAME}/LIST/AWAIT`,
  SUCCESS: `${MODULE_NAME}/LIST/SUCCESS`,
  FAIL: `${MODULE_NAME}/LIST/FAIL`,
};

export const getPlans = () => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({
    type: PLAN_LIST_REQUEST.AWAIT,
  });
  try {
    const res = await client.query({
      query: PlanApi.PLANS,
    });

    if (res && res.data && res.data.plans) {
      dispatch({
        type: PLAN_LIST_REQUEST.SUCCESS,
        payload: res.data.plans,
      });
    }
    dispatch(UIActions.appReady());
  } catch (err) {
    dispatch(UIActions.appReady());
    let errMsg;
    if (err.message) errMsg = err.message;
    else if (err.graphQLErrors)
      errMsg = err.graphQLErrors.map(item => item.message).join(". ");
    else
      errMsg =
        "We are having problem getting plan list. Please try again later.";

    dispatch(UIActions.addError(errMsg));
    dispatch({
      type: PLAN_LIST_REQUEST.FAIL,
      payload: { err: errMsg },
    });
  }
};
