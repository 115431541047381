import { Box } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { ALERT_VARIANT } from "./constants";
import useStyles from "./styles";

const Alert = ({ children, variant }) => {
  const classes = useStyles();
  return (
    <Box
      className={clsx(
        classes.alert,
        variant === ALERT_VARIANT.SUCCESS
          ? classes.successAlert
          : variant === ALERT_VARIANT.ERROR
          ? classes.errorAlert
          : ""
      )}
    >
      {children}
    </Box>
  );
};

export default Alert;
