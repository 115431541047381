import { gql } from "@apollo/client";

export const lessons = gql`
  query Lessons($filter: LessonFilterInput) {
    lessons(filter: $filter) {
      _createdOn
      _id
      _ref
      category {
        _id
        name
        type
      }
      subCategory {
        _id
        name
      }
      status
      title
      tags
      subTopics {
        subTitle
        content
      }
      trial
    }
  }
`;

export const lessonById = gql`
  query LessonById($_id: ObjectId!) {
    lesson(_id: $_id) {
      _createdOn
      _id
      category {
        _id
        name
      }
      subCategory {
        _id
        name
      }
      status
      title
      tags
      subTopics {
        subTitle
        content
      }
      trial
    }
  }
`;

export const createLesson = gql`
  mutation CreateLesson($input: CreateLessonInput) {
    createLesson(input: $input) {
      _createdOn
      _id
      category {
        _id
        name
      }
      subCategory {
        _id
        name
      }
      status
      title
      tags
      subTopics {
        subTitle
        content
      }
    }
  }
`;

export const updateLesson = gql`
  mutation UpdateLesson($input: UpdateLessonInput) {
    updateLesson(input: $input) {
      _createdOn
      _id
      category {
        _id
        name
      }
      subCategory {
        _id
        name
      }
      status
      title
      tags
      subTopics {
        subTitle
        content
      }
    }
  }
`;

export const removeLesson = gql`
  mutation RemoveLesson($_id: ObjectId!, $permanent: Boolean) {
    removeLesson(_id: $_id, permanent: $permanent)
  }
`;
