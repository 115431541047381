import React from "react";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";

import { INPUT_UNDERLINE_STYLES } from "../../styles/constants";

const useStyles = makeStyles(theme => ({
  searchInputContainer: {
    width: 400,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    position: "relative",
  },
  searchInput: {
    ...INPUT_UNDERLINE_STYLES,
  },
  searchIcon: {
    height: 26,
    width: 26,
    position: "absolute",
    right: 0,
    bottom: 2,
    color: "#757575",
  },
}));

const SearchInput = props => {
  const { onChange, value } = props;
  const classes = useStyles();

  return (
    <div className={classes.searchInputContainer}>
      <TextField
        label="Search"
        onChange={onChange}
        value={value}
        InputProps={{
          style: { fontSize: 15 },
          className: classes.searchInput,
        }}
        InputLabelProps={{
          style: { fontSize: 15 },
        }}
        FormHelperTextProps={{
          style: { fontSize: 13 },
        }}
        fullWidth
        color="secondary"
      />
      <SearchIcon className={classes.searchIcon} />
    </div>
  );
};

export default SearchInput;
