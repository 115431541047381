import { gql } from "@apollo/client";

export const GET_VOUCHERS = gql`
  query Vouchers($filter: StatusFilterInput) {
    vouchers(filter: $filter) {
      _createdOn
      _id
      _ref
      status
      code
      _expiredOn
      limit
      redeemedCount
    }
  }
`;

export const GET_VOUCHER = gql`
  query Voucher($_id: ObjectId!) {
    voucher(_id: $_id) {
      _createdOn
      _id
      _ref
      code
      _expiredOn
      unit
      mode
      discount
      audienceType
      audienceIds
      includedPlanType
      pricePlanIds
      limit
      redeemedCount
      _redeemHistory {
        creator {
          firstName
          lastName
          email
        }
        plan {
          title
        }
        _redeemedOn
      }
    }
  }
`;

export const CREATE_VOUCHER = gql`
  mutation CreateVoucher($input: CreateVoucherInput!) {
    createVoucher(input: $input) {
      _id
    }
  }
`;

export const UPDATE_VOUCHER = gql`
  mutation UpdateVoucher($_id: ObjectId!, $input: UpdateVoucherInput!) {
    updateVoucher(_id: $_id, input: $input) {
      _id
    }
  }
`;

export const REMOVE_VOUCHER = gql`
  mutation RemoveVoucher($_id: ObjectId!, $permanent: Boolean) {
    removeVoucher(_id: $_id, permanent: $permanent)
  }
`;

export const CHECK_VOUCHER = gql`
  query Checkvoucher($input: CheckVoucherInput!) {
    checkVoucher(input: $input) {
      valid
      code
      unit
      discount
    }
  }
`;
