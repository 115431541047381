const styles = theme => ({
  root: {
    height: "100%",
    padding: theme.spacing(3),
    boxShadow: "1px 3px 0 0 rgb(0 0 0 / 5%)",
  },
  ul: {
    paddingLeft: theme.spacing(2.5),
  },
  resetBtn: {
    textTransform: "none",
    marginTop: theme.spacing(1),
  },
});
export default styles;
