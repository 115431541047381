import { Box, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getTransactionById,
  setTransaction,
} from "../../actions/transactionAction";
import usePath from "../../hooks/usePath";
import { selectTransaction } from "../../selectors/transaction";
import ReceiptContainer from "./components/ReceiptContainer";
import { companyLogo } from "resources/assets";
import useStyles from "./styles";
import { safeFormatDefaultReceiptDateTime } from "../../utils";
import { parseReceiptTransactionSource } from "./helper";
import { PrimaryButton } from "Components/CustomButton";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import { selectUserInfo } from "../../selectors/user";
import ReceiptAddress from "./components/ReceiptAddress";
import ReceiptItemTable from "./components/ReceiptItemTable";
import ReceiptTotalTable from "./components/ReceiptTotalTable";

const ReceiptPage = () => {
  const classes = useStyles();
  const { queryParams } = usePath();
  const dispatch = useDispatch();
  const transaction = useSelector(selectTransaction);
  const user = useSelector(selectUserInfo);

  const handlePrintReceipt = () => {
    return window.print();
  };

  useEffect(() => {
    if (queryParams) {
      const { id = null } = queryParams;
      dispatch(getTransactionById(id));
    }

    return () => {
      dispatch(setTransaction(null));
    };
  }, [dispatch]);

  if (!transaction) return null;

  return (
    <>
      <ReceiptContainer>
        <Grid container justify="center">
          <Grid item xs={12} md={10} lg={8}>
            <Grid container>
              <Grid item xs={12}>
                <img
                  src={companyLogo}
                  alt="Logo of AMCKEY"
                  className={classes.logo}
                />
              </Grid>
            </Grid>
            <Box mt={5}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <Box display="flex" flexDirection="column">
                    <Box component={"h2"} mb={1}>
                      Payment Receipt
                    </Box>
                    <Box
                      component={"span"}
                    >{`Order Number : ${transaction._ref}`}</Box>
                    <Box
                      component={"span"}
                    >{`Order Date : ${safeFormatDefaultReceiptDateTime(
                      transaction._createdOn
                    )}`}</Box>
                    <Box component={"span"}>
                      {parseReceiptTransactionSource(transaction.source)}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box height="100%" display="flex" alignItems="center">
                    <PrimaryButton
                      onClick={handlePrintReceipt}
                      className={classes.printBtn}
                    >
                      <Box display="flex" alignItems="center">
                        <PrintOutlinedIcon />
                        <Box ml={1}>Print Receipt</Box>
                      </Box>
                    </PrimaryButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box my={3}>
              <ReceiptAddress user={user} />
            </Box>
            <Box my={1}>
              <Grid container>
                <Grid item xs={12}>
                  <ReceiptItemTable transaction={transaction} />
                </Grid>
              </Grid>
            </Box>
            <Grid container>
              <Grid item xs={12}>
                <ReceiptTotalTable transaction={transaction} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ReceiptContainer>
    </>
  );
};

export default ReceiptPage;
