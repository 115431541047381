import React, { Fragment } from "react";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import { parseResponsiveHTML } from "../../../utils";

const QuestionInfoModal = ({ visible, question, onClose }) => {
  return (
    <Fragment>
      <div
        className={`modalCont htmlContentContainer ${visible && "show"}`}
        onClick={onClose}
      >
        <div
          className={`modal questionInfoModal ${visible && "show"}`}
          onClick={e => e.stopPropagation()}
        >
          <div className="titleCont">
            <div className="fTitle">Question Details</div>
          </div>
          <div className="bodyCont">
            {question && (
              <Fragment>
                {parseResponsiveHTML(question.question)}
                <ul>
                  {question.answers.map((answer, index) => (
                    <li key={index}>
                      {answer.content}{" "}
                      {answer.validAnswer && (
                        <FontAwesomeIcon icon={faCheckCircle} />
                      )}
                    </li>
                  ))}
                </ul>
                <p>Explanation:</p>
                {parseResponsiveHTML(question.explanation)}
              </Fragment>
            )}
          </div>
          <div className="actionCont">
            <Button variant="outlined" color="default" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default QuestionInfoModal;
