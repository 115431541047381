import { Box } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import {
  selectCartItems,
  selectPaymentMethod,
  selectPayPalOrderConfig,
  selectStripeOrderConfig,
} from "../../../../selectors/cart";
import { PAYMENT_METHOD_LIST } from "../constants";
import { checkIsFreeTrialPlan } from "../helper";
import FreeTrialPaymentButton from "./FreeTrialPaymentButton";
import PayPalPaymentButton from "./PayPalPaymentButton";
import StripePaymentButton from "./StripePaymentButton";

const ValidPaymentChoices = () => {
  const cartItems = useSelector(selectCartItems);
  const paymentMethod = useSelector(selectPaymentMethod);
  const paypalOrderConfig = useSelector(selectPayPalOrderConfig);
  const stripeOrderConfig = useSelector(selectStripeOrderConfig);
  const isFreeTrialPlan = checkIsFreeTrialPlan(cartItems);

  return (
    <Box display="flex" flexDirection="column">
      <Box fontSize="1.8rem" mb={2}>
        Pay With
      </Box>
      <Box width={"100%"} maxWidth={250}>
        {isFreeTrialPlan ? (
          <FreeTrialPaymentButton />
        ) : paymentMethod === PAYMENT_METHOD_LIST[0].value &&
          paypalOrderConfig ? (
          <PayPalPaymentButton paypalOrderConfig={paypalOrderConfig} />
        ) : paymentMethod === PAYMENT_METHOD_LIST[1].value &&
          stripeOrderConfig ? (
          <StripePaymentButton />
        ) : null}
      </Box>
    </Box>
  );
};

export default ValidPaymentChoices;
