import { useEffect } from "react";

const useDisableRightClick = () => {
  const preventDefaultEvent = evt => {
    evt.preventDefault();
  };
  useEffect(() => {
    document.addEventListener("contextmenu", preventDefaultEvent);
    return () => {
      document.removeEventListener("contextmenu", preventDefaultEvent);
      return false;
    };
  }, []);
};
export default useDisableRightClick;
