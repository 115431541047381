import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  leftBox: {
    [theme.breakpoints.up("md")]: {
      borderRight: "1px solid rgba(0,0,0,.2)",
    },
  },
  formContainer: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
}));
