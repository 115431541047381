import { THIN_SCROLLBAR_STYLING } from "../../styles/constants";

const styles = theme => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  },
  button: {
    display: "flex",
    alignItems: "center",
    color: "#2196f3",
    backgroundColor: "#fff",
    padding: "15px",
    border: "none",
    fontSize: "1em",
  },
  launchIcon: {
    marginRight: theme.spacing(0.5),
    marginBottom: "3px",
    width: 14,
    height: 14,
  },
  stepContainer: {
    display: "inline-flex",
    alignItems: "center",
  },
  infoIcon: {
    backgroundColor: "#2196f3",
    color: "#fff",
    width: 16,
    height: 16,
    borderRadius: 8,
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  disabledCheckbox: {
    background: "#fbfbfb !important",
    cursor: "default !important",
    border: "1px solid rgba(0, 0, 0, 0.1) !important",
    "&:hover": {
      border: "1px solid rgba(0, 0, 0, 0.1) !important",
    },
  },
  errorMsg: {
    color: "#f44336",
    marginTop: 20,
    display: "flex",
    width: "100%",
  },
  customFLabelCont: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0 !important",
    },
  },
  mb15: {
    marginBottom: 15,
  },
  submitBtn: {
    marginTop: 30,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#1e8ce3",
      boxShadow: "none",
    },
  },
  disabledSubmitBtn: {
    backgroundColor: "#88cee7 !important",
    color: "#fff !important",
    opacity: "0.65 !important",
  },
  customLeftCont: {
    height: 350,
    overflow: "auto",
    padding: "0 16px",
    ...THIN_SCROLLBAR_STYLING,
  },
  customRightCont: {
    padding: "0 16px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 16px",
    },
  },
  customRightRowCont: {
    paddingBottom: "1rem",
  },
  testIDCont: {
    height: 34,
    display: "flex",
    alignItems: "center",
  },
  testIDInput: {
    width: 180,
    height: "100%",
    padding: "6px 12px",
    fontSize: 14,
    color: "#65656a",
    border: "1px solid #e5e6e7",
    outline: "none",
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    },
    "&:focus": {
      borderColor: "#1c84c6!important",
    },
  },
  testIDBtn: {
    height: "100%",
    borderRadius: 0,
    color: "#fff",
    backgroundColor: "#2196f3",
    border: "none",
    outline: "none",
    fontSize: 14,
    minWidth: 64,
    padding: "0 16px",
    "&:disabled": {
      backgroundColor: "#88cee7 !important",
      color: "#fff !important",
      opacity: "0.65 !important",
    },
  },
  customQuestionArea: {
    height: 200,
    width: 275,
    overflow: "auto",
    resize: "none",
    border: "1px solid #d3cfc8",
    color: "#65656a",
    fontSize: 13.5,
    padding: 8,
  },
  questionIDsErrMsg: {
    color: "#f44336",
    fontSize: 13,
    maxWidth: "100%",
  },
});

export default styles;
