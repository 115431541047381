import React, { useState, useEffect, Fragment } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import styles from "../FlashcardModal/styles";
import DoubleSideView from "./DoubleSideView";
import SingleSideView from "./SingleSideView";
import FlashcardMenu from "../FlashcardMenu";
import { FLASHCARD_MODE_TYPE } from "../../../constants";
import { convertVWToPX } from "../../../utils";

const useStyles = makeStyles(theme => styles(theme));

const FlashcardView = props => {
  const {
    flashcardlistModalWidth,
    isDarkMode,
    parsedFlashcardsData,
    setCurrentFlashcard,
    currentFlashcard,
    currentPagination,
    setCurrentPagination,
    showFrontView,
    setShowFrontView,
    openModalState,
    setOpenModalState,
    handleGoBack,
    onRemoveFlashcard,
  } = props;
  const classes = useStyles();

  const [anchorFlashcardMenu, setAnchorFlashcardMenu] = useState(null);
  const [showSingleSideView, setShowSingleSideView] = useState(true);
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const isMinimumSizeAndMobileView =
    xsDown ||
    (flashcardlistModalWidth
      ? convertVWToPX(flashcardlistModalWidth) < 600
      : false);

  // Handle Flashcardmenu Start
  const handleOpenMenu = event => {
    setAnchorFlashcardMenu(event.currentTarget);
  };

  const handleCloseFlashcardMenu = () => {
    setAnchorFlashcardMenu(null);
  };

  const startEditMode = () => {
    setOpenModalState({
      ...openModalState,
      show: true,
      mode: FLASHCARD_MODE_TYPE["EDIT"],
      fromMainPage: false,
    });

    handleCloseFlashcardMenu();
  };
  // Handle Flashcardmenu End

  // Handle Flashcard View Start
  const setPreviousFlashcard = () => {
    setCurrentPagination(currentPagination - 1);
    // Reset to frontview
    setShowFrontView(true);
  };
  const setNextFlashcard = () => {
    setCurrentPagination(currentPagination + 1);
    // Reset to frontview
    setShowFrontView(true);
  };

  const toggleShowFrontView = () => {
    setShowFrontView(!showFrontView);
  };
  const toggleShowSingleSideView = () => {
    setShowSingleSideView(!showSingleSideView);
  };
  // Handle Flashcard View End

  const totalFlashcard = parsedFlashcardsData.length;

  useEffect(() => {
    if (parsedFlashcardsData[currentPagination]) {
      setCurrentFlashcard(parsedFlashcardsData[currentPagination]);
    }
  }, [currentPagination]);

  return (
    <Fragment>
      <div className={classes.contentHeaderCont}>
        <div></div>
        <span
          className={clsx(
            isDarkMode ? classes.headerDark : classes.headerLight,
            classes.header
          )}
          onClick={toggleShowSingleSideView}
        >
          {showSingleSideView ? "Single Side View" : "Double Side View"}
          <KeyboardArrowDownIcon
            className={classes.arrowDownIcon}
            style={{ color: isDarkMode ? "#fff" : "#696969" }}
          />
        </span>
        <span>
          <MoreVertIcon className={classes.moreIcon} onClick={handleOpenMenu} />
        </span>
      </div>
      <div className={classes.cardCont}>
        {showSingleSideView ? (
          <SingleSideView
            isDarkMode={isDarkMode}
            flashcard={currentFlashcard}
            showFrontView={showFrontView}
            setShowFrontView={setShowFrontView}
          />
        ) : (
          <DoubleSideView
            isDarkMode={isDarkMode}
            flashcard={currentFlashcard}
            showFrontView={showFrontView}
            toggleShowFrontView={toggleShowFrontView}
          />
        )}
      </div>
      <div
        className={clsx(
          isDarkMode && classes.dialogActionsContDark,
          classes.dialogActionsCont
        )}
      >
        <div className={classes.pageindex}>
          {`${currentPagination + 1} of ${totalFlashcard}`}{" "}
        </div>
        <div className={classes.actionsButtonCont}>
          <Button
            disabled={currentPagination < 1}
            className={clsx(
              isDarkMode
                ? classes.actionsButtonDark
                : classes.actionsButtonLight,
              classes.actionsButton
            )}
            style={{ width: isMinimumSizeAndMobileView ? "fit-content" : 119 }}
            classes={{
              disabled: isDarkMode && classes.disabledActionsButtonDark,
            }}
            onClick={setPreviousFlashcard}
          >
            {isMinimumSizeAndMobileView ? <ArrowBackIcon /> : "Previous"}
          </Button>
          <Button
            className={clsx(
              isDarkMode
                ? classes.actionsButtonDark
                : classes.actionsButtonLight,
              classes.actionsButton,
              classes.mx
            )}
            style={{ width: isMinimumSizeAndMobileView ? "fit-content" : 119 }}
            onClick={toggleShowFrontView}
          >
            {showFrontView ? (
              isMinimumSizeAndMobileView ? (
                <VisibilityOffIcon />
              ) : (
                "Show Answer"
              )
            ) : isMinimumSizeAndMobileView ? (
              <VisibilityIcon />
            ) : (
              "Hide Answer"
            )}
          </Button>
          <Button
            disabled={currentPagination === totalFlashcard - 1}
            className={clsx(
              isDarkMode
                ? classes.actionsButtonDark
                : classes.actionsButtonLight,
              classes.actionsButton
            )}
            classes={{
              disabled: isDarkMode && classes.disabledActionsButtonDark,
            }}
            style={{ width: isMinimumSizeAndMobileView ? "fit-content" : 119 }}
            onClick={setNextFlashcard}
          >
            {isMinimumSizeAndMobileView ? <ArrowForwardIcon /> : "Next"}
          </Button>
        </div>
      </div>
      <FlashcardMenu
        currentFlashcard={currentFlashcard}
        anchorEl={anchorFlashcardMenu}
        handleCloseMenu={handleCloseFlashcardMenu}
        onClickEditBtnHandler={() => startEditMode()}
        handleGoBack={handleGoBack}
        onRemoveFlashcard={onRemoveFlashcard}
      />
    </Fragment>
  );
};

export default FlashcardView;
