import { makeStyles } from "@material-ui/styles";
import { ERROR_COLOR, SUCCESS_COLOR } from "../../styles/constants";

export default makeStyles(theme => ({
  alert: {
    textAlign: "center",
    padding: "0.5rem",
    fontSize: "0.85rem",
  },
  successAlert: {
    backgroundColor: "#d8ebd1",
    color: SUCCESS_COLOR,
  },
  errorAlert: {
    backgroundColor: "#F1E4E4",
    color: ERROR_COLOR,
  },
}));
