const sortQuestion = (questions, isAsc, orderBy) =>
  questions
    ? questions.sort((a, b) => {
        const validAnsA = a.answers.filter(answer => {
          if (answer.validAnswer) return answer;
        });
        const correctAnswerPercentageA = validAnsA[0]["percentage"]
          ? validAnsA[0]["percentage"]
          : 0;

        const validAnsB = b.answers.filter(answer => {
          if (answer.validAnswer) return answer;
        });
        const correctAnswerPercentageB = validAnsB[0]["percentage"]
          ? validAnsB[0]["percentage"]
          : 0;
        if (isAsc) {
          switch (orderBy) {
            case "id":
              return a._id > b._id ? 1 : -1;
            case "subjects":
              return a.category.name > b.category.name ? 1 : -1;
            case "systems":
              return a.subCategory.name > b.subCategory.name ? 1 : -1;
            case "correctAnswerPercentage":
              return correctAnswerPercentageA > correctAnswerPercentageB
                ? 1
                : -1;
          }
        } else {
          switch (orderBy) {
            case "id":
              return a._id < b._id ? 1 : -1;
            case "subjects":
              return a.category.name < b.category.name ? 1 : -1;
            case "systems":
              return a.subCategory.name < b.subCategory.name ? 1 : -1;
            case "correctAnswerPercentage":
              return correctAnswerPercentageA < correctAnswerPercentageB
                ? 1
                : -1;
          }
        }
      })
    : [];
export { sortQuestion };
