import { QUESTION_STATUS, REPORTS_BAR_TYPE } from "../../../constants";

export const headCells = [
  {
    id: "name",
    label: "NAME",
  },
  {
    id: "usage",
    label: "USAGE",
  },
  {
    id: "firstAttemptCorrect",
    label: QUESTION_STATUS["CORRECT"],
  },
  {
    id: "firstAttemptIncorrect",
    label: QUESTION_STATUS["INCORRECT"],
  },
  {
    id: "firstAttemptOmitted",
    label: QUESTION_STATUS["OMITTED"],
  },
  {
    id: "pRank",
    label: "P-RANK",
  },
];

export const showBarsArr = [
  {
    name: QUESTION_STATUS["CORRECT"],
    label: QUESTION_STATUS["CORRECT"],
  },
  {
    name: QUESTION_STATUS["INCORRECT"],
    label: QUESTION_STATUS["INCORRECT"],
  },
  {
    name: QUESTION_STATUS["OMITTED"],
    label: QUESTION_STATUS["OMITTED"],
  },
];

export const barTypeArr = [
  {
    name: REPORTS_BAR_TYPE["SCORE"],
    label: "Score",
  },
  {
    name: REPORTS_BAR_TYPE["QBANKUSAGE"],
    label: "QBank Usage",
  },
];
