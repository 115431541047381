import { Box, Grid } from "@material-ui/core";
import React from "react";
import {
  parseUsername,
  safeParseCountry,
  safeParseState,
} from "../../../utils";
import { parseAddressLine } from "../helper";
import useStyles from "./styles";

const ReceiptAddress = ({ user }) => {
  const classes = useStyles();

  if (!user) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8} className={classes.userAddressGrid}>
        <Box display="flex" flexDirection="column">
          <Box component={"h4"} mb={1} fontWeight="bold">
            User Address
          </Box>
          <Box component={"span"}>
            {parseUsername(user.firstName, user.lastName)}
          </Box>
          <Box component={"span"}>
            {parseAddressLine(user.address1)}
            {parseAddressLine(user.address2)}
            {`${user.city}, ${safeParseState(user.state, user.country)} - ${
              user.zipCode
            }`}
            <br />
            {safeParseCountry(user.country)}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.billingAddressGrid}>
        <Box display="flex" flexDirection="column">
          <Box component={"h4"} mb={1} fontWeight="bold">
            Billing Address
          </Box>
          <Box component={"span"}>
            {parseUsername(user.billing_firstName, user.billing_lastName)}
          </Box>
          <Box component={"span"}>
            {parseAddressLine(user.billing_address1)}
            {parseAddressLine(user.billing_address2)}
            {`${
              user.billing_city ? `${user.billing_city}, ` : ""
            }${safeParseState(user.billing_state, user.billing_country)}${
              user.billing_zipCode ? ` - ${user.billing_zipCode}` : ""
            }`}
            <br />
            {safeParseCountry(user.billing_country)}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReceiptAddress;
