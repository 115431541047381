import React from "react";
import ReactHtmlParser from "react-html-parser";

import {
  extractHTMLString,
  isTopicDetailPage,
  shortenString,
  trimStr,
} from ".";
import { FLASHCARD_LIST_URL } from "../routes/constants";

const renderFormCharLimit = (htmlField, maxChar) => {
  const htmlStrLength = trimStr(extractHTMLString(htmlField.trim())).length;
  const characterLeft = maxChar - htmlStrLength;
  return characterLeft < 0 ? (
    <span>Max characters exceeded</span>
  ) : htmlStrLength > 0 ? (
    <span>{`${characterLeft} characters remaining`}</span>
  ) : (
    <span>
      Max&nbsp;{maxChar}&nbsp;<i>characters allowed</i>
    </span>
  );
};

const parseFlashcardThumbnail = html => {
  // If contain image then return first image as thumbnail
  if (html.match(/<img/)) {
    let firstImageTagStr = html.match(/<img\s+[^>]*src=([^"]*)[^>]*>/i)
      ? html.match(/<img\s+[^>]*src=([^"]*)[^>]*>/i)[0]
      : html.match(/<img\s+[^>]*src="([^"]*)"[^>]*>/i)
      ? html.match(/<img\s+[^>]*src="([^"]*)"[^>]*>/i)[0]
      : null;

    return ReactHtmlParser(firstImageTagStr, {
      transform: node => {
        if (node.name === "img") {
          const { src } = node.attribs;
          return (
            <img src={src} key={src} alt="Amckey" className="img-responsive" />
          );
        }
      },
    });
  }
  // Else concat all text together
  else {
    return shortenString(extractHTMLString(html));
  }
};

const filterFlashcardsByKeyWord = (flashcards, keyword) => {
  if (!flashcards) return [];
  let tempFlashcards = [...flashcards];
  if (keyword) {
    tempFlashcards = tempFlashcards.filter(flashcard => {
      const lowerCaseKeyword = keyword.toLowerCase();
      const flashcardTags = flashcard.tags ? flashcard.tags : [];
      let lowerCaseTags = flashcardTags.map(tag => tag.toLowerCase());
      return (
        !keyword ||
        extractHTMLString(flashcard.front)
          .toLowerCase()
          .includes(lowerCaseKeyword) ||
        extractHTMLString(flashcard.back)
          .toLowerCase()
          .includes(lowerCaseKeyword) ||
        lowerCaseTags.toString().includes(lowerCaseKeyword)
      );
    });
  }
  return tempFlashcards;
};

// const filterFlashcardsByCategories = (
//   flashcards,
//   selectedSubjects,
//   selectedSystems
// ) => {
//   if (!flashcards) return [];
//   let tempFlashcards = [...flashcards];
//   if (Array.isArray(selectedSubjects) && Array.isArray(selectedSystems)) {
//     tempFlashcards = tempFlashcards.filter(flashcard => {
//       const { question, category, subCategory } = flashcard;
//       let category_id, subCategory_id;
//       category_id = subCategory_id = "";
//       if (Boolean(question)) {
//         category_id = question.category_id;
//         subCategory_id = question.subCategory_id;
//       } else if (Boolean(category && Boolean(subCategory))) {
//         category_id = category._id;
//         subCategory_id = subCategory._id;
//       }
//       return (
//         selectedSubjects.includes(category_id) &&
//         selectedSystems.includes(subCategory_id)
//       );
//     });
//   }

//   return tempFlashcards;
// };

export const isShowCategoriesInputPage = pathname => {
  return isTopicDetailPage(pathname) || pathname === FLASHCARD_LIST_URL;
};

export {
  filterFlashcardsByKeyWord,
  parseFlashcardThumbnail,
  renderFormCharLimit,
};
