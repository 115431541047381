import * as UIActions from "./uiAction";
import * as NoteApi from "../services/note";
import { AppConfig } from "../configs";

const { client } = AppConfig;

export const getNotes = () => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.query({
      query: NoteApi.notes,
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError(
          "We are having problem getting notes. Please try again later."
        )
      );
  }
};

export const createNote = data => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: NoteApi.createNote,
      variables: data,
    });
    dispatch(UIActions.showSuccess("Note created successfully."));
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error creating note"));
  }
};

export const updateNote = data => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: NoteApi.updateNote,
      variables: data,
    });
    dispatch(UIActions.showSuccess("Note updated successfully."));
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error updating note"));
  }
};

export const removeNote = id => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: NoteApi.removeNote,
      variables: { _id: id },
    });
    dispatch(UIActions.showSuccess("Note removed successfully."));
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error removing note"));
  }
};
