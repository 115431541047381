export const LAB_VALUES_CONSTANTS = {
  SERUM: "Serum",
  CEREBROSPINAL: "Cerebrospinal",
  BLOOD: "Blood",
  URINE_AND_BMI: "Urine and BMI",
};

export const LAB_VALUES_HEADER = {
  [LAB_VALUES_CONSTANTS["SERUM"]]: "Serum",
  [LAB_VALUES_CONSTANTS["CEREBROSPINAL"]]: "Cerebrospinal Fluid",
  [LAB_VALUES_CONSTANTS["BLOOD"]]: "Hematologic",
  [LAB_VALUES_CONSTANTS["URINE_AND_BMI"]]: "Urine",
};

export const labValuesObj = {
  // Serum
  [LAB_VALUES_CONSTANTS["SERUM"]]: [
    {
      cols: ["Serum", "Reference Range", "SI Reference Interval"],
      showLine: true,
    },
    {
      cols: ["Alanine aminotransferase (ALT)", "10-40 U/L", "10-40 U/L"],
      showLine: true,
    },
    {
      cols: ["Aspartate aminotransferase (AST)", "12-38 U/L", "12-38 U/L"],
      showLine: true,
    },
    {
      cols: ["Alkaline phosphatase", "25-100 U/L", "25-100 U/L"],
      showLine: true,
    },
    {
      cols: ["Amylase", "25-125 U/L", "25-125 U/L"],
      showLine: true,
    },
    {
      cols: ["Bilirubin"],
      showLine: false,
    },
    {
      cols: ["Total", "0.1-1.0 mg/dL", "2-17 μmol/L"],
      showLine: false,
    },
    {
      cols: ["Direct", "0.0-0.3 mg/dL", "0-5 μmol/L"],
      showLine: true,
    },
    {
      cols: ["Calcium", "8.4-10.2 mg/dL", "2.1-2.6 mmol/L"],
      showLine: true,
    },
    {
      cols: ["Cholesterol"],
      showLine: false,
    },
    {
      cols: [{ spacing: 2, text: "Total" }],
      showLine: false,
    },
    {
      cols: [{ spacing: 4, text: "Normal" }, "<200 mg/dL", "<5.2 mmol/L"],
      showLine: false,
    },
    {
      cols: [{ spacing: 4, text: "High" }, ">240mg/dL", ">6.2 mmol/L"],
      showLine: false,
    },
    {
      cols: [{ spacing: 2, text: "HDL" }, "40-60 mg/dL", "1.0-1.6 mmol/L"],
      showLine: false,
    },
    {
      cols: [{ spacing: 2, text: "LDL" }, "<160 mg/dL", "<4.2 mmol/L"],
      showLine: true,
    },
    {
      cols: ["Triglycerides"],
      showLine: false,
    },
    {
      cols: ["Normal", "<150 mg/dL", "<1.70 mmol/"],
      showLine: false,
    },
    {
      cols: ["Borderline", "151-199 mg/dL", "1.71-2.25 mmol/L"],
      showLine: true,
    },
    {
      cols: ["Cortisol"],
      showLine: false,
    },
    {
      cols: ["0800 h", "5-23 μg/dL", "138-635 nmol/L"],
      showLine: false,
    },
    {
      cols: ["1600 h", "3-15 μg/dL", "82-413 nmol/L"],
      showLine: false,
    },
    {
      cols: ["2000 h", "<50% of 0800 h", "Fraction of 0800 h: <0.50"],
      showLine: true,
    },
    {
      cols: ["Creatine kinase"],
      showLine: false,
    },
    {
      cols: ["Male", "25-90 U/L", "25-90 U/L"],
      showLine: false,
    },
    {
      cols: ["Female", "10-70 U/L", "10-70 U/L"],
      showLine: true,
    },
    {
      cols: ["Creatinine", "0.6-1.2 mg/dL", "53-106 μmol/L"],
      showLine: true,
    },
    {
      cols: ["Urea nitrogen", "7-18 mg/dL", "1.2-3.0 mmol/L"],
      showLine: true,
    },
    {
      cols: ["Electrolytes, serum"],
      showLine: false,
    },
    {
      cols: ["Sodium (Na⁺)", "136-146 mEq/L", "136-146 mmol/L"],
      showLine: false,
    },
    {
      cols: ["Potassium (K⁺)", "3.5-5.0 mEq/L", "3.5-5.0 mmol/L"],
      showLine: false,
    },
    {
      cols: ["Chloride (Cl⁻)", "95-105 mEq/L", "95-105 mmol/L"],
      showLine: false,
    },
    {
      cols: ["Bicarbonate (HCO₃⁻)", "22-28 mEq/L", "22-28 mmol/L"],
      showLine: false,
    },
    {
      cols: ["Magnesium (Mg²⁺)", "1.5-2.0 mEq/L", "0.75-1.0 mmol/L"],
      showLine: true,
    },
    {
      cols: ["Ferritin"],
      showLine: false,
    },
    {
      cols: ["Male:", "20-250 ng/mL", "20-250 μg/L"],
      showLine: false,
    },
    {
      cols: ["Female:", "10-120 ng/mL", "10-120 μg/L"],
      showLine: true,
    },
    {
      cols: ["Follicle-stimulating hormone"],
      showLine: false,
    },
    {
      cols: ["Male", "4-25 mIU/mL", "4-25 U/L"],
      showLine: false,
    },
    {
      cols: ["Female"],
      showLine: false,
    },
    {
      cols: [{ spacing: 2, text: "premenopause" }, "4-30 mIU/mL", "4-30 U/L"],
      showLine: false,
    },
    {
      cols: [
        { spacing: 2, text: "midcycle peak" },
        "10-90 mIU/mL",
        "10-90 U/L",
      ],
      showLine: false,
    },
    {
      cols: [
        { spacing: 2, text: "postmenopause" },
        "40-250 mIU/mL",
        "40-250 U/L",
      ],
      showLine: true,
    },
    {
      cols: ["Glucose"],
      showLine: false,
    },
    {
      cols: ["Fasting", "70-110 mg/dL", "3.8-5.6 mmol/L"],
      showLine: false,
    },
    {
      cols: ["Random, non-fasting", "<140 mg/dL", "<7.7 mmol/L"],
      showLine: true,
    },
    {
      cols: ["Growth hormone- arginine stimulation"],
      showLine: false,
    },
    {
      cols: ["Fasting", "<5 ng/mL", "<5 μg/L"],
      showLine: false,
    },
    {
      cols: ["Provocative stimuli", ">7 ng/mL", ">7 μg/L"],
      showLine: true,
    },
    {
      cols: ["Iron"],
      showLine: false,
    },
    {
      cols: ["Male", "65-175 μg/dL", "11.6-31.3 μmol/L"],
      showLine: false,
    },
    {
      cols: ["Female", "50-170 μg/dL", "9.0-30.4 μmol/L"],
      showLine: true,
    },
    {
      cols: [
        "Total iron-binding capacity",
        "250-400 μg/dL",
        "44.8-71.6 μmol/L",
      ],
      showLine: true,
    },
    {
      cols: ["Transferrin", "200-360 mg/dL", "2.0-3.6 g/L"],
      showLine: true,
    },
    {
      cols: ["Lactate dehydrogenase", "45-200 U/L", "45-200 U/L"],
      showLine: true,
    },
    {
      cols: ["Luteinizing hormone"],
      showLine: false,
    },
    {
      cols: ["Male", "6-23 mIU/mL", "6-23 U/L"],
      showLine: false,
    },
    {
      cols: ["Female"],
      showLine: false,
    },
    {
      cols: [
        { spacing: 2, text: "follicular phase" },
        "5-30 mIU/mL",
        "5-30 U/L",
      ],
      showLine: false,
    },
    {
      cols: [{ spacing: 2, text: "midcycle" }, "75-150 mIU/mL", "75-150 U/L"],
      showLine: false,
    },
    {
      cols: [
        { spacing: 2, text: "postmenopause" },
        "30-200 mIU/mL",
        "30-200 U/L",
      ],
      showLine: true,
    },
    {
      cols: ["Osmolality", "275-295 mOsmol/kg H₂O", "275-295 mOsmol/kg H₂O"],
      showLine: true,
    },
    {
      cols: ["Intact parathyroid hormone (PTH)", "10-60 pg/mL", "10-60 ng/mL"],
      showLine: true,
    },
    {
      cols: ["Phosphorus (inorganic)", "3.0-4.5 mg/dL", "1.0-1.5 mmol/L"],
      showLine: true,
    },
    {
      cols: ["Prolactin (hPRL)"],
      showLine: false,
    },
    {
      cols: ["Male", "<17 ng/mL", "<17 μg/L"],
      showLine: false,
    },
    {
      cols: ["Female", "<25 ng/mL", "<25 μg/L"],
      showLine: true,
    },
    {
      cols: ["Proteins"],
      showLine: false,
    },
    {
      cols: [{ spacing: 2, text: "Total" }, "6.0-7.8 g/dL", "60-78 g/L"],
      showLine: false,
    },
    {
      cols: [{ spacing: 2, text: "Albumin" }, "3.5-5.5 g/dL", "35-55 g/L"],
      showLine: false,
    },
    {
      cols: [{ spacing: 2, text: "Globulin" }, "2.3-3.5 g/dL", "23-35 g/L"],
      showLine: true,
    },
    {
      cols: ["Troponin I", "<0.04 ng/dL", "<0.04 μg/L"],
      showLine: true,
    },
    {
      cols: ["TSH", "0.4-4.0 μU/mL", "0.4-4.0 μU/mL"],
      showLine: true,
    },
    {
      cols: [
        "Thyroidal iodine (¹²³I) uptake",
        "8%-30% of administered dose/24 h",
        "0.08-0.30/24 h",
      ],
      showLine: true,
    },
    {
      cols: ["Thyroxine (T₄)", "5-12 μg/dL", "64-155 nmol/L"],
      showLine: true,
    },
    {
      cols: ["Free (T₄)", "0.9-1.7 ng/dL", "12.0-21.9 pmol/L"],
      showLine: true,
    },
    {
      cols: ["Triiodothyronine (T₃) (RIA)", "100-200 ng/dL", "1.5-3.1 nmol/L"],
      showLine: true,
    },
    {
      cols: ["Triiodothyronine (T₃) resin uptake	", "25%-35%", "0.25-0.35"],
      showLine: true,
    },
    {
      cols: ["Uric acid", "3.0-8.2 mg/dLL", "0.18-0.48 mmol/L"],
      showLine: true,
    },
    {
      cols: ["Immunoglobulins"],
      showLine: false,
    },
    {
      cols: ["IgA", "76-390 mg/dL", "0.76-3.90 g/L"],
      showLine: false,
    },
    {
      cols: ["IgE", "0-380 IU/mL", "0-380 kIU/L"],
      showLine: false,
    },
    {
      cols: ["IgG", "650-1500 mg/dL", "6.5-15.0 g/L"],
      showLine: false,
    },
    {
      cols: ["IgM", "50-300 mg/dL", "0.5-3.0 g/L"],
      showLine: true,
    },
    {
      cols: ["Gases, arterial blood (room air)"],
      showLine: false,
    },
    {
      cols: ["pH", "7.35-7.45", "[H⁺] 36-44 nmol/L"],
      showLine: false,
    },
    {
      cols: ["Pco₂", "33-45 mm Hg", "4.4-5.9 kPa"],
      showLine: false,
    },
    {
      cols: ["Po₂", "75-105 mm Hg", "10.0-14.0 kPa"],
      showLine: true,
    },
  ],
  // Cerebrospinal
  [LAB_VALUES_CONSTANTS["CEREBROSPINAL"]]: [
    {
      cols: ["Cerebrospinal Fluid", "Reference Range", "SI Reference Interval"],
      showLine: true,
    },
    {
      cols: ["Cell count", "0-5/mm³", "0-5 x 10⁶/L"],
      showLine: true,
    },
    { cols: ["Chloride", "118-132 mEq/L", "118-132 mmol/L"], showLine: true },
    {
      cols: ["Gamma globulin", "3%-12% total proteins", "0.03-0.12"],
      showLine: true,
    },
    { cols: ["Glucose", "40-70 mg/dL", "2.2-3.9 mmol/L"], showLine: true },
    {
      cols: ["Pressure", "70-180 mm H₂O	", "70-180 mm H₂O"],
      showLine: true,
    },
    { cols: ["Proteins, total", "<40 mg/dL", "<0.40 g/L"], showLine: true },
  ],
  // Blood
  [LAB_VALUES_CONSTANTS["BLOOD"]]: [
    {
      cols: ["Hematologic", "Reference Range", "SI Reference Interval"],
      showLine: true,
    },
    {
      cols: ["Erythrocyte count"],
      showLine: false,
    },
    {
      cols: ["Male", "4.3-5.9 million/mm³", "4.3-5.9 x 10¹²/L"],
      showLine: false,
    },
    {
      cols: ["Female", "3.5-5.5 million/mm³", "3.5-5.5 x 10¹²/L"],
      showLine: true,
    },
    {
      cols: ["Erythrocyte sedimentation rate (Westergren)"],
      showLine: false,
    },
    {
      cols: ["Male", "0-15 mm/h", "0-15 mm/h"],
      showLine: false,
    },
    {
      cols: ["Female", "0-20 mm/h", "0-20 mm/h"],
      showLine: true,
    },
    {
      cols: ["Hematocrit"],
      showLine: false,
    },
    {
      cols: ["Male", "41%-53%", "0.41-0.53"],
      showLine: false,
    },
    {
      cols: ["Female", "36%-46%", "0.36-0.46"],
      showLine: true,
    },
    {
      cols: ["Hemoglobin, blood"],
      showLine: false,
    },
    {
      cols: ["Male", "13.5-17.5 g/dL", "135-175 g/L"],
      showLine: false,
    },
    {
      cols: ["Female", "12.0-16.0 g/dL", "120-160 g/L"],
      showLine: true,
    },
    {
      cols: ["Hemoglobin A₁꜀", "≤6%", "≤42 mmol/mol"],
      showLine: true,
    },
    {
      cols: ["Hemoglobin, plasma", "<4 mg/dL", "<0.62 mmol/L"],
      showLine: true,
    },
    {
      cols: ["Leukocyte count (WBC)", "4500-11,000/mm³", "4.5-11.0 x 10⁹/L"],
      showLine: true,
    },
    {
      cols: ["Neutrophils, segmented", "54%-62%", "0.54-0.62"],
      showLine: true,
    },
    {
      cols: ["Neutrophils, bands", "3%-5%", "0.03-0.05"],
      showLine: true,
    },
    {
      cols: ["Eosinophils", "1%-3%", "0.01-0.03"],
      showLine: true,
    },
    {
      cols: ["Basophils", "0%-0.75%", "0.00-0.0075"],
      showLine: true,
    },
    {
      cols: ["Lymphocytes", "25%-33%", "0.25-0.33"],
      showLine: true,
    },
    {
      cols: ["Monocytes", "3%-7%", "0.03-0.07"],
      showLine: true,
    },
    {
      cols: ["CD4+ T-lymphocyte count", "≥500/mm³", "≥0.5 x 10⁹/L"],
      showLine: true,
    },
    {
      cols: ["Platelet count", "150,000-400,000/mm³", "150-400 x 10⁹/L"],
      showLine: true,
    },
    {
      cols: ["Reticulocyte count", "0.5%-1.5%", "0.005-0.015"],
      showLine: true,
    },
    {
      cols: ["D-Dimer", "≤250 ng/mL", "≤1.4 nmol/L"],
      showLine: true,
    },
    {
      cols: [
        "Partial thromboplastin time (PTT)(activated)",
        "25-40 seconds",
        "25-40 seconds",
      ],
      showLine: true,
    },
    {
      cols: ["Prothrombin time (PT)", "11-15 seconds", "11-15 seconds"],
      showLine: true,
    },
    {
      cols: [
        "Mean corpuscular hemoglobin (MCH)",
        "25-35 pg/cell",
        "0.39-0.54 fmol/cell",
      ],
      showLine: true,
    },
    {
      cols: [
        "Mean corpuscular hemoglobin concentration (MCHC)",
        "31%-36% Hb/cell",
        "4.8-5.6 mmol Hb/L",
      ],
      showLine: true,
    },
    {
      cols: ["Mean corpuscular volume (MCV)", "80-100 μm³", "80-100 fL"],
      showLine: true,
    },
    {
      cols: ["Volume"],
      showLine: false,
    },
    {
      cols: ["Plasma"],
      showLine: false,
    },
    {
      cols: [{ spacing: 2, text: "Male" }, "25-43 mL/kg	", "0.025-0.043 L/kg"],
      showLine: false,
    },
    {
      cols: [{ spacing: 2, text: "Female" }, "28-45 mL/kg", "0.028-0.045 L/kg"],
      showLine: true,
    },
    {
      cols: ["Red cell"],
      showLine: false,
    },
    {
      cols: [{ spacing: 2, text: "Male" }, "20-36 mL/kg", "0.020-0.036 L/kg"],
      showLine: false,
    },
    {
      cols: [{ spacing: 2, text: "Female" }, "19-31 mL/kg", "0.019-0.031 L/kg"],
      showLine: true,
    },
  ],
  // Urine and BMI
  [LAB_VALUES_CONSTANTS["URINE_AND_BMI"]]: [
    {
      cols: ["Urine", "Reference Range", "SI Reference Interval"],
      showLine: true,
    },
    {
      cols: ["Calcium", "100-300 mg/24 h", "2.5-7.5 mmol/24 h"],
      showLine: true,
    },
    { cols: ["Creatine clearance"], showLine: false },
    { cols: ["Male", "97-137 mL/min", "97-137 mL/min"], showLine: false },
    { cols: ["Female", "88-128 mL/min", "88-128 mL/min"], showLine: true },
    {
      cols: ["Osmolality", "50-1200 mOsmol/kg H₂O", "50-1200 mmol/kg"],
      showLine: true,
    },
    { cols: ["Oxalate", "8-40 μg/mL", "90-445 μmol/L"], showLine: true },
    {
      cols: ["Proteins, total", "<150 mg/24 h", "<0.15 g/24 h"],
      showLine: true,
    },
    { cols: ["17-Hydroxycorticosteroids"], showLine: false },
    {
      cols: ["Male", "3.0-10.0 mg/24 h", "8.2-27.6 μmol/24 h"],
      showLine: false,
    },
    {
      cols: ["Female", "2.0-8.0 mg/24 h", "5.5-22.0 μmol/24 h"],
      showLine: true,
    },
    { cols: ["17-Ketosteroids, total"], showLine: false },
    { cols: ["Male", "8-20 mg/24 h", "28-70 μmol/24 h"], showLine: false },
    { cols: ["Female", "6-15 mg/24 h", "21-52 μmol/24 h"], showLine: true },
    {
      cols: ["Body Mass Index (BMI)", "Adult: 19-25 kg/m²", ""],
      showLine: true,
    },
  ],
};

// Tour steps
const TOUR_STEPS = [
  {
    target: ".tour-step1",
    content: "Mark questions to review later",
    placement: "right-start",
    disableBeacon: true,
  },
  {
    target: ".tour-step2",
    content: "Navigate to previous question using the previous button",
    placement: "right-start",
    disableBeacon: true,
  },
  {
    target: ".tour-step3",
    content: "Navigate to next question using the next button",
    placement: "right-start",
    disableBeacon: true,
  },
  {
    target: ".tour-step4",
    content: "Toggle between full screen view and regular view",
    placement: "right-end",
    disableBeacon: true,
  },
  {
    target: ".tour-step5",
    content: "Reference sheet for lab values",
    placement: "right-end",
    disableBeacon: true,
  },
  {
    target: ".tour-step6",
    content: "Add important things to notes",
    placement: "right-end",
    disableBeacon: true,
  },
  {
    target: ".tour-step7",
    content: "Provides access to calculator",
    placement: "right-end",
    disableBeacon: true,
  },
  {
    target: ".tour-step8",
    content: "Adjust your color preference using the reverse color option",
    placement: "right-end",
    disableBeacon: true,
  },

  {
    target: ".tour-step9",
    content: "Adjust your preferences using the settings option",
    placement: "right-end",
    disableBeacon: true,
  },

  {
    target: ".tour-step10",
    content: "Add important concepts/images to flashcards",
    placement: "right-end",
    disableBeacon: true,
  },
  {
    target: ".tour-step11",
    content: "Provide feedback on an individual question",
    placement: "right-end",
    disableBeacon: true,
  },
  {
    target: ".tour-step12",
    content: "Suspend test to resume later",
    placement: "right-end",
    disableBeacon: true,
  },
  {
    target: ".tour-step13",
    content: "End the test",
    placement: "right-end",
    disableBeacon: true,
  },
];

export const INITIAL_TOUR_STATE = {
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0, // Make the component controlled
  steps: TOUR_STEPS,
  key: new Date(), // This field makes the tour to re-render when the tour is restarted
};
