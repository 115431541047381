import clsx from "clsx";
import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import ImagePreviewModal from "../../../TestDetailPage/modal/themedModal/ImagePreviewModal";
import useStyles from "../styles";

const FileImage = ({ url, filename, setShowImagePreview }) => {
  const classes = useStyles();

  function getMeta(url) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => reject();
      img.src = url;
    });
  }
  const handleClickImage = async () => {
    const img = await getMeta(url);
    let width = img.width;
    let height = img.height;

    setShowImagePreview({
      show: true,
      imgAttr: { url, width, height },
    });
  };
  return (
    <div>
      <img
        src={url}
        className={clsx(classes.fileImg, "clickable")}
        onClick={handleClickImage}
      />
      <div className={classes.fileName}>{filename}</div>
    </div>
  );
};

export default FileImage;
