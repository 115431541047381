export * from "./serverError";
export * from "./validation";

export const PRIMARY_PLAN_ID = "61421c6cfc9655260eec9acf";

export const QUESTION_STATUS = {
  CORRECT: "Correct",
  INCORRECT: "Incorrect",
  MARKED: "Marked",
  OMITTED: "Omitted",
};

export const REPORTS_DATA_TYPE = {
  SUBJECTS: "SUBJECTS",
  SYSTEMS: "SYSTEMS",
};

export const REPORTS_BAR_TYPE = {
  SCORE: "SCORE",
  QBANKUSAGE: "QBANKUSAGE",
};

export const NOTE_ORDER_BY_TYPE = {
  QUESTION_ID: "QUESTION_ID",
  SUBJECT: "SUBJECT",
  SYSTEM: "SYSTEM",
};

export const REGEX = {
  "HH:MM:SS": /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/,
};

export const FLASHCARD_MODE_TYPE = {
  VIEW: "VIEW",
  CREATE: "CREATE",
  EDIT: "EDIT",
  MAIN: "MAIN",
  CREATE_WITH_ELEMENT: "CREATE_WITH_ELEMENT",
  INSERT_WITH_ELEMENT: "INSERT_WITH_ELEMENT",
};

export const FLASHCARD_LIST_TYPE = {
  ALL: "ALL",
  OTHER: "OTHER",
  OWN: "OWN",
  SEARCH: "SEARCH",
};

export const FLASHCARD_SIDE_TYPE = {
  FRONT: "front",
  BACK: "back",
};

export const HTML_TAG_TYPE = {
  IMG: "IMG",
  TEXT: "TEXT",
};

export const CATEGORY_TYPE = {
  SUBJECT: "subject",
  SYSTEM: "system",
};

export const CURRENCY_FORMAT = "$";
export const CURRENCY_CODE = "USD";

export const SUBSCRIPTION_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  REFUNDED: "REFUNDED",
  EXPIRED: "EXPIRED",
  TRIAL: "TRIAL",
  PENDING: "PENDING",
};

export const TRANSACTION_SOURCE = {
  PAYPAL: "Paypal",
  STRIPE: "Stripe",
  MANUAL: "Manual",
  FREE: "Free",
};

export const TRANSACTION_STATUS = {
  REFUNDED: "REFUNDED",
  FAILED: "FAILED",
  COMPLETED: "COMPLETED",
  PENDING: "PENDING",
};

export const FREE_TRIAL_PLAN_NAME = "QBank Free Trial";

export const FREE_TRIAL_PRICE_PLAN = {
  title: FREE_TRIAL_PLAN_NAME,
  price: 0,
};

export const USER_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  DELETED: "deleted",
  SUSPENDED: "suspended",
};

export const USER_TYPE = {
  ADMIN: "admin",
  INSTRUCTOR: "instructor",
  STUDENT: "student",
};

export const USER_TYPE_LIST = [
  {
    label: "Admin",
    value: USER_TYPE.ADMIN,
  },
  {
    label: "Instructor",
    value: USER_TYPE.INSTRUCTOR,
  },
  {
    label: "Student",
    value: USER_TYPE.STUDENT,
  },
];

export const REQUEST_STATUS = {
  UNINITIALIZED: "UNINITIALIZED",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
};

export const EMAIL_VERIFICATION_STATUS = {
  VERIFIED: "Verified",
  UNVERIFIED: "Unverified",
};

export const RESOURCE_STATUS = {
  ACTIVE: "active",
  DELETED: "deleted",
};

export const DISCOUNT_MODE = {
  FIRST_PAYMENT: "FIRST_PAYMENT",
  RECURRING_PAYMENT: "RECURRING_PAYMENT",
};

export const DISCOUNT_UNIT = {
  AMOUNT: "AMOUNT",
  PERCENTAGE: "PERCENTAGE",
};

export const DISCOUNT_AUDIENCE_TYPE = {
  ALL: "ALL",
  CUSTOM: "CUSTOM",
};

export const DISCOUNT_INCLUDED_PLAN_TYPE = {
  ALL: "ALL",
  CUSTOM: "CUSTOM",
};

export const MUIDATATABLE_DEFAULT_OPTIONS = {
  download: false,
  print: false,
  selectableRows: "none",
};

export const XS_MUIDATATABLE_OPTIONS = {
  ...MUIDATATABLE_DEFAULT_OPTIONS,
  search: false,
  viewColumns: false,
  filter: false,
  rowsPerPage: 5,
  rowsPerPageOptions: [],
};

export const USER_EXAM_STATUS = {
  END: "end",
};

export const TOPIC_SUBTOPIC_TITLE = {
  REFERENCES: "References",
};
