import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import ChipInput from "material-ui-chip-input";

import * as ExamActions from "../../../actions/examAction";
import * as TopicAction from "../../../actions/topicAction";
import SubTopicArray from "./SubTopicArray";
import { COURSE_APP_SUB_ROUTE } from "../../../routes/constants";
import { FormControlLabel, Switch } from "@material-ui/core";

const TopicForm = ({
  match,
  history,
  getCategoryList,
  getTopicById,
  createTopic,
  updateTopic,
}) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    errors,
    getValues,
    setValue,
    defaultValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      subject: "",
      system: "",
      title: "",
      tags: [],
      subTopics: [],
      trial: false,
    },
  });
  const [subjects, setSubjects] = useState([]);
  const [systems, setSystems] = useState([]);
  const [topicTitle, setTopicTitle] = useState();

  const topicId = match.params.id;

  const loadInitial = async () => {
    const { categories: categoriesData } = await getCategoryList();
    if (categoriesData) {
      setSubjects(
        categoriesData.filter(category => category.type === "subject")
      );
      setSystems(categoriesData.filter(category => category.type === "system"));
    }

    if (!topicId) return;

    const res = await getTopicById(topicId);

    if (!res && !res.lesson) {
      return history.push(`${COURSE_APP_SUB_ROUTE}/manage-topic`);
    } else {
      const topicData = res.lesson;
      setTopicTitle(topicData.title);
      reset({
        subject:
          topicData.category && topicData.category._id
            ? topicData.category._id
            : "",
        system:
          topicData.subCategory && topicData.subCategory
            ? topicData.subCategory._id
            : "",
        title: topicData.title,
        tags: topicData.tags,
        subTopics: !topicData.subTopics ? [] : topicData.subTopics,
        trial: topicData.trial || false,
      });
    }
  };

  useEffect(() => {
    loadInitial();

    return () => {
      setTopicTitle(undefined);
    };
  }, []);

  const onSubmit = async ({
    subject,
    system,
    title,
    tags,
    subTopics,
    trial,
  }) => {
    const data = {
      category_id: subject,
      subCategory_id: system,
      title: title.trim(),
      tags,
      subTopics,
      trial,
    };

    if (topicId) {
      const isTitleChanged = topicTitle !== watch("title");

      await updateTopic({
        input: {
          ...data,
          title: isTitleChanged ? title.trim() : undefined,
          _id: topicId,
        },
      });
    } else {
      const res = await createTopic({
        input: data,
      });
      if (res && res.createLesson)
        history.push(`${COURSE_APP_SUB_ROUTE}/manage-topic`);
    }
  };

  return (
    <Fragment>
      <div className="pageCont createTopicPage">
        <div className="bodyCont">
          <div className="pageAction">
            <div className="pageActionLeft">
              <Link to={`${COURSE_APP_SUB_ROUTE}/manage-topic`}>
                <FontAwesomeIcon icon={faReply} />
                Back to topic list
              </Link>
            </div>
          </div>
          <div className="pageContent">
            <h4 className="pageTitle hiddenWeb hiddenTablet">
              {topicId ? "Update" : "Create"} Topic
            </h4>
            <h3 className="pageTitle hiddenMobile">
              {topicId ? "Update" : "Create"} Topic
            </h3>
            <form className="createTopicForm" id="createTopicForm">
              <Controller
                control={control}
                name="subject"
                rules={{ required: "Subject cannot be empty" }}
                render={({ onChange, onBlur, value, name, ref }) => (
                  <TextField
                    name={name}
                    label="Subject"
                    onChange={e => {
                      onChange(e.target.value);
                      setValue("system", "");
                    }}
                    error={!!errors.subject}
                    inputRef={ref}
                    helperText={errors.subject && errors.subject.message}
                    value={value}
                    InputProps={{
                      style: { fontSize: 15 },
                    }}
                    InputLabelProps={{
                      style: { fontSize: 15, color: "rgba(0, 0, 0, 0.25)" },
                    }}
                    FormHelperTextProps={{
                      style: { fontSize: 13 },
                    }}
                    select
                    fullWidth
                    color="secondary"
                  >
                    {subjects.map(subject => (
                      <MenuItem value={subject._id} key={subject._id}>
                        {subject.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="system"
                rules={{ required: "System cannot be empty" }}
                render={({ onChange, onBlur, value, name, ref }) => (
                  <TextField
                    name={name}
                    label="System"
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={!!errors.system}
                    inputRef={ref}
                    helperText={errors.system && errors.system.message}
                    InputProps={{
                      style: { fontSize: 15 },
                    }}
                    InputLabelProps={{
                      style: { fontSize: 15, color: "rgba(0, 0, 0, 0.25)" },
                    }}
                    FormHelperTextProps={{
                      style: { fontSize: 13 },
                    }}
                    select
                    fullWidth
                    color="secondary"
                  >
                    {systems
                      .filter(system =>
                        Array.isArray(system.parents) &&
                        system.parents.length > 0
                          ? system.parent_ids.includes(watch("subject"))
                          : false
                      )
                      .map(system => (
                        <MenuItem value={system._id} key={system._id}>
                          {system.name}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name={"title"}
                rules={{ required: "Title cannot be empty" }}
                render={({ onChange, value, name, ref }) => {
                  return (
                    <TextField
                      name={name}
                      label="Title"
                      onChange={e => onChange(e.target.value)}
                      inputRef={ref}
                      value={value}
                      error={!!errors.title}
                      helperText={errors.title && errors.title.message}
                      InputProps={{
                        style: { fontSize: 15 },
                      }}
                      InputLabelProps={{
                        style: { fontSize: 15, color: "rgba(0, 0, 0, 0.25)" },
                      }}
                      FormHelperTextProps={{
                        style: { fontSize: 13 },
                      }}
                      fullWidth
                      color="secondary"
                    />
                  );
                }}
              />

              <Controller
                control={control}
                name="tags"
                rules={{
                  validate: value =>
                    value.length >= 1 ? true : "Tags cannot be empty",
                }}
                render={({ onChange, value, name }) => (
                  <ChipInput
                    onAdd={chip => onChange([...value, chip])}
                    onDelete={chip => onChange(value.filter(c => c !== chip))}
                    value={value}
                    name={name}
                    label="Tags"
                    error={!!errors.tags}
                    helperText={errors.tags && errors.tags.message}
                    InputProps={{
                      style: { fontSize: 15 },
                    }}
                    InputLabelProps={{
                      style: { fontSize: 15, color: "rgba(0, 0, 0, 0.25)" },
                    }}
                    FormHelperTextProps={{
                      style: { fontSize: 13 },
                    }}
                    fullWidth
                    color="primary"
                  />
                )}
              />
              <Controller
                control={control}
                name="trial"
                render={({ onChange, value, name }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={value}
                        onChange={e => onChange(e.target.checked)}
                        name={name}
                      />
                    }
                    label="Free Trial"
                  />
                )}
              />

              {/* SubTopics Input Section Start here */}
              <p
                style={{
                  color: "rgba(0, 0, 0, 0.25)",
                  fontSize: "15px",
                }}
              >
                Subtopics Section
              </p>
              <SubTopicArray
                topicId={topicId}
                loadInitial={loadInitial}
                {...{
                  control,
                  register,
                  defaultValues,
                  getValues,
                  setValue,
                  errors,
                  watch,
                }}
              />
              {/* SubTopics Input Section End here */}
            </form>
          </div>
          <Button
            onClick={handleSubmit(onSubmit)}
            color="secondary"
            variant="contained"
            className="submitButton"
            size="large"
          >
            {topicId ? "Update" : "Create"}
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getCategoryList: () => dispatch(ExamActions.getCategoryList()),
  getTopicById: id => dispatch(TopicAction.getTopicById(id)),
  createTopic: data => dispatch(TopicAction.createTopic(data)),
  updateTopic: data => dispatch(TopicAction.updateTopic(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopicForm)
);
