import * as UIActions from "./uiAction";
import * as FeedbackApi from "../services/feedback";
import { AppConfig } from "../configs";

const { client } = AppConfig;

export const getFeedbacks = filter => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.query({
      query: FeedbackApi.GET_FEEDBACKS,
      variables: { filter },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else
      dispatch(
        UIActions.addError(
          "We are having problem getting feedbacks. Please try again later."
        )
      );
  }
};

export const createFeedback = data => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: FeedbackApi.CREATE_FEEDBACK,
      variables: { input: data },
    });
    dispatch(UIActions.showSuccess("Feedback submitted successfully."));
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error submitting feedback"));
  }
};

export const resolveFeedback = id => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: FeedbackApi.RESOLVE_FEEDBACK,
      variables: { _id: id },
    });
    dispatch(UIActions.showSuccess("Feedback resolved successfully."));
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error resolving feedback"));
  }
};

export const unresolveFeedback = id => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: FeedbackApi.UNRESOLVE_FEEDBACK,
      variables: { _id: id },
    });
    dispatch(UIActions.showSuccess("Feedback unresolved successfully."));
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error unresolving feedback"));
  }
};

export const removeFeedback = (id, permanent) => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.mutate({
      mutation: FeedbackApi.REMOVE_FEEDBACK,
      variables: { _id: id, permanent },
    });
    dispatch(UIActions.showSuccess("Feedback removed successfully."));
    dispatch(UIActions.appReady());
    return res.data.removeFeedback;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else if (err.message) dispatch(UIActions.addError(err.message));
    else dispatch(UIActions.addError("Error removing Feedback"));
  }
};
