import { INPUT_UNDERLINE_STYLES, LIGHTGREY_TEXT } from "../../styles/constants";
const styles = theme => ({
  searchFieldContainer: {
    padding: "35px 20px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginBottom: "1.5rem",
  },
  searchInputContainer: {
    width: 400,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  searchInput: {
    ...INPUT_UNDERLINE_STYLES,
  },
  searchIcon: {
    height: 26,
    width: 26,
    margin: "20px 15px 0px 15px",
    cursor: "pointer",
  },
  infoIcon: {
    backgroundColor: "#2196f3",
    color: "#fff",
    width: 18,
    height: 18,
    borderRadius: 9,
    marginTop: 20,
    cursor: "pointer",
  },
  customTooltipWidth: {
    maxWidth: 500,
  },
  searchResultContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  searchResultHeader: {
    fontSize: 16,
    color: "#65656a",
    fontWeight: 600,
    paddingLeft: 20,
    marginBottom: 24,
  },
  lightGreyText: {
    ...LIGHTGREY_TEXT,
  },
  viewText: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
});

export default styles;
