import * as UIActions from "./uiAction";
import * as UserApi from "../services/user";
import { AppConfig } from "../configs";
import {
  ACCESS_TOKEN_EXPIRED_TIME,
  AUTH_TOKEN,
  IS_AUTEHNTICATED,
  USER_TYPE,
} from "../localStorage/constants";
import { handleAxiosError } from "./errorAction";
import history from "../routes/history";
import {
  CHECKOUT_URL,
  LOGIN_URL,
  QUERY_PARAM_TRUE_VALUE,
  REDIRECT_CHECKOUT_QUERY_PARAM,
  SUBSCRIPTIONS_URL,
} from "../routes/constants";
import { ERROR_CODE, REQUEST_STATUS, SERVER_ERROR } from "../constants";
import { checkShouldBlockUser } from "../utils/access-helper";

const { client } = AppConfig;

const MODULE_NAME = "USER";

export const GET_USER_LIST = {
  AWAIT: `${MODULE_NAME}/LIST/AWAIT`,
  SUCCESS: `${MODULE_NAME}/LIST/SUCCESS`,
  FAIL: `${MODULE_NAME}/LIST/FAIL`,
};
export const GET_USER_BY_ID = {
  AWAIT: `${MODULE_NAME}/BY_ID/AWAIT`,
  SUCCESS: `${MODULE_NAME}/BY_ID/SUCCESS`,
  FAIL: `${MODULE_NAME}/BY_ID/FAIL`,
};
export const GET_USER_PROFILE = {
  AWAIT: `${MODULE_NAME}/PROFILE/AWAIT`,
  SUCCESS: `${MODULE_NAME}/PROFILE/SUCCESS`,
  FAIL: `${MODULE_NAME}/PROFILE/FAIL`,
};
export const UPDATE_USER_PROFILE = {
  AWAIT: `${MODULE_NAME}/UPDATE_PROFILE/AWAIT`,
  SUCCESS: `${MODULE_NAME}/UPDATE_PROFILE/SUCCESS`,
  FAIL: `${MODULE_NAME}/UPDATE_PROFILE/FAIL`,
};
export const ACTIVATE_USER = {
  AWAIT: `${MODULE_NAME}/ACTIVATE/AWAIT`,
  SUCCESS: `${MODULE_NAME}/ACTIVATE/SUCCESS`,
  FAIL: `${MODULE_NAME}/ACTIVATE/FAIL`,
};
export const CHANGE_PASSWORD = {
  AWAIT: `${MODULE_NAME}/CHANGE_PASSWORD/AWAIT`,
  SUCCESS: `${MODULE_NAME}/CHANGE_PASSWORD/SUCCESS`,
  FAIL: `${MODULE_NAME}/CHANGE_PASSWORD/FAIL`,
};
export const TRIGGER_RESET_PASSWORD = {
  AWAIT: `${MODULE_NAME}/TRIGGER_RESET_PASSWORD/AWAIT`,
  SUCCESS: `${MODULE_NAME}/TRIGGER_RESET_PASSWORD/SUCCESS`,
  FAIL: `${MODULE_NAME}/TRIGGER_RESET_PASSWORD/FAIL`,
};
export const FORGOT_PASSWORD = {
  AWAIT: `${MODULE_NAME}/FORGOT_PASSWORD/AWAIT`,
  SUCCESS: `${MODULE_NAME}/FORGOT_PASSWORD/SUCCESS`,
  FAIL: `${MODULE_NAME}/FORGOT_PASSWORD/FAIL`,
};
export const SET_FORGOT_PASSWORD_STATUS = `${MODULE_NAME}/SET_FORGOT_PASSWORD_STATUS`;
export const RESET_PASSWORD = {
  AWAIT: `${MODULE_NAME}/RESET_PASSWORD/AWAIT`,
  SUCCESS: `${MODULE_NAME}/RESET_PASSWORD/SUCCESS`,
  FAIL: `${MODULE_NAME}/RESET_PASSWORD/FAIL`,
};
export const CHECK_EMAIL = {
  AWAIT: `${MODULE_NAME}/CHECK_EMAIL/AWAIT`,
  SUCCESS: `${MODULE_NAME}/CHECK_EMAIL/SUCCESS`,
  FAIL: `${MODULE_NAME}/CHECK_EMAIL/FAIL`,
};
export const REMOVE_USER = {
  AWAIT: `${MODULE_NAME}/REMOVE_USER/AWAIT`,
  SUCCESS: `${MODULE_NAME}/REMOVE_USER/SUCCESS`,
  FAIL: `${MODULE_NAME}/REMOVE_USER/FAIL`,
};
export const USER_REGISTER = {
  AWAIT: `${MODULE_NAME}/REGISTER/AWAIT`,
  SUCCESS: `${MODULE_NAME}/REGISTER/SUCCESS`,
  FAIL: `${MODULE_NAME}/REGISTER/FAIL`,
};
export const SET_EMAIL_AVAILABILITY = `${MODULE_NAME}/SET_EMAIL_AVAILABILITY`;
export const USER_LOGIN = {
  AWAIT: `${MODULE_NAME}/LOGIN/AWAIT`,
  SUCCESS: `${MODULE_NAME}/LOGIN/SUCCESS`,
  FAIL: `${MODULE_NAME}/LOGIN/FAIL`,
};

export const USER_LOGOUT = `${MODULE_NAME}/LOGOUT`;

export const userLogout = () => ({
  type: USER_LOGOUT,
});
export const logout = (showMessage = true) => dispatch => {
  localStorage.removeItem(IS_AUTEHNTICATED);
  localStorage.removeItem(ACCESS_TOKEN_EXPIRED_TIME);
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(USER_TYPE);
  if (showMessage) {
    dispatch(UIActions.showSuccess("Logout Successfully"));
  }

  dispatch(userLogout());
};

export const login = (loginData, queryParams = null) => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: USER_LOGIN.AWAIT });
  try {
    const userStationRes = await client.query({
      query: UserApi.GET_USER_STATION_DETAIL,
    });

    if (userStationRes.data.getUserStationDetail) {
      const {
        ipAddress,
        country,
        city,
      } = userStationRes.data.getUserStationDetail;
      const res = await client.mutate({
        mutation: UserApi.login,
        variables: {
          input: {
            ...loginData,
            ipAddress,
            country,
            city,
          },
        },
      });

      localStorage.setItem(IS_AUTEHNTICATED, true);
      localStorage.setItem(
        ACCESS_TOKEN_EXPIRED_TIME,
        res.data.login.accessTokenExpiredOn
      );
      localStorage.setItem(AUTH_TOKEN, res.data.login.accessToken);
      localStorage.setItem(USER_TYPE, res.data.login.type);

      dispatch(UIActions.appReady());
      dispatch({ type: USER_LOGIN.SUCCESS, payload: res.data.login });
      dispatch(getUserProfile());
      if (
        queryParams &&
        queryParams[REDIRECT_CHECKOUT_QUERY_PARAM] &&
        queryParams[REDIRECT_CHECKOUT_QUERY_PARAM] === QUERY_PARAM_TRUE_VALUE
      ) {
        history.push(CHECKOUT_URL);
      } else {
        history.push(SUBSCRIPTIONS_URL);
      }

      return true;
    }
  } catch (err) {
    let loginErrorMsg = "Unable to login";
    dispatch(UIActions.appReady());
    if (err.graphQLErrors) {
      err.graphQLErrors.map(({ extensions }) => {
        if (extensions.exception && extensions.exception.code) {
          switch (extensions.exception.code) {
            case ERROR_CODE.INVALID_PASSWORD:
            case ERROR_CODE.NO_MATCHED_RECORD:
              loginErrorMsg =
                "Sorry, the email or password is incorrect. Please try again. ";
              break;
            case ERROR_CODE.ACCOUNT_HAS_NOT_ACTIVATED:
              loginErrorMsg =
                "Please click the activation link sent to your email. If you don't receive the email, please check the junk folder.";
              break;
          }
        }
      });
    }

    dispatch({ type: USER_LOGIN.FAIL, payload: { loginErrorMsg } });
    return false;
  }
};

export const register = signupData => async dispatch => {
  dispatch({ type: USER_REGISTER.AWAIT });
  dispatch(UIActions.appLoading());
  try {
    const userStationRes = await client.query({
      query: UserApi.GET_USER_STATION_DETAIL,
    });

    if (userStationRes.data.getUserStationDetail) {
      const {
        ipAddress: registeredIpAddress,
        country: registeredCountry,
        city: registeredCity,
      } = userStationRes.data.getUserStationDetail;

      await client.mutate({
        mutation: UserApi.register,
        variables: {
          input: {
            ...signupData,
            registeredIpAddress,
            registeredCountry,
            registeredCity,
          },
        },
      });
      dispatch({ type: USER_REGISTER.SUCCESS });
      dispatch(UIActions.showSuccess("Account created successfully."));
      history.push(LOGIN_URL);
    }

    dispatch(UIActions.appReady());
    return true;
  } catch (err) {
    dispatch({ type: USER_REGISTER.FAIL });
    dispatch(UIActions.appReady());
    let errMsg = "Some error occur. Please try again later.";
    if (err.message) errMsg = err.message;
    // In order to get the email validation error message
    if (err.graphQLErrors) {
      err.graphQLErrors.map(({ extensions }) => {
        if (
          extensions.exception &&
          extensions.exception.code === ERROR_CODE.VALIDATION_ERROR
        ) {
          extensions.exception.data.forEach(item => {
            const {
              constraints: { IsUniqueConstraint = "" },
            } = item;

            if (IsUniqueConstraint) {
              errMsg = IsUniqueConstraint;
            }
          });
        }
      });
    }
    dispatch(UIActions.addError(errMsg));
    return false;
  }
};

export const updateUser = data => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    let isValid = true;
    let result;
    if (data.input.email) {
      const res = await client.query({
        query: UserApi.CHECK_EMAIL,
        variables: { email: data.input.email },
      });
      isValid = res.data.checkEmail.available;
    }

    if (isValid) {
      result = await client.mutate({
        mutation: UserApi.updateUser,
        variables: data,
      });
      dispatch(UIActions.appReady());
    } else {
      throw new Error("Email already exists");
    }

    return result.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.message) dispatch(UIActions.addError(err.message));
    else if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else
      dispatch(
        UIActions.addError(
          "We are having problem on updating user. Please try again later."
        )
      );
  }
};

export const getUserListForVoucherForm = () => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({
    type: GET_USER_LIST.AWAIT,
  });
  try {
    const res = await client.query({
      query: UserApi.USERS,
    });
    dispatch({
      type: GET_USER_LIST.SUCCESS,
      payload: res.data.users,
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.message) dispatch(UIActions.addError(err.message));
    else if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else
      dispatch(
        UIActions.addError(
          "We are having problem getting users list. Please try again later."
        )
      );
    dispatch({
      type: GET_USER_LIST.FAIL,
    });
  }
};

export const getUsersList = filter => async dispatch => {
  dispatch(UIActions.appLoading());

  try {
    const res = await client.query({
      query: UserApi.usersList,
      variables: { filter },
    });

    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.message) dispatch(UIActions.addError(err.message));
    else if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else
      dispatch(
        UIActions.addError(
          "We are having problem getting users list. Please try again later."
        )
      );
  }
};

export const getUserById = id => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.query({
      query: UserApi.userById,
      variables: { _id: id },
    });
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.message) dispatch(UIActions.addError(err.message));
    else if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else
      dispatch(
        UIActions.addError(
          "We are having problem getting user data. Please try again later."
        )
      );
  }
};

export const createUser = data => async dispatch => {
  dispatch(UIActions.appLoading());
  try {
    await client.mutate({
      mutation: UserApi.createUser,
      variables: data,
    });
    dispatch(UIActions.showSuccess("Invited User!"));
    dispatch(UIActions.appReady());
    return true;
  } catch (err) {
    dispatch(UIActions.appReady());
    if (err.graphQLErrors)
      dispatch(
        UIActions.addError(
          err.graphQLErrors.map(item => item.message).join(". ")
        )
      );
    else
      dispatch(UIActions.addError("Some error occur. Please try again later."));
    return false;
  }
};

export const removeUser = (id, permanent) => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: REMOVE_USER.AWAIT });
  try {
    const res = await client.mutate({
      mutation: UserApi.REMOVE_USER,
      variables: { _id: id, permanent },
    });
    dispatch({ type: REMOVE_USER.SUCCESS });
    dispatch(UIActions.showSuccess("User removed successfully."));
    dispatch(UIActions.appReady());
    return res.data.removeUser;
  } catch (err) {
    dispatch({ type: REMOVE_USER.FAIL });
    handleAxiosError(dispatch, err, "Error removing User");
  }
};

export const getUserProfile = () => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: GET_USER_PROFILE.AWAIT });
  try {
    const res = await client.query({
      query: UserApi.GET_USER_PROFILE,
    });
    dispatch(UIActions.appReady());
    if (res.data && res.data.profile) {
      dispatch({
        type: GET_USER_PROFILE.SUCCESS,
        payload: {
          ...res.data.profile,
          isBlocked: checkShouldBlockUser(res.data.profile),
        },
      });
    }
    return res.data;
  } catch (err) {
    handleAxiosError(
      dispatch,
      err,
      "We are having problem getting user profile. Please try again later."
    );
    dispatch({
      type: GET_USER_PROFILE.FAIL,
    });
  }
};

export const updateUserProfile = data => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: UPDATE_USER_PROFILE.AWAIT });
  try {
    let isValid = true;
    if (data.input.email) {
      const res = await client.query({
        query: UserApi.CHECK_EMAIL,
        variables: { email: data.input.email },
      });
      isValid = res.data.checkEmail.available;
    }

    if (isValid) {
      const res = await client.mutate({
        mutation: UserApi.UPDATE_USER_PROFILE,
        variables: data,
      });
      dispatch(UIActions.appReady());

      if (data.input.email) {
        dispatch(logout());
        history.push(LOGIN_URL);
      } else {
        if (res.data && res.data.updateUserProfile) {
          dispatch(UIActions.showSuccess("Profile updated successfully."));
          dispatch({
            type: UPDATE_USER_PROFILE.SUCCESS,
            payload: {
              ...res.data.updateUserProfile,
              isBlocked: checkShouldBlockUser(res.data.updateUserProfile),
            },
          });
        }
      }
    } else {
      throw new Error("Email already exists");
    }
  } catch (err) {
    handleAxiosError(
      dispatch,
      err,
      "We are having problem updating user profile. Please try again later."
    );
    dispatch({
      type: UPDATE_USER_PROFILE.FAIL,
    });
  }
};

export const activateUser = (data, byAdmin = false) => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: ACTIVATE_USER.AWAIT });
  try {
    const res = await client.mutate({
      mutation: UserApi.ACTIVATE_USER,
      variables: data,
    });
    dispatch(UIActions.appReady());
    if (res && res.data && res.data.activate && res.data.activate._id) {
      dispatch(UIActions.showSuccess("Account activated successfully."));
      dispatch({
        type: ACTIVATE_USER.SUCCESS,
      });
    }
    if (!byAdmin) {
      history.push(
        `${LOGIN_URL}?${REDIRECT_CHECKOUT_QUERY_PARAM}=${QUERY_PARAM_TRUE_VALUE}`
      );
    }
    return res.data;
  } catch (err) {
    handleAxiosError(
      dispatch,
      err,
      "We are having problem updating user profile. Please try again later."
    );
    dispatch({
      type: ACTIVATE_USER.FAIL,
    });
    if (!byAdmin) {
      history.push(`${LOGIN_URL}`);
    }
  }
};

export const changePassword = data => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: CHANGE_PASSWORD.AWAIT });
  try {
    const res = await client.mutate({
      mutation: UserApi.CHANGE_PASSWORD,
      variables: data,
    });

    dispatch(UIActions.appReady());
    if (res && res.data && res.data.changePassword) {
      dispatch(UIActions.showSuccess("Password changed successfully."));
      dispatch({
        type: CHANGE_PASSWORD.SUCCESS,
      });
    }
    return res.data.changePassword;
  } catch (err) {
    handleAxiosError(
      dispatch,
      err,
      "We are having problem updating user profile. Please try again later."
    );
    dispatch({
      type: CHANGE_PASSWORD.FAIL,
    });
  }
};

export const triggerResetPassword = data => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: TRIGGER_RESET_PASSWORD.AWAIT });
  try {
    const res = await client.mutate({
      mutation: UserApi.TRIGGER_RESET_PASSWORD,
      variables: data,
    });

    dispatch(UIActions.appReady());
    if (res && res.data && res.data.triggerResetPassword) {
      dispatch(
        UIActions.showSuccess("Reset password email sent successfully.")
      );
      dispatch({
        type: TRIGGER_RESET_PASSWORD.SUCCESS,
      });
    }
    return res.data.triggerResetPassword;
  } catch (err) {
    handleAxiosError(
      dispatch,
      err,
      "We are having problem updating user profile. Please try again later."
    );
    dispatch({
      type: TRIGGER_RESET_PASSWORD.FAIL,
    });
  }
};

export const forgotPassword = data => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: FORGOT_PASSWORD.AWAIT });
  try {
    const res = await client.mutate({
      mutation: UserApi.FORGOT_PASSWORD,
      variables: data,
    });

    dispatch(UIActions.appReady());
    if (res && res.data && res.data.forgotPassword) {
      dispatch(
        UIActions.showSuccess("Reset password email sent successfully.")
      );
      dispatch({
        type: FORGOT_PASSWORD.SUCCESS,
      });
      history.push(LOGIN_URL);
    }
    return res.data.forgotPassword;
  } catch (err) {
    dispatch(UIActions.appReady());
    let errMsg;
    if (err.graphQLErrors) {
      err.graphQLErrors.map(({ extensions }) => {
        if (extensions.exception && extensions.exception.code) {
          switch (extensions.exception.code) {
            case ERROR_CODE.NO_MATCHED_RECORD:
              errMsg = "Sorry, the email is incorrect. Please try again.";
              break;
          }
        }
      });
    } else if (err.message) errMsg = err.message;
    else
      errMsg =
        "We are having problem updating user profile. Please try again later.";

    dispatch(UIActions.addError(errMsg));
    dispatch({
      type: FORGOT_PASSWORD.FAIL,
    });
  }
};

export const resetPassword = data => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: RESET_PASSWORD.AWAIT });
  try {
    const res = await client.mutate({
      mutation: UserApi.RESET_PASSWORD,
      variables: data,
    });

    dispatch(UIActions.appReady());
    if (res && res.data && res.data.resetPassword) {
      dispatch(UIActions.showSuccess("Password reset successfully."));
      dispatch({
        type: RESET_PASSWORD.SUCCESS,
      });
      history.push(LOGIN_URL);
    }
    return res.data.resetPassword;
  } catch (err) {
    handleAxiosError(
      dispatch,
      err,
      "We are having problem updating user profile. Please try again later."
    );
    dispatch({
      type: RESET_PASSWORD.FAIL,
    });
  }
};

export const checkEmail = email => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({ type: CHECK_EMAIL.AWAIT });
  try {
    const res = await client.query({
      query: UserApi.CHECK_EMAIL,
      variables: { email },
    });

    dispatch(UIActions.appReady());
    dispatch({
      type: CHECK_EMAIL.SUCCESS,
      payload: res.data.checkEmail.available,
    });
    return res.data.checkEmail;
  } catch (err) {
    handleAxiosError(
      dispatch,
      err,
      "Some errors occur. Please try again later."
    );
    dispatch({
      type: CHECK_EMAIL.FAIL,
    });
  }
};

export const setEmailAvailability = availability => ({
  type: SET_EMAIL_AVAILABILITY,
  payload: availability,
});

export const resetForgotPasswordStatus = (
  status = REQUEST_STATUS.UNINITIALIZED
) => ({
  type: SET_FORGOT_PASSWORD_STATUS,
  payload: status,
});
