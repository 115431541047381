import React from "react";
import { PayPalButton } from "react-paypal-button-v2";
import { useDispatch } from "react-redux";
import { paypalPaymentApproved } from "../../../../actions/subscriptionAction";
import { AppConfig } from "../../../../configs";
import { CURRENCY_CODE } from "../../../../constants";

const PayPalPaymentButton = ({ paypalOrderConfig }) => {
  const dispatch = useDispatch();
  const onApprovePaypalPayment = orderID => {
    dispatch(paypalPaymentApproved(orderID));
  };

  return (
    <PayPalButton
      onApprove={(data, actions) => onApprovePaypalPayment(data.orderID)}
      createOrder={(data, actions) => {
        if (paypalOrderConfig) return actions.order.create(paypalOrderConfig);
      }}
      onError={err => {
        alert("The subscription has failed with error");
        console.error(err.message);
      }}
      onCancel={() => alert("You have cancelled the subscription")}
      style={{
        layout: "horizontal",
        tagline: "false",
        label: "pay",
        shape: "pill",
        height: 45,
      }}
      options={{
        currency: CURRENCY_CODE,
        clientId: AppConfig.PAYPAL_CLIENT_ID,
      }}
    />
  );
};

export default PayPalPaymentButton;
