import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { PAYMENT_METHOD_LIST } from "../constants";
import { Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectPaymentMethod } from "../../../../selectors/cart";
import { setPaymentMethod } from "../../../../actions/cartAction";
import useStyles from "./styles";

const PaymentMethods = () => {
  const classes = useStyles();

  const paymentMethod = useSelector(selectPaymentMethod);
  const dispatch = useDispatch();

  const handleChange = event => {
    dispatch(setPaymentMethod(event.target.value));
  };

  return (
    <Box mb={2}>
      <FormControl component="fieldset" color="secondary">
        <FormLabel>Payment Method</FormLabel>
        <RadioGroup
          aria-label="paymentMethod"
          name="paymentMethod"
          value={paymentMethod}
          onChange={handleChange}
        >
          {PAYMENT_METHOD_LIST.map(paymentMethod => (
            <>
              <FormControlLabel
                key={paymentMethod.value}
                value={paymentMethod.value}
                control={<Radio />}
                label={paymentMethod.label}
              />
              <img
                src={paymentMethod.img}
                className={classes.paymentMethodIcon}
              />
            </>
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default PaymentMethods;
