import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import NavContainer from "../Container/NavContainer";
import useStyles from "./styles";
import { whiteCompanyLogo } from "../../resources/assets";
import { PrimaryButton } from "../CustomButton";
import { contactInfos, menuItems } from "./constants";

const Footer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.footerContainer} width="100%" height="100%">
      <NavContainer className={classes.footerContent}>
        {/* Top Section */}
        <Grid container spacing={8} direction="row" justify="space-between">
          <Grid item xs={12} md={4} style={{ maxWidth: 450 }}>
            <Grid container spacing={3}>
              <Grid item>
                <img
                  src={whiteCompanyLogo}
                  alt="Logo of AMCKEY"
                  className={classes.logo}
                />
              </Grid>
              <Grid item className={classes.info}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it
              </Grid>
              <Grid item>
                <PrimaryButton className={classes.readMoreBtn}>
                  READ MORE...
                </PrimaryButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} style={{ maxWidth: 350 }}>
            <Grid container spacing={4} direction="column">
              <Grid item>
                <Box className={classes.title}>Contact Info</Box>
              </Grid>
              {contactInfos.map(item => (
                <Grid item key={item.imgAlt} className={classes.infoSection}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={2}>
                      <img
                        src={item.imgPath}
                        alt={item.imgAlt}
                        className={classes.contactLogo}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      {item.infos.map((info, index) => (
                        <Box key={index} className={classes.info}>
                          {info}
                        </Box>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} style={{ maxWidth: 350 }}>
            <Grid container spacing={3} direction="column">
              <Grid item>
                <Box className={classes.title}>Menu</Box>
              </Grid>
              {menuItems.map((item, index) => (
                <Grid className={classes.info} item key={index}>
                  {item.title}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        {/* Bottom Section */}
      </NavContainer>
      <Box py={3} className={classes.bottomSection} mt="auto">
        <NavContainer> © 2021 Amckey.com - All rights reserved.</NavContainer>
      </Box>
    </Box>
  );
};

export default Footer;
