import { Box } from "@material-ui/core";
import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { PrimaryButton } from "../../../../Components/CustomButton";
import { CURRENCY_FORMAT } from "../../../../constants";
import { safeFormatDefaultDate } from "../../../../utils";
import { parsePaymentHistoryStatus } from "../helper";
import useStyles from "./styles";
import history from "../../../../routes/history";
import { ORDER_RECEIPT_URL } from "../../../../routes/constants";

const PaymentHistoryBox = ({ transaction }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const onNavigateReceiptPage = () => {
    history.push(`${ORDER_RECEIPT_URL}?id=${transaction._id}`);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.historyContainer}
    >
      <Box mb={1} className={classes.greyTxt}>
        Order# {transaction._ref} |{" "}
        {safeFormatDefaultDate(transaction._createdOn)}
      </Box>
      <Box mb={3} fontSize="1rem">
        {transaction.term.title}
      </Box>
      <Box mb={1}>
        {parsePaymentHistoryStatus(transaction.status, transaction.source)}{" "}
      </Box>
      <Box
        display="flex"
        flexDirection={mdUp ? "row" : "column"}
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="column" mb={mdUp ? 0 : 2}>
          <Box className={classes.greyTxt} mb={0.5}>
            Total
          </Box>
          <Box fontSize="1rem">
            {transaction.total_price
              ? `${CURRENCY_FORMAT}${transaction.total_price.toFixed(2)}`
              : "N/A"}
          </Box>
        </Box>
        <PrimaryButton
          className={classes.viewReceiptBtn}
          onClick={onNavigateReceiptPage}
        >
          View Receipt
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default PaymentHistoryBox;
