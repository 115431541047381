import { isValid } from "date-fns";

export const NAME_REGEX = /[a-zA-Z]/g;
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NUMBER_REGEX = /^\d+$/;
export const validateDate = (value, label) => {
  if (!value) {
    return `${label} cannot be empty`;
  }
  if (!isValid(new Date(value))) {
    return "Invalid date format";
  }

  return false;
};
