import React from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";

const options = {
  primaryColor: "#2196f3",
  textColor: "#65656a",
  zIndex: 100001,
};

const CustomTour = props => {
  const { tourState, dispatchTour, steps } = props;

  const callback = data => {
    const { action, index, type, status } = data;
    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatchTour({ type: "STOP" });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatchTour({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        steps={steps}
        showSkipButton={true}
        showProgress={true}
        scrollOffset={80}
        floaterProps={{
          disableAnimation: true,
          styles: {
            arrow: {
              length: 6,
              spread: 10,
            },
          },
        }}
        styles={{
          options,
          tooltipContainer: {
            textAlign: "left",
          },
          buttonBack: {
            backgroundColor: options.primaryColor,
            borderRadius: 4,
            color: "#fff",
          },
        }}
        locale={{
          last: "Done",
        }}
      />
    </>
  );
};

export default CustomTour;
