import React, { Fragment } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import BackViewCardFooter from "./BackViewCardFooter";
import styles from "../FlashcardModal/styles";

const useStyles = makeStyles(theme => styles(theme));

const SingleSideView = props => {
  const { isDarkMode, flashcard, showFrontView, setShowFrontView } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.cardFaceCont}>
        <div
          className={clsx(
            isDarkMode ? classes.cardFaceDark : classes.cardFaceLight,
            classes.cardFace,
            !showFrontView &&
              (isDarkMode
                ? classes.cardFaceActiveDark
                : classes.cardFaceActiveLight)
          )}
          onClick={() => setShowFrontView(false)}
        >
          Back
        </div>
        <div
          className={clsx(
            isDarkMode ? classes.cardFaceDark : classes.cardFaceLight,
            classes.cardFace,
            showFrontView &&
              (isDarkMode
                ? classes.cardFaceActiveDark
                : classes.cardFaceActiveLight)
          )}
          onClick={() => setShowFrontView(true)}
        >
          Front
        </div>
      </div>

      <div
        className={clsx(
          isDarkMode ? classes.cardBodyDark : classes.cardBodyLight,
          classes.cardBody,
          !showFrontView && classes.backViewCardBody
        )}
        dangerouslySetInnerHTML={{
          __html: showFrontView ? flashcard.front : flashcard.back,
        }}
      ></div>

      {!showFrontView && (
        <BackViewCardFooter isDarkMode={isDarkMode} tags={flashcard.tags} />
      )}

      {/* Single Side View End here*/}
    </Fragment>
  );
};

export default SingleSideView;
