import {
  LIGHT_PRIMARY_COLOR,
  LIGHT_FONT_COLOR,
  LIGHT_TOOL_TEXT_COLOR,
  DARK_PRIMARY_COLOR,
  DARK_SECONDARY_COLOR,
  DARK_TERTIARY_COLOR,
  COLOR_WHITE,
  DARK_DIVIDER_COLOR,
  FLASHCARD_CONTENT_STYLING,
  FLASHCARD_CONT_STYLING,
} from "../../../../styles/constants";

const styles = theme => ({
  // Themed Modal Styling
  zIndex1: {
    zIndex: 1,
    float: "right",
  },
  zIndex2: {
    zIndex: "2 !important",
    width: "100%",
    height: "100%",
  },
  calculatorModal: {
    boxShadow: "0 5px 10px rgb(0 0 0 / 30%)",
    background: "#f3f3f4",
    borderRadius: "4px",
  },
  calculatorTitleCont: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
    background: "rgba(0,0,0,.05)",
    padding: "20px 15px",
  },
  calculatorTitle: {
    fontSize: 18,
    lineHeight: "18px",
    fontWeight: 400,
    textAlign: "left",
    flex: "1 1",
  },
  calculatorQBIcon: {
    userSelect: "none",
    marginLeft: 15,
    width: 15,
    height: 15,
    cursor: "pointer",
    objectFit: "contain",
  },

  zIndex1001: {
    zIndex: 1001,
  },
  dFlex: {
    display: "flex",
  },
  modalCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    cursor: "default",
  },
  modalContLight: {
    backgroundColor: "#EEE",
    border: `2px solid ${LIGHT_PRIMARY_COLOR}`,
  },
  modalContDark: {
    backgroundColor: DARK_SECONDARY_COLOR,
    border: `2px solid ${DARK_DIVIDER_COLOR}`,
  },
  header: {
    backgroundColor: LIGHT_PRIMARY_COLOR,
    height: 30,
    margin: 2,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerTitle: {
    color: COLOR_WHITE,
    fontWeight: 500,
    fontSize: 15,
  },
  restoreIcon: {
    color: "#d3daf0",
    cursor: "pointer",
    marginTop: 3,
  },
  closeIcon: {
    cursor: "pointer",
    width: 20,
    height: 20,
  },
  formCont: {
    margin: 10,
  },
  textAreaRoot: {
    fontSize: 15,
    padding: 4,
    "& fieldset": {
      border: "2px solid #BDBDBD",
    },
    "&:hover $notchedOutline": {
      border: "2px solid #BDBDBD",
    },
    "&$focused $notchedOutline": {
      borderColor: LIGHT_PRIMARY_COLOR,
    },
  },
  textAreaRootLight: {
    backgroundColor: COLOR_WHITE,
    color: LIGHT_FONT_COLOR,
  },
  textAreaRootDark: {
    backgroundColor: DARK_TERTIARY_COLOR,
    color: COLOR_WHITE,
  },
  notchedOutline: {},
  focused: {},
  // Note Modal Styling
  actionCont: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    paddingBottom: 15,
    marginTop: "auto",
  },
  saveBtn: { width: 119, height: 27, borderStyle: "none" },
  deleteBtn: {
    width: 103,
    height: 27,
    borderStyle: "none",
  },
  // Feedback Modal Styling
  feedbackHeader: {
    backgroundColor: LIGHT_PRIMARY_COLOR,
    minHeight: 35,
    margin: 2,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  feedbackHeaderTitle: {
    color: COLOR_WHITE,
    fontWeight: 500,
    fontSize: 18,
  },
  feedbackActionCont: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 10px",
    marginBottom: 5,
    marginTop: "auto",
  },
  checkBoxLabel: {
    fontSize: 13,
  },
  checkBoxLabelLight: {
    color: LIGHT_FONT_COLOR,
  },
  checkBoxLabelDark: {
    color: COLOR_WHITE,
  },
  submitBtn: {
    backgroundRepeat: "no-repeat",
    width: 103,
    height: 27,
    borderStyle: "none",
  },
  // Image Preview Modal Styling
  imgPreviewCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .react-transform-wrapper": {
      width: "100% !important",
    },
    cursor: "pointer",
  },
  imgContLight: {
    backgroundColor: COLOR_WHITE,
  },
  imgContDark: {
    backgroundColor: DARK_TERTIARY_COLOR,
  },
  toolsCont: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    margin: "auto",
    height: 45,
  },
  toolBtn: {
    display: "flex",
    marginRight: 25,
    cursor: "pointer",
    fontSize: 15,
  },
  toolTextLight: {
    color: LIGHT_TOOL_TEXT_COLOR,
  },
  toolTextDark: {
    color: COLOR_WHITE,
  },
  toolIcon: {
    fontSize: 18,
    marginRight: 8,
  },
  stackCont: {
    position: "relative",
    marginLeft: "-2px",
    width: "20px",
    verticalAlign: "text-top",
    height: "20px",
    marginRight: 8,
  },
  flashIcon: {
    position: "absolute",
    fontSize: 18,
    left: 0,
  },
  squareIcon: {
    position: "absolute",
    left: 4,
    fontSize: 18,
  },
  // FlashcardList Modal Styling
  flashcardModalCont: {
    boxShadow: "0 4px 20px 0 rgb(0 0 0 / 25%)",
    borderRadius: 4,
  },
  flashcardListModalContLight: {
    backgroundColor: "#f3f3f4;",
  },
  flashcardListModalContDark: {
    backgroundColor: DARK_PRIMARY_COLOR,
    color: COLOR_WHITE,
    border: `2px solid ${DARK_DIVIDER_COLOR}`,
  },
  flashcardListHeader: {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    height: 51,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 20px",
  },
  flashcardListHeaderLight: {
    background: "#d9d9d9",
  },
  flashcardListHeaderDark: {
    backgroundColor: DARK_SECONDARY_COLOR,
  },
  flashcardListSearchBarLight: {
    backgroundColor: COLOR_WHITE,
  },
  flashcardListSearchBarDark: {
    backgroundColor: DARK_TERTIARY_COLOR,
  },
  flashcardListSearchBar: {
    padding: "5px 25px",
    boxShadow: "0 2px 4px 0 rgb(0 0 0 / 15%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 1,
  },
  searchIcon: {
    marginRight: 8,
    cursor: "not-allowed",
    color: "rgba(0,0,0,.38)",
  },
  searchInputRoot: {
    width: "100%",
  },
  filterIcon: {
    marginLeft: 8,
    fontSize: 18,
  },
  flashcardListContentCont: {
    overflowX: "hidden",
    height: "100%",
    padding: "20px 25px",
  },
  cardsCont: {
    marginTop: 4,
    marginBottom: 16,
  },
  addCardIcon: {
    color: "#5AB0F6",
    marginBottom: 4,
  },
  card: {
    ...FLASHCARD_CONT_STYLING,
  },
  cardDark: {
    backgroundColor: DARK_TERTIARY_COLOR,
    color: `${COLOR_WHITE} !important`,
  },
  cardContent: {
    ...FLASHCARD_CONTENT_STYLING,
  },
  // INSERT WITH IMAGE CASE STYLING
  snackBarRoot: {
    "& .MuiSnackbarContent-root": {
      backgroundColor: "rgba(95,99,104,.8)",
      "& .MuiSnackbarContent-message": {
        textAlign: "center",
        width: "100% !important",
      },
    },
  },
});
export default styles;
