import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";
import { EMAIL_REGEX } from "../../../constants";

import PrimaryTextField from "../../PrimaryTextField";
import { PrimaryButton } from "../../CustomButton";
import useStyles from "../styles";
import { NavLink } from "react-router-dom";
import { CHECKOUT_SIGNUP_URL } from "../../../routes/constants";
import { useDispatch } from "react-redux";
import { checkUserExists } from "../../../actions/signupAction";

const CheckEmailForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, control, errors } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = ({ email }) => {
    dispatch(checkUserExists(email));
  };

  return (
    <>
      <Grid direction="column" container>
        <Grid container>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: "Email cannot be empty",
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Invalid Email Format",
                },
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Email"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" mt={3}>
              <PrimaryButton
                color="primary"
                variant="contained"
                fullWidth
                onClick={handleSubmit(onSubmit)}
              >
                CONTINUE
              </PrimaryButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" my={3}>
              OR
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <NavLink
                exact
                to={CHECKOUT_SIGNUP_URL}
                className={classes.signupLink}
              >
                Sign up now
              </NavLink>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CheckEmailForm;
