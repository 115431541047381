import { Box } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

const ReceiptContainer = ({ children }) => {
  const classes = useStyles();

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={2}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        className={classes.receiptContainer}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ReceiptContainer;
