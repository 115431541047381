import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  toolTipDescription: {
    margin: "1rem 0",
    color: "#65656a",
  },

  tableTd: {
    borderTop: "1px solid #dee2e6",
    padding: "0.75rem",
  },

  tableTr: {
    borderBottom: "1px solid #dee2e6",
    borderTop: "1px solid #dee2e6",
  },
}));
