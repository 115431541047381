import { Box } from "@material-ui/core";
import React from "react";
import { COLOR_WHITE } from "../../styles/constants";

const FormContainer = ({ children }) => {
  return (
    <Box p={3} style={{ backgroundColor: COLOR_WHITE }}>
      {children}
    </Box>
  );
};

export default FormContainer;
