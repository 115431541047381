import React, { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useFieldArray } from "react-hook-form";
import { Controller } from "react-hook-form/dist/index.ie11";
import { Editor } from "@tinymce/tinymce-react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import * as TopicAction from "../../../actions/topicAction";
import * as MediaAction from "../../../actions/mediaAction";
import { AppConfig } from "../../../configs";
import { preprocessImg } from "../../../utils";
import { COURSE_APP_SUB_ROUTE } from "../../../routes/constants";
import { uploadImageToDOSpace } from "../../../services/uploadImage";
import { EDITOR_TOOLBAR, TABLE_TOOLBAR } from "../constants";

const useStyles = makeStyles(theme => ({
  draggableItem: {
    marginBottom: "1rem",
  },
  errorMsg: {
    color: "#bf1650",
    marginTop: "15px",
  },
  mt: {
    marginTop: "25px !important",
  },
}));

const SubTopicArray = props => {
  const {
    control,
    errors,
    getValues,
    watch,
    updateTopic,
    createTopic,
    createMedia,
    loadInitial,
  } = props;
  const classes = useStyles();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "subTopics",
  });
  const watchSubTopics = watch("subTopics");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchSubTopics[index],
    };
  });

  const [open, setOpen] = useState(false);
  const [currentID, setCurrentID] = useState(false);

  const history = useHistory();
  const params = useParams();
  const topicId = params.id;

  const handleClickOpen = ID => {
    setCurrentID(ID);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnDragEnd = async result => {
    if (!result.destination) return;
    let subTopics = Array.from(controlledFields);
    const [reorderedSubTopic] = subTopics.splice(result.source.index, 1);
    subTopics.splice(result.destination.index, 0, reorderedSubTopic);
    subTopics = subTopics.map(({ subTitle, content }) => {
      return {
        subTitle,
        content,
      };
    });
    const data = {
      category_id: getValues("categoryId"),
      title: getValues("title"),
      tags: getValues("tags"),
      subTopics,
    };
    move(result.source.index, result.destination.index);

    if (topicId) {
      const res = await updateTopic({
        input: { ...data, _id: topicId },
      });
      if (res && res.updateLesson) {
        await loadInitial();
      }
    } else {
      const res = await createTopic({
        input: data,
      });

      if (res && res.createLesson)
        history.push(
          `${COURSE_APP_SUB_ROUTE}/manage-topic/${res.createLesson._id}/edit`
        );
    }
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Fragment>
        <div id="preview"></div>
        <Droppable droppableId="subTopics">
          {provided => (
            <div
              className="subTopics"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {controlledFields.map((item, index) => {
                return (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {provided => (
                      <div className={classes.draggableItem}>
                        <Accordion
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <span>{item.subTitle}</span>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div style={{ width: "100%" }}>
                              <Controller
                                control={control}
                                defaultValue={item.subTitle}
                                name={`subTopics[${index}].subTitle`}
                                rules={{ required: "Subtitle cannot be empty" }}
                                render={({ onChange, value, name, ref }) => (
                                  <TextField
                                    name={name}
                                    label="Subtitle"
                                    onChange={e => onChange(e.target.value)}
                                    error={
                                      !!errors.subTopics &&
                                      !!errors.subTopics[index] &&
                                      !!errors.subTopics[index].subTitle
                                    }
                                    helperText={
                                      errors.subTopics &&
                                      errors.subTopics[index] &&
                                      errors.subTopics[index].subTitle &&
                                      errors.subTopics[index].subTitle.message
                                    }
                                    inputRef={ref}
                                    value={value}
                                    InputProps={{
                                      style: { fontSize: 15 },
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        fontSize: 15,
                                        color: "rgba(0, 0, 0, 0.25)",
                                      },
                                    }}
                                    FormHelperTextProps={{
                                      style: { fontSize: 13 },
                                    }}
                                    style={{ marginBottom: "25px" }}
                                    fullWidth
                                    color="secondary"
                                  />
                                )}
                              />

                              <Controller
                                className={classes.mt}
                                control={control}
                                name={`subTopics[${index}].content`}
                                rules={{ required: "Content cannot be empty" }}
                                defaultValue={item.content}
                                render={({ value, onChange }) => {
                                  return (
                                    <Editor
                                      apiKey={AppConfig.tinyMceApiKey}
                                      value={value}
                                      init={{
                                        height: 500,
                                        branding: false,
                                        plugins: [
                                          "advlist autolink lists link image charmap print preview anchor",
                                          "searchreplace visualblocks code fullscreen",
                                          "insertdatetime media table paste code help wordcount",
                                        ],
                                        menubar: "insert edit table",
                                        toolbar: EDITOR_TOOLBAR,
                                        table_toolbar: TABLE_TOOLBAR,
                                        images_upload_handler: async function(
                                          blobInfo,
                                          success,
                                          failure
                                        ) {
                                          if (!topicId) {
                                            failure(
                                              "Please only upload image in edit mode"
                                            );
                                            return;
                                          }

                                          try {
                                            let preprocessedImg = await preprocessImg(
                                              blobInfo
                                            );

                                            const data = await uploadImageToDOSpace(
                                              preprocessedImg
                                            );
                                            if (data.Location) {
                                              const input = {
                                                url: data.Location,
                                                filename:
                                                  preprocessedImg.filename,
                                                size: preprocessedImg.size,
                                                lesson_id: topicId
                                                  ? topicId
                                                  : "",
                                              };
                                              const res = await createMedia(
                                                input
                                              );
                                              if (res) success(data.Location);
                                            }
                                          } catch (err) {
                                            failure(err);
                                          }
                                        },
                                      }}
                                      onEditorChange={(content, _editor) => {
                                        onChange(content);
                                      }}
                                    />
                                  );
                                }}
                              />
                              {!!errors.subTopics &&
                                !!errors.subTopics[index] &&
                                !!errors.subTopics[index].content && (
                                  <p className={classes.errorMsg}>
                                    {errors.subTopics[index].content.message}
                                  </p>
                                )}

                              <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<DeleteIcon />}
                                onClick={() => handleClickOpen(item.id)}
                                className={classes.mt}
                              >
                                Delete
                              </Button>
                            </div>
                          </AccordionDetails>
                        </Accordion>

                        <Dialog
                          open={open && currentID === item.id}
                          onClose={handleClose}
                        >
                          <DialogTitle>
                            {`Are you sure you want to remove `}
                            <span style={{ fontWeight: "bold" }}>
                              {item.subTitle}
                            </span>
                            ?
                          </DialogTitle>

                          <DialogActions>
                            <Button
                              onClick={() => remove(index)}
                              color="secondary"
                            >
                              Remove
                            </Button>
                            <Button onClick={handleClose} autoFocus>
                              Cancel
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        <section className={classes.mt}>
          <Button
            variant="contained"
            style={{ marginRight: "1.5rem" }}
            onClick={() => {
              append({
                subTitle: "New Subtopics",
                content: "<p>Initial Content</p>",
              });
            }}
          >
            Create New Subtopic
          </Button>
        </section>
      </Fragment>
    </DragDropContext>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  createTopic: data => dispatch(TopicAction.createTopic(data)),
  updateTopic: data => dispatch(TopicAction.updateTopic(data)),
  createMedia: data => dispatch(MediaAction.createMedia(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubTopicArray)
);
