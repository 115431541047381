import React, { useState, useEffect, useMemo, Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";

import * as FlashcardActions from "../../actions/flashcardAction";
import * as ExamActions from "../../actions/examAction";
import styles from "./styles";
import SearchInput from "../../Components/SearchInput";
import FlashcardModal from "./FlashcardModal";
import {
  parseFlashcardThumbnail,
  filterFlashcardsByKeyWord,
} from "../../utils";
import { FLASHCARD_MODE_TYPE, FLASHCARD_LIST_TYPE } from "../../constants";
import FlashcardMenu from "./FlashcardMenu";
import FlashcardFilter from "./FlashcardFilter";

const useStyles = makeStyles(theme => styles(theme));

const FlashCardsPage = props => {
  const {
    getFlashcards,
    createFlashcard,
    updateFlashcard,
    removeFlashcard,
    getCategoryList,
  } = props;

  const [flashcardsData, setFlashcardsData] = useState(null);
  const [searchedFlashcardsData, setSearchedFlashcardsData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [openModalState, setOpenModalState] = useState({
    show: false,
    mode: "",
  });
  const [anchorFlashcardMenu, setAnchorFlashcardMenu] = useState(null);
  const [currentFlashcard, setCurrentFlashcard] = useState(null);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedSystems, setSelectedSystems] = useState([]);
  const [anchorFilterMenu, setAnchorFilterMenu] = useState(null);
  const [applyCategoriesFilter, setApplyCategoriesFilter] = useState(false);
  const [filteredTotal, setFilteredTotal] = useState(0);

  const classes = useStyles({ applyCategoriesFilter });

  // API Request Functions Start here
  const fetchFlashcards = async () => {
    const { flashcards } = await getFlashcards();
    if (flashcards) {
      setFlashcardsData(flashcards);
    }
  };

  const fetchFlashcardsByCategoriesFilter = async () => {
    const filter = {
      category_ids: selectedSubjects,
      subCategory_ids: selectedSystems,
    };
    const { flashcards } = await getFlashcards(filter);
    if (flashcards) {
      setFlashcardsData(flashcards);
      setFilteredTotal(flashcards.length);
    }
  };

  const onCreateFlashcard = async data => {
    const res = await createFlashcard(data);
    if (res && res.createFlashcard) {
      let tempFlashcardsData = flashcardsData;
      tempFlashcardsData.unshift(res.createFlashcard);
      setFlashcardsData(tempFlashcardsData);
    }
  };

  const onUpdateFlashcard = async data => {
    const res = await updateFlashcard(data);
    if (res && res.updateFlashcard) {
      // Update Whole List
      let index = flashcardsData.findIndex(
        flashcard => flashcard._id === res.updateFlashcard._id
      );
      let tempFlashcardsData = flashcardsData;
      tempFlashcardsData.splice(index, 1, res.updateFlashcard);
      setFlashcardsData(tempFlashcardsData);

      // Update Search List
      if (openModalState.listType === FLASHCARD_LIST_TYPE["SEARCH"]) {
        let searchListIndex = searchedFlashcardsData.findIndex(
          flashcard => flashcard._id === res.updateFlashcard._id
        );
        let tempFlashcardsData = searchedFlashcardsData;
        tempFlashcardsData.splice(searchListIndex, 1, res.updateFlashcard);

        setSearchedFlashcardsData(tempFlashcardsData);
      }
    }
  };

  const onRemoveFlashcard = async flashcardID => {
    const res = await removeFlashcard(flashcardID);
    if (res && res.removeFlashcard) {
      setFlashcardsData(
        flashcardsData.filter(flashcard => flashcard._id !== flashcardID)
      );
    }
    return res;
  };
  // API Request Functions End here

  // Handle Modal Start
  const handleViewFlashcardMode = (flashcard, listType) => {
    setCurrentFlashcard(flashcard);
    setOpenModalState({
      show: true,
      mode: FLASHCARD_MODE_TYPE["VIEW"],
      listType,
    });
  };

  const handleEditFlashcardMode = () => {
    setOpenModalState({
      show: true,
      mode: FLASHCARD_MODE_TYPE["EDIT"],
      fromMainPage: true,
    });
  };

  const handleCreateFlashcardMode = () => {
    setOpenModalState({ show: true, mode: FLASHCARD_MODE_TYPE["CREATE"] });
  };
  const handleCloseFlashcardModal = () => {
    setOpenModalState({ show: false, mode: "" });
  };

  const onClickFlashcard = (flashcard, listType) => {
    handleViewFlashcardMode(flashcard, listType);
    if (listType === FLASHCARD_LIST_TYPE["SEARCH"]) {
      setSearchedFlashcardsData(filteredFlashcardsData);
    }
  };
  // Handle Modal End

  // Handle Flashcardmenu Start
  const handleOpenFlashcardMenu = (event, flashcard) => {
    setCurrentFlashcard(flashcard);
    setAnchorFlashcardMenu(event.currentTarget);
  };
  const handleCloseFlashcardMenu = () => {
    setAnchorFlashcardMenu(null);
  };
  const onClickEditBtnHandler = () => {
    handleEditFlashcardMode();
    handleCloseFlashcardMenu();
  };
  // Handle Flashcardmenu End

  // Handle Filter Menu Start
  const handleOpenFilterMenu = event => {
    setAnchorFilterMenu(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setAnchorFilterMenu(null);
  };
  // Handle Filter Menu Start

  useEffect(() => {
    fetchFlashcards();
    getCategoryList();
  }, []);

  if (!flashcardsData) return null;
  const byKeyword = filterFlashcardsByKeyWord(flashcardsData, searchKeyword);
  const filteredFlashcardsData = byKeyword;

  return (
    <Paper elevation={0} className={classes.root}>
      <div className={classes.topContainer}>
        <Grid
          container
          justify="space-between"
          spacing={3}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="flex-end">
              <SearchInput
                onChange={e => setSearchKeyword(e.target.value)}
                value={searchKeyword}
              />
              <FontAwesomeIcon
                className={classes.filterIcon}
                icon={faSlidersH}
                onClick={handleOpenFilterMenu}
              />
              <FlashcardFilter
                total={filteredTotal}
                onClose={handleCloseFilterMenu}
                anchorFilterMenu={anchorFilterMenu}
                selectedSubjects={selectedSubjects}
                selectedSystems={selectedSystems}
                setSelectedSubjects={setSelectedSubjects}
                setSelectedSystems={setSelectedSystems}
                setApplyCategoriesFilter={setApplyCategoriesFilter}
                applyCategoriesFilter={applyCategoriesFilter}
                fetchFlashcards={fetchFlashcards}
                fetchFlashcardsByCategoriesFilter={
                  fetchFlashcardsByCategoriesFilter
                }
                setSearchKeyword={setSearchKeyword}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <button
              className={classes.createCardBtn}
              onClick={handleCreateFlashcardMode}
            >
              <AddIcon />
              New Card
            </button>
          </Grid>
        </Grid>
      </div>
      {flashcardsData && flashcardsData.length > 0 ? (
        <Fragment>
          <Grid container spacing={3} className={classes.bottomContainer}>
            {searchKeyword
              ? filteredFlashcardsData.map(flashcard => (
                  <Fragment key={flashcard._id}>
                    <Grid item>
                      <Paper className={classes.card}>
                        <MoreHorizIcon
                          onClick={e => handleOpenFlashcardMenu(e, flashcard)}
                        />
                        <div
                          className={classes.cardContent}
                          onClick={() =>
                            onClickFlashcard(
                              flashcard,
                              FLASHCARD_LIST_TYPE["SEARCH"]
                            )
                          }
                        >
                          {parseFlashcardThumbnail(flashcard.front)}
                        </div>
                      </Paper>
                    </Grid>
                  </Fragment>
                ))
              : flashcardsData.map(flashcard => (
                  <Fragment key={flashcard._id}>
                    <Grid item>
                      <Paper className={classes.card}>
                        <MoreHorizIcon
                          onClick={e => handleOpenFlashcardMenu(e, flashcard)}
                        />
                        <div
                          className={classes.cardContent}
                          onClick={() => onClickFlashcard(flashcard)}
                        >
                          {parseFlashcardThumbnail(flashcard.front)}
                        </div>
                      </Paper>
                    </Grid>
                  </Fragment>
                ))}
          </Grid>
        </Fragment>
      ) : (
        <div className={classes.notFoundTxt}>No cards found</div>
      )}
      <FlashcardModal
        openModalState={openModalState}
        setOpenModalState={setOpenModalState}
        handleCloseFlashcardModal={handleCloseFlashcardModal}
        currentFlashcard={currentFlashcard}
        setCurrentFlashcard={setCurrentFlashcard}
        parsedFlashcardsData={filteredFlashcardsData}
        searchedFlashcardsData={searchedFlashcardsData}
        onCreateFlashcard={onCreateFlashcard}
        onUpdateFlashcard={onUpdateFlashcard}
        onRemoveFlashcard={onRemoveFlashcard}
      />
      <FlashcardMenu
        currentFlashcard={currentFlashcard}
        anchorEl={anchorFlashcardMenu}
        anchorOrigin={{
          vertical: "center",
          horizontal: 160,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: 160,
        }}
        handleCloseMenu={handleCloseFlashcardMenu}
        handleCloseFlashcardModal={handleCloseFlashcardModal}
        onClickEditBtnHandler={onClickEditBtnHandler}
        handleGoBack={() => {}}
        onRemoveFlashcard={onRemoveFlashcard}
      />
    </Paper>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getFlashcards: filter => dispatch(FlashcardActions.getFlashcards(filter)),
  createFlashcard: data => dispatch(FlashcardActions.createFlashcard(data)),
  updateFlashcard: data => dispatch(FlashcardActions.updateFlashcard(data)),
  removeFlashcard: id => dispatch(FlashcardActions.removeFlashcard(id)),
  getCategoryList: () => dispatch(ExamActions.getCategoryList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlashCardsPage);
