import { extractHTMLString } from "../../../utils";

export const parseMediaTableData = medias =>
  medias.map(media => [
    media.url,
    media.size,
    media.creator
      ? `${media.creator.firstName ? `${media.creator.firstName} ` : ""}${
          media.creator.lastName ? `${media.creator.lastName}` : ""
        }`
      : "",
    media.lesson
      ? media.lesson.title
      : media.question
      ? media.question.question
      : "N/A",
    media._createdOn,
    media.title,
  ]);

export const sortMedias = (medias, isAsc, orderBy) => {
  return medias
    ? [...medias].sort((a, b) => {
        const aAttach = a.lesson
          ? a.lesson.title
          : a.question
          ? extractHTMLString(a.question.question)
          : "N/A";
        const bAttach = b.lesson
          ? b.lesson.title
          : b.question
          ? extractHTMLString(b.question.question)
          : "N/A";
        if (isAsc) {
          switch (orderBy) {
            case "date":
              return a._createdOn > b._createdOn ? 1 : -1;
            case "attach":
              return aAttach > bAttach ? 1 : -1;
          }
        } else {
          switch (orderBy) {
            case "date":
              return a._createdOn < b._createdOn ? 1 : -1;
            case "attach":
              return aAttach < bAttach ? 1 : -1;
          }
        }
      })
    : [];
};
