import { Box, Button } from "@material-ui/core";
import React from "react";
import { convertDateGMT_Plus10ToPlus08 } from "../../utils";

const CalendarPresentButton = ({ setFunc }) => {
  const handleSelectCurrentTime = () => {
    // GMT+10 Current time
    setFunc(convertDateGMT_Plus10ToPlus08(new Date()));
  };

  return (
    <Box mt={0.5}>
      <Button color="primary" onClick={handleSelectCurrentTime}>
        PRESENT
      </Button>
    </Box>
  );
};

export default CalendarPresentButton;
