import { Box } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React, { useMemo } from "react";
import MUICustomHeader from "../../../../../Components/MUICustomHeader";
import { XS_MUIDATATABLE_OPTIONS } from "../../../../../constants";
import useStyles from "./styles";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import DnsOutlinedIcon from "@material-ui/icons/DnsOutlined";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import LocationCityOutlinedIcon from "@material-ui/icons/LocationCityOutlined";
import { parseDateHtml, safeParseCountry } from "../../../../../utils";

const LoginHistoriesTable = props => {
  const { loginHistory } = props;
  const classes = useStyles();

  const data = useMemo(
    () =>
      Array.isArray(loginHistory) && loginHistory.length > 0
        ? loginHistory
            .sort((a, b) => (a._createdOn < b._createdOn ? 1 : -1))
            .map(history => [
              history._createdOn,
              history.ipAddress,
              history.country,
              history.city,
            ])
        : [],
    [loginHistory]
  );

  const columns = [
    {
      name: "Logged in On",
      options: {
        customBodyRender: value => {
          return parseDateHtml(value);
        },
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <CalendarTodayOutlinedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
    {
      name: "IP Address",
      options: {
        customBodyRender: value => <Box width="100px">{value}</Box>,
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <DnsOutlinedIcon className={classes.thIcon} color="secondary" />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
    {
      name: "Country",
      options: {
        customBodyRender: value => safeParseCountry(value),
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <PublicOutlinedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
    {
      name: "City",
      options: {
        customHeadRender: columnMeta => {
          return (
            <MUICustomHeader label={columnMeta.label}>
              <>
                {columnMeta.label}
                <LocationCityOutlinedIcon
                  className={classes.thIcon}
                  color="secondary"
                />
              </>
            </MUICustomHeader>
          );
        },
      },
    },
  ];

  return (
    <Box width="100%" mb={2}>
      <MUIDataTable
        data={data}
        columns={columns}
        options={{ ...XS_MUIDATATABLE_OPTIONS }}
      />
    </Box>
  );
};

export default LoginHistoriesTable;
