import { CONTENT_PADDING } from "../../../styles/constants";
const menuContentPaddingVertical = "20px";

const styles = theme => ({
  root: {
    ...CONTENT_PADDING(theme),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #e7eaec",
    width: "100%",
    paddingBottom: theme.spacing(1),
    fontSize: "17px",
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuPaper: {
    boxShadow: "0 8px 16px 0 rgb(0 0 0 / 20%)",
  },
  filterContainer: {
    display: "flex",
    margin: "40px 20px",
  },
  filterHeader: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  dateRangeFilterTxt: {
    padding: 10,
  },
  menuLeftContainer: {
    padding: menuContentPaddingVertical,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  menuRightContainer: {
    padding: `${menuContentPaddingVertical} 0`,
  },
  dateInput: {
    color: "#757575",
    "&:before": {
      borderBottom: "1px solid #e8e8e8",
    },
    "&:hover": {
      "&::before": {
        borderBottom: "1px solid #e8e8e8 !important",
      },
    },
  },
  actionsBtnContainer: {
    display: "flex",
    width: "100%",
    marginTop: 20,
  },
  searchBtn: {
    width: 77,
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    marginRight: 15,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "none",
    },
  },
  cancelBtn: {
    width: 77,
    border: "1px solid #b2b2b2",
    color: "grey",
    textTransform: "capitalize",
    "&:hover": {
      boxShadow: "none",
    },
  },
  // Performance Graph Component Styling
  customTooltip: {
    margin: 0,
    padding: 10,
    backgroundColor: "rgb(255,255,255)",
    border: "1px solid rgb(204,204,204)",
    whiteSpace: "nowrap",
  },
  centerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  statsContainer: {
    padding: "4px 0",
  },
  stats: {
    padding: "0 6px",
  },
});
export default styles;
