import {
  THIN_SCROLLBAR_STYLING,
  LIGHT_FONT_COLOR,
  DARK_PRIMARY_COLOR,
  DARK_TERTIARY_COLOR,
  DARK_FOURTH_COLOR,
  COLOR_WHITE,
} from "../../../styles/constants";

const BACKGROUND_STYLING = { backgroundColor: "#f3f3f4", width: "100%" };
const CARD_BODY_STYLING = {
  flex: "1 1 0",
  outline: 0,
  overflow: "auto",
  borderRadius: 8,
  borderTopRightRadius: 0,
  ...THIN_SCROLLBAR_STYLING,
};

const TAG_ICON_STYLING = {
  color: "#949494",
};
// Flashcard View Dialog styling
const styles = theme => ({
  dialogContentCont: {
    ...BACKGROUND_STYLING,
    height: 550,
  },
  // Flashcard View Dialog Header styling
  contentHeaderCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  header: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-between",

    cursor: "pointer",
  },
  headerLight: {
    color: "#696969",
  },
  headerDark: {
    color: COLOR_WHITE,
  },
  arrowDownIcon: {
    width: 18,
    height: 21,
    color: "#696969",
    marginLeft: 3,
  },
  moreIcon: {
    color: "#696969",
    marginTop: 6,
    cursor: "pointer",
  },
  menuItemCont: {
    display: "flex",
    alignItems: "center",
  },
  menuItemText: {
    paddingLeft: ".5rem",
    fontSize: "14px !important",
    marginTop: 2,
  },
  menuItemIcon: {
    width: 18,
    height: 18,
  },
  // Flashcard View Dialog Action styling
  dialogActionsContDark: {
    backgroundColor: `${DARK_PRIMARY_COLOR} !important`,
  },
  dialogActionsCont: {
    ...BACKGROUND_STYLING,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 10,
  },
  pageindex: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  actionsButtonCont: {
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionsButtonLight: {
    border: "1px solid #bfbfbf",
    color: " rgba(74,74,74,.9)",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  actionsButtonDark: {
    border: `1px solid ${COLOR_WHITE}`,
    color: COLOR_WHITE,
  },
  disabledActionsButtonDark: {
    color: `${COLOR_WHITE} !important`,
    border: `1px solid ${COLOR_WHITE} !important`,
    opacity: "0.5 !important",
  },
  actionsButton: {
    height: 34,
    borderRadius: 15,
    textTransform: "none",
  },
  mx: {
    margin: "0 16px",
  },
  // Single/Double Side View styling
  cardCont: {
    marginTop: 6,
    height: "calc(100% - 120px)",
  },
  cardFaceCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  cardFace: {
    width: 80,
    height: 30,
    textAlign: "center",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingTop: 4,
    cursor: "pointer",
  },
  cardFaceLight: {
    backgroundColor: "rgba(217,217,217,.5)",
  },
  cardFaceDark: {
    backgroundColor: DARK_FOURTH_COLOR,
  },
  cardFaceActiveLight: {
    backgroundColor: "#fff",
    color: LIGHT_FONT_COLOR,
  },
  cardFaceActiveDark: {
    backgroundColor: DARK_TERTIARY_COLOR,
    color: COLOR_WHITE,
  },
  cardBody: {
    height: "calc(100% - 40px)",
    maxHeight: "calc(100% - 40px)",
    padding: "20px 10px",
    ...CARD_BODY_STYLING,
  },
  cardBodyLight: {
    backgroundColor: "#fff",
    color: LIGHT_FONT_COLOR,
  },
  cardBodyDark: {
    backgroundColor: DARK_TERTIARY_COLOR,
    color: COLOR_WHITE,
  },
  // Double side View component styling
  doubleSideViewCont: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  mlAuto: {
    marginLeft: "auto",
  },
  backCardInactive: {
    display: "block",
    flex: "1 1 auto",
    cursor: "pointer",
    background: "rgba(217,217,217,.5)",
  },
  sideCardCont: {
    width: "48%",
    height: "100%",
  },
  // BackViewCard styling
  backViewCardBody: {
    height: "calc(100% - 90px)",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },

  backViewCardFooterLight: {
    backgroundColor: "#fff",
    color: LIGHT_FONT_COLOR,
  },
  backViewCardFooterDark: {
    backgroundColor: DARK_TERTIARY_COLOR,
    color: COLOR_WHITE,
  },
  backViewCardFooter: {
    height: 50,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderTop: "1px solid #e0e0e0",

    padding: ".85rem .5rem",
    display: "flex",
    alignItems: "center",
    overflow: "auto",
    overflowY: "hidden",
    ...THIN_SCROLLBAR_STYLING,
  },
  tagIcon: {
    ...TAG_ICON_STYLING,
    fontSize: 18,
    marginBottom: -4,
  },
  tagChip: {
    color: "#757575",
    backgroundColor: "#f4f5f7",
    marginLeft: ".25rem",
    marginRight: ".25rem",
  },
  // Flashcard Form styling
  popoverMenuPaper: {
    boxShadow:
      "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;",
    marginTop: "0.5em",
    maxHeight: 200,
    maxWidth: 310,
    [theme.breakpoints.down("xs")]: {
      width: "200px",
    },
  },
  select: {
    paddingLeft: 36,
    "&:focus": {
      backgroundColor: "#fff !important",
    },
  },
  adornedStartIconContainer: {
    position: "absolute",
    left: 8,
    top: "30%",
  },
  adornedStart: {
    padding: 0,
  },
  textFieldRoot: {
    backgroundColor: "#fff !important",
    "&$focused $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
  notchedOutline: {},
  focused: {},
  insertModalContLight: {
    backgroundColor: COLOR_WHITE,
  },
  insertModalContDark: {
    backgroundColor: DARK_FOURTH_COLOR,
  },
  insertModalCont: {
    cursor: "default",
    position: "absolute",
    top: "calc(50% - 83px)",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    maxWidth: 456,
    width: "90%",
    height: 190,
    borderRadius: 5,
    boxShadow: "0 4px 20px 0 rgb(0 0 0 / 25%)",
    zIndex: 99997,
    display: "flex",
    flexDirection: "column",
    alignItems: " center",
  },
  closeIconCont: {
    width: "100%",
    marginTop: 15,
    marginRight: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  insertModalActionsCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "15px 30px",
    height: 50,
  },
  insertModalActionBtn: {
    width: 63,
    borderRadius: 2,
    backgroundColor: "#1E88E5",
    height: 35,
    marginLeft: 5,
    marginRight: 5,
    outline: 0,
    border: "none",
    color: "#fff",
    fontSize: 14,
  },
  exchangeIcon: {
    cursor: "pointer",
    fontSize: "1rem",
    margin: "0 8px",
  },
  //100% - 40px
  sideFormCont: {
    height: "calc(100% - 150px) !important",
  },
  categorySideFormCont: {
    height: "calc(100% - 270px) !important",
  },
  cardFormBody: {
    height: "calc(100% - 40px)",
    maxHeight: "calc(100% - 40px)",
    wordBreak: "break-word",
    ...CARD_BODY_STYLING,
  },
  cardFormBodyInActiveLight: {
    backgroundColor: "rgba(217,217,217,.5)",
    color: "#000000",
  },
  cardFormBodyInActiveDark: {
    backgroundColor: DARK_TERTIARY_COLOR,
    color: COLOR_WHITE,
  },
  cardFormBodyInActive: {
    padding: 15,
    "& *": {
      lineHeight: 1.15,
      fontSize: 16,
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;',
    },
  },
  editorFooterText: {
    color: "#a6a6a6",
    marginTop: 3,
    fontSize: "80%",
  },
  categoriesInputCont: {
    margin: "15px 0",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  categoryInput: {
    width: "48%",
    padding: "5px 0",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  tagsInputCont: {
    marginTop: 20,
    height: 53,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    overflow: "auto",
    backgroundColor: "#fff",
    borderRadius: 10,
    borderTop: 0,
  },
  tagIconCont: {
    padding: "0 10px",
  },
  tagInputIcon: {
    ...TAG_ICON_STYLING,
    fontSize: 20,
    marginBottom: -4,
  },
  formActionsCont: {
    marginTop: 18,
    padding: "0 15px 10px 15px",
  },
  formActionsBtnCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 6,
  },
  formActionsButtonLight: {
    color: "#737373",
  },
  formActionsButtonDark: {
    color: COLOR_WHITE,
  },
  formActionsButton: {
    fontSize: "18px!important",
    textTransform: "none",
    padding: "0 16px",
  },
  errorMsg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    fontSize: 12,
    color: "#dd3c49",
  },
});

export default styles;
