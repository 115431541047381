import { Button, MenuItem, TextField } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import React, { Fragment, useState } from "react";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";

import * as UserAction from "../../../actions/userAction";
import { MANAGE_USER_URL } from "../../../routes/constants";
import { textFieldProps } from "../helper";
import { EMAIL_REGEX, USER_TYPE_LIST } from "../../../constants";

const CreateUserPage = ({ history, createUser }) => {
  const { handleSubmit, control, errors, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      type: "",
      password: "",
      confirmPassword: "",
    },
  });
  let pwd = watch("password");

  const onSubmitCreate = async ({
    email,
    type,
    firstName,
    lastName,
    password,
  }) => {
    const data = {
      email: email,
      type: type,
      firstName: firstName,
      lastName: lastName,
      password: password,
    };

    const res = await createUser({
      input: data,
    });
    if (!res) return;

    history.push(MANAGE_USER_URL);
  };

  return (
    <Fragment>
      <div className="pageCont">
        <div className="bodyCont">
          <div className="pageAction">
            <div className="pageActionLeft">
              <Link to={MANAGE_USER_URL}>
                <FontAwesomeIcon icon={faReply} />
                Back to user list
              </Link>
            </div>
          </div>
          <div className="pageContent">
            <h3 className="pageTitle">Invite User</h3>
            <form className="createCreateUserPage">
              <Controller
                control={control}
                name="firstName"
                rules={{
                  required: "First name cannot be empty",
                }}
                render={({ onChange, _onBlur, value, name, ref }) => (
                  <TextField
                    name={name}
                    label="First Name"
                    inputRef={ref}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={!!errors.firstName}
                    helperText={errors.firstName && errors.firstName.message}
                    {...textFieldProps}
                  />
                )}
              />
              <Controller
                control={control}
                name="lastName"
                rules={{
                  required: "Last name cannot be empty",
                }}
                render={({ onChange, _onBlur, value, name, ref }) => (
                  <TextField
                    name={name}
                    label="Last Name"
                    inputRef={ref}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={!!errors.lastName}
                    helperText={errors.lastName && errors.lastName.message}
                    {...textFieldProps}
                  />
                )}
              />
              <Controller
                control={control}
                name="password"
                rules={{
                  required: "Password cannot be empty",
                  minLength: { value: 8, message: "Minimum 8 characters" },
                }}
                render={({ onChange, _onBlur, value, name, ref }) => (
                  <TextField
                    name={name}
                    label="Password"
                    inputRef={ref}
                    value={value}
                    type="password"
                    onChange={e => onChange(e.target.value)}
                    error={!!errors.password}
                    helperText={errors.password && errors.password.message}
                    {...textFieldProps}
                    inputProps={{ autoComplete: "new-password" }}
                  />
                )}
              />
              <Controller
                control={control}
                name="confirmPassword"
                rules={{
                  validate: value =>
                    value === pwd ||
                    "Confirm password does not match with password",
                  required: "Required",
                }}
                render={({ onChange, _onBlur, value, name, ref }) => (
                  <TextField
                    name={name}
                    label="Confirm Password"
                    inputRef={ref}
                    value={value}
                    type="password"
                    onChange={e => onChange(e.target.value)}
                    error={!!errors.confirmPassword}
                    helperText={
                      errors.confirmPassword && errors.confirmPassword.message
                    }
                    {...textFieldProps}
                  />
                )}
              />
              <Controller
                control={control}
                name="email"
                rules={{
                  required: "Email cannot be empty",
                  pattern: {
                    value: EMAIL_REGEX,
                    message: "Invalid Email Format",
                  },
                }}
                render={({ onChange, _onBlur, value, name, ref }) => (
                  <TextField
                    name={name}
                    label="Email"
                    inputRef={ref}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={!!errors.email}
                    helperText={errors.email && errors.email.message}
                    {...textFieldProps}
                  />
                )}
              />
              <Controller
                control={control}
                name="type"
                rules={{
                  required: "Role cannot be empty",
                }}
                render={({ onChange, value, name, ref }) => (
                  <TextField
                    name={name}
                    value={value}
                    label="Role"
                    inputRef={ref}
                    {...textFieldProps}
                    fullWidth
                    onChange={e => onChange(e.target.value)}
                    select
                    color="secondary"
                  >
                    {USER_TYPE_LIST.map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </form>
          </div>
        </div>

        <Button
          onClick={handleSubmit(onSubmitCreate)}
          color="secondary"
          variant="contained"
          className="submitButton"
          size="large"
        >
          Invite
        </Button>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  createUser: data => dispatch(UserAction.createUser(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateUserPage)
);
