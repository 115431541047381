const styles = theme => ({
  expandIcon: {
    width: props => props.expandIconSize,
    height: props => props.expandIconSize,
    opacity: 0.4,
    objectFit: "contain",
    "-webkit-transition": "all 400ms ease-in-out",
    transition: "all 400ms ease-in-out",
    cursor: "pointer",
  },
  inActiveExpandIcon: {
    "-webkit-transform": "rotate(180deg)",
    transform: "rotate(180deg)",
    "-webkit-transition": "all 400ms ease-in-out",
    transition: "all 400ms ease-in-out",
  },
});

export default styles;
