import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { textFieldProps } from "../../helper";
import * as UserAction from "../../../../actions/userAction";
import { connect } from "react-redux";
import {
  CustomCancelBtn,
  CustomConfirmDialogActions,
  CustomActionBtn,
} from "../../../../Components/CustomDialogElements";

const EditPasswordSection = ({ user, updateUser, triggerResetPassword }) => {
  const { handleSubmit, control, reset, errors, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });
  let pwd = watch("password");
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  // Handle Confirmtion Modal Start
  const handleOpenUpdatePasswordModal = () => {
    setShowUpdatePasswordModal(true);
  };

  const handleCloseUpdatePasswordModal = () => {
    setShowUpdatePasswordModal(false);
  };

  const handleOpenResetPasswordModal = () => {
    setShowResetPasswordModal(true);
  };

  const handleCloseResetPasswordModal = () => {
    setShowResetPasswordModal(false);
  };
  // Handle Confirmtion Modal End

  const onSubmitUpdate = async ({ password }) => {
    if (user._id) {
      const res = await updateUser({
        _id: user._id,
        input: { password },
      });
      if (!res) return;
    }
    handleCloseUpdatePasswordModal();
    reset({
      password: "",
      confirmPassword: "",
    });
  };

  const onTriggerResetPassword = async () => {
    await triggerResetPassword({ email: user.email });
    handleCloseResetPasswordModal();
  };

  return (
    <>
      <Dialog
        onClose={handleCloseUpdatePasswordModal}
        open={showUpdatePasswordModal}
      >
        <DialogContent>
          Are you sure you wish to change this user password?
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomActionBtn onClick={handleSubmit(onSubmitUpdate)}>
            Update
          </CustomActionBtn>
          <CustomCancelBtn onClick={handleCloseUpdatePasswordModal}>
            Cancel
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>
      <Dialog
        onClose={handleCloseResetPasswordModal}
        open={showResetPasswordModal}
      >
        <DialogContent>
          Are you sure you wish to trigger system to send email for this user to
          reset password?
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomActionBtn onClick={onTriggerResetPassword}>
            Yes
          </CustomActionBtn>
          <CustomCancelBtn onClick={handleCloseResetPasswordModal}>
            Cancel
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>

      <Controller
        control={control}
        name="password"
        rules={{
          required: "Password cannot be empty",
          minLength: { value: 8, message: "Minimum 8 characters" },
        }}
        render={({ onChange, _onBlur, value, name, ref }) => (
          <TextField
            name={name}
            label="Password"
            inputRef={ref}
            value={value}
            type="password"
            onChange={e => onChange(e.target.value)}
            error={!!errors.password}
            helperText={errors.password && errors.password.message}
            {...textFieldProps}
            inputProps={{ autoComplete: "new-password" }}
          />
        )}
      />
      <Controller
        control={control}
        name="confirmPassword"
        rules={{
          validate: value =>
            value === pwd || "Confirm password does not match with password",
          required: "Required",
        }}
        render={({ onChange, _onBlur, value, name, ref }) => (
          <TextField
            name={name}
            label="Confirm Password"
            inputRef={ref}
            value={value}
            type="password"
            onChange={e => onChange(e.target.value)}
            error={!!errors.confirmPassword}
            helperText={
              errors.confirmPassword && errors.confirmPassword.message
            }
            {...textFieldProps}
          />
        )}
      />
      <Box mt={2} display="flex">
        <Box mr={2}>
          <Button
            onClick={handleOpenUpdatePasswordModal}
            color="secondary"
            variant="contained"
          >
            Update Password
          </Button>
        </Box>
        <Button
          onClick={handleOpenResetPasswordModal}
          color="secondary"
          variant="contained"
        >
          Reset Password
        </Button>
      </Box>
    </>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  updateUser: data => dispatch(UserAction.updateUser(data)),
  triggerResetPassword: data => dispatch(UserAction.triggerResetPassword(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPasswordSection);
