import React, { Fragment } from "react";
import { Box, Button } from "@material-ui/core";
import {
  getLabelFromValue,
  parseCapitalizeHtml,
  parseUsername,
  safeFormatDefaultDateTime,
  safeParseCountry,
  safeParseState,
} from "../../../../utils";
import { parseUserStatusHtml } from "../../ManageUser/helper";
import {
  CAREER_STATUS_LIST,
  EDUCATIONAL_GROUP_LIST,
} from "../../../../Components/Form/constants";
import PlanHistoriesTable from "./components/PlanHistoriesTable";
import PreviousTestListTable from "./components/PreviousTestListTable";
import ResetHistoriesTable from "./components/ResetHistoriesTable";
import RegistrationSection from "./components/RegistrationSection";

const UserInfoModal = ({ visible, user, onClose }) => {
  if (!user || !visible) return null;

  const sortedLoginHistory =
    Array.isArray(user._loginHistory) && user._loginHistory.length > 0
      ? user._loginHistory.sort((a, b) =>
          a._createdOn < b._createdOn ? 1 : -1
        )
      : [];
  const latestLoginHistory = sortedLoginHistory[0]
    ? sortedLoginHistory[0]
    : null;

  return (
    <Fragment>
      <div className={`modalCont ${visible && "show"}`} onClick={onClose}>
        <div
          className={`modal userInfoModal ${visible && "show"}`}
          onClick={e => e.stopPropagation()}
        >
          <div className="titleCont">
            <div className="fTitle">User Details</div>
          </div>
          <div className="bodyCont">
            {user && (
              <Fragment>
                <h4>
                  <b>ID</b>
                </h4>
                <h4>{user._ref}</h4>
                <h4>
                  <b>Username</b>
                </h4>
                <h4>{parseUsername(user.firstName, user.lastName)}</h4>
                <h4>
                  <b>Email</b>
                </h4>
                <h4>{user.email}</h4>
                <h4>
                  <b>Address</b>
                </h4>
                <h4>{user.address1}</h4>
                <h4>
                  <b>Address 2</b>
                </h4>
                <h4>{user.address2}</h4>
                <h4>
                  <b>Country</b>
                </h4>
                <h4>{safeParseCountry(user.country)}</h4>
                <h4>
                  <b>State</b>
                </h4>
                <h4>{safeParseState(user.state, user.country)}</h4>
                <h4>
                  <b>City</b>
                </h4>
                <h4>{user.city}</h4>
                <h4>
                  <b>Zip Code</b>
                </h4>
                <h4>{user.zipCode}</h4>
                <h4>
                  <b>Phone Number</b>
                </h4>
                <h4>{user.phoneNo}</h4>
                <h4>
                  <b>Group</b>
                </h4>
                <h4>
                  {getLabelFromValue(
                    EDUCATIONAL_GROUP_LIST,
                    user.educationalGroup
                  )}
                </h4>
                <h4>
                  <b>Career Status</b>
                </h4>
                <h4>
                  {getLabelFromValue(CAREER_STATUS_LIST, user.careerStatus)}
                </h4>
                <h4>
                  <b>Graduating School</b>
                </h4>
                <h4>{user.graduatingSchool}</h4>
                <h4>
                  <b>Status</b>
                </h4>
                <h4>{parseUserStatusHtml(user.status)}</h4>
                <h4>
                  <b>Plan</b>
                </h4>
                <PlanHistoriesTable user={user} />
                <h4>
                  <b>Role</b>
                </h4>
                <h4>{parseCapitalizeHtml(user.type)}</h4>
                <h4>
                  <b>Registration</b>
                </h4>
                <RegistrationSection user={user} />
                <h4>
                  <b>Progress Details</b>
                </h4>
                <PreviousTestListTable userId={user._id} />
                <h4>
                  <b># Logins</b>
                </h4>
                <Box display="flex" flexDirection="column">
                  <h4>No of logins : {sortedLoginHistory.length}</h4>
                  <h4>
                    Last Login IP Address:{" "}
                    {latestLoginHistory ? latestLoginHistory.ipAddress : ""}
                  </h4>
                  <h4>
                    Last Login Country:{" "}
                    {latestLoginHistory
                      ? safeParseCountry(latestLoginHistory.country)
                      : ""}
                  </h4>
                  <h4>
                    Last Login City:{" "}
                    {latestLoginHistory ? latestLoginHistory.city : ""}
                  </h4>
                  <h4>
                    Last Login On:{" "}
                    {latestLoginHistory
                      ? safeFormatDefaultDateTime(latestLoginHistory._createdOn)
                      : ""}
                  </h4>
                </Box>
                <h4>
                  <b>Block</b>
                </h4>
                {/* Wait backend */}
                <h4>
                  <b>Reset Count</b>
                </h4>
                <h4>
                  Reset Usage:{" "}
                  {user.subscription ? user.subscription.resetUsage : "0/0"}
                </h4>
                <ResetHistoriesTable user={user} />
                <h4>
                  <b>Remark</b>
                </h4>
                <h4>{user.remark}</h4>
              </Fragment>
            )}
          </div>
          <div className="actionCont">
            <Button variant="outlined" color="default" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserInfoModal;
