import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  root: {
    width: "100%",
  },
  questionHeader: {
    fontWeight: 600,
    marginBottom: "0.8em",
  },
  question: {
    fontSize: 14.5,
  },
  answerLeftIcon: {
    width: "25px",
    height: "25px",
    marginRight: 6,
    marginBottom: 4,
  },
  answerContent: {
    display: "flex",
    alignItems: "center",
  },
  explainContainer: {
    marginTop: 20,
  },
  explanationContainer: {
    width: "100%",
    marginTop: 20,
  },
  tabCont: {
    display: "flex",
    width: "100%",
    borderBottom: "1px solid #e3e6e8",
    marginBottom: "1em",
  },
  tabItem: {
    color: "#555",
    padding: "10px 20px",
    backgroundColor: "transparent",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
  tabHeader: {
    fontSize: "0.9rem",
  },
  tabItemActive: {
    border: "1px solid #e3e6e8",
    borderBottomColor: "#f0f3f5 !important",
    marginBottom: "-1px",
  },
}));
