import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import {
  CustomConfirmDialogActions,
  CustomActionBtn,
  CustomCancelBtn,
} from "../../Components/CustomDialogElements";
import styles from "./styles";
import { SecondaryButton } from "../../Components/CustomButton";
import { resetSubscription } from "../../actions/subscriptionAction";

const useStyles = makeStyles(theme => styles(theme));

const ResetPage = () => {
  const classes = useStyles();
  const { subscription } = useSelector(state => state.SubscriptionStore);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  // Handle Confirmtion Modal Start
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  // Handle Confirmtion Modal End

  const onResetSubscription = () => {
    dispatch(resetSubscription(subscription._id));
    handleCloseModal();
  };

  return (
    <>
      <Dialog onClose={handleCloseModal} open={showModal}>
        <DialogContent>Are you sure you wish to reset test data?</DialogContent>
        <CustomConfirmDialogActions>
          <CustomActionBtn onClick={onResetSubscription}>Reset</CustomActionBtn>
          <CustomCancelBtn onClick={handleCloseModal}>Cancel</CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>
      <Paper elevation={1} className={classes.root}>
        <Box>
          <ul className={classes.ul}>
            <li>
              Subscribers who have subscribed for 6 months or more will be able
              to reset their Test Data.
            </li>
            <li>
              Resetting Test Data will DELETE ALL data related to notes,
              highlights, performance and all previous tests.
            </li>
            <li>
              <strong>
                {subscription && subscription.resetLimit > 0
                  ? `${subscription.resetLimit} time${
                      subscription.resetLimit > 1 ? "s" : ""
                    }, irreversible change.`
                  : "Your account is not eligible for reset feature at the moment."}
              </strong>
            </li>
          </ul>
        </Box>
        <Badge
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={subscription ? subscription.resetUsage : "0/0"}
          color="error"
        >
          <SecondaryButton
            className={classes.resetBtn}
            color={"secondary"}
            variant="contained"
            disabled={
              !Boolean(subscription) || !Boolean(subscription.resetLimit > 0)
            }
            onClick={handleOpenModal}
          >
            Reset Test Data
          </SecondaryButton>
        </Badge>
      </Paper>
    </>
  );
};

export default ResetPage;
