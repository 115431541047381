import { makeStyles } from "@material-ui/styles";
import {
  LIST_ITEM_ACTIVE_COLOR,
  LIST_ITEM_ACTIVE_STYLE,
} from "../../../styles/constants";

export default makeStyles(theme => ({
  container: {
    display: "flex",
    width: "100%",
    marginLeft: "126px",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      justifyContent: "center",
    },
  },
  itemColumnsContainer: {
    width: "auto",
    display: "flex",
  },
  itemColumn: {
    margin: "0px 20px",
  },
  icon: {
    width: "24px",
    height: "24px",
  },
  listRoot: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    boxShadow:
      "rgb(61 74 84 / 10%) 0px 3px 8px 0px, rgb(61 74 84 / 6%) 0px 3px 12px 0px",
    width: "350px",
  },
  title: {
    paddingBottom: theme.spacing(2),
    fontFamily: "metropolis",
    fontWeight: "900",
  },
  activeLinkContainer: {
    ...LIST_ITEM_ACTIVE_STYLE,
    "& span": {
      color: `${LIST_ITEM_ACTIVE_COLOR} !important`,
    },
  },
  linkContainer: {
    borderColor: "rgb(238, 242, 245)",
    borderStyle: "solid",
    borderWidth: "1px 0px",
    wordBreak: "break-word",
    transition: "all 100ms ease-in-out",
    "& span": {
      color: LIST_ITEM_ACTIVE_COLOR,
      fontFamily: "metropolis",
    },
    "&:hover": {
      "& span": {
        color: LIST_ITEM_ACTIVE_COLOR,
      },
      backgroundColor: "rgb(248, 250, 252)",
      cursor: "pointer",
    },
  },
  topicIcon: {
    color: "#ff8c00",
  },
  backActionContainer: {
    height: "24px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  subTitle: {
    fontFamily: "metropolis",
    fontWeight: "900",
    color: "rgb(153, 167, 179)",
  },
  chevron: {
    color: "rgb(153, 167, 179)",
    fontSize: "0.9rem",
    marginTop: "6px",
  },
}));
