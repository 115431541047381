import { gql } from "@apollo/client";

export const PLANS = gql`
  query {
    plans {
      _id
      _ref
      name
      price {
        _id
        price
        title
      }
    }
  }
`;
