import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";
import { companyLogo } from "../../../resources/assets";

const FormCardContainer = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      className={classes.container}
    >
      <Box className={classes.cardContainer}>
        <Grid container spacing={2}>
          {!xsDown && (
            <Grid item xs={12} md={6}>
              <Box
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={companyLogo}
                  alt="Logo of AMCKEY"
                  className={classes.logo}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Box width="100%" height="100%" display="flex" alignItems="center">
              {children}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FormCardContainer;
