import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";

import * as TopicAction from "../../../../actions/topicAction";
import { parseDateHtml } from "../../../../utils";
import TableTitle from "./TableTitle";
import ConfirmationModal from "../../../../Components/Modal/ConfirmationModal";
import useModal from "../../../../hooks/useModal";
import { DELETED_STATUS_FILTER } from "../constants";
import { handleRemoveTableRow } from "../helper";

const TopicTable = ({ getTopicList, removeTopic }) => {
  const [topics, setTopics] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState([]);

  const { open, handleOpen, handleClose } = useModal();
  const handleOpenDeleteModal = () => {
    if (selectedIndexes.length > 0) {
      handleOpen();
    }
  };

  const fetchTopicList = async () => {
    const res = await getTopicList(DELETED_STATUS_FILTER);
    if (res && res.lessons) {
      setTopics(res.lessons);
    }
  };

  useEffect(() => {
    fetchTopicList();
  }, []);

  const onRemoveTopic = async () => {
    await handleRemoveTableRow(
      topics,
      setTopics,
      selectedIndexes,
      setSelectedIndexes,
      removeTopic,
      handleClose
    );
  };

  return (
    <Fragment>
      <ConfirmationModal
        open={open}
        onClose={handleClose}
        actionFn={onRemoveTopic}
        ctaTitle={"Are you sure you wish to permanently delete these topic(s)?"}
        ctaTxt={"Delete"}
      />
      <TableTitle title="Topic" handleOpenDeleteModal={handleOpenDeleteModal} />
      <MUIDataTable
        data={topics.map(topic => [
          topic._ref,
          topic._createdOn,
          topic.title,
          topic.category ? topic.category.name : "",
          topic.tags.join(", "),
        ])}
        columns={[
          "ID",
          {
            name: "Date Created",
            options: {
              customBodyRenderLite: dataIndex => {
                let val = topics[dataIndex]._createdOn;
                return parseDateHtml(val);
              },
              setCellHeaderProps: () => ({
                className: "globalCustomMuiTableHead",
              }),
            },
          },
          "Title",
          "Subject",
          "Tags",
        ]}
        options={{
          download: false,
          print: false,
          sort: false,
          onRowSelectionChange: (
            currentRowsSelected,
            allRowsSelected,
            rowsSelected
          ) => {
            setSelectedIndexes(rowsSelected);
          },
          customToolbarSelect: () => {},
          rowsSelected: selectedIndexes,
        }}
      />
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getTopicList: filter => dispatch(TopicAction.getTopicList(filter)),
  removeTopic: (id, permanent) =>
    dispatch(TopicAction.removeTopic(id, permanent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopicTable);
