import React, { useState, useEffect, Fragment, useMemo } from "react";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import AntTabs from "../../../Components/TabElements/AntTabs";
import AntTab from "../../../Components/TabElements/AntTab";
import {
  CustomTableCell,
  HoverSelectedTableRow,
  SubjectsRowCell,
  SystemsRowCell,
  CustomTableSortLabel,
  AddIcon,
  RemoveIcon,
} from "../../../Components/CustomTableElements";
import * as AnalyticsActions from "../../../actions/analyticsAction";
import styles from "./styles";
import {
  QUESTION_STATUS,
  REPORTS_BAR_TYPE,
  REPORTS_DATA_TYPE,
} from "../../../constants";
import { headCells, showBarsArr, barTypeArr } from "./reportsArr";
import {
  sortReports,
  parseReportDetail,
  parseSystemPerformanceReport,
} from "./reportsDataParser";
import FilterMenu from "../../../Components/FilterMenu";
import { ScoreBar, ProgressBar } from "../../../Components/customBar";

const useStyles = makeStyles(theme => styles(theme));

const ReportsPage = ({ getUserPerformance }) => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [reportDataType, setReportDataType] = useState(
    REPORTS_DATA_TYPE["SUBJECTS"]
  );
  const [reportsDataArr, setReportsDataArr] = useState(null);
  const [userPerformanceData, setUserPerformanceData] = useState(null);
  const [systemsData, setSystemsData] = useState(null);
  const [tab, setTab] = useState(0);
  const [orderBy, setOrderBy] = useState("name");
  const [isAsc, setIsAsc] = useState(true);
  const [anchorElBarsMenu, setAnchorElBarsMenu] = useState(null);
  const [anchorElBarTypeMenu, setAnchorElBarTypeMenu] = useState(null);
  const [showBarsList, setShowBarsList] = useState([
    QUESTION_STATUS["CORRECT"],
  ]);

  const [barType, setBarType] = useState(REPORTS_BAR_TYPE["SCORE"]);
  const [expandedList, setExpandedList] = useState([]);
  const [expandAll, setExpandAll] = useState(false);

  const fetchUserPerformance = async () => {
    const res = await getUserPerformance();
    if (res && res.userPerformance) {
      setUserPerformanceData(res.userPerformance);
    }
  };
  const handleChangeTab = (event, newTab) => {
    setTab(newTab);
  };

  // Handle Bars Menu Start
  const handleOpenShowBarMenu = event => {
    setAnchorElBarsMenu(event.currentTarget);
  };

  const handleCloseShowBarMenu = () => {
    setAnchorElBarsMenu(null);
  };

  const handleOpenBarTypeMenu = event => {
    setAnchorElBarTypeMenu(event.currentTarget);
  };

  const handleCloseBarTypeMenu = () => {
    setAnchorElBarTypeMenu(null);
  };

  const handleShowBarsList = event => {
    const status = event.target.name;

    if (event.target.checked) {
      if (showBarsList.includes(status)) {
        setShowBarsList(showBarsList.filter(elem => elem !== status));
      } else {
        setShowBarsList([...showBarsList, status]);
      }
    } else {
      setShowBarsList(showBarsList.filter(elem => elem !== status));
    }
  };

  const handleBarType = type => {
    setBarType(type);
  };

  const renderSelectedBarType = () => {
    if (barType === REPORTS_BAR_TYPE["SCORE"]) return "Score";
    else return "QBank Usage";
  };
  const renderSelectedBars = () => {
    if (showBarsList.length === 0) return "None";
    if (showBarsList.length === 1) return `${showBarsList[0]} Only`;
    if (showBarsList.length >= 3) return "All";
    else {
      return showBarsList.join(",");
    }
  };
  // Handle Bars Menu End

  // Handle Accordion Collapse Start
  const onClickAccordion = subjectName => {
    if (expandedList.includes(subjectName)) {
      setExpandedList(expandedList.filter(elem => elem !== subjectName));
    } else {
      setExpandedList([...expandedList, subjectName]);
    }
  };

  const toggleExpandAll = () => {
    setExpandAll(!expandAll);
  };
  useEffect(() => {
    if (expandAll) {
      setExpandedList(subjectsNameArr);
    } else {
      setExpandedList([]);
    }
  }, [expandAll]);
  // Handle Accordion Collapse End

  const onSortReports = value => {
    if (orderBy === value) {
      setIsAsc(!isAsc);
    } else {
      setOrderBy(value);
    }
  };

  useEffect(() => {
    fetchUserPerformance();
  }, []);

  // Interchange Subjects Data and Systems Data
  useMemo(() => {
    if (userPerformanceData) {
      if (reportDataType === REPORTS_DATA_TYPE["SUBJECTS"]) {
        const sorted = sortReports(
          userPerformanceData.category,
          orderBy,
          isAsc
        );
        setReportsDataArr(sorted);
      } else {
        if (systemsData) {
          const sorted = sortReports(systemsData, orderBy, isAsc);
          setReportsDataArr(sorted);
        } else {
          const parsedSystemsData = parseSystemPerformanceReport(
            userPerformanceData
          );
          const sorted = sortReports(parsedSystemsData, orderBy, isAsc);
          setReportsDataArr(sorted);
          //  For memomization
          setSystemsData(parsedSystemsData);
        }
      }
    }
  }, [userPerformanceData, reportDataType, orderBy, isAsc]);

  if (!userPerformanceData || !reportsDataArr) return null;

  const subjectsNameArr = userPerformanceData.category.map(data => data.name);

  return (
    <Paper elevation={0} className={classes.root}>
      <AntTabs
        className={classes.tabs}
        value={tab}
        onChange={handleChangeTab}
        aria-label="ant example"
      >
        <AntTab
          label="Subjects"
          onClick={() => setReportDataType(REPORTS_DATA_TYPE["SUBJECTS"])}
        />
        <AntTab
          label="Systems"
          onClick={() => setReportDataType(REPORTS_DATA_TYPE["SYSTEMS"])}
        />
      </AntTabs>
      <div className={classes.showContainer}>
        <Grid container spacing={3} direction={smDown ? "column" : "row"}>
          <Grid item>
            {/* Bar Type Filter (Score/QBank Usage) Start Here */}
            <FilterMenu
              handleOpenMenu={handleOpenBarTypeMenu}
              handleCloseMenu={handleCloseBarTypeMenu}
              anchorEl={anchorElBarTypeMenu}
              menuItemArr={barTypeArr}
              text={renderSelectedBarType()}
              selectedFilter={barType}
              handleSelectedFilter={handleBarType}
              filterType={"STRING"}
              showCheckBox={false}
            />
            {/* Bar Type Filter (Score/QBank Usage) End Here */}
          </Grid>
          <Grid item>
            {/* Bars Filter (Correct/Incorrect/Omitted) Start Here */}
            {barType === REPORTS_BAR_TYPE["SCORE"] && (
              <FilterMenu
                handleOpenMenu={handleOpenShowBarMenu}
                handleCloseMenu={handleCloseShowBarMenu}
                anchorEl={anchorElBarsMenu}
                menuItemArr={showBarsArr}
                text={renderSelectedBars()}
                selectedFilter={showBarsList}
                handleSelectedFilter={handleShowBarsList}
                filterType={"ARRAY"}
                displayShowText={false}
              />
            )}
            {/* Bars Filter (Correct/Incorrect/Omitted) End Here */}
          </Grid>
        </Grid>
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {reportDataType === REPORTS_DATA_TYPE["SUBJECTS"] && (
                <CustomTableCell>
                  {expandAll ? (
                    <RemoveIcon onClick={toggleExpandAll} />
                  ) : (
                    <AddIcon onClick={toggleExpandAll} />
                  )}
                </CustomTableCell>
              )}
              {headCells.map(headCell => {
                return (
                  <CustomTableCell
                    key={headCell.id}
                    padding={"normal"}
                    sortDirection={isAsc ? "asc" : "desc"}
                  >
                    <CustomTableSortLabel
                      active={orderBy === headCell.id}
                      direction={isAsc ? "asc" : "desc"}
                      onClick={() => onSortReports(headCell.id)}
                    >
                      {headCell.label}
                    </CustomTableSortLabel>
                  </CustomTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {reportsDataArr &&
              reportsDataArr.length > 0 &&
              reportsDataArr.map((reportsData, index) => {
                const {
                  rank,
                  correctPercentStr,
                  incorrectPercentStr,
                  omittedPercentStr,
                  usagePercentStr,
                } = parseReportDetail(reportsData);

                return (
                  <Fragment key={reportsData._id}>
                    <HoverSelectedTableRow
                      hover
                      selected={expandedList.includes(reportsData.name)}
                      onClick={() => onClickAccordion(reportsData.name)}
                    >
                      {reportDataType === REPORTS_DATA_TYPE["SUBJECTS"] && (
                        <SubjectsRowCell
                          style={{
                            width: "5%",
                          }}
                        >
                          {expandedList.includes(reportsData.name) ? (
                            <RemoveIcon />
                          ) : (
                            <AddIcon />
                          )}
                        </SubjectsRowCell>
                      )}
                      <SubjectsRowCell
                        style={{
                          width: "30%",
                        }}
                      >
                        <div style={{ marginBottom: 12 }}>
                          {reportsData.name}
                        </div>

                        {barType === REPORTS_BAR_TYPE["SCORE"] ? (
                          <ScoreBar
                            correctWidth={
                              showBarsList.includes(QUESTION_STATUS["CORRECT"])
                                ? correctPercentStr
                                : "0%"
                            }
                            incorrectWidth={
                              showBarsList.includes(
                                QUESTION_STATUS["INCORRECT"]
                              )
                                ? incorrectPercentStr
                                : "0%"
                            }
                            omittedWidth={
                              showBarsList.includes(QUESTION_STATUS["OMITTED"])
                                ? omittedPercentStr
                                : "0%"
                            }
                          />
                        ) : (
                          <ProgressBar progressWidth={usagePercentStr} />
                        )}
                      </SubjectsRowCell>
                      <SubjectsRowCell>{reportsData.usage}</SubjectsRowCell>
                      <SubjectsRowCell>{`${reportsData.firstAttemptCorrect} (${correctPercentStr})`}</SubjectsRowCell>
                      <SubjectsRowCell>{`${reportsData.firstAttemptIncorrect} (${incorrectPercentStr})`}</SubjectsRowCell>
                      <SubjectsRowCell>{`${reportsData.firstAttemptOmitted} (${omittedPercentStr})`}</SubjectsRowCell>
                      <SubjectsRowCell>{rank}</SubjectsRowCell>
                    </HoverSelectedTableRow>

                    {reportDataType === REPORTS_DATA_TYPE["SUBJECTS"] &&
                      expandedList.includes(reportsData.name) &&
                      Boolean(reportsData.subCategory) &&
                      Boolean(reportsData.subCategory.length) &&
                      reportsData.subCategory.map(systemData => {
                        const {
                          correctPercentStr,
                          incorrectPercentStr,
                          omittedPercentStr,
                        } = parseReportDetail(systemData);
                        return (
                          <TableRow key={systemData._id}>
                            <SystemsRowCell></SystemsRowCell>
                            <SystemsRowCell>{systemData.name}</SystemsRowCell>
                            <SystemsRowCell>{systemData.usage}</SystemsRowCell>
                            <SystemsRowCell>{`${systemData.firstAttemptCorrect} (${correctPercentStr})`}</SystemsRowCell>
                            <SystemsRowCell>{`${systemData.firstAttemptIncorrect} (${incorrectPercentStr})`}</SystemsRowCell>
                            <SystemsRowCell>{`${systemData.firstAttemptOmitted} (${omittedPercentStr})`}</SystemsRowCell>
                            <SystemsRowCell>{"-"}</SystemsRowCell>
                          </TableRow>
                        );
                      })}
                  </Fragment>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getUserPerformance: () => dispatch(AnalyticsActions.userPerformance()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
