import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";

import * as ExamActions from "../../../actions/examAction";

const ImportQuestion = ({ importQuestion }) => {
  const handleSubmit = async e => {
    e.preventDefault();

    const data = await importQuestion(e.target.file.files[0]);
  };

  return (
    <Fragment>
      <div className="pageCont importQuestionPage animated fadeInRight">
        <div className="bodyCont">
          <div className="pageContent">
            <h4 className="pageTitle hiddenWeb hiddenTablet">
              Import Questions
            </h4>
            <h3 className="pageTitle hiddenMobile">Import Questions</h3>
            <div className="sectionCont">
              <form onSubmit={handleSubmit} className="importForm">
                <input
                  name="file"
                  type="file"
                  accept=".xlsx,.xls"
                  className="importFormInput"
                />
                <Button
                  type="contained"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className="importFormSubmit"
                >
                  Import
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  importQuestion: file => dispatch(ExamActions.importQuestion(file)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ImportQuestion)
);
