import { Box } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../../../actions/cartAction";
import { FREE_TRIAL_PRICE_PLAN } from "../../../constants";
import { PRICING_URL } from "../../../routes/constants";
import history from "../../../routes/history";
import { selectCartItems } from "../../../selectors/cart";
import useStyles from "../styles";

const FreeTrialLink = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);

  const handleAddFreeTrialPlan = () => {
    if (Boolean(cartItems.length)) {
      dispatch(removeFromCart());
    }
    dispatch(addToCart(FREE_TRIAL_PRICE_PLAN));
    history.push(PRICING_URL);
  };

  return (
    <Box className={classes.notAUserYetLinkContainer}>
      <Box className="clickable" onClick={handleAddFreeTrialPlan}>
        Not a user yet? Click here for free trial
      </Box>
    </Box>
  );
};

export default FreeTrialLink;
