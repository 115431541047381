import * as ActionTypes from "../actions/userAction";
import { REQUEST_STATUS } from "../constants";

const {
  USER_LOGIN,
  USER_LOGOUT,
  GET_USER_LIST,
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  CHANGE_PASSWORD,
  CHECK_EMAIL,
  SET_EMAIL_AVAILABILITY,
  FORGOT_PASSWORD,
  SET_FORGOT_PASSWORD_STATUS,
} = ActionTypes;

const initialStatus = {
  changePasswordStatus: REQUEST_STATUS.UNINITIALIZED,
  checkEmailStatus: REQUEST_STATUS.UNINITIALIZED,
  forgotPasswordStatus: REQUEST_STATUS.UNINITIALIZED,
};

export const initialState = {
  // Admin
  users: [],
  // Global User
  authToken: null,
  type: null,
  userInfo: null,
  // Profile
  status: initialStatus,
  emailAvailability: null,
  // Login
  loginErrorMsg: "",
};

const UserReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_LOGIN.AWAIT: {
      return {
        ...state,
        loginErrorMsg: "",
      };
    }
    case USER_LOGIN.SUCCESS: {
      return {
        ...state,
        authToken: payload.accessToken,
        type: payload.type,
      };
    }
    case USER_LOGIN.FAIL: {
      return {
        ...state,
        loginErrorMsg: payload.loginErrorMsg,
      };
    }
    case UPDATE_USER_PROFILE: {
      return {
        ...state,
        ...action.data,
      };
    }
    case GET_USER_PROFILE.AWAIT:
    case UPDATE_USER_PROFILE.AWAIT:
    case GET_USER_PROFILE.FAIL:
    case UPDATE_USER_PROFILE.FAIL:
      return { ...state };
    case GET_USER_LIST.SUCCESS:
      return {
        ...state,
        users: payload,
      };
    case GET_USER_PROFILE.SUCCESS:
    case UPDATE_USER_PROFILE.SUCCESS:
      return {
        ...state,
        userInfo: payload,
      };
    case CHANGE_PASSWORD.AWAIT: {
      return {
        ...state,
        status: {
          ...state.status,
          changePasswordStatus: REQUEST_STATUS.LOADING,
        },
      };
    }
    case CHANGE_PASSWORD.SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          changePasswordStatus: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case CHANGE_PASSWORD.FAIL: {
      return {
        ...state,
        status: { ...state.status, changePasswordStatus: REQUEST_STATUS.FAIL },
      };
    }
    case CHECK_EMAIL.AWAIT: {
      return {
        ...state,
        status: {
          ...state.status,
          checkEmailStatus: REQUEST_STATUS.LOADING,
        },
      };
    }
    case CHECK_EMAIL.SUCCESS: {
      return {
        ...state,
        status: { ...state.status, checkEmailStatus: REQUEST_STATUS.SUCCESS },
        emailAvailability: payload,
      };
    }
    case CHECK_EMAIL.FAIL: {
      return {
        ...state,
        status: { ...state.status, checkEmailStatus: REQUEST_STATUS.FAIL },
      };
    }
    case SET_EMAIL_AVAILABILITY: {
      return {
        ...state,
        emailAvailability: payload,
      };
    }
    case FORGOT_PASSWORD.AWAIT: {
      return {
        ...state,
        status: {
          ...state.status,
          forgotPasswordStatus: REQUEST_STATUS.LOADING,
        },
      };
    }
    case FORGOT_PASSWORD.SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          forgotPasswordStatus: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case FORGOT_PASSWORD.FAIL: {
      return {
        ...state,
        status: { ...state.status, forgotPasswordStatus: REQUEST_STATUS.FAIL },
      };
    }
    case SET_FORGOT_PASSWORD_STATUS: {
      return {
        ...state,
        status: { ...state.status, forgotPasswordStatus: payload },
      };
    }
    case USER_LOGOUT: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default UserReducer;
