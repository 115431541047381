import { NOTE_ORDER_BY_TYPE } from "../../constants";

export const orderByArr = [
  {
    name: NOTE_ORDER_BY_TYPE["QUESTION_ID"],
    label: "Question ID",
  },
  {
    name: NOTE_ORDER_BY_TYPE["SUBJECT"],
    label: "Subject",
  },
  {
    name: NOTE_ORDER_BY_TYPE["SYSTEM"],
    label: "System",
  },
];
