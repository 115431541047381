import { uniqBy } from "lodash";

const getCategorizedCount = (questionCount, currentQuestion, filterFunc) => {
  let selectedQuestionCount = questionCount[currentQuestion];
  if (filterFunc) {
    selectedQuestionCount = selectedQuestionCount.filter(filterFunc);
  }
  if (
    Array.isArray(selectedQuestionCount) &&
    selectedQuestionCount.length > 0
  ) {
    const total = selectedQuestionCount.reduce(
      (acc, curr) => parseInt(acc) + parseInt(curr.total),
      0
    );

    return total;
  }

  return 0;
};

const getUniqueSystemChildrenDocs = subjects => {
  let childrenDocs = [];
  subjects.map(subject => {
    subject.children.map(child => {
      childrenDocs.push(child);
    });
  });
  return uniqBy(childrenDocs, "_id");
};

export { getCategorizedCount, getUniqueSystemChildrenDocs };
