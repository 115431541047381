export const DATE_RANGE = {
  LAST_7_DAYS: "LAST_7_DAYS",
  LAST_30_DAYS: "LAST_30_DAYS",
  LAST_90_DAYS: "LAST_90_DAYS",
  LAST_YEAR: "LAST_YEAR",
  ALL_TIME: "ALL_TIME",
};

export const dateRangeArr = [
  {
    label: "Last 7 Days",
    option: DATE_RANGE["LAST_7_DAYS"],
  },
  {
    label: "Last 30 Days",
    option: DATE_RANGE["LAST_30_DAYS"],
  },
  {
    label: "Last 90 Days",
    option: DATE_RANGE["LAST_90_DAYS"],
  },
  {
    label: "Last Year",
    option: DATE_RANGE["LAST_YEAR"],
  },
  {
    label: "All Time",
    option: DATE_RANGE["ALL_TIME"],
  },
];
