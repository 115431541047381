import * as UIActions from "./uiAction";
import * as AnalyticsApi from '../services/analytics'
import { AppConfig } from '../configs'

const { client } = AppConfig

export const userPerformance = () => async (dispatch) => {
  dispatch(UIActions.appLoading());
  try {
    const res = await client.query({
      query: AnalyticsApi.userPerformance,
    })
    dispatch(UIActions.appReady());
    return res.data
  } catch (err)  {
      dispatch(UIActions.appReady());
      if (err.message) 
        dispatch(UIActions.addError(err.message))
      else if (err.graphQLErrors) 
        dispatch(UIActions.addError(err.graphQLErrors.map(item => item.message).join(". ")))
      else 
        dispatch(UIActions.addError("We are having problem getting data. Please try again later."))
  }
};