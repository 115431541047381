import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { SUBSCRIPTION_STATUS } from "../../../../constants";
import {
  activateSubscription,
  toggleActivationModal,
} from "../../../../actions/subscriptionAction";
import PrimaryConfirmationModal from "../../../../Components/Modal/PrimaryConfirmationModal";
import {
  selectActivationModalState,
  selectSubscription,
} from "../../../../selectors/subscription";
import OrderSummary from "../components/OrderSummary";
import NewUserSection from "../components/NewUserSection";
import { useForm } from "react-hook-form";
import {
  selectCartItems,
  selectIsValidToPay,
} from "../../../../selectors/cart";
import ValidPaymentChoices from "../components/ValidPaymentChoices";
import AuthUserCheckoutSection from "../components/AuthUserCheckoutSection";
import { clearPaymentConfigs } from "../../../../actions/cartAction";

const PaymentSection = props => {
  const { handleOpenRemoveModal, handleBack } = props;
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);
  const { authToken } = useSelector(state => state.UserStore);
  const { open, msg } = useSelector(selectActivationModalState);
  const subscription = useSelector(selectSubscription);
  const isValidToPay = useSelector(selectIsValidToPay);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      city: "",
      country: "",
      state: "",
      zipCode: "",
      phoneNo: "",
      educationalGroup: "",
      careerStatus: "",
      graduatingSchool: "",
    },
  });

  const handleCloseActivationModal = () => {
    dispatch(
      toggleActivationModal({
        open: false,
        msg,
      })
    );
  };

  const handleActivateSubscriptionPlan = () => {
    const inactivePlan = subscription._planHistory.find(
      p => p.status === SUBSCRIPTION_STATUS.INACTIVE
    );

    dispatch(activateSubscription(subscription._id, inactivePlan._id));
    dispatch(toggleActivationModal({ open: false, msg: "" }));
  };

  useEffect(() => {
    if (!Boolean(cartItems.length)) {
      handleBack();
    }

    return () => {
      dispatch(clearPaymentConfigs());
    };
  }, [cartItems, handleBack]);

  return (
    <>
      <PrimaryConfirmationModal
        open={open}
        onClose={handleCloseActivationModal}
        actionFn={handleActivateSubscriptionPlan}
        ctaTitle={msg}
        ctaTxt={"Confirm"}
      />
      <Grid
        direction={lgUp ? "row" : "column"}
        justify="space-between"
        container
        spacing={4}
      >
        <Grid item xs={12} sm={lgUp ? 6 : 12}>
          {isValidToPay && authToken ? (
            <ValidPaymentChoices />
          ) : authToken ? (
            <AuthUserCheckoutSection methods={methods} />
          ) : (
            <NewUserSection />
          )}
        </Grid>
        <Grid item xs={12} sm={lgUp ? 6 : 12}>
          <OrderSummary
            cartItems={cartItems}
            handleOpenRemoveModal={handleOpenRemoveModal}
            {...methods}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentSection;
