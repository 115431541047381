import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import styles from "./styles";
import {
  CustomDialog,
  CustomDialogTitle,
  CustomDialogContent,
  CustomConfirmDialogActions,
  CustomActionBtn,
  CustomCancelBtn,
} from "../../../Components/CustomDialogElements";
import FlashcardForm from "../FlashcardForm";
import FlashcardView from "../FlashcardView";
import { FLASHCARD_MODE_TYPE, FLASHCARD_LIST_TYPE } from "../../../constants";

const useStyles = makeStyles(theme => styles(theme));

const FlashcardModal = props => {
  const {
    openModalState,
    setOpenModalState,
    handleCloseFlashcardModal,
    currentFlashcard,
    setCurrentFlashcard,
    parsedFlashcardsData,
    searchedFlashcardsData,
    onCreateFlashcard,
    onUpdateFlashcard,
    onRemoveFlashcard,
  } = props;
  const classes = useStyles();

  const [showFrontView, setShowFrontView] = useState(true);
  const [currentPagination, setCurrentPagination] = useState(null);
  const [isUnsavedForm, setIsUnsavedForm] = useState(null);
  const [showUnsavedWarningModal, setShowUnsavedWarningModal] = useState(false);
  const [shouldCloseModal, setShouldCloseModal] = useState(null);

  // Handle Confirmtion Modal Start
  const handleCloseUnsavedWarningModal = () => {
    setShowUnsavedWarningModal(false);
  };

  const handleOpenUnsavedWarningModal = () => {
    setShowUnsavedWarningModal(true);
  };

  const onClickUnsavedModalYesBtn = () => {
    if (shouldCloseModal) {
      // Reset shouldCloseModal state
      setShouldCloseModal(false);
      onCloseFlashcardModal();
    } else {
      if (
        openModalState.mode === FLASHCARD_MODE_TYPE["CREATE"] ||
        openModalState.mode === FLASHCARD_MODE_TYPE["CREATE_WITH_ELEMENT"]
      ) {
        goToMainMode();
      } else if (openModalState.mode === FLASHCARD_MODE_TYPE["EDIT"]) {
        goBackFromEditMode();
      } else if (
        openModalState.mode === FLASHCARD_MODE_TYPE["INSERT_WITH_ELEMENT"]
      ) {
        onCloseFlashcardModal();
      }
    }

    handleCloseUnsavedWarningModal();
  };
  // Handle Confirmtion Modal End

  // Handle Modal Start
  const goBackFromEditMode = () => {
    if (openModalState.fromMainPage) {
      onCloseFlashcardModal();
    } else {
      setOpenModalState({
        ...openModalState,
        show: true,
        mode: FLASHCARD_MODE_TYPE["VIEW"],
      });
    }
  };

  const goToMainMode = () => {
    setOpenModalState({ mode: FLASHCARD_MODE_TYPE["MAIN"] });
    setShowFrontView(true);
  };

  const onClickCancelBtnInCreateCase = () => {
    if (isUnsavedForm) {
      handleOpenUnsavedWarningModal();
      return;
    }
    goToMainMode();
  };

  const onClickCancelBtnInEditCase = () => {
    if (isUnsavedForm) {
      handleOpenUnsavedWarningModal();
      return;
    }
    goBackFromEditMode();
  };

  const isEditingForm = () => {
    return (
      openModalState.mode === FLASHCARD_MODE_TYPE["EDIT"] ||
      openModalState.mode === FLASHCARD_MODE_TYPE["CREATE"]
    );
  };

  const onClickCloseBtn = () => {
    setShouldCloseModal(true);
    if (isEditingForm()) {
      if (isUnsavedForm) {
        handleOpenUnsavedWarningModal();
        return;
      }
    }
    onCloseFlashcardModal();
  };

  const onCloseFlashcardModal = () => {
    handleCloseFlashcardModal();
    setShowFrontView(true);
  };
  // Handle Modal End

  useEffect(() => {
    if (openModalState.mode === FLASHCARD_MODE_TYPE["VIEW"]) {
      let index;
      if (openModalState.listType === FLASHCARD_LIST_TYPE["SEARCH"]) {
        index = searchedFlashcardsData.findIndex(
          flashcard => flashcard._id === currentFlashcard._id
        );
      } else {
        index = parsedFlashcardsData.findIndex(
          flashcard => flashcard._id === currentFlashcard._id
        );
      }

      setCurrentPagination(index);
    }
  }, [openModalState]);

  return (
    <Fragment>
      {/* Unsaved Warning Modal */}
      <Dialog
        onClose={handleCloseUnsavedWarningModal}
        open={showUnsavedWarningModal}
      >
        <DialogContent>
          Flashcard not saved. Any unsaved changes will be lost. Do you really
          wish to cancel the changes?
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomActionBtn onClick={onClickUnsavedModalYesBtn}>
            Yes
          </CustomActionBtn>
          <CustomCancelBtn onClick={handleCloseUnsavedWarningModal}>
            No
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>

      {/* Flashcard Modal */}
      <CustomDialog
        onClose={onClickCloseBtn}
        open={openModalState.show}
        className="noselect"
      >
        <CustomDialogTitle onClose={onClickCloseBtn}>
          Flashcards
        </CustomDialogTitle>
        <CustomDialogContent className={classes.dialogContentCont}>
          {openModalState.mode === FLASHCARD_MODE_TYPE["CREATE"] ? (
            <FlashcardForm
              mode={openModalState.mode}
              onCreateFlashcard={onCreateFlashcard}
              onClickCancelBtnHandler={onClickCancelBtnInCreateCase}
              handleGoBack={onCloseFlashcardModal}
              setIsUnsavedForm={setIsUnsavedForm}
            />
          ) : openModalState.mode === FLASHCARD_MODE_TYPE["EDIT"] ? (
            <FlashcardForm
              flashcard={currentFlashcard}
              mode={openModalState.mode}
              onUpdateFlashcard={onUpdateFlashcard}
              onClickCancelBtnHandler={onClickCancelBtnInEditCase}
              handleGoBack={goBackFromEditMode}
              setIsUnsavedForm={setIsUnsavedForm}
            />
          ) : openModalState.mode === FLASHCARD_MODE_TYPE["VIEW"] ? (
            <FlashcardView
              parsedFlashcardsData={
                openModalState.listType === FLASHCARD_LIST_TYPE["SEARCH"]
                  ? searchedFlashcardsData
                  : parsedFlashcardsData
              }
              currentFlashcard={currentFlashcard}
              setCurrentFlashcard={setCurrentFlashcard}
              currentPagination={currentPagination}
              setCurrentPagination={setCurrentPagination}
              showFrontView={showFrontView}
              setShowFrontView={setShowFrontView}
              openModalState={openModalState}
              setOpenModalState={setOpenModalState}
              handleGoBack={onCloseFlashcardModal}
              onRemoveFlashcard={onRemoveFlashcard}
            />
          ) : null}
        </CustomDialogContent>
      </CustomDialog>
    </Fragment>
  );
};

export default FlashcardModal;
