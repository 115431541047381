import { gql } from "@apollo/client";

export const GET_TRANSACTIONS = gql`
  query Transactions($own: Boolean) {
    transactions(own: $own) {
      _id
      _ref
      _createdOn
      _updatedOn
      _expiredOn
      _refundedOn
      order_id
      status
      transaction_fee
      term {
        _id
        price
        term
        unit
        title
        resetQuota
        renewedResetQuota
      }
      total_price
      discount
      payer_id
      payer {
        _id
        firstName
        lastName
        email
      }
      source
    }
  }
`;

export const GET_TRANSACTION = gql`
  query Transaction($_id: ObjectId!) {
    transaction(_id: $_id) {
      _id
      _ref
      _createdOn
      _updatedOn
      _expiredOn
      _refundedOn
      order_id
      status
      transaction_fee
      term {
        _id
        price
        term
        unit
        title
        resetQuota
        renewedResetQuota
      }
      total_price
      discount
      payer_id
      payer {
        _id
        firstName
        lastName
        email
      }
      source
    }
  }
`;

export const UPDATE_TRANSACTION = gql`
  mutation UpdateTransaction($_id: ObjectId!, $input: UpdateTransactionInput!) {
    updateTransaction(_id: $_id, input: $input) {
      _id
      _ref
      _createdOn
      _updatedOn
      _expiredOn
      _refundedOn
      order_id
      status
      transaction_fee
      term {
        _id
        price
        term
        unit
        title
        resetQuota
        renewedResetQuota
      }
      total_price
      discount
      payer_id
      payer {
        _id
        firstName
        lastName
        email
      }
      source
    }
  }
`;
