const styles = theme => ({
  root: {
    height: "100%",
    padding: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3),
    },
  },
  showContainer: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    paddingTop: "25px",
    paddingBottom: "25px",
    color: "#979797",
  },
});
export default styles;
