import { Box, Button, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { SELECT_INPUT_WIDTH, textFieldProps } from "../../helper";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ConfirmationModal from "../../../../Components/Modal/ConfirmationModal";
import useModal from "../../../../hooks/useModal";
import { PRIMARY_PLAN_ID, validateDate } from "../../../../constants";
import { assignManualSubscriptionPlan } from "../../../../actions/subscriptionAction";
import {
  dateFormatAdapter,
  formatDateWithDefaultTimeZone,
} from "../../../../utils";
import { differenceInDays } from "date-fns";
import moment from "moment";

const AssignPlanSection = ({ user }) => {
  const dispatch = useDispatch();
  const { handleSubmit, control, errors, formState } = useForm({
    mode: "onChange",
    defaultValues: {
      title: "",
      startDate: "",
      endDate: "",
    },
  });
  const { isValid } = formState;
  const [minDate, setMinDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { open, handleOpen, handleClose } = useModal();
  const validateStartDate = validateDate(startDate, "Start date");
  const validateEndDate = validateDate(endDate, "End date");

  const handleAssignSubscriptionPlan = data => {
    const assignData = {
      _id: user.subscription._id,
      input: {
        planStartOn: dateFormatAdapter(startDate),
        planEndOn: dateFormatAdapter(endDate),
        term: {
          term: differenceInDays(new Date(endDate), new Date(startDate)) + 1,
          unit: "day",
          title: data.title,
        },
        plan_id: PRIMARY_PLAN_ID,
      },
    };

    dispatch(assignManualSubscriptionPlan(assignData));
    handleClose();
  };

  useEffect(() => {
    if (user.subscription && user.subscription.subscriptionPlanEndOn) {
      // Add one day for mininum date to ignore the time restriction
      const minDateTime = moment(
        new Date(user.subscription.subscriptionPlanEndOn)
      ).add(1, "d");

      setStartDate(minDateTime);
      setMinDate(minDateTime);
    }
  }, [user]);

  return (
    <>
      <ConfirmationModal
        open={open}
        onClose={handleClose}
        actionFn={handleSubmit(handleAssignSubscriptionPlan)}
        ctaTitle={"Are you wish to assign this subscription plan to this user"}
        ctaTxt={"Assign"}
      />
      <Box display="flex" flexDirection="column">
        <Controller
          control={control}
          name="title"
          rules={{
            required: "Title cannot be empty",
          }}
          render={({ onChange, _onBlur, value, name, ref }) => (
            <TextField
              name={name}
              label="Title"
              inputRef={ref}
              value={value}
              onChange={e => onChange(e.target.value)}
              error={!!errors.title}
              helperText={errors.title && errors.title.message}
              {...textFieldProps}
            />
          )}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin="normal"
            value={startDate}
            label="Start Date"
            format="yyyy-MM-dd"
            onChange={date => {
              setStartDate(date);
            }}
            minDate={minDate}
            error={Boolean(validateStartDate)}
            helperText={validateStartDate}
            style={{ maxWidth: SELECT_INPUT_WIDTH }}
            color="secondary"
            InputLabelProps={{ shrink: true }}
          />
          <KeyboardDatePicker
            margin="normal"
            value={endDate}
            label="End Date"
            format="yyyy-MM-dd"
            onChange={date => {
              setEndDate(date);
            }}
            minDate={minDate}
            error={Boolean(validateEndDate)}
            helperText={validateEndDate}
            style={{ maxWidth: SELECT_INPUT_WIDTH }}
            color="secondary"
            InputLabelProps={{ shrink: true }}
          />
        </MuiPickersUtilsProvider>
        <Box mt={3}>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleOpen}
            disabled={
              !isValid ||
              Boolean(validateStartDate) ||
              Boolean(validateEndDate) ||
              Boolean(!user.subscription)
            }
          >
            Assign
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AssignPlanSection;
