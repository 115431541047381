import { ERROR_CODE } from "../constants";

export const validationErrorAdapter = graphQLErrors => {
  let errMsg;
  graphQLErrors.map(({ extensions }) => {
    if (
      extensions.exception &&
      extensions.exception.code === ERROR_CODE.VALIDATION_ERROR
    ) {
      extensions.exception.data.forEach(item => {
        const { constraints } = item;
        const apiErrMsg = Object.values(constraints);

        if (
          !errMsg &&
          apiErrMsg &&
          Array.isArray(apiErrMsg) &&
          apiErrMsg.length > 0
        ) {
          errMsg = apiErrMsg[0];
        }
      });
    }
  });

  return errMsg ? errMsg : "Internal Server Error";
};
