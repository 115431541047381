import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import Chip from "@material-ui/core/Chip";

import styles from "../FlashcardModal/styles";

const useStyles = makeStyles(theme => styles(theme));

const BackViewCardFooter = props => {
  const { isDarkMode, tags } = props;
  const classes = useStyles();

  return (
    <div
      className={clsx(
        isDarkMode
          ? classes.backViewCardFooterDark
          : classes.backViewCardFooterLight,
        classes.backViewCardFooter
      )}
    >
      <span>
        <LocalOfferOutlinedIcon className={classes.tagIcon} />
      </span>
      {tags &&
        tags.length > 0 &&
        tags.map(tag => (
          <Chip
            key={tag}
            size={"small"}
            label={tag}
            className={classes.tagChip}
          />
        ))}
    </div>
  );
};

export default BackViewCardFooter;
