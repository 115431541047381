import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";
import { useDispatch, useSelector } from "react-redux";
import { EMAIL_REGEX } from "../../../constants";

import PrimaryTextField from "../../PrimaryTextField";
import { PrimaryButton } from "../../CustomButton";
import useStyles from "../styles";
import { login } from "../../../actions/userAction";
import { selectUserLoginErrorMsg } from "../../../selectors/user";
import { ERROR_COLOR } from "../../../styles/constants";
import usePath from "../../../hooks/usePath";
import VisitorFormTitle from "../../../views/auth/components/VisitorFormTitle";
import FreeTrialLink from "./FreeTrialLink";
import { FORGOT_PASSWORD_URL } from "../../../routes/constants";
import history from "../../../routes/history";
import PasswordField from "../../FormField/PasswordField";

const LoginForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loginErrorMsg = useSelector(selectUserLoginErrorMsg);
  const { handleSubmit, control, errors } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const { queryParams } = usePath();
  const onSubmit = data => {
    dispatch(login(data, queryParams));
  };

  return (
    <>
      <Grid direction="column" container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <VisitorFormTitle title="Member Login" />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: "Email cannot be empty",
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Invalid Email Format",
                },
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PrimaryTextField
                  label="Email"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="password"
              rules={{ required: "Required" }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <PasswordField
                  label="Password"
                  name={name}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value}
                  inputRef={ref}
                  error={Boolean(errors.password)}
                  helperText={errors.password && errors.password.message}
                />
              )}
            />
          </Grid>

          {loginErrorMsg && (
            <Box p={1} fontSize={"0.9rem"} color={ERROR_COLOR}>
              {loginErrorMsg}
            </Box>
          )}

          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" mt={3}>
              <PrimaryButton
                color="primary"
                variant="contained"
                fullWidth
                onClick={handleSubmit(onSubmit)}
                className={classes.continueButton}
              >
                LOG IN
              </PrimaryButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.forgotPasswordCTAContainer}>
              <span
                onClick={() => {
                  history.push(FORGOT_PASSWORD_URL);
                }}
                className={"clickable"}
              >
                Forgot Username / Password?
              </span>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FreeTrialLink />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginForm;
