import React, { useState, useEffect } from "react";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Grid from "@material-ui/core/Grid";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@material-ui/icons/ArrowDropUpOutlined";
import Paper from "@material-ui/core/Paper";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import * as ExamActions from "../../../actions/examAction";
import styles from "./styles";
import { parsePerformanceByDate } from "./performanceDataParser";
import PerformanceGraph from "./PerformanceGraph";
import { dateRangeArr, DATE_RANGE } from "./dateRangeConstants";
import { CustomMenuItem } from "../../../Components/MenuElements";

const useStyles = makeStyles(theme => styles(theme));

const GraphsPage = ({ getUserExamList }) => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [userExamListData, setUserExamListData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());

  // Handle API Request Start
  // Initial fetch data function
  const fetchUserExamList = async () => {
    const res = await getUserExamList();

    if (res && res.userExams.length > 0) {
      setUserExamListData(res.userExams);
      const firstCreatedTestDate = res.userExams[0].exam._createdOn;
      const currentDate = new Date();
      confirmStartDate(firstCreatedTestDate);
      confirmEndDate(currentDate);
    } else {
      confirmStartDate(new Date());
    }
  };

  const fetchUserExamListByDateRange = async (startDT, endDT) => {
    // Confirm Date Range
    confirmStartDate(startDT);
    confirmEndDate(endDT);
    const filter = {
      fromDate: moment(startDT).format("YYYY-MM-DD"),
      toDate: moment(endDT).format("YYYY-MM-DD"),
    };
    const res = await getUserExamList(filter);
    if (res && res.userExams) {
      setUserExamListData(res.userExams);
    }
  };
  // Handle API Request End

  // Handle Date Filter Menu Start
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickSearch = async () => {
    await fetchUserExamListByDateRange(startDate, endDate);
  };
  // Handle Date Filter Menu End

  // Handle Date Value Start
  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const confirmStartDate = startDT => {
    setStartDate(startDT);
    setSelectedStartDate(startDT);
  };

  const confirmEndDate = endDT => {
    setEndDate(endDT);
    setSelectedEndDate(endDT);
  };
  // Handle Date Value End

  const handleSelectedDateRange = async option => {
    const currentDate = new Date();
    const calculatedStartDate = new Date();

    switch (option) {
      case DATE_RANGE["LAST_7_DAYS"]:
        calculatedStartDate.setDate(calculatedStartDate.getDate() - 6);
        break;
      case DATE_RANGE["LAST_30_DAYS"]:
        calculatedStartDate.setDate(calculatedStartDate.getDate() - 29);
        break;
      case DATE_RANGE["LAST_90_DAYS"]:
        calculatedStartDate.setDate(calculatedStartDate.getDate() - 89);
        break;
      case DATE_RANGE["LAST_YEAR"]:
        calculatedStartDate.setFullYear(calculatedStartDate.getFullYear() - 1);
        break;
    }

    // Eiter Fetch Data with filter Or without filter
    if (option === DATE_RANGE["ALL_TIME"]) {
      fetchUserExamList();
    } else {
      await fetchUserExamListByDateRange(calculatedStartDate, currentDate);
    }
    // Close Modal
    handleClose();
  };

  // Initial Fetch Data
  useEffect(() => {
    fetchUserExamList();
  }, []);

  if (!userExamListData) return null;

  const performanceByDateData = parsePerformanceByDate(userExamListData);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Paper elevation={0} className={classes.root}>
        <div className={classes.titleContainer}>Performance By Date</div>
        <Menu
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{
            list: classes.menuList,
            paper: classes.menuPaper,
          }}
        >
          <Grid container justify="center">
            <Grid item>
              <div className={classes.menuLeftContainer}>
                <KeyboardDatePicker
                  variant="inline"
                  format="MM/DD/YYYY"
                  margin="normal"
                  value={startDate}
                  onChange={handleStartDateChange}
                  label="Enter Start Date"
                  inputVariant="standard"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  autoOk={true}
                  InputProps={{ className: classes.dateInput }}
                  color="secondary"
                />
                <KeyboardDatePicker
                  variant="inline"
                  format="MM/DD/YYYY"
                  margin="normal"
                  label="Enter End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  autoOk={true}
                  InputProps={{ className: classes.dateInput }}
                  color="secondary"
                />
                <div className={classes.actionsBtnContainer}>
                  <div>
                    <Button
                      className={classes.searchBtn}
                      onClick={onClickSearch}
                    >
                      Search
                    </Button>
                  </div>
                  <div>
                    <Button className={classes.cancelBtn} onClick={handleClose}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Grid>

            {smDown ? (
              <Grid item xs={12}>
                <div
                  style={{
                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                    marginTop: 10,
                  }}
                ></div>
              </Grid>
            ) : (
              <Divider orientation="vertical" flexItem />
            )}

            <Grid item>
              <div className={classes.menuRightContainer}>
                {dateRangeArr.map(item => (
                  <CustomMenuItem
                    key={item.option}
                    onClick={() => handleSelectedDateRange(item.option)}
                  >
                    {item.label}
                  </CustomMenuItem>
                ))}
              </div>
            </Grid>
          </Grid>
        </Menu>
        <div className={classes.filterContainer}>
          <div className={classes.filterHeader} onClick={handleClick}>
            <div className={classes.dateRangeFilterTxt}>
              {moment(selectedStartDate).format("MMM DD, YYYY")} -{" "}
              {moment(selectedEndDate).format("MMM DD, YYYY")}
            </div>

            {Boolean(anchorEl) ? (
              <ArrowDropUpOutlinedIcon fontSize="small" />
            ) : (
              <ArrowDropDownOutlinedIcon fontSize="small" />
            )}
          </div>
        </div>
        <PerformanceGraph performanceByDateData={performanceByDateData} />
      </Paper>
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getUserExamList: filter => dispatch(ExamActions.getUserExamList(filter)),
});
export default connect(mapStateToProps, mapDispatchToProps)(GraphsPage);
