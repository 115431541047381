import { gql } from "@apollo/client";

export const GET_FEEDBACKS = gql`
  query Feedbacks($filter: StatusFilterInput) {
    feedbacks(filter: $filter) {
      _id
      content
      exam {
        _id
      }
      question {
        _id
        _ref
        question
      }
      creator {
        firstName
        lastName
      }
      status
      resolved
      technicalIssue
      _createdOn
    }
  }
`;

export const CREATE_FEEDBACK = gql`
  mutation CreateFeedback($input: CreateFeedbackInput!) {
    createFeedback(input: $input) {
      _id
      status
    }
  }
`;

export const RESOLVE_FEEDBACK = gql`
  mutation UpdateFeedback($_id: ObjectId!) {
    updateFeedback(_id: $_id, input: { resolved: true }) {
      _id
      content
      exam {
        _id
      }
      question {
        _id
        _ref
        question
      }
      creator {
        firstName
        lastName
      }
      status
      resolved
      technicalIssue
      _createdOn
    }
  }
`;

export const UNRESOLVE_FEEDBACK = gql`
  mutation UpdateFeedback($_id: ObjectId!) {
    updateFeedback(_id: $_id, input: { resolved: false }) {
      _id
      content
      exam {
        _id
      }
      question {
        _id
        _ref
        question
      }
      creator {
        firstName
        lastName
      }
      status
      resolved
      technicalIssue
      _createdOn
    }
  }
`;

export const REMOVE_FEEDBACK = gql`
  mutation RemoveFeedback($_id: ObjectId!, $permanent: Boolean) {
    removeFeedback(_id: $_id, permanent: $permanent)
  }
`;
