import React, { Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FeedbackIcon from "@material-ui/icons/Feedback";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-scroll";

import { userMenuItemArr, adminMenuItemArr } from "../SideBar/sideBarArr";
import {
  LIST_ITEM_ACTIVE_COLOR,
  LIST_ITEM_MUTE_COLOR,
  LIST_ITEM_ACTIVE_STYLE,
} from "../../styles/constants";
import { TopicDetailContext } from "../../context/topicDetail";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    flexWrap: "nowrap",
    display: props => (props.notShowMainSideBar ? "" : "none"),
  },
  icon: {
    width: "24px",
    height: "24px",
  },
  iconRoot: {
    minWidth: 0,
  },
  divider: {
    margin: 0,
    height: "100vh",
  },
  activeLinkContainer: {
    ...LIST_ITEM_ACTIVE_STYLE,
    transitionDelay: "2s",
    "& span": {
      color: `${LIST_ITEM_ACTIVE_COLOR} !important`,
    },
  },
  linkContainer: {
    color: "#333",
    minHeight: "50px",
    transition: "all 150ms ease-in-out",
    borderColor: "transparent",
    borderStyle: "solid",
    borderWidth: "1px 0px",

    "& span": {
      fontFamily: "metropolis",
      wordWrap: "break-word",
    },
    "&:hover": {
      textDecoration: "none",
      "& span": {
        color: LIST_ITEM_ACTIVE_COLOR,
      },
      backgroundColor: "rgb(248, 250, 252)",
      cursor: "pointer",
      borderColor: "rgb(238, 242, 245)",
      borderStyle: "solid",
      borderWidth: "1px 0px",
    },
  },
  subTopicListCont: {
    width: "100%",
    overflowY: props => (props.notShowMainSideBar ? "auto" : "hidden"),
  },
  subTopicList: {
    width: "100%",
    flex: 1,
  },
  topicTitle: {
    fontWeight: 700,
    padding: "8px 16px",
    wordBreak: "break-word",
  },
}));
const SubSideBar = ({ setShowMainSideBar, notShowMainSideBar }) => {
  const classes = useStyles({
    notShowMainSideBar,
  });
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const {
    topicDetail,
    activeSubtopic,
    setActiveSubtopic,
    expandedList,
    setExpandedList,
  } = useContext(TopicDetailContext);
  const userType = useSelector(state => state.UserStore.type);

  const onClickSubtopicListItem = subTitle => {
    if (!expandedList.includes(subTitle)) {
      setExpandedList([...expandedList, subTitle]);
    }
  };
  const handleSetActive = e => {
    setActiveSubtopic(e);
  };

  const openMainSideBar = () => {
    setShowMainSideBar(true);
  };

  if (!topicDetail) return null;

  return (
    <Fragment>
      <Grid container className={classes.root}>
        <div onMouseEnter={openMainSideBar} onTouchStart={openMainSideBar}>
          <List>
            {userMenuItemArr.map(list => (
              <div key={list.title}>
                <ListItem className={classes.linkContainer}>
                  <ListItemIcon classes={{ root: classes.iconRoot }}>
                    <img
                      src={list.imgPath}
                      alt={list.alt}
                      className={classes.icon}
                    />
                  </ListItemIcon>
                </ListItem>
              </div>
            ))}
            {userType === "admin" &&
              adminMenuItemArr.map(list => (
                <div className={classes.linkContainer} key={list.title}>
                  <ListItem>
                    <ListItemIcon classes={{ root: classes.iconRoot }}>
                      {list.title === "Manage Feedback" ? (
                        <FeedbackIcon htmlColor="rgb(153, 167, 179)" />
                      ) : (
                        <img
                          src={list.imgPath}
                          alt={list.alt}
                          className={classes.icon}
                        />
                      )}
                    </ListItemIcon>
                  </ListItem>
                </div>
              ))}
            <div className={classes.linkContainer}>
              <ListItem>
                <ListItemIcon classes={{ root: classes.iconRoot }}>
                  <ExitToAppRoundedIcon
                    style={{ color: LIST_ITEM_MUTE_COLOR }}
                  />
                </ListItemIcon>
              </ListItem>
            </div>
          </List>
        </div>

        <Divider className={classes.divider} orientation="vertical" flexItem />
        <div className={classes.subTopicListCont}>
          <h4 className={classes.topicTitle}>{topicDetail.title}</h4>
          <List classes={{ root: classes.subTopicList }}>
            {topicDetail.subTopics &&
              topicDetail.subTopics.length > 0 &&
              topicDetail.subTopics.map((subtopic, index) => (
                <ListItem
                  key={index}
                  selected={activeSubtopic === subtopic.subTitle}
                  classes={{
                    root: classes.linkContainer,
                    selected: classes.activeLinkContainer,
                  }}
                  component={Link}
                  to={subtopic.subTitle}
                  smooth={true}
                  isDynamic={true}
                  duration={500}
                  spy={true}
                  onSetActive={handleSetActive}
                  offset={mdUp ? -140 : -60}
                  onClick={() => onClickSubtopicListItem(subtopic.subTitle)}
                >
                  <ListItemText primary={subtopic.subTitle} />
                </ListItem>
              ))}
          </List>
        </div>
      </Grid>
    </Fragment>
  );
};

export default SubSideBar;
