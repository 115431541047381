import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    top: 12,
    left: "calc(-50% + 20px)",
    right: "calc(50% + 20px)",
    position: "absolute",
    flex: "1 1 auto",
  },
  arrow: {
    width: 12.5,
    height: 25,
    [theme.breakpoints.down("xs")]: {
      width: 10,
      height: 20,
      top: -9,
    },
    top: -12,
    left: "calc(50% - 12.5px)",
    position: "absolute",
  },
  line: {
    borderTopStyle: "solid",
    borderTopWidth: "1px",
    display: "block",
    borderColor: theme.palette.primary.light,
  },
  lineActive: {
    borderColor: theme.palette.primary.main,
  },
}));
