import * as ActionTypes from "../actions/productAction";
import * as UserTypes from "../actions/userAction";

const { PRODUCT_LIST_ADD, PRODUCT_LIST_UPDATE } = ActionTypes;
const { USER_LOGOUT } = UserTypes;
const initialAppState = {
  productList: [],
  grandTotal: 0,
};

const ProductReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case PRODUCT_LIST_ADD: {
      return {
        ...state,
        productList: [...state.productList, action.data.productList],
        grandTotal: action.data.grandTotal,
      };
    }
    case PRODUCT_LIST_UPDATE: {
      return {
        ...state,
        productList: action.data.productList,
        grandTotal: action.data.grandTotal,
      };
    }
    case USER_LOGOUT: {
      return initialAppState;
    }

    default:
      return state;
  }
};

export default ProductReducer;
