import { QUESTION_STATUS } from "../../constants";

export const headCells = [
  {
    id: "id",
    label: "ID",
  },

  {
    id: "subjects",
    label: "SUBJECTS",
  },
  {
    id: "systems",
    label: "SYSTEMS",
  },
  {
    id: "correctAnswerPercentage",
    label: "% CORRECT OTHERS",
  },

  {
    id: "timeSpent",
    label: "TIME SPENT",
  },
];

export const showRowsArr = [
  {
    name: QUESTION_STATUS["CORRECT"],
    label: QUESTION_STATUS["CORRECT"],
  },
  {
    name: QUESTION_STATUS["INCORRECT"],
    label: QUESTION_STATUS["INCORRECT"],
  },
  {
    name: QUESTION_STATUS["MARKED"],
    label: QUESTION_STATUS["MARKED"],
  },
  {
    name: QUESTION_STATUS["OMITTED"],
    label: QUESTION_STATUS["OMITTED"],
  },
];
