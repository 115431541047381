import { AppConfig } from "../configs";
import { parseUploadToSpacesParams } from "../utils";
const S3Client = AppConfig.S3Client;

export const uploadImageToDOSpace = async imgObject => {
  try {
    const params = parseUploadToSpacesParams(
      imgObject.blob,
      imgObject.filename
    );

    const data = await S3Client.upload(params, function(err, data) {
      if (err) {
        console.log(err, err.stack);
      } else {
        console.log(data);
      }
    }).promise();

    return data;
  } catch (error) {
    console.log(error);
  }
};
