export const headCells = [
  {
    id: "score",
    label: "SCORE",
  },
  {
    id: "name",
    label: "NAME",
  },
  {
    id: "date",
    label: "DATE",
  },
  {
    id: "mode",
    label: "MODE",
  },
  {
    id: "questionMode",
    label: "Q.POOL",
  },
  {
    id: "subjects",
    label: "SUBJECTS",
  },
  {
    id: "systems",
    label: "SYSTEMS",
  },
  {
    id: "qs",
    label: "#QS",
  },
  {
    id: "actions",
    label: "ACTIONS",
  },
];

export const showColumnsArr = [
  {
    name: "date",
    label: "Date",
  },
  {
    name: "mode",
    label: "Mode",
  },
  {
    name: "questionMode",
    label: "Question Pool",
  },
  {
    name: "subjects",
    label: "Subjects",
  },
  {
    name: "systems",
    label: "Systems",
  },
  {
    name: "qs",
    label: "Number of Questions",
  },
];
