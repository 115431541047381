import { Button, MenuItem, TextField } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";

import { MANAGE_TRANSACTION_URL } from "../../../routes/constants";
import {
  getTransactionById,
  updateTransaction,
} from "../../../actions/transactionAction";
import { TRANSACTION_SOURCE_LIST, TRANSACTION_STATUS_LIST } from "./constants";
import FormContainer from "../../../Components/Layout/FormContainer";
import useStyles from "./styles";
import { selectTransactionForm } from "../../../selectors/transaction";

const TransactionForm = () => {
  const classes = useStyles();
  const { handleSubmit, control, reset, errors } = useForm({
    mode: "onChange",
    defaultValues: {
      status: "",
      termTitle: "",
      termPrice: 0,
      transactionFee: 0,
      discount: 0,
      totalPrice: 0,
      source: "",
    },
  });
  const dispatch = useDispatch();

  const params = useParams();
  const transactionId = params.id;
  const form = useSelector(selectTransactionForm);

  useEffect(() => {
    const fetchTransactionById = id => {
      if (!id) return;
      const isForm = true;
      dispatch(getTransactionById(id, isForm));
    };

    fetchTransactionById(transactionId);
  }, [transactionId, dispatch, getTransactionById]);

  useEffect(() => {
    if (form) {
      reset({
        status: form.status || "",
        termTitle: form.term.title || "",
        termPrice: form.term.price || 0,
        transactionFee: form.transaction_fee || 0,
        discount: form.discount || 0,
        totalPrice: form.total_price || 0,
        source: form.source || "",
      });
    }
  }, [form]);

  const onSubmitUpdate = ({
    status,
    termTitle,
    termPrice,
    transactionFee,
    discount,
    totalPrice,
    source,
  }) => {
    const data = {
      _id: transactionId,
      input: {
        status,
        term: {
          ...form.term,
          __typename: undefined,
          title: termTitle,
          price: Number(termPrice),
        },
        transaction_fee: Number(transactionFee),
        discount: Number(discount),
        total_price: Number(totalPrice),
        source,
      },
    };
    dispatch(updateTransaction(data));
  };

  const textFieldProps = {
    InputLabelProps: {
      style: { fontSize: 15, color: "rgba(0, 0, 0, 0.25)" },
    },
    FormHelperTextProps: {
      style: { fontSize: 13 },
    },
    color: "secondary",
    fullWidth: true,
  };

  return (
    <FormContainer>
      <Link to={MANAGE_TRANSACTION_URL}>
        <FontAwesomeIcon icon={faReply} />
        Back to transaction list
      </Link>

      <div>
        <h3>Update Transaction</h3>
        <form onSubmit={handleSubmit(onSubmitUpdate)}>
          <Controller
            control={control}
            name="status"
            rules={{
              required: "Status cannot be empty",
            }}
            render={({ onChange, onBlur, value, name, ref }) => (
              <TextField
                name={name}
                label="Status"
                inputRef={ref}
                value={value}
                onChange={e => onChange(e.target.value)}
                error={!!errors.status}
                helperText={errors.status && errors.status.message}
                select
                {...textFieldProps}
              >
                <MenuItem value={null} disabled>
                  None
                </MenuItem>
                {TRANSACTION_STATUS_LIST.map(option => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            control={control}
            name="termTitle"
            rules={{
              required: "Membership cannot be empty",
            }}
            render={({ onChange, onBlur, value, name, ref }) => (
              <TextField
                name={name}
                label="Membership"
                inputRef={ref}
                value={value}
                onChange={e => onChange(e.target.value)}
                error={!!errors.termTitle}
                helperText={errors.termTitle && errors.termTitle.message}
                {...textFieldProps}
              />
            )}
          />
          <Controller
            control={control}
            name="source"
            rules={{
              required: "Gateway cannot be empty",
            }}
            render={({ onChange, onBlur, value, name, ref }) => (
              <TextField
                name={name}
                label="Gateway"
                inputRef={ref}
                value={value}
                onChange={e => onChange(e.target.value)}
                error={!!errors.source}
                helperText={errors.source && errors.source.message}
                select
                {...textFieldProps}
              >
                <MenuItem value={null} disabled>
                  None
                </MenuItem>
                {TRANSACTION_SOURCE_LIST.map(option => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            control={control}
            name="termPrice"
            rules={{
              required: "Price cannot be empty",
            }}
            render={({ onChange, onBlur, value, name, ref }) => (
              <TextField
                name={name}
                label="Price"
                inputRef={ref}
                value={value}
                onChange={e => onChange(e.target.value)}
                error={!!errors.termPrice}
                helperText={errors.termPrice && errors.termPrice.message}
                variant="filled"
                type="number"
                {...textFieldProps}
                InputProps={{
                  classes: { ...classes, formControlLabel: undefined },
                  style: { fontSize: 15 },
                }}
                InputLabelProps={{
                  classes: {
                    formControl: classes.formControlLabel,
                  },
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="transactionFee"
            rules={{
              required: "Transaction Fee cannot be empty",
            }}
            render={({ onChange, onBlur, value, name, ref }) => (
              <TextField
                name={name}
                label="Transaction Fee"
                inputRef={ref}
                value={value}
                onChange={e => onChange(e.target.value)}
                error={!!errors.transactionFee}
                helperText={
                  errors.transactionFee && errors.transactionFee.message
                }
                variant="filled"
                type="number"
                {...textFieldProps}
                InputProps={{
                  classes: { ...classes, formControlLabel: undefined },
                  style: { fontSize: 15 },
                }}
                InputLabelProps={{
                  classes: {
                    formControl: classes.formControlLabel,
                  },
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="discount"
            rules={{
              required: "Discount cannot be empty",
            }}
            render={({ onChange, onBlur, value, name, ref }) => (
              <TextField
                name={name}
                label="Discount"
                inputRef={ref}
                value={value}
                onChange={e => onChange(e.target.value)}
                error={!!errors.discount}
                helperText={errors.discount && errors.discount.message}
                variant="filled"
                type="number"
                {...textFieldProps}
                InputProps={{
                  classes: { ...classes, formControlLabel: undefined },
                  style: { fontSize: 15 },
                }}
                InputLabelProps={{
                  classes: {
                    formControl: classes.formControlLabel,
                  },
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="totalPrice"
            rules={{
              required: "Total Price cannot be empty",
            }}
            render={({ onChange, onBlur, value, name, ref }) => (
              <TextField
                name={name}
                label="Total Price"
                inputRef={ref}
                value={value}
                onChange={e => onChange(e.target.value)}
                error={!!errors.totalPrice}
                helperText={errors.totalPrice && errors.totalPrice.message}
                variant="filled"
                type="number"
                {...textFieldProps}
                InputProps={{
                  classes: { ...classes, formControlLabel: undefined },
                  style: { fontSize: 15 },
                }}
                InputLabelProps={{
                  classes: {
                    formControl: classes.formControlLabel,
                  },
                }}
              />
            )}
          />
        </form>
      </div>
      <Button
        onClick={handleSubmit(onSubmitUpdate)}
        color="secondary"
        variant="contained"
        className="submitButton"
        size="large"
        style={{ marginTop: 40 }}
      >
        Update
      </Button>
    </FormContainer>
  );
};

export default TransactionForm;
