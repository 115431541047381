import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearCart } from "../../../actions/cartAction";
import { PrimaryButton } from "../../../Components/CustomButton";
import usePath from "../../../hooks/usePath";
import { SUBSCRIPTIONS_URL } from "../../../routes/constants";
import history from "../../../routes/history";

const OrderSuccessPage = () => {
  const dispatch = useDispatch();
  const navigateToSubscriptionsPage = () => {
    history.push(SUBSCRIPTIONS_URL);
  };
  const { queryParams } = usePath();

  useEffect(() => {
    if (queryParams && queryParams["sessionId"]) {
      dispatch(clearCart());
    }
  }, [queryParams]);

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <Box component={"h1"} mb={3}>
        Thank you for completing your order
      </Box>
      <Box component={"p"} mb={3}>
        We are currently processing your order and will send you a confirmation
        email shortly
      </Box>
      <PrimaryButton onClick={navigateToSubscriptionsPage}>
        Back to Subscriptions Page
      </PrimaryButton>
    </Box>
  );
};

export default OrderSuccessPage;
