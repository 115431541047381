import * as UIActions from "./uiAction";
import * as VoucherApi from "../services/voucher";
import { AppConfig } from "../configs";
import { handleAxiosError } from "./errorAction";
import history from "../routes/history";
import { MANAGE_DISCOUNT_CODE_URL } from "../routes/constants";
import { DELETED_STATUS_FILTER } from "../views/admin/DeletedItem/constants";

const { client } = AppConfig;

const MODULE_NAME = "VOUCHER";

export const GET_VOUCHER_LIST = {
  AWAIT: `${MODULE_NAME}/LIST/AWAIT`,
  SUCCESS: `${MODULE_NAME}/LIST/SUCCESS`,
  FAIL: `${MODULE_NAME}/LIST/FAIL`,
};

export const GET_VOUCHER_BY_ID = {
  AWAIT: `${MODULE_NAME}/FORM_BY_ID/AWAIT`,
  SUCCESS: `${MODULE_NAME}/FORM_BY_ID/SUCCESS`,
  FAIL: `${MODULE_NAME}/FORM_BY_ID/FAIL`,
};
export const CREATE_VOUCHER = {
  AWAIT: `${MODULE_NAME}/CREATE/AWAIT`,
  SUCCESS: `${MODULE_NAME}/CREATE/SUCCESS`,
  FAIL: `${MODULE_NAME}/CREATE/FAIL`,
};
export const UPDATE_VOUCHER = {
  AWAIT: `${MODULE_NAME}/UPDATE/AWAIT`,
  SUCCESS: `${MODULE_NAME}/UPDATE/SUCCESS`,
  FAIL: `${MODULE_NAME}/UPDATE/FAIL`,
};
export const REMOVE_VOUCHER = {
  AWAIT: `${MODULE_NAME}/REMOVE/AWAIT`,
  SUCCESS: `${MODULE_NAME}/REMOVE/SUCCESS`,
  FAIL: `${MODULE_NAME}/REMOVE/FAIL`,
};
export const CHECK_VOUCHER = {
  AWAIT: `${MODULE_NAME}/CHECK/AWAIT`,
  SUCCESS: `${MODULE_NAME}/CHECK/SUCCESS`,
  FAIL: `${MODULE_NAME}/CHECK/FAIL`,
};
export const SET_VOUCHER_AVAILABILITY = `${MODULE_NAME}/SET_VOUCHER_AVAILABILITY`;

export const getVoucherList = filter => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({
    type: GET_VOUCHER_LIST.AWAIT,
  });
  try {
    const res = await client.query({
      query: VoucherApi.GET_VOUCHERS,
      variables: { filter },
    });
    if (res && res.data && res.data.vouchers) {
      dispatch({
        type: GET_VOUCHER_LIST.SUCCESS,
        payload: res.data.vouchers,
      });
    }
    dispatch(UIActions.appReady());
    return res.data;
  } catch (err) {
    handleAxiosError(
      dispatch,
      err,
      "We are having problem getting discount codes. Please try again later."
    );
    dispatch({
      type: GET_VOUCHER_LIST.FAIL,
    });
  }
};

export const getVoucherById = id => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({
    type: GET_VOUCHER_BY_ID.AWAIT,
  });

  try {
    const res = await client.query({
      query: VoucherApi.GET_VOUCHER,
      variables: { _id: id },
    });
    if (res && res.data && res.data.voucher) {
      dispatch({
        type: GET_VOUCHER_BY_ID.SUCCESS,
        payload: res.data.voucher,
      });
    }
    dispatch(UIActions.appReady());
  } catch (err) {
    handleAxiosError(
      dispatch,
      err,
      "We are having problem getting discount code. Please try again later."
    );

    dispatch({
      type: GET_VOUCHER_BY_ID.FAIL,
    });
  }
};

export const createVoucher = data => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({
    type: CREATE_VOUCHER.AWAIT,
  });

  try {
    const res = await client.mutate({
      mutation: VoucherApi.CREATE_VOUCHER,
      variables: data,
    });
    dispatch({
      type: CREATE_VOUCHER.SUCCESS,
    });
    dispatch(UIActions.showSuccess("Discount code created successfully."));
    dispatch(UIActions.appReady());
    history.push(MANAGE_DISCOUNT_CODE_URL);
    return res.data;
  } catch (err) {
    handleAxiosError(dispatch, err, "Error creating discount code");
    dispatch({
      type: CREATE_VOUCHER.FAIL,
    });
  }
};

export const updateVoucher = data => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({
    type: UPDATE_VOUCHER.AWAIT,
  });

  try {
    const res = await client.mutate({
      mutation: VoucherApi.UPDATE_VOUCHER,
      variables: data,
    });
    dispatch({
      type: UPDATE_VOUCHER.SUCCESS,
      payload: res.data.updateVoucher,
    });
    dispatch(UIActions.showSuccess("Discount code updated successfully."));
    dispatch(UIActions.appReady());
    history.push(MANAGE_DISCOUNT_CODE_URL);
    return res.data;
  } catch (err) {
    handleAxiosError(dispatch, err, "Error updating discount code");
    dispatch({
      type: UPDATE_VOUCHER.FAIL,
    });
  }
};

export const removeVoucher = (
  id,
  permanent,
  shouldRefresh = true,
  byDeleted = false
) => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({
    type: REMOVE_VOUCHER.AWAIT,
  });

  try {
    await client.mutate({
      mutation: VoucherApi.REMOVE_VOUCHER,
      variables: { _id: id, permanent },
    });
    dispatch({
      type: REMOVE_VOUCHER.SUCCESS,
    });
    dispatch(UIActions.showSuccess("Discount code removed successfully."));
    dispatch(UIActions.appReady());
    if (shouldRefresh) {
      if (byDeleted) {
        dispatch(getVoucherList(DELETED_STATUS_FILTER));
      } else {
        dispatch(getVoucherList());
      }
    }

    return true;
  } catch (err) {
    handleAxiosError(dispatch, err, "Error removing discount code");
    dispatch({
      type: REMOVE_VOUCHER.FAIL,
    });
    return false;
  }
};

export const checkVoucher = data => async dispatch => {
  dispatch(UIActions.appLoading());
  dispatch({
    type: CHECK_VOUCHER.AWAIT,
  });

  try {
    const res = await client.query({
      query: VoucherApi.CHECK_VOUCHER,
      variables: data,
    });

    if (res && res.data) {
      dispatch({
        type: CHECK_VOUCHER.SUCCESS,
        payload:
          res.data && res.data.checkVoucher
            ? res.data.checkVoucher
            : { valid: false },
      });
    }
    dispatch(UIActions.appReady());
  } catch (err) {
    handleAxiosError(
      dispatch,
      err,
      "We are having problem getting discount code. Please try again later."
    );

    dispatch({
      type: CHECK_VOUCHER.FAIL,
      payload: { valid: false },
    });
  }
};

export const setVoucherAvailability = availability => ({
  type: SET_VOUCHER_AVAILABILITY,
  payload: availability,
});
