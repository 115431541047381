import React from "react";
import clsx from "clsx";
import ordinal from "ordinal";
import { makeStyles } from "@material-ui/core/styles";
import {
  LIST_ITEM_ACTIVE_COLOR,
  LIST_ITEM_MUTE_COLOR,
  YOUR_SCORE_RANK_COLOR,
  MEDIAN_SCORE_RANK_COLOR,
} from "../../styles/constants";

const useStyles = makeStyles(theme => ({
  statColumn: {
    display: "flex",
    flexDirection: "column",
  },
  statTitleRow: {
    color: LIST_ITEM_ACTIVE_COLOR,
    marginBottom: theme.spacing(1),
    fontSize: "16px",
  },
  statRow: {
    color: LIST_ITEM_MUTE_COLOR,
    display: "flex",
    justifyContent: "space-between",
    height: "40px",
    alignItems: "flex-end",
    paddingBottom: "8px",
    borderBottom: "1px solid #e7eaec",
    fontSize: "15px",
  },
  chip: {
    marginLeft: "auto",
    height: "21px",
    lineHeight: "21px",
    paddingLeft: "7px",
    paddingRight: "7px",
    borderRadius: "11px",
    backgroundColor: "#e8e8e8",
    color: "#4a4a4a",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 600,
  },
  circle: {
    height: "20px",
    width: "20px",
    borderRadius: "10px",
    marginRight: "15px",
  },
  greenCircle: { backgroundColor: YOUR_SCORE_RANK_COLOR },
  blueCircle: { backgroundColor: MEDIAN_SCORE_RANK_COLOR },
}));

const PercentileStatsColumn = ({
  yourScore,
  medianScore,
  avgTimeSpent,
  otherAvgTimeSpent,
  style,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.statColumn} style={style}>
      <div className={classes.statTitleRow}>{"Percentile Rank"}</div>

      <div className={classes.statRow}>
        <div className={clsx(classes.circle, classes.greenCircle)}></div>

        <div className={classes.subStatTitle}>
          {`Your Score (${ordinal(yourScore.rank)} rank)`}
        </div>

        <div className={classes.chip}>
          {`${yourScore.percentile.toFixed(0)}%`}
        </div>
      </div>
      <div className={classes.statRow}>
        <div className={clsx(classes.circle, classes.blueCircle)}></div>

        <div className={classes.subStatTitle}>
          Median Score ({`${ordinal(medianScore.rank)}`} rank)
        </div>

        <div className={classes.chip}>
          {`${medianScore.percentile.toFixed(0)}%`}
        </div>
      </div>
      <div className={classes.statRow}>
        <div className={classes.subStatTitle}>
          Your Average Time Spent (sec)
        </div>
        <div className={classes.chip}>{avgTimeSpent}</div>
      </div>
      <div className={classes.statRow}>
        <div className={classes.subStatTitle}>
          Other's Average Time Spent (sec)
        </div>
        <div className={classes.chip}>{otherAvgTimeSpent}</div>
      </div>
    </div>
  );
};

export default PercentileStatsColumn;
