import {
  Box,
  Button,
  MenuItem,
  TextField,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import * as UserAction from "../../../actions/userAction";
import {
  COURSE_APP_SUB_ROUTE,
  MANAGE_USER_URL,
} from "../../../routes/constants";
import { textFieldProps } from "../helper";
import { EMAIL_REGEX, USER_TYPE_LIST } from "../../../constants";
import {
  CAREER_STATUS_LIST,
  EDUCATIONAL_GROUP_LIST,
} from "../../../Components/Form/constants";
import { parseUserStatusHtml } from "../ManageUser/helper";
import EditPasswordSection from "./components/EditPasswordSection";
import {
  CustomCancelBtn,
  CustomConfirmDialogActions,
  CustomActionBtn,
} from "../../../Components/CustomDialogElements";
import ActivateUserSection from "./components/ActivateUserSection";
import AssignPlanSection from "./components/AssignPlanSection";
import PlanHistoriesTable from "../modal/Userinfo/components/PlanHistoriesTable";
import SectionTitle from "./components/SectionTitle";
import RegistrationSection from "../modal/Userinfo/components/RegistrationSection";
import PreviousTestListTable from "../modal/Userinfo/components/PreviousTestListTable";
import LoginHistoriesTable from "../modal/Userinfo/components/LoginHistoriesTable";
import BlockUserSection from "./components/BlockUserSection";
import ResetHistoriesTable from "../modal/Userinfo/components/ResetHistoriesTable";
import { getAllCountries, getCountryByCode, getStatesOfCountry } from "utils";

const UserForm = ({ match, history, getUserById, updateUser }) => {
  const [userInfo, setUserInfo] = useState(null);
  const { handleSubmit, control, reset, errors, watch, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      address1: "",
      address2: "",
      city: "",
      country: "",
      state: "",
      zipCode: "",
      phoneNo: "",
      educationalGroup: "",
      careerStatus: "",
      graduatingSchool: "",
      type: "",
      remark: "",
      ref: "",
      status: "",
    },
  });

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Handle Confirmtion Modal Start
  const handleOpenUpdateModal = () => {
    setShowUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
  };
  // Handle Confirmtion Modal End

  const watchCountry = watch("country");
  const COUNTRIES = useMemo(() => getAllCountries(), []);
  const STATES = useMemo(() => {
    const states = getStatesOfCountry(watchCountry);
    if (watchCountry) {
      if (Array.isArray(states) && states.length > 0) {
        return states;
      } else {
        const foundCountry = getCountryByCode(watchCountry);
        return foundCountry ? [foundCountry] : [];
      }
    } else return [];
  }, [watchCountry]);
  const userId = match.params.id;

  const fetchUser = async () => {
    if (!userId) return;
    const { user: userData } = await getUserById(userId);
    if (!userData) return history.push(`${COURSE_APP_SUB_ROUTE}/manage-user`);
    setUserInfo(userData);

    reset({
      ref: userData._ref,
      email: userData.email || "",
      firstName: userData.firstName,
      lastName: userData.lastName,
      phoneNo: userData.phoneNo,
      status: userData.status,
      address1: userData.address1 || "",
      address2: userData.address2 || "",
      city: userData.city || "",
      country: userData.country || "",
      state: userData.state || "",
      zipCode: userData.zipCode || "",
      educationalGroup: userData.educationalGroup || "",
      careerStatus: userData.careerStatus || "",
      graduatingSchool: userData.graduatingSchool || "",
      type: userData.type || "",
      remark: userData.remark || "",
    });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const onSubmitUpdate = async ({
    firstName,
    lastName,
    email,
    address1,
    address2,
    city,
    country,
    state,
    zipCode,
    phoneNo,
    educationalGroup,
    careerStatus,
    graduatingSchool,
    type,
    remark,
  }) => {
    const isEmailChanged = userInfo.email !== getValues("email");

    const data = {
      firstName,
      lastName,
      email: isEmailChanged ? email : undefined,
      address1,
      address2,
      city,
      country,
      state,
      zipCode,
      phoneNo,
      educationalGroup,
      careerStatus,
      graduatingSchool,
      type,
      remark,
    };
    if (userId) {
      const res = await updateUser({
        _id: userId,
        input: data,
      });
      if (!res) return;
    }
    history.push(MANAGE_USER_URL);
  };

  if (!userInfo) return null;

  return (
    <>
      <Dialog onClose={handleCloseUpdateModal} open={showUpdateModal}>
        <DialogContent>
          Are you sure you wish to update this user?
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomActionBtn onClick={handleSubmit(onSubmitUpdate)}>
            Update
          </CustomActionBtn>
          <CustomCancelBtn onClick={handleCloseUpdateModal}>
            Cancel
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>
      <Fragment>
        <div className="pageCont">
          <div className="bodyCont" style={{ overflow: "hidden" }}>
            <div className="pageAction">
              <div className="pageActionLeft">
                <Link to={MANAGE_USER_URL}>
                  <FontAwesomeIcon icon={faReply} />
                  Back to user list
                </Link>
              </div>
            </div>
            <div className="pageContent">
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <SectionTitle text="Update User" />
                </AccordionSummary>
                <AccordionDetails>
                  <form className="createUserForm">
                    <Controller
                      control={control}
                      name="ref"
                      render={({ onChange, _onBlur, value, name, ref }) => (
                        <TextField
                          name={name}
                          label="ID"
                          inputRef={ref}
                          value={value}
                          disabled
                          {...textFieldProps}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="firstName"
                      rules={{
                        required: "First name cannot be empty",
                      }}
                      render={({ onChange, _onBlur, value, name, ref }) => (
                        <TextField
                          name={name}
                          label="First Name"
                          inputRef={ref}
                          value={value}
                          onChange={e => onChange(e.target.value)}
                          error={!!errors.firstName}
                          helperText={
                            errors.firstName && errors.firstName.message
                          }
                          {...textFieldProps}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="lastName"
                      rules={{
                        required: "Last name cannot be empty",
                      }}
                      render={({ onChange, _onBlur, value, name, ref }) => (
                        <TextField
                          name={name}
                          label="Last Name"
                          inputRef={ref}
                          value={value}
                          onChange={e => onChange(e.target.value)}
                          error={!!errors.lastName}
                          helperText={
                            errors.lastName && errors.lastName.message
                          }
                          {...textFieldProps}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="email"
                      rules={{
                        required: "Email cannot be empty",
                        pattern: {
                          value: EMAIL_REGEX,
                          message: "Invalid Email Format",
                        },
                      }}
                      render={({ onChange, _onBlur, value, name, ref }) => (
                        <TextField
                          name={name}
                          label="Email"
                          inputRef={ref}
                          value={value}
                          onChange={e => onChange(e.target.value)}
                          error={!!errors.email}
                          helperText={errors.email && errors.email.message}
                          {...textFieldProps}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="address1"
                      rules={{
                        required: "Address 1 cannot be empty",
                      }}
                      render={({ onChange, _onBlur, value, name, ref }) => (
                        <TextField
                          name={name}
                          label="Address 1"
                          inputRef={ref}
                          value={value}
                          onChange={e => onChange(e.target.value)}
                          error={!!errors.address1}
                          helperText={
                            errors.address1 && errors.address1.message
                          }
                          {...textFieldProps}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="address2"
                      render={({ onChange, _onBlur, value, name, ref }) => (
                        <TextField
                          name={name}
                          label="Address 2"
                          inputRef={ref}
                          value={value}
                          onChange={e => onChange(e.target.value)}
                          error={!!errors.address2}
                          helperText={
                            errors.address2 && errors.address2.message
                          }
                          {...textFieldProps}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="city"
                      rules={{
                        required: "City cannot be empty",
                      }}
                      render={({ onChange, _onBlur, value, name, ref }) => (
                        <TextField
                          name={name}
                          label="City"
                          inputRef={ref}
                          value={value}
                          onChange={e => onChange(e.target.value)}
                          error={!!errors.city}
                          helperText={errors.city && errors.city.message}
                          {...textFieldProps}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="country"
                      rules={{
                        required: "Country cannot be empty",
                      }}
                      render={({ onChange, _onBlur, value, name, ref }) => (
                        <TextField
                          name={name}
                          label="Country"
                          inputRef={ref}
                          value={value}
                          onChange={e => onChange(e.target.value)}
                          error={!!errors.country}
                          helperText={errors.country && errors.country.message}
                          {...textFieldProps}
                          select
                        >
                          {COUNTRIES.map(country => (
                            <MenuItem
                              key={country.isoCode}
                              value={country.isoCode}
                            >
                              {country.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    <Controller
                      control={control}
                      name="state"
                      rules={{
                        required: "State cannot be empty",
                      }}
                      render={({ onChange, _onBlur, value, name, ref }) => (
                        <TextField
                          name={name}
                          label="State"
                          inputRef={ref}
                          value={value}
                          onChange={e => onChange(e.target.value)}
                          error={!!errors.state}
                          helperText={errors.state && errors.state.message}
                          {...textFieldProps}
                          select
                        >
                          <MenuItem aria-label="None" value="" />
                          {STATES.length > 0 &&
                            STATES.map(state => (
                              <MenuItem
                                key={state.isoCode}
                                value={state.isoCode}
                              >
                                {state.name}
                              </MenuItem>
                            ))}
                        </TextField>
                      )}
                    />
                    <Controller
                      control={control}
                      name="zipCode"
                      rules={{
                        required: "Zip Code cannot be empty",
                      }}
                      render={({ onChange, _onBlur, value, name, ref }) => (
                        <TextField
                          name={name}
                          label="Zip Code"
                          inputRef={ref}
                          value={value}
                          onChange={e => onChange(e.target.value)}
                          error={!!errors.zipCode}
                          helperText={errors.zipCode && errors.zipCode.message}
                          {...textFieldProps}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="phoneNo"
                      rules={{
                        required: "Phone Number cannot be empty",
                      }}
                      render={({ onChange, _onBlur, value, name, ref }) => (
                        <TextField
                          name={name}
                          label="Phone Number"
                          inputRef={ref}
                          value={value}
                          onChange={e => onChange(e.target.value)}
                          error={!!errors.phoneNo}
                          helperText={errors.phoneNo && errors.phoneNo.message}
                          {...textFieldProps}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="educationalGroup"
                      rules={{
                        required: "Group cannot be empty",
                      }}
                      render={({ onChange, _onBlur, value, name, ref }) => (
                        <TextField
                          name={name}
                          label="Select Group"
                          inputRef={ref}
                          value={value}
                          onChange={e => onChange(e.target.value)}
                          error={!!errors.educationalGroup}
                          helperText={
                            errors.educationalGroup &&
                            errors.educationalGroup.message
                          }
                          {...textFieldProps}
                          select
                        >
                          {EDUCATIONAL_GROUP_LIST.map(state => (
                            <MenuItem key={state.label} value={state.value}>
                              {state.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    <Controller
                      control={control}
                      name="careerStatus"
                      rules={{
                        required: "Career status cannot be empty",
                      }}
                      render={({ onChange, _onBlur, value, name, ref }) => (
                        <TextField
                          name={name}
                          label="Select Career Status"
                          inputRef={ref}
                          value={value}
                          onChange={e => onChange(e.target.value)}
                          error={!!errors.careerStatus}
                          helperText={
                            errors.careerStatus && errors.careerStatus.message
                          }
                          {...textFieldProps}
                          select
                        >
                          {CAREER_STATUS_LIST.map(state => (
                            <MenuItem key={state.label} value={state.value}>
                              {state.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    <Controller
                      control={control}
                      name="graduatingSchool"
                      rules={{
                        required: "Graduating School cannot be empty",
                      }}
                      render={({ onChange, _onBlur, value, name, ref }) => (
                        <TextField
                          name={name}
                          label="Graduating School"
                          inputRef={ref}
                          value={value}
                          onChange={e => onChange(e.target.value)}
                          error={!!errors.graduatingSchool}
                          helperText={
                            errors.graduatingSchool &&
                            errors.graduatingSchool.message
                          }
                          {...textFieldProps}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="status"
                      render={({ onChange, value, name, ref }) => (
                        <TextField
                          name={name}
                          value={parseUserStatusHtml(value)}
                          disabled
                          label="Status"
                          inputRef={ref}
                          {...textFieldProps}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="type"
                      rules={{
                        required: "Role cannot be empty",
                      }}
                      render={({ onChange, value, name, ref }) => (
                        <TextField
                          name={name}
                          value={value}
                          label="Role"
                          onChange={e => onChange(e.target.value)}
                          select
                          inputRef={ref}
                          {...textFieldProps}
                        >
                          {USER_TYPE_LIST.map(item => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    <Controller
                      control={control}
                      name="remark"
                      render={({ onChange, _onBlur, value, name, ref }) => (
                        <TextField
                          name={name}
                          label="Remark"
                          inputRef={ref}
                          value={value}
                          onChange={e => onChange(e.target.value)}
                          {...textFieldProps}
                        />
                      )}
                    />
                    <Box my={2}>
                      <Button
                        onClick={handleOpenUpdateModal}
                        color="secondary"
                        variant="contained"
                        className="submitButton"
                        size="large"
                      >
                        Update
                      </Button>
                    </Box>
                  </form>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <SectionTitle text="Change Password" />
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }}>
                  <EditPasswordSection user={userInfo} />
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <SectionTitle text="Verify User" />
                </AccordionSummary>
                <AccordionDetails>
                  <ActivateUserSection user={userInfo} />
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <SectionTitle text={"Plan History"} />
                </AccordionSummary>
                <AccordionDetails>
                  <PlanHistoriesTable user={userInfo} isEditing={true} />
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <SectionTitle text="Manual Assign Plan" />
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }}>
                  <AssignPlanSection user={userInfo} />
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <SectionTitle text="Registration" />
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }}>
                  <RegistrationSection user={userInfo} />
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel7"}
                onChange={handleChange("panel7")}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <SectionTitle text="Progress Details" />
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }}>
                  <PreviousTestListTable
                    userId={userInfo._id}
                    subscriptionId={
                      userInfo.subscription ? userInfo.subscription._id : null
                    }
                    isEditing={true}
                  />
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel8"}
                onChange={handleChange("panel8")}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <SectionTitle text="Logins" />
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }}>
                  <LoginHistoriesTable loginHistory={userInfo._loginHistory} />
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel9"}
                onChange={handleChange("panel9")}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <SectionTitle text="Block User" />
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }}>
                  <BlockUserSection user={userInfo} />
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel10"}
                onChange={handleChange("panel10")}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <SectionTitle text="Reset Count" />
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }}>
                  <ResetHistoriesTable user={userInfo} isEditing={true} />
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getUserById: id => dispatch(UserAction.getUserById(id)),
  updateUser: data => dispatch(UserAction.updateUser(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserForm)
);
