export const DRAWER_WIDTH = 290;
export const LIST_ITEM_MUTE_COLOR = "#8991a1";
export const LIST_ITEM_ACTIVE_COLOR = "#000000DE";
export const LIST_ITEM_ACTIVE_STYLE = {
  position: "relative",
  borderColor: "rgb(238, 242, 245)",
  borderStyle: "solid",
  borderWidth: "1px 0px",
  backgroundColor: "rgb(248, 250, 252) !important",
  "&:before": {
    borderLeft: `solid 4px rgb(123, 142, 157) !important`,
    content: "''",
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
  },
};
export const YOUR_SCORE_PIE_COLORS = ["#69c869", "#fad7d8", "#bfd1f2"];
export const QBank_USAGE_PIE_COLORS = ["#1e88e5", "#a8a8a8"];
export const YOUR_SCORE_RANK_COLOR = "#69c869";
export const MEDIAN_SCORE_RANK_COLOR = "#2196f3";
export const CONTENT_PADDING = theme => ({
  padding: theme.spacing(5),
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(3),
  },
});
export const CONTENT_VERTICAL_PADDING = theme => ({
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(7),
  [theme.breakpoints.down("xs")]: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
});

export const INPUT_UNDERLINE_STYLES = {
  "&:before": {
    borderBottom: "1px solid #e8e8e8",
  },
  "&:hover": {
    "&::before": {
      borderBottom: "1px solid #e8e8e8 !important",
    },
  },
};
export const LIGHTGREY_TEXT = {
  color: "#979797 !important",
  fontFamily: "metropolis",
};

export const THIN_SCROLLBAR_STYLING = {
  scrollbarWidth: "thin",
  scrollbarColor: "auto",
  "&::-webkit-scrollbar": {
    width: 5,
    height: 5,
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#cdcdcd",
  },
  "&::-webkit-scrollbar-track": {
    background: "none",
  },
};

export const FLASHCARD_CONT_STYLING = {
  width: 150,
  height: 200,
  borderRadius: 2,
  boxShadow: "0 2px 8px 0 rgb(0 0 0 / 25%)",
  color: "#686a6c",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  cursor: "pointer",
  wordBreak: "break-all",
  position: "relative",
  "& .MuiSvgIcon-root": {
    display: "none",
  },
  "&:hover": {
    "& .MuiSvgIcon-root": {
      display: "block",
      cursor: "pointer",
      position: "absolute",
      top: "2%",
      right: "2%",
      color: "rgba(0,0,0,.38)",
    },
  },
};

export const FLASHCARD_CONTENT_STYLING = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  overflow: "hidden",
  padding: "24px 16px",
};

export const PAYMENY_SECTION_BUTTON_STYLING = {
  height: 58,
  fontSize: "1.25rem",
  textTransform: "none",
};

export const TH_ICON_STYLING = {
  fontSize: 18,
  marginLeft: 4,
};

export const PRIMARY_ACTIVE_LINK_STYLING = {
  color: "#000000",
  textDecoration: "underline",
};

// Test Detail Page theme color
export const LIGHT_PRIMARY_COLOR = "#3852a4";
export const LIGHT_SECONDARY_COLOR =
  "linear-gradient(180deg,#FCFCFC 0,#D7DCED 100%)";
export const LIGHT_TERTIARY_COLOR = "#d7dced";
export const LIGHT_FONT_COLOR = "#000";
export const LIGHT_DIVIDER_COLOR = "#eee";
export const LIGHT_TOOL_TEXT_COLOR = "#737373";

export const DARK_PRIMARY_COLOR = "#141414";
export const DARK_SECONDARY_COLOR = "#303030";
export const DARK_TERTIARY_COLOR = "rgba(255, 255, 255, 0.08)";
export const DARK_FOURTH_COLOR = "#1e1e1e";
export const DARK_FIFTH_COLOR = "#2a2a2a";
export const DARK_DIVIDER_COLOR = "rgba(255, 255, 255, 0.12)";

//COLORS
export const COLOR_PRIMARY = "#F15A24";
export const COLOR_WHITE = "#fff";
export const COLOR_BLACK = "#000000";
export const COLOR_GREY = "#00000061";
export const SUCCESS_COLOR = "#66b847";
export const ERROR_COLOR = "#f44336";
