import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { CustomMenu, CustomMenuItem } from "../MenuElements";

const useStyles = makeStyles(theme => ({
  centerContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  showText: {
    paddingRight: 20,
    color: "#979797",
    fontSize: 15,
  },
  select: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #e8e8e8",
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    cursor: "pointer",
    color: "#757575",
  },
  formControlLabelRoot: {
    width: "100%",
  },
  labelTxt: {
    fontFamily: "metropolis",
    color: "#757575",
    fontSize: "14px",
  },
}));

const FilterMenu = props => {
  const {
    handleOpenMenu,
    handleCloseMenu,
    anchorEl,
    menuItemArr,
    text,
    selectedFilter,
    handleSelectedFilter,
    filterType = "OBJECT",
    align,
    displayShowText = true,
    showText = "Show",
    showCheckBox = true,
  } = props;
  const classes = useStyles();

  const handleMenuItemClick = name => {
    handleSelectedFilter(name);
    handleCloseMenu();
  };

  return (
    <Fragment>
      <div
        className={classes.centerContainer}
        style={{ marginLeft: align === "right" ? "auto" : "" }}
      >
        {displayShowText && <div className={classes.showText}>{showText}:</div>}

        <div className={classes.select} onClick={handleOpenMenu}>
          <span>{text}</span>
          <ArrowDropDownIcon />
        </div>
      </div>

      <CustomMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {menuItemArr.map(bar => {
          return (
            <CustomMenuItem
              key={bar.name}
              onClick={
                showCheckBox ? null : () => handleMenuItemClick(bar.name)
              }
              selected={
                filterType === "STRING" ? selectedFilter === bar.name : null
              }
            >
              {showCheckBox ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        filterType === "ARRAY"
                          ? selectedFilter.includes(bar.name)
                          : selectedFilter[bar.name]
                      }
                      onChange={handleSelectedFilter}
                      name={bar.name}
                      color="secondary"
                      disableRipple={true}
                    />
                  }
                  label={bar.label}
                  classes={{
                    root: classes.formControlLabelRoot,
                    label: classes.labelTxt,
                  }}
                />
              ) : (
                <span className={classes.labelTxt}>{bar.label}</span>
              )}
            </CustomMenuItem>
          );
        })}
      </CustomMenu>
    </Fragment>
  );
};

export default FilterMenu;
