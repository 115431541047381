import { useLocation } from "react-router";
import queryString from "query-string";
const SEPARATOR = "/";

const usePath = () => {
  const location = useLocation();
  const { pathname, search, hash } = location;
  const [, rootPageId] = pathname.split(SEPARATOR);

  return {
    rootPageId,
    fullPath: pathname,
    queryParams: search ? queryString.parse(search) : null,
    hash,
  };
};

export default usePath;
