import React from "react";
import { Box } from "@material-ui/core";
import { TRANSACTION_SOURCE, TRANSACTION_STATUS } from "../../../constants";
import {
  COLOR_BLACK,
  COLOR_GREY,
  ERROR_COLOR,
  SUCCESS_COLOR,
} from "../../../styles/constants";
import { capitalizeFirstLetter } from "../../../utils";

export const parsePaymentHistoryStatus = (status, source) => {
  let text = capitalizeFirstLetter(status);
  let color;
  if (source === TRANSACTION_SOURCE.MANUAL) {
    text = "Manual";
    color = COLOR_GREY;
  } else {
    switch (status) {
      case TRANSACTION_STATUS.COMPLETED:
        color = SUCCESS_COLOR;
        break;
      case TRANSACTION_STATUS.REFUNDED:
        color = ERROR_COLOR;
        break;
      case TRANSACTION_STATUS.PENDING:
        color = COLOR_GREY;
        break;
      default:
        color = COLOR_BLACK;
    }
  }

  return <Box color={color}>{text}</Box>;
};
