import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import ShowMoreText from "react-show-more-text";
import MUIDataTable from "mui-datatables";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import * as FeedbackActions from "../../actions/feedbackAction";
import {
  CustomConfirmDialogActions,
  CustomActionBtn,
  CustomCancelBtn,
} from "../../Components/CustomDialogElements";
import {
  CustomDeleteIcon,
  CustomResolveIcon,
  CustomUnresolveIcon,
} from "../../Components/CustomActionIcons";
import { parseDateHtml } from "../../utils";

// constants
const UPDATE_FEEDBACK_TYPE = {
  RESOLVE: "Resolve",
  UNRESOLVE: "Unresolve",
};

const ManageFeedback = ({
  getFeedbacks,
  resolveFeedback,
  unresolveFeedback,
  removeFeedback,
}) => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [showResolveModal, setShowResolveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Handle Confirmtion Modal Start
  const handleOpenDeleteModal = event => {
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleOpenResolveModal = () => {
    setShowResolveModal(true);
  };

  const handleCloseResolveModal = () => {
    setShowResolveModal(false);
  };
  // Handle Confirmtion Modal End

  const fetchFeedbackData = async () => {
    const res = await getFeedbacks();
    if (res && res.feedbacks)
      setFeedbacks(sortFeedbacksByDateAndResolvedStatus(res.feedbacks));
  };

  const onRemoveFeedback = async () => {
    const selectedID = selectedFeedback._id;
    const res = await removeFeedback(selectedID);
    if (res) {
      setFeedbacks(
        sortFeedbacksByDateAndResolvedStatus(
          feedbacks.filter(feedback => feedback._id !== selectedID)
        )
      );
    }
    handleCloseDeleteModal();
  };

  const onUpdateFeedback = async type => {
    let res;
    if (type === UPDATE_FEEDBACK_TYPE["RESOLVE"]) {
      res = await resolveFeedback(selectedFeedback._id);
    } else {
      res = await unresolveFeedback(selectedFeedback._id);
    }

    if (res && res.updateFeedback) {
      setFeedbacks(
        sortFeedbacksByDateAndResolvedStatus(
          feedbacks.map(feedback =>
            feedback._id === res.updateFeedback._id
              ? res.updateFeedback
              : feedback
          )
        )
      );
    }
    handleCloseResolveModal();
  };

  const sortFeedbacksByDateAndResolvedStatus = feedbacks => {
    return feedbacks
      ? [...feedbacks].sort((a, b) => {
          // sort by resolved status (Open > Closed) then followed by date created in descending order
          const resolvedStatusB = b.resolved ? "Closed" : "Open";
          const resolvedStatusA = a.resolved ? "Closed" : "Open";
          if (resolvedStatusA === resolvedStatusB) {
            return a._createdOn < b._createdOn ? 1 : -1;
          } else {
            return resolvedStatusB === "Open" ? 1 : -1;
          }
        })
      : [];
  };

  useEffect(() => {
    fetchFeedbackData();
  }, []);

  return (
    <Fragment>
      {/* Delete Feedback Confirmation Modal */}
      <Dialog onClose={handleCloseDeleteModal} open={showDeleteModal}>
        <DialogContent>
          Are you sure you wish to delete this feedback?
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomActionBtn onClick={onRemoveFeedback}>Delete</CustomActionBtn>
          <CustomCancelBtn onClick={handleCloseDeleteModal}>
            Cancel
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>

      {/* Resolve/Unresolve Feedback Confirmation Modal */}
      <Dialog onClose={handleCloseResolveModal} open={showResolveModal}>
        <DialogContent>
          {`Are you sure you wish to ${
            selectedFeedback && selectedFeedback.resolved
              ? "unresolve"
              : "resolve"
          } this feedback?`}
        </DialogContent>
        <CustomConfirmDialogActions>
          <CustomActionBtn
            onClick={() => {
              onUpdateFeedback(
                selectedFeedback.resolved
                  ? UPDATE_FEEDBACK_TYPE["UNRESOLVE"]
                  : UPDATE_FEEDBACK_TYPE["RESOLVE"]
              );
            }}
          >
            {`${
              selectedFeedback && selectedFeedback.resolved
                ? UPDATE_FEEDBACK_TYPE["UNRESOLVE"]
                : UPDATE_FEEDBACK_TYPE["RESOLVE"]
            }`}
          </CustomActionBtn>
          <CustomCancelBtn onClick={handleCloseResolveModal}>
            Cancel
          </CustomCancelBtn>
        </CustomConfirmDialogActions>
      </Dialog>

      <div className="pageCont manageFeedbackPage">
        <div className="bodyCont">
          <div className="pageContent">
            <MUIDataTable
              data={feedbacks.map(feedback => [
                feedback.question ? feedback.question._ref : "",
                feedback.content,
                feedback.question ? feedback.question.question : "",
                feedback.creator
                  ? `${
                      feedback.creator.firstName
                        ? `${feedback.creator.firstName} `
                        : ""
                    }${
                      feedback.creator.lastName
                        ? `${feedback.creator.lastName}`
                        : ""
                    }`
                  : "",
                feedback.resolved ? "Closed" : "Open",
                feedback._createdOn,
                feedback.technicalIssue ? "Yes" : "No",
              ])}
              columns={[
                {
                  name: "Question ID",
                  options: {
                    setCellHeaderProps: () => ({
                      className: "globalCustomMuiTableHead",
                    }),
                  },
                },
                {
                  name: "Feedback",
                  options: {
                    customBodyRender: value => {
                      return (
                        <div style={{ width: 175, wordBreak: "break-word" }}>
                          {value}
                        </div>
                      );
                    },
                  },
                },
                {
                  name: "Question",
                  options: {
                    customBodyRender: value => {
                      const parsedHTML = ReactHtmlParser(value, {
                        transform: node => {
                          if (node.name === "img") {
                            const { src } = node.attribs;
                            return (
                              <a href={src} key={src} target="_blank">
                                {src}
                              </a>
                            );
                          }
                        },
                      });
                      return (
                        <div style={{ width: 175, wordBreak: "break-word" }}>
                          <ShowMoreText>{parsedHTML}</ShowMoreText>
                        </div>
                      );
                    },
                  },
                },
                "User",
                "Resolved",
                {
                  name: "Date Created",
                  options: {
                    customBodyRenderLite: dataIndex => {
                      let val = feedbacks[dataIndex]._createdOn;
                      return parseDateHtml(val);
                    },
                    setCellHeaderProps: () => ({
                      className: "globalCustomMuiTableHead",
                    }),
                  },
                },
                "Technical",
                {
                  name: "Action",
                  options: {
                    customBodyRenderLite: dataIndex => {
                      const currentFeedback = feedbacks[dataIndex];

                      return (
                        <div style={{ display: "flex" }}>
                          <div
                            onClick={() => {
                              setSelectedFeedback(currentFeedback);
                              handleOpenResolveModal();
                            }}
                          >
                            {currentFeedback.resolved ? (
                              <CustomUnresolveIcon />
                            ) : (
                              <CustomResolveIcon />
                            )}
                          </div>
                          <CustomDeleteIcon
                            onClickHandler={() => {
                              setSelectedFeedback(currentFeedback);
                              handleOpenDeleteModal();
                            }}
                            style={{ marginLeft: 8 }}
                          />
                        </div>
                      );
                    },
                  },
                },
              ]}
              options={{
                download: false,
                print: false,
                sort: false,
                selectableRows: "none",
                rowHover: false,
                setRowProps: (row, dataIndex, rowIndex) => {
                  const currentFeedback = feedbacks[dataIndex];

                  return {
                    style: {
                      backgroundColor: currentFeedback.resolved
                        ? "#fff"
                        : "rgba(0, 0, 0, 0.04)",
                    },
                  };
                },
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getFeedbacks: () => dispatch(FeedbackActions.getFeedbacks()),
  resolveFeedback: id => dispatch(FeedbackActions.resolveFeedback(id)),
  unresolveFeedback: id => dispatch(FeedbackActions.unresolveFeedback(id)),
  removeFeedback: id => dispatch(FeedbackActions.removeFeedback(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageFeedback)
);
