import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import SearchIcon from "@material-ui/icons/Search";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import { useForm, Controller } from "react-hook-form/dist/index.ie11";

import * as ExamActions from "../../actions/examAction";
import styles from "./styles";
import { headCells } from "./headCellsArr";
import {
  CustomTableCell,
  CustomTableSortLabel,
} from "../../Components/CustomTableElements";
import {
  CustomDialog,
  CustomDialogTitle,
  CustomDialogContent,
} from "../../Components/CustomDialogElements";
import { sortQuestion } from "./questionsDataParser";
import QuestionExplanation from "../../Components/QuestionExplanation";

const useStyles = makeStyles(theme => styles(theme));

const SearchQuestionPage = props => {
  const { getQuestionList } = props;
  const classes = useStyles();
  const {
    handleSubmit,
    control,

    errors,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      searchField: "",
    },
  });
  const [questionsData, setQuestionsData] = useState([]);
  const [isAsc, setIsAsc] = useState(true);
  const [orderBy, setOrderBy] = useState("id");
  const [modalState, setModalState] = useState({ id: "" });

  // Handle Modal Start
  const handleOpenModal = id => {
    setModalState({ id });
  };
  const handleCloseModal = () => {
    setModalState({ id: "" });
  };
  // Handle Modal End

  const onSort = value => {
    if (orderBy === value) {
      setIsAsc(!isAsc);
    } else {
      setOrderBy(value);
    }
  };

  const onSubmitSearch = async ({ searchField }) => {
    const filter = {
      key: "_ref",
      value: searchField,
    };

    const { questions } = await getQuestionList(filter);
    if (questions) setQuestionsData(questions);
  };

  const sortedQuestions = sortQuestion(questionsData, isAsc, orderBy);

  return (
    <Fragment>
      <Paper elevation={0} className={classes.searchFieldContainer}>
        <form onSubmit={handleSubmit(onSubmitSearch)}>
          <Controller
            control={control}
            name={"searchField"}
            rules={{
              required: "Field cannot be empty",
              pattern: {
                value: /^\d+$/,
                message: "Field must be number",
              },
            }}
            render={({ onChange, value, name, ref }) => {
              return (
                <div className={classes.searchInputContainer}>
                  <TextField
                    name={name}
                    label="Enter Question Id"
                    onChange={e => onChange(e.target.value)}
                    inputRef={ref}
                    value={value}
                    error={!!errors.searchField}
                    helperText={
                      errors.searchField && errors.searchField.message
                    }
                    InputProps={{
                      style: { fontSize: 15 },
                      className: classes.searchInput,
                    }}
                    InputLabelProps={{
                      style: { fontSize: 15 },
                    }}
                    FormHelperTextProps={{
                      style: { fontSize: 13 },
                    }}
                    fullWidth
                    color="secondary"
                  />
                </div>
              );
            }}
          />
        </form>
        <Tooltip title="Search">
          <SearchIcon
            color="secondary"
            className={classes.searchIcon}
            onClick={handleSubmit(onSubmitSearch)}
          />
        </Tooltip>

        <Tooltip
          title="Search from the questions you have already used by entering the question ID"
          classes={{ tooltip: classes.customTooltipWidth }}
        >
          <InfoRoundedIcon className={classes.infoIcon} />
        </Tooltip>
      </Paper>
      {sortedQuestions && sortedQuestions.length > 0 && (
        <Paper elevation={0} className={classes.searchResultContainer}>
          <div className={classes.searchResultHeader}>
            Total results found: {sortedQuestions.length}
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map(headCell => {
                    return (
                      <CustomTableCell
                        key={headCell.id}
                        padding={"normal"}
                        sortDirection={isAsc ? "asc" : "desc"}
                      >
                        {headCell.id !== "actions" ? (
                          <CustomTableSortLabel
                            active={orderBy === headCell.id}
                            direction={isAsc ? "asc" : "desc"}
                            onClick={() => onSort(headCell.id)}
                          >
                            {headCell.label}
                          </CustomTableSortLabel>
                        ) : (
                          <div className={classes.lightGreyText}>
                            {headCell.label}
                          </div>
                        )}
                      </CustomTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedQuestions.map(question => {
                  const validAns = question.answers.filter(answer => {
                    if (answer.validAnswer) return answer;
                  });
                  const correctOthersPercentage = validAns[0]["percentage"]
                    ? validAns[0]["percentage"]
                    : 0;

                  return (
                    <Fragment key={question._id}>
                      <TableRow>
                        <CustomTableCell style={{ width: "12%" }}>
                          {question._ref}
                        </CustomTableCell>
                        <CustomTableCell style={{ width: "26%" }}>
                          {question.category.name}
                        </CustomTableCell>
                        <CustomTableCell style={{ width: "22%" }}>
                          {question.subCategory.name}
                        </CustomTableCell>
                        <CustomTableCell style={{ width: "25%" }}>
                          {`${correctOthersPercentage.toFixed(0)}%`}
                        </CustomTableCell>
                        <CustomTableCell style={{ width: "15%" }}>
                          <span
                            className={classes.viewText}
                            onClick={() => handleOpenModal(question._id)}
                          >
                            View
                          </span>
                        </CustomTableCell>
                      </TableRow>
                      <CustomDialog
                        onClose={handleCloseModal}
                        open={modalState.id === question._id}
                      >
                        <CustomDialogTitle onClose={handleCloseModal}>
                          Question ID: {question._ref}
                        </CustomDialogTitle>
                        <CustomDialogContent>
                          <QuestionExplanation
                            question={question}
                            disabledImagePreview={true}
                          />
                        </CustomDialogContent>
                      </CustomDialog>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Fragment>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  getQuestionList: filter => dispatch(ExamActions.getQuestionList(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchQuestionPage);
